import React, { useEffect, useState } from "react";
import "../floorplans.css";
import { getPropertyDetails } from "../../../Api/PropertyAddApi";
import { ApiBaseUrl } from "../../../Utils/urls";
import FloatingLoader from "../../Loader/FloatingLoader";
import SuccessAlert from "../../Alert/SuccessAlert";
import ErrorAlert from "../../Alert/ErrorAlert";

import EditFloorPlanCard from "./EditFloorPlanCard";
import { useParams } from "react-router-dom";
import CustomButton from "../../Common/CustomeButton";

export default function EditFloorPlans(props) {
  const accessToken = localStorage.getItem("accesstoken");
  const adminId = localStorage.getItem("adminId");
  const { id: propertyId } = useParams();
  const [floorPlans, setFloorPlans] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");

  const [isFloorPlanAdded, setFloorPlanadded] = useState(false);
  const [isFloorPlErr, setFloorPlErr] = useState(false);
  const { MintedToken } = props;

  const fetchPropDetails = () => {
    getPropertyDetails(
      undefined,
      propertyId,
      adminId,
      accessToken,
      undefined,
      undefined,
      undefined,
      setFloorPlans,
      undefined,
      undefined,
      undefined
    );
  };
  useEffect(() => {
    if (propertyId) {
      fetchPropDetails();
    }
  }, []);

  const handleAddFloorPlan = () => {
    setFloorPlans([
      ...floorPlans,
      { title: "", value: "", description: "", floor_image: {} }
    ]);
  };

  const handleFloorPlanChange = (index, field, value) => {
    const newFloorPlans = [...floorPlans];
    newFloorPlans[index][field] = value;
    setFloorPlans(newFloorPlans);
  };

  const [propImgErr, setPropImgErr] = useState("");

  const handlePropImageChange = (e, index) => {
    const file = e.target.files[0];

    if (file) {
      const fileTypeParts = file.type.split("/");
      const fileExtension = fileTypeParts.length === 2 ? fileTypeParts[1] : "";
      const maxSize = 5 * 1024 * 1024;

      if (file.type !== "image/jpeg" && file.type !== "image/png") {
        setPropImgErr("Only JPG or PNG formats are allowed");
      } else {
        setPropImgErr("");
        const reader = new FileReader();
        reader.onload = (event) => {
          const base64String = event.target.result;

          const newFloorPlans = [...floorPlans];
          newFloorPlans[index].floor_image = {
            extension: fileExtension,
            file_type: file.type,
            base64: base64String
          };
          setFloorPlans(newFloorPlans);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  //image upload
  const handleUploadFloorPlanImages = () => {
    let base64Array = floorPlans.map((el) => el.floor_image);
    setLoading(true);
    fetch(`http://43.205.189.98:3000/image/upload`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken
      },
      body: JSON.stringify({
        admin_id: adminId,
        folder: "floor_plan",
        property_id: propertyId,
        images: base64Array
      })
    })
      .then((response) => {
        return response.json();
      })
      .then((resdata) => {
        //console.log('Response from server:', resdata);

        if (resdata.success) {
          let floorPlansFormatted = floorPlans.map((item, index) => {
            return {
              ...item,
              floor_image: resdata.data[index]
            };
          });

          if (floorPlans.length > 0) {
            handleAddUpdateFlooplan(floorPlansFormatted);
          }
        } else if (!resdata.success) {
        }
      })
      .catch((error) => {
        console.error("error details:", error);
      });
  };

  const handleDeleteFloorPlan = (index) => {
    const updatedFloorPlans = floorPlans.filter((_, i) => i !== index);
    setFloorPlans(updatedFloorPlans);
  };

  const isAllFieldsFilled = floorPlans?.every(
    (el) =>
      el.title &&
      el.value &&
      (el.floor_image?.base64 || el.floor_image?.Key) &&
      el.description
  );

  const handleAddUpdateFlooplan = (floorPlans) => {
    fetch(`${ApiBaseUrl}/fr-admin/update-property-floor-plan`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken
      },
      body: JSON.stringify({
        admin_id: adminId,
        property_id: propertyId,
        floor_plan: floorPlans
      })
    })
      .then((response) => {
        return response.json();
      })
      .then((resdata) => {
        //console.log('Response from server:', resdata);
        setLoading(false);
        if (resdata.success) {
          setFloorPlanadded(true);
          setAlertMsg(resdata.data);
          fetchPropDetails();

          setTimeout(() => {
            setFloorPlanadded(false);
            setAlertMsg("");
          }, 3000);
        } else if (!resdata.success) {
          setFloorPlErr(true);
          setAlertMsg(resdata.data);
          setTimeout(() => {
            setFloorPlErr(false);
            setAlertMsg("");
          }, 3000);
        }
      })
      .catch((error) => {
        console.error("error details:", error);
      });
  };

  return (
    <>
      {/* image popup  */}

      <div className="flex flex-col gap-3 relative">
        {isLoading && <FloatingLoader />}
        {isFloorPlanAdded && <SuccessAlert message={alertMsg} />}

        {isFloorPlErr && <ErrorAlert message={alertMsg} />}
        <div className="flex justify-between items-center">
          <p className="text-lg font-medium text-black">
            Floor Plans
            <span style={{ color: "#bc2222" }}>*</span>
          </p>

          <div className="flex gap-5 items-center">
            <button
              className="propSaveBtn bg-pink text-white"
              onClick={handleAddFloorPlan}
            >
              Add Floor Plan
            </button>

            <CustomButton
              onClick={handleUploadFloorPlanImages}
              isDisabled={MintedToken > 0 || !isAllFieldsFilled}
              buttonText={"Save Details"}
              disabledMessage="Editing is no longer available. The token has already been minted"
              className="propSaveBtn"
              position="top"
              MintedToken={MintedToken}
            />
          </div>
        </div>

        <div className="flex gap-4 overflow-x-auto whitespace-nowrap">
          {floorPlans &&
            floorPlans.map((plan, index) => (
              <EditFloorPlanCard
                key={index}
                index={index}
                plan={plan}
                handleFloorPlanChange={handleFloorPlanChange}
                handlePropImageChange={handlePropImageChange}
                propImgErr={propImgErr}
                handleDeleteFloorPlan={handleDeleteFloorPlan}
              />
            ))}
        </div>
      </div>
    </>
  );
}
