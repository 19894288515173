import React, { useEffect, useState } from 'react'
import { CiEdit } from 'react-icons/ci'
import { FaCircleCheck } from 'react-icons/fa6'
import { PropertImageBaseUrl } from '../../../Utils/urls'
import { MdCheck, MdContentCopy, MdOutlineCameraAlt, MdOutlineErrorOutline } from 'react-icons/md'
import Switch from 'react-switch'; 
import { countries } from "countries-list"
import ReactFlagsSelect from 'react-flags-select'
import "./admin-view.css"
import profileAvatar from "../../../Assets/Icons/profile_avatar.svg"
import { changeDateFormatAdmin } from '../../../Utils/dateFormat'
import { useParams } from 'react-router-dom'

import { HiOutlineEye, HiOutlineEyeOff } from 'react-icons/hi'


export default function ViewAdminDetails(
  {adminData,
    handleInputChange,
    fileError,
    handleProfilePicChange,
    setAdminData,
    setSelected,
    selected,
    customLabels,
    selectedProfilePic,
    adminAccess,
    isLoading,

    isLengthValid,
    hasSymbolOrNumber,
    hasUpperCase,
    changeConfirmPass ,
confirmpassword,
isConfirmPassCorrect,
passwordMismatch
  
  }) {

    const {id:staffId}=useParams()
    const [activateAdminChecked, setActivateAdminChecked] = useState(adminData.profile?.active);
  
 
    const [copyPassword, setcopyPassword] = useState(false);
  
   
   
    

const handleActivateAccnt = (checked) => {
  setActivateAdminChecked(checked);
  setAdminData(prevState => ({
    ...prevState,
    profile: {
        ...prevState.profile,
        active: checked
    }
}));
}


const [isSuperAdmin,setIsSuperAdmin]=useState(false)



useEffect(() => {
  const permissions = adminData.profile?.permissions?.add_admin;
  setIsSuperAdmin(permissions && Object.values(permissions).every(value => value === 1));
}, [adminData]);




 

const handleCopyPassword = () => {
  navigator.clipboard.writeText(adminData.profile?.password || '')
    .then(() => {
      setcopyPassword(true);
      setTimeout(() => setcopyPassword(false), 2000); 
    })
    .catch(err => {
      console.error('Failed to copy password: ', err);
    });
};







  return (
    <div className='propCard'>

    {/* sec-1 */}
    
      <div className='flex justify-between items-center'>
        <p className='text-2xl font-medium text-gray'>{isSuperAdmin?'Super Admin':'Sub Admin'}</p>
        {/* <button className='propSaveBtn bg-lightgray text-gray'>Save</button> */}
      </div>
    
    {/* sec-2 */}
      <div className='flex justify-between'>
    
        <div className='flex flex-col gap-4 adminDetails'>
    
    <div className='flex justify-between items-center'>


<div  className='flex gap-7'>
<div className='flex gap-2 py-3  items-center'>
<span className="material-symbols-outlined">
    admin_panel_settings
    </span>
             <p className='text-gray text-lg font-medium'>Admin ID {staffId && staffId}</p>
          </div>

         
</div>
  




    </div>

          
    
          <div className='flex justify-between'>

              <div className='flex flex-col gap-2.5 items-start adminInputText'>
                  <p className='text-sm text-blue'>User Name <span style={{color:"#af2525"}}>*</span> </p>
                  <input type="text" 
                  name="name" 
               
                  placeholder='E.g John Deo'
                  data-section="profile"
                  className={`propDisableInputText `}
                  disabled
                  value={adminData.profile?.name}
                  onChange={handleInputChange}
                   />
              </div>
    
  
           


       

       <div className='flex flex-col gap-2.5 items-start adminInputText'>
                  <p className='text-sm text-blue'>Mobile <span style={{color:"#af2525"}}>*</span> </p>

               <div className='flex gap-2.5  w-full'>

               <ReactFlagsSelect

selectButtonClassName="menu-flags-button "
className="menu-flags"
    selected={selected}
    onSelect={(code) => setSelected(code)}
    searchable
    searchPlaceholder="Search countries"
    showSelectedLabel={false}
    customLabels={customLabels}
    showSecondarySelectedLabel={false}

  />
 <input type="text" 
                  name="mobile" 
             
                  placeholder='E.g 1234556890'
                  data-section="profile"
                  value={adminData.profile?.mobile}
                  onChange={handleInputChange}
                  maxLength={10}
                  className={`propDisableInputText`}
                  disabled
                   />


                </div> 
                
                
                
                 
            

</div>



    
</div>
    
    
<div className='flex justify-between'>


<div className='flex flex-col gap-2.5 items-start adminInputText'>
                  <p className='text-sm text-blue'>Email <span style={{color:"#af2525"}}>*</span> </p>
                  <input type="email" 
                  name="email" 
                  className={`propDisableInputText`}
                  disabled
                  placeholder='E.g JohnDeo@gmail.com  '
                  data-section="profile"
                  value={adminData.profile?.email}
                  onChange={handleInputChange}
                   />
              </div>
    


  



    <div className='flex flex-col gap-2.5 items-start adminInputText'>
                  <p className='text-sm text-blue'>Gender </p>
                 <select name="gender" 
                  value={adminData.profile?.gender} 
                  onChange={handleInputChange} 
                  className={`propDisableInputText`}
                  disabled
                   data-section="profile">

                  <option disabled selected>Select</option>
                  <option value="Female" >Female</option>
                  <option value="Male" >Male</option>
                  <option value="Other" >Other</option>
                 </select>
              </div>
    
</div>
    

<div className='flex justify-between'>

<div className='flex flex-col gap-2.5 items-start adminInputText'>
                  <p className='text-sm text-blue'>Date  of Birth</p>
                  <input type="date" 
                  name="dob" 
                  data-section="profile"
                
                  value={adminData && adminData.profile ? changeDateFormatAdmin(adminData.profile.dob) : ""}
                  onChange={handleInputChange}
                  className={`propDisableInputText`}
                  disabled
                  placeholder='01/02/2004'
    
                   />
              </div>

<div className='flex flex-col gap-2.5 items-start adminInputText relative'>
{
  copyPassword && (
<div className='passwordCopyText'>
        <p>Password is copied</p>
      </div>
  )
}

                  <p className='text-sm text-blue'>Password <span style={{color:"#af2525"}}>*</span> </p>
                  <div className='relative w-full'>
      <input
        type={"text"}
        name="password"
        className='propDisableInputText'
        placeholder='E.g Shubhankar456#'
        data-section="profile"
        value={adminData.profile?.password}
         onChange={handleInputChange}
         disabled
      />
    

     <div className='absolute right-2 top-3 cursor-pointer' 
    >
        {copyPassword ? <MdCheck   className='text-pink' /> : <MdContentCopy className='text-pink' onClick={handleCopyPassword}/>}
      </div> 

    </div>
  
  

              </div>
    

             

  
    
</div>
    






   <div className='flex justify-between'>
 

    
</div> 
    



        </div>
    
    
    {/* prop image main */}
        <div className='propImage flex flex-col gap-5'> 
        
        <div className='photoContainer'>

        <div class="profilepic-container">



{/* {adminData.profile?.profile_pic?.base64 ? (
                <img src={adminData.profile?.profile_pic?.base64} alt="Avatar" className="profilePic" />
              ) : (
                <img src={profileAvatar} alt=""  className="ProfileAvatar" />
             
              )} */}

{
      adminData.profile?.profile_pic?.base64 ? (
        <img src={adminData.profile?.profile_pic?.base64} alt="Avatar" className="profilePic" />
      ) : (
        adminData.profile?.profile_pic?.Key ? (
          <img className="profilePic" src={`${PropertImageBaseUrl}/${adminData.profile?.profile_pic?.Key}`} alt="Avatar" />
        ) : (
          <img className="ProfileAvatar" src={profileAvatar} alt="Avatar" />
        )
      )
    }        


              <input
                type="file"
                className="profileImgFile"
        
              
                name="profile_pic"
                id="file-upload"
                accept="image/jpeg,image/png"
                onChange={handleProfilePicChange}
                disabled
              /> 
 





</div>

<label for="file-upload" class="upload-icon">
        <MdOutlineCameraAlt />
    </label>


    
</div>
    

<div>
{fileError && <p style={{ color: 'red',fontSize:"12px" }}>{fileError}</p>}
</div>
 
    
    
    {/* property-activation toggle */}
    
    <div className='flex items-center justify-center gap-2.5'>
    
          <p className='text-gray text-sm'>Deactivate Account   </p>
       
          <Switch
             checked={adminData.profile?.active}
             onChange={handleActivateAccnt}
            offColor="#888" 
            onColor="#419E6A"
            uncheckedIcon={false}  
            checkedIcon={false}
            width={36}
            height={20}
            handleDiameter={13.5}
            disabled
            
          />
    
    
    </div>
    
    
    
        </div>
    
    
      </div>
    
    
    
   
    
   
    
    
    
    
    </div>
  )
}
