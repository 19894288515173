import React, { useEffect, useState } from 'react'
import { IoEyeOutline } from 'react-icons/io5';
import Switch from 'react-switch';
import CustomeCheckbox from '../CustomeCheckbox';
import { MdOutlineModeEdit } from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';
import useAdminAccess from '../../AdminAccessContext/Useadminaccess';


export default function EditPropertyManage({adminData,handleInputChange,setAdminData,adminAccess}) {

    const [checked, setChecked] = useState(false);
    const [permissionsChecked, setPermissionsChecked] = useState(false);
   

    const handlePermissionsSwitchChange = (checked) => {
        setPermissionsChecked(checked);
      
      setAdminData(prevState => ({
            ...prevState,
            profile: {
                ...prevState.profile,
                permissions: {
                    ...prevState.profile.permissions,
                    view_property: checked,
                    edit_property: checked,
                    create_new_property: checked,
                    delete_property: checked
                }
            }
        }));


        
      };
      useEffect(() => {
        const anyUnchecked =
            adminData.profile?.permissions.view_property ||
            adminData.profile?.permissions.edit_property ||
            adminData.profile?.permissions.create_new_property ||
            adminData.profile?.permissions.delete_property;
        setPermissionsChecked(anyUnchecked);
    }, [adminData.profile?.permissions]);



    //   console.log(adminData)
  return (
    <div className='UserManageformSect font-nunito'>
<div className='flex justify-between items-center'>
<p className='text-2xl font-medium'>Property management</p>
<Switch
             checked={permissionsChecked}
             onChange={handlePermissionsSwitchChange}
            offColor="#888" 
            onColor="#419E6A"
            uncheckedIcon={false}  
            checkedIcon={false}
            width={36}
            height={20}
            handleDiameter={13.5}
            disabled={adminAccess?.edit_admin==0}
            
          />
</div>

<p className='font-bold text-lg text-left'>Property Section Permission</p>

<div className='flex justify-between items-center'>
    <div className='flex flex-col'>
        <div className='flex gap-2 items-center text-viewblue font-medium'>
            <IoEyeOutline />
            <p className='text-lg '>View Property</p>
        </div>
        <p className='text-gray text-xs'>Access and view stored data 
within the dashboard.</p>
    </div>

   

        <CustomeCheckbox 
      
        onChange={handleInputChange}
        dataSection="permissions" 
        name="view_property"
        checked={adminData.profile?.permissions?.view_property}
        disabled={adminAccess?.edit_admin==0}
        />
       
</div>



<div className='flex justify-between items-center'>
    <div className='flex flex-col'>
        <div className='flex gap-2 items-center text-edityellow font-medium'>
        <MdOutlineModeEdit />
            <p className='text-lg '>Edit Property</p>
        </div>
        <p className='text-gray text-xs'>Users can modify content 
 in the dashboard.</p>
    </div>

   

        <CustomeCheckbox  
        onChange={handleInputChange}
        dataSection="permissions" 
        name="edit_property"
        checked={adminData.profile?.permissions?.edit_property }
        disabled={adminAccess?.edit_admin==0}
        />
    
</div>




<div className='flex justify-between items-center'>
    <div className='flex flex-col'>
        <div className='flex gap-2 items-center text-gray font-medium'>
        <span className="material-symbols-outlined">
admin_panel_settings
</span>
            <p className='text-lg '>Create New Property</p>
        </div>
        <p className='text-gray text-xs'>Add new data or 
        Property to the dashboard.</p>
    </div>

   

        <CustomeCheckbox 
         onChange={handleInputChange}
        dataSection="permissions" 
        name="create_new_property"
        checked={adminData.profile?.permissions?.create_new_property}
        disabled={adminAccess?.edit_admin==0}
         />
       
</div>



<div className='flex justify-between items-center'>
    <div className='flex flex-col'>
        <div className='flex gap-2 items-center text-delred font-medium'>
        <RiDeleteBin6Line />
            <p className='text-lg '>Delete Property</p>
        </div>
        <p className='text-gray text-xs'>Remove data or 
Property to the dashboard.</p>
    </div>

   

        <CustomeCheckbox  
        onChange={handleInputChange}
        dataSection="permissions" 
        name="delete_property"
        checked={adminData.profile?.permissions?.delete_property}
        disabled={adminAccess?.edit_admin==0}
         />
       
</div>


    </div>
  )
}
