import React, { useEffect, useRef, useState } from 'react';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';
import { RiEdit2Fill } from 'react-icons/ri';

export default function ViewTenantCard(props) {
  const {
    tenant_name,
    lease_period,
    lock_in_period,
    price_per_sqft,
    index,
    handleInputChange,
    handleDeleteTenant,
    cardRef ,
    isEditing ,
    handleEditTenant

  } = props;




 
  return (
    <div  ref={cardRef} className='flex flex-col gap-3 p-2.5 rounded-lg border border-lightgray '>

<div className='flex justify-end relative' >
   <div   className='cursor-pointer p-1'>
   <BiDotsHorizontalRounded />
   </div>



</div>



<div  className='flex gap-11 tenantsection'>

<div className='flex flex-col gap-4 w-1/2'>
        <div className='flex flex-col w-full justify-start gap-1.5'>
          <p className='text-sm text-blue text-start'>Tenant Name </p>
          <input type="text" 
            name="tenant_name"
            value={tenant_name}
           disabled
            className='propDisableInputText'
            placeholder='E.g Prestige Group'
            onChange={(e) => handleInputChange(index, e)}
          />
        </div>

        <div className='flex flex-col w-full justify-start gap-1.5'>
          <p className='text-sm text-blue text-start'>Lease Period (Yrs)</p>
          <input type="text" 
            name="lease_period"
            value={lease_period}
            className='propDisableInputText'
            disabled
            placeholder='E.g 1'
            onChange={(e) => handleInputChange(index, e)}
          />
        </div>
      </div>

      <div className='flex flex-col gap-4 w-1/2'>
        <div className='flex flex-col w-full justify-start gap-1.5'>
          <p className='text-sm text-blue text-start'> Lock-in Period (Yrs) </p>
          <input type="text" 
            name="lock_in_period"
            value={lock_in_period}
           
            placeholder='E.g 1'
            className='propDisableInputText'
            disabled
            onChange={(e) => handleInputChange(index, e)}
          />
        </div>

        <div className='flex flex-col w-full justify-start gap-1.5'>
          <p className='text-sm text-blue text-start'>Price per sqft (INR)</p>
          <input type="text" 
            name="price_per_sqft"
            value={price_per_sqft}
            className='propDisableInputText'
            disabled
            placeholder='E.g 12,000'
            onChange={(e) => handleInputChange(index, e)}
          />
        </div>
      </div>
</div>



    </div>
  );
}
