import React, { useEffect, useRef, useState } from 'react'
import { BiDotsHorizontalRounded } from 'react-icons/bi'
import { MdDelete, MdOutlineRemoveRedEye } from 'react-icons/md'
import { RiEdit2Fill } from 'react-icons/ri'
import { formatDate } from '../../Utils/dateFormat'
import "./property-style.css"
import { Link, useParams } from 'react-router-dom'
import { ApiBaseUrl } from '../../Utils/urls'
import { TruncatedText } from '../../Utils/TruncatedString'



export default function PropertyCard(props) {
const {
  property_id,
  property_name,
  listing_date,
  total_shares,
  sold_shares,
  property_price,
  price_per_share,
  sale_type,
  property_phase,
  property_sale_type,
  index,
  adminAccess
}=(props)

const [showopt, setshowopt] = useState(false);
const accesstoken=localStorage.getItem("accesstoken")
const adminId=localStorage.getItem("adminId")
const [propStatus,setPropSatus]=useState(false)

const handleShowActionOpitons=()=>{
  setshowopt(!showopt)
}

const optionsRef = useRef(null);

useEffect(() => {
   const handleClickOutside = (event) => {
     if (optionsRef.current && !optionsRef.current.contains(event.target)) {
      setshowopt(false);
     }
   };

   document.addEventListener('mousedown', handleClickOutside);
   return () => {
     document.removeEventListener('mousedown', handleClickOutside);
   };
 }, []);



const getPropertySaleClass = (sale) => {
  switch (sale) {
    case 'RESALE':
      return 'resaleTag';
    case 'LAUNCHING':
      return 'launchingTag';
    case 'OPEN':
      return 'openTag';
    case 'SOLD OUT':
      return 'soldoutTag';
    default:
      return '';
  }
};

const getPropertySaleLabel = (sale) => {
  switch (sale) {
    case 'RESALE':
      return 'Resale';
    case 'LAUNCHING':
      return 'Launching';
    case 'OPEN':
      return 'Open';
    case 'SOLD OUT':
      return 'Sold out';
    default:
      return '';
  }
};

const getPropertyPhaseClass = (phase) => {
  switch (phase) {
    case 'Presale':
      return 'presaleTag';
    case 'Public Sale':
      return 'publicTag';
   
    default:
      return '';
  }
};


const handleUpdatedPropStatus=(propertyid)=>{
  fetch(`${ApiBaseUrl}/fr-admin/update-property-status`,
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': accesstoken
    },
    body: JSON.stringify({
     
      
        "admin_id": adminId,
        "property_id": propertyid,
        "active": !propStatus 
    
    })
  }
)
    .then(response => {
    
     
     
      return response.json();
    })
    .then(resdata => {
     
     //console.log('Response from server:', resdata);
     
        if(resdata.success)
          
        {
          //fetchPropertyDetails()
        
        }

    })
    .catch(error => {
     
     
    });
}


  return (
    <div className='flex w-full gap-2 tableHead  justify-center  propRow'>
    <div className='slNoP'>
 {index+1}
  </div>
  
  <div className='ListingDateClsP'>
{listing_date && formatDate(listing_date)}  </div>
  <div className='propIdclsP'>
 {property_id && property_id}
  </div>
  <div className='propNameClsP'>
  {property_name && TruncatedText(property_name,60)}
  </div>
  <div className='totalTokenClsP'>
  {total_shares && total_shares}
  </div>
  <div className='tokenPriceClsP'>
 {price_per_share && parseFloat(Number(price_per_share)).toLocaleString()}
  </div>
  <div className='propPriceClsP'>
  {property_price && parseFloat(Number(property_price)).toLocaleString()}
  </div>
  <div className='propPhraseClsP'>
    <button className={`phaseBtn ${getPropertyPhaseClass(property_phase)}`}>
    {property_phase && property_phase}
    </button>

  </div>
  <div className='saleTypeClsP'>
  <button className={`phaseBtn ${getPropertySaleClass(property_sale_type)}`}>
    {getPropertySaleLabel(property_sale_type)}
    </button>
  </div>
  <div className='actionClsP'>
  <BiDotsHorizontalRounded  onClick={handleShowActionOpitons}/>
  
  {/* action menu */}
  {
    showopt && (
<div className='actionMenu' ref={optionsRef}>

 
  <Link to={`/property/${property_id}`}>
  <div className='actionBtn viewBtnP'>
            <MdOutlineRemoveRedEye />
            <p>View</p> 
        </div>
  </Link>
  {
  adminAccess?.edit_property==1 && (
    <Link to={`/property/edit/${property_id}`}> 
    <div className='actionBtn editBtnP'>
        <RiEdit2Fill />
        <p>Edit</p> 
    </div>
    </Link>
  ) 

 }

{/* {
  adminAccess?.delete_property==1 && 
  <div className='actionBtn deleteBtnP' >
            <MdDelete />
            <p>Delete</p>
          
        </div>
} */}
 
      
      </div>
    )
  }
      
  
  </div>
  
    </div>    
  )
}
