import React from 'react'
import PopUp from '../PopUp/PopUp'
import FloatingLoader from '../Loader/FloatingLoader'
import { IoMdClose } from 'react-icons/io'

export default function AddCityPopUp(
    {
        isCityLoading,
        isSaveButtonDisabled,
        handleAddCity,
        handleCloseAddCityPopUp,
        cityName,
        setCityName,
        handleCityIconChange,
        cityIcon,
        cityIconError,
        newLocation,
        setNewLocation,
        locationList,
        handleRemoveLocation,
        handleAddLocation
    }
) {
  return (
    <PopUp maxWidth={'max-w-xl'} >
    {
       isCityLoading && (
    <FloatingLoader/>
       )
    }
    
             
    <div className='flex flex-col gap-4'>
    <div className='flex justify-between'>
          <p className='text-blue text-lg font-bold' >Add City*</p>
    
          <div className='flex gap-4 items-center'>
             <button className={`${isSaveButtonDisabled?'text-gray bg-lightgray':'text-white bg-pink'} rounded-lg text-xs px-2 py-1`}
              disabled={isSaveButtonDisabled}
              onClick={handleAddCity}
              > 
              Save Details
              </button>
             <IoMdClose className='text-2xl cursor-pointer' onClick={handleCloseAddCityPopUp} />
          </div>
    
       </div>
       <hr className='prophrClass' />
    
       <div className='flex gap-5'>
                  <div className='flex flex-col gap-2.5 items-start popUpinput'>
                      <p className='text-sm text-blue'>City <span style={{color:"#af2525"}}>*</span> </p>
                      <input type="text" 
                      name="" 
                      value={cityName}
                      onChange={(e) => setCityName(e.target.value)}
                      className='propInputText'
                      placeholder='E.g Bengaluru'
        
                       />
                  </div>
    
                  <div className='flex flex-col gap-2.5 items-start '>
                  <p className='text-sm text-blue'>City Icon <span style={{color:"#af2525"}}>*</span> </p>
                  <div className="CityLogoSec">
                     <label className="cityLogoLabel" for="file">
                        <span className="material-symbols-outlined"> publish</span>
                     </label>
                  <input id="file" type="file" onChange={handleCityIconChange}/>
                  {/* <img src={``} alt="City"  className='cityImg' /> */}
    
    
                   {
                      cityIcon && cityIcon.base64 && (
                         <img className="profile-img" src={cityIcon.base64} alt="Avatar" />
                      )
                  }
          
                  </div>
    
    {cityIconError && <p className='text-xs text-red'>{cityIconError}</p> }
                   </div>
        
                 
        
        
              </div>
    
    
              <div className='flex gap-5'>
                  <div className='flex flex-col gap-2.5 items-start popUpinput'>
    
                   <div className='flex w-full justify-between items-center'>
                   <p className='text-sm text-blue'>Location  <span style={{color:"#af2525"}}>*</span> </p>
                     <button className='bg-blue text-white rounded text-xs px-2 cursor-pointer' onClick={handleAddLocation}>Add</button>
                   </div>
                    
                     
                      <input type="text" 
                      name="" 
                      value={newLocation}
                      onChange={(e) => setNewLocation(e.target.value)}
                      className='propInputText'
                      placeholder='Brookefield Mall'
        
                       />
                  </div>
    
                
                 
        
        
              </div>
    
    
              <div className='flex gap-5 h-24'>
                  <div className='cityTagDiv'>
                  {locationList && locationList.map((location, index) => (
                        <div key={index} className='locationBadge'>
                          <p>{location}</p>
                          <IoMdClose className='cursor-pointer' onClick={() => handleRemoveLocation(index)} />
                        </div>
                      ))}
    
    
   
    
                  </div>
                
                 
        
        
              </div>
    </div>
    
    
      
    </PopUp>
  )
}
