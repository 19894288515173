import React, { useEffect, useRef, useState } from 'react'
import enableIcon from "../../Assets/Icons/verified.svg"
import "./adminlist-style.css"
import { BsThreeDots } from 'react-icons/bs'
import { Link, useParams } from 'react-router-dom'
import { MdDelete, MdOutlineRemoveRedEye } from 'react-icons/md'
import { RiEdit2Fill } from 'react-icons/ri'
import { ApiBaseUrl, PropertImageBaseUrl } from '../../Utils/urls'
import profileAvatar from "../../Assets/images/avatar.png"
import useAdminAccess from '../AdminAccessContext/Useadminaccess'
import PopUp from '../PopUp/PopUp'
import { IoCloseOutline } from 'react-icons/io5'
import { FetchAdminList } from '../../Api/AdminApi'

export default function AdminCard(props) {
    const [showopt, setshowopt] = useState(false);
    const [showDeleteModal,setShowDeleteModal]=useState(false)
    const [isLoading,setLoading]=useState(false)
    const accessToken=localStorage.getItem("accesstoken")
const adminId=localStorage.getItem("adminId")

const {name,profile_pic,staff_id,permissions, getAdminList,adminAccess }=props


   
    const handleShowActionOpitons=()=>{
      setshowopt(!showopt)
    }
    const handleCloseDeleModal=()=>{
        setShowDeleteModal(false)
    }

 const handleShowDeleModal=()=>{
        setShowDeleteModal(true)
    }


//const {adminAccess} = useAdminAccess()


const handleDeleteAdmin=()=>{
    fetch(`${ApiBaseUrl}/fr-admin/delete-admin`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken
      },
      body: JSON.stringify({
        "admin_id":adminId,
        "staff_id":props.staff_id
    })
    }
  )
      .then(response => {
      
        // if (!response.ok) {
        //   throw new Error('Network response was not ok');
        // }
       
        return response.json();
      })
      .then(resdata => {
       
       console.log('Response from server:', resdata);
       
          if(resdata.success)
          {
            setLoading(false)
            setShowDeleteModal(false)
            getAdminList()
          }
  
      })
      .catch(error => {
       
        console.error('error details:', error);
      });
}



const isSuperAdmin=permissions.add_admin &&
 permissions.edit_admin &&
  permissions.view_admin &&

  permissions.delete_user &&
  permissions.delete_admin &&
  permissions.edit_property &&
  permissions.view_property &&
  permissions.create_new_user &&
  permissions.delete_property &&
  permissions.overview_access &&
  permissions.edit_user_profile &&
  permissions.view_user_profile && 
  permissions.create_new_property &&
  permissions.transactions_access 

const optionsRef=useRef(null)
  useEffect(() => {
    const handleClickOutside = (event) => {
        if (optionsRef.current && !optionsRef.current.contains(event.target)) {
            setshowopt(false);
        }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, []);







  return (

    <>


{
    showDeleteModal && (
    <PopUp closeModal={handleCloseDeleModal} showCloseIcon={"true"} maxWidth={"max-w-xl"}>
<div  className='flex flex-col gap-3 font-nunito'>

<p className='text-lg font-bold text-gray '>Are you sure you want to delete this admin?</p>


<div  className='flex gap-3 justify-center'>
<button  className='border border-pink text-pink text-lg py-1 px-5 rounded-md' onClick={handleDeleteAdmin}>Yes</button>
<button  className='bg-pink text-white text-lg py-1 px-5 rounded-md'  onClick={handleCloseDeleModal}>No</button>
</div>

</div>



    </PopUp>
    )
}

    <div className='adminCard font-nunito'>
        <div className='flex justify-end relative'>
        <BsThreeDots  className='cursor-pointer' onClick={handleShowActionOpitons}/>

{
    showopt && (

        <div className='actionMenu' ref={optionsRef}>


   <Link to={`/admin/${staff_id}`}>
  <div className='actionBtn viewBtnP'>
            <MdOutlineRemoveRedEye />
            <p>View</p> 
        </div>
  </Link>
       
  {

adminAccess?.edit_admin==1 && (
    <Link to={`/admin/edit/${staff_id}`}>
    <div className='actionBtn editBtnP'>
          <RiEdit2Fill />
          <p>Edit</p> 
      </div>

    </Link>
)
}   
    {
        permissions?.delete_admin==1 && (
            <div className='actionBtn deleteBtnP cursor-pointer' onClick={handleShowDeleModal}>
            <MdDelete />
            <p>Delete</p>
          
        </div>
        )
    }
       
      
      </div>
    )
}



        </div>
   
    
    <div className='adminprofileBx'>
        <div className='profImagebox'>
            {
              profile_pic &&  profile_pic.Key ?
              <img className='profImg' src={`${PropertImageBaseUrl}/${profile_pic?.Key}`} alt="" />
    :
    <img className='profImg' src={profileAvatar} alt="" />
    
            }
         </div>
    
    <div className='profDetails'>
        <div className='flex justify-between'>
            <div className='flex gap-1.5 items-center'>
            <span className="material-symbols-outlined text-pink">
    shield_person
    </span>
    <p className='text-sm text-pink'>{isSuperAdmin?"Super Admin":"Sub Admin"}</p>
            </div>
    
          
        </div>
    
        <div className='flex justify-between'>
            <div className='flex gap-1.5 items-center'>
            <span className="material-symbols-outlined">
    admin_panel_settings
    </span>
    <p className='text-sm'>Admin Id</p>
            </div>
    
            <p className='text-sm'>{staff_id && staff_id}</p>
        </div>
    
        <div className='flex justify-between'>
            <div className='flex gap-1.5 items-center'>
            <span className="material-symbols-outlined">
    account_circle
    </span>
    <p className='text-sm'>Admin Name</p>
            </div>
           
            <p className='text-sm'>
                {name}
                {/* {name && name.substring(0, 7)}.. */}
                
                </p>
        </div>
    </div>
    
    
    </div>
    
    <hr className='profileHr' />
    
    <div className='accessSection'>
    
{
    permissions?.add_admin==1 && (
        <div className='flex justify-between items-start w-full'>
        <p className='text-sm font-bold'>Create Admin </p>
        <img src={enableIcon} alt=""  />
        </div>
    )
}

{
    permissions?.edit_admin==1 && (
        <div className='flex justify-between items-start w-full'>
        <p className='text-sm font-bold'>Edit Admin </p>
        <img src={enableIcon} alt=""  />
        </div>
    )
}
{
    permissions?.view_admin==1 && (
        <div className='flex justify-between items-start w-full'>
        <p className='text-sm font-bold'>View Admin </p>
        <img src={enableIcon} alt=""  />
        </div>
    )
}
{
    permissions?.delete_admin==1 && (
        <div className='flex justify-between items-start w-full'>
        <p className='text-sm font-bold'>Delete Admin </p>
        <img src={enableIcon} alt="" />
        </div>
    )
}
   
{
    permissions?.create_new_property==1 && (
        <div className='flex justify-between items-start w-full'>
        <p className='text-sm font-bold'>Create New Property </p>
        <img src={enableIcon} alt=""  />
        </div>
    )
}

{
    permissions?.edit_property==1 && (
        <div className='flex justify-between items-start w-full'>
        <p className='text-sm font-bold'>Edit Property </p>
        <img src={enableIcon} alt=""  />
        </div>
    )
}


{
    permissions?.view_property==1 && (
        <div className='flex justify-between items-start w-full'>
        <p className='text-sm font-bold'>View Property </p>
        <img src={enableIcon} alt="" />
        </div>
    )
}

{
    permissions?.delete_property==1 && (
        <div className='flex justify-between items-start w-full'>
        <p className='text-sm font-bold'>Delete Property </p>
        <img src={enableIcon} alt="" />
        </div>
    )
}


{
    permissions?.create_new_user==1 && (
        <div className='flex justify-between items-start w-full'>
        <p className='text-sm font-bold'>
Create New User Profile</p>
        <img src={enableIcon} alt="" />
        </div>
    )
}
{
    permissions?.view_user_profile==1 && (
        <div className='flex justify-between items-start w-full'>
        <p className='text-sm font-bold'>View Users Profile</p>
        <img src={enableIcon} alt=""  />
        </div>
    )
}

{
    permissions?.edit_user_profile==1 && (
        <div className='flex justify-between items-start w-full'>
        <p className='text-sm font-bold'>Edit User Profile</p>
        <img src={enableIcon} alt=""  />
        </div>
    )
}

{
    permissions?.delete_user==1 && (
        <div className='flex justify-between items-start w-full'>
        <p className='text-sm font-bold'>Delete User Profile </p>
        <img src={enableIcon} alt=""  />
        </div>
    )
}



    
   
    </div>
    
    
    
    </div>
    </>
  )
}
