import React,{useState,useEffect} from 'react'
import "./TransactionDetails.css"
import AdminContainer from '../AdminContainer/AdminContainer'
import { FiDownload } from "react-icons/fi";
import { FiEye } from "react-icons/fi";
import { IoMdCopy } from "react-icons/io";
import { MdContentCopy } from "react-icons/md";
import SuccessAlert from '../Alert/SuccessAlert';
import userImage from '../../Assets/images/userName.svg'
import { FaLocationDot } from "react-icons/fa6";
import PropImage from '../../Assets/images/propImage.svg'
import { useParams } from 'react-router-dom';
import { ApiBaseUrl, PropertImageBaseUrl } from '../../Utils/urls';
import FloatingLoader from '../Loader/FloatingLoader';
import { formatDateWithMonthName } from '../../Utils/dateFormat';
import { FaUser } from 'react-icons/fa';

export default function TransactionDetails() {
    const [showSuccessAlert,setShowSucAlert]=useState(false)
    const [alertMessage, setalertMessage] = useState("");
    const [transDetails,setTransDetails]=useState({})
    const [isLoading,setLoading]=useState(false)

    const adminId = localStorage.getItem('adminId');
    const accesstoken= localStorage.getItem('accesstoken');
    const {id:transactionId}=useParams()
  

      
    const copyToClipboard = () => {
        const transactionId =  navigator.clipboard.writeText(transDetails?.transaction_id)
       
        transactionId.then(() => {
            setShowSucAlert(true);
            setalertMessage("Transaction Id copied successfully");
            setTimeout(() => {
                setShowSucAlert(false);
                setalertMessage("");
            }, 3000);
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };

    const copyToClipboardUserId = () => {
        const transactionId = document.querySelector('.userIdTransaction').innerText;
        navigator.clipboard.writeText(transactionId).then(() => {
            setShowSucAlert(true);
            setalertMessage("User Id copied successfully");
            setTimeout(() => {
                setShowSucAlert(false);
                setalertMessage("");
            }, 3000);
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };

const getTransactionDetails=()=>{
    setLoading(true)
    fetch(`${ApiBaseUrl}/fr-admin/get-purchase-transaction-details`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': accesstoken
          },
          body: JSON.stringify({
            "admin_id": adminId,
           "transaction_id":transactionId
        })
        }
      )
          .then(response => {
          
          
            return response.json();
          })
          .then(resdata => {
           
           //console.log('Response from server:', resdata);
           setLoading(false)
              if(resdata.success)
              {
                setTransDetails(resdata.data.transactions[0])
              }
      
          })
          .catch(error => {
           
            console.error('error details:', error);
          });
}

const getTranStatusCls = (transstat) => {
    switch (transstat) {
      case 'COMPLETED':
        return 'completedStatus';
      case 'PENDING':
        return 'pendingStatus';
      case 'CANCEL':
        return 'cancelStatus';
      
      default:
        return '';
    }
  };
  
  function formatWord(word) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

useEffect(()=>{
    getTransactionDetails()
},[])



const getPropertySaleClass = (sale) => {
    switch (sale) {
      case 'RESALE':
        return 'resaleTag';
      case 'LAUNCHING':
        return 'launchingTag';
      case 'OPEN':
        return 'openTag';
      case 'SOLD OUT':
        return 'soldoutTag';
      default:
        return '';
    }
  };

  return (
    <AdminContainer backLinkPath={'/transaction'} 
    pageTitle="Transaction" 
    subtitle={`Finance Management / Transactions / #${transDetails?.transaction_id}`}>
{
    isLoading && (
<FloatingLoader/>
    )
}


    <div className=' flex flex-row justify-between gap-[10px] font-nunito '>
        <div className=' flex flex-col gap-[10px] p-[20px] bg-[#F8F8F8]' style={{width:"72%"}}>
            <div className=' flex flex-row justify-between'>
                <div className=' flex flex-col gap-[10px]'>
                    <div className=' flex gap-[12px] items-center'>
                        <div className='text-[21px] font-normal text-[#AEA9B1] '>Transaction ID</div>
                        <div className='flex flex-col justify-center' onClick={copyToClipboard}>
                            <MdContentCopy style={{color:"#AEA9B1",height:"20px",width:"20px"}} className='cursor-pointer' /></div>
                    </div>
                    <div className=' text-[28px] text-black font-bold transactionId text-left'>#{transDetails && transDetails.transaction_id?.substring(0,10)}...</div>
                </div>


{
    transDetails?.invoice && (
<div className='flex flex-row gap-[20px]'>
                    <div className='flex flex-col justify-center'>
                        <a href={`${PropertImageBaseUrl}/${transDetails?.invoice?.Key}`} target="_blank">
                        <div className=' flex flex-row gap-[10px] h-[41px] rounded-xl p-[10px] bg-pink'>
                            <div className=' flex flex-col justify-center'><FiEye style={{color:"#fff"}} /></div>
                            <div className=' text-[14px] font-bold flex flex-col justify-center text-white'>View Invoice</div>
                        </div>
                        </a>
                      
                     

                    </div>
                    {/* <div className='flex flex-col justify-center'>
                        <div className=' flex flex-row gap-[10px] h-[41px] rounded-xl p-[10px] bg-[#D328A7]'>
                            <div className=' flex flex-col justify-center'><FiDownload style={{color:"white"}} /></div>
                            <div className=' text-[14px] font-bold flex flex-col justify-center text-white'>Download Report</div>
                        </div>
                    </div> */}
                </div>
    )
}
                

            </div>
            <div className=' flex flex-row gap-[57px]'>
                <div className=' flex flex-col gap-[7.5px]'>
                    <div className=' text-[13.5px] font-normal text-[#AEA9B1]'>Date Of Purchase</div>
                    <div className=' text-start text-[16.5px] font-normal'>{formatDateWithMonthName(transDetails?.transaction_date)}</div>
                </div>
                <div className=' flex flex-col gap-[7.5px]'>
                <div className=' text-[13.5px] font-normal text-[#AEA9B1]'>Payment Method</div>
                <div className=' text-start text-[16.5px] font-normal'>{transDetails?.mode_of_payment}</div>
            </div>
            </div>
            
            <div className=' flex flex-row gap-[90px]'>
                <div className=' flex flex-col gap-[7.5px]'>
                    <div className=' text-[13.5px] font-normal text-[#AEA9B1]'>Token Price</div>
                    <div className=' text-start text-[16.5px] font-normal'>₹{transDetails?.total_price}</div>
                </div>
                <div className=' flex flex-col gap-[7.5px]'>
                    <div className=' text-[13.5px] font-normal text-[#AEA9B1]'>Number of Token Purchase</div>
                    <div className=' text-start text-[16.5px] font-normal'>{transDetails?.no_of_tokens}</div>
                </div>
                <div className=' flex flex-col gap-[7.5px]'>
                    <div className=' text-[13.5px] font-normal text-[#AEA9B1]'>Transaction Status</div>
                    <div 
                    className={` flex flex-col justify-center rounded-full text-[10.8px] font-bold w-[79px] h-[32px] ${getTranStatusCls(transDetails?.transaction_status)} `}>
                        {transDetails && transDetails.transaction_status &&  formatWord(transDetails?.transaction_status)}</div>
                </div>
            </div>
            <div>
                <hr style={{color:"#AEA9B1"}}></hr>
            </div>

            <div className=' flex flex-col gap-[7.5px]'>
                <div className=' text-start text-[16.5px] font-normal text-[#AEA9B1]'>User Info</div>
                <div className=' flex flex-row justify-between'>
                    <div className=' flex flex-row gap-[15px]'>
                    
                            {
                               transDetails?.profile_pic?.Key
                               ?
                  ( <div className=' rounded-full w-[56.25px] h-[56.25px]'>
                            {
                            <img src={`${PropertImageBaseUrl}/${transDetails?.profile_pic?.Key}`}/>
                            }
                     </div>)
                          :
                          (<div class="flex flex-col justify-center ">
                            <div class="defaultProfilePic">
                                  <FaUser/>
                             </div>
                     </div>)
                            }

                      
                        <div className=' flex flex-col gap-[10px]'>
                            <div className=' text-[13.5px] font-normal text-[#AEA9B1] text-start'>User Name </div>
                            <div className=' text-[16.5px] font-normal'>{transDetails?.first_name}</div>
                        </div>
                    </div>
                    <div className=' flex flex-col gap-[7.5px]'>
                        <div className=' text-[13.5px] font-normal text-[#AEA9B1] '>Account Status</div>
                        <div className='flex flex-row justify-center'>
                            <div 
                            className={`flex flex-col justify-center rounded-full text-[10.8px] font-bold w-[60px] h-[24px]  ${transDetails.active?'bg-[#DDF3DE] text-[#3A974C]':'bg-[#F5CDCD] text-[#E71D36]'} `}>
                                {transDetails.active?"Active":"Inactive"}
                                
                                </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className=' flex flex-col gap-[7.5px]'>
            
            <div className=' flex flex-row justify-between px-[70px] py-[20px]'>
                <div className=' flex flex-col gap-[10px]'>
                    <div className=' flex flex-row justify-between gap-[12px]'>
                        <div className='text-[13.5px] font-medium text-[#AEA9B1] '>User Id</div>
                        <div className='flex flex-col justify-center' onClick={copyToClipboardUserId}><MdContentCopy style={{color:"#AEA9B1",height:"20px",width:"20px"}} /></div>
                    </div>
                    <div className=' text-[16.5px] font-normal text-black text-start userIdTransaction'>#{transDetails?.user_id}</div>
                </div>
            </div>
        </div>

        </div>


        <div className=' rounded-lg rightTransactionDetail flex flex-col' style={{width:"28%"}}>
            <div className=' text-[20px] font-normal text-gray '>Property Info</div>
            <div className=' h-[191px]  mt-4 '>
            {
  transDetails && transDetails.property_images && transDetails.property_images.length > 0 && (
    <img style={{width:"100%",height:"100%",objectFit:"cover"}} src={`${PropertImageBaseUrl}/${transDetails.property_images[0].Key}`} className='rounded-md'/>
  )
}

                </div>

            <div className='flex flex-col px-[30px]'>
                <div className=' mt-[20px] text-[16px] font-normal'>
                    {transDetails?.property_name}
                    </div>

                <div className=' mt-[10px] flex flex-row gap-5 justify-center'>
                    <div className='flex flex-col justify-center'><FaLocationDot style={{width:"10px",color:"#AEA9B1"}}/></div>
                    <div className=' text-[16px] font-normal text-[#AEA9B1] '>{transDetails?.property_location}, {transDetails?.property_city}</div>
                </div>
                <div className=' mt-[20px] flex flex-row justify-between'>
                    <div className=' flex flex-col justify-center  text-[12px] font-normal text-[#AEA9B1]'>Property Type</div>
                    <div className=' flex flex-col justify-center font-medium phaseBtnTransdetails  bg-[#E6E6E6] text-[#5D5C5C]'>{transDetails?.property_type}</div>
                </div>
                <div className=' mt-[10px] flex flex-row justify-between'>
                    <div className=' flex flex-col justify-center  text-[12px] font-normal text-[#AEA9B1]'>Property Sale Type</div>
                    <div className={`' flex flex-col justify-center text-[12px] phaseBtnTransdetails ${getPropertySaleClass(transDetails?.property_sale_type)}`}>
                        
                    {transDetails?.property_sale_type}</div>
                </div>
                <div className=' mt-[10px] flex flex-row justify-between'>
                <div className=' flex flex-col justify-center  text-[12px] font-normal text-[#AEA9B1]'>Property Phase</div>
                <div className={`flex flex-col justify-center  font-medium phaseBtnTransdetails rounded-md ${transDetails?.property_phase=="Presale"?'presaleTag':'publicTag'} `}>
                    
                    {transDetails?.property_phase}</div>
                </div>
                <div className=' flex flex-row justify-center gap-[10px] mt-[22px]'>
                    <div className=' text-[16px] font-semibold'><span className='text-[18px] font-bold'>{transDetails?.total_shares-transDetails?.sold_shares}</span>/{transDetails?.total_shares}</div>
                    <div className=' text-[16px] font-semibold flex flex-col justify-center'>Tokens Available</div>
                </div>
            </div>

        </div>
    </div>

    {
        showSuccessAlert && (
          <SuccessAlert message={alertMessage}/>
        )
      }
      

        </AdminContainer>
  )
}
