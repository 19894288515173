import React, { useState } from 'react'
import { IoIosClose } from 'react-icons/io'


export default function OverviewSort(props) {
const {showTransactionSort,setShowTransactionSort}=props
const handleCloseSprtPopup=()=>{
  setShowTransactionSort(false)
}


const handleClearAllSort =()=>{
    props.setsortSoldShares("");
    props.setsortTokenSaleAmount("");
}



const handleClearSortByTokenAmount =()=>{
    props.setsortTokenSaleAmount("");
    }
const handleClearSortByTokenNumber =()=>{
props.setsortSoldShares("");
}



  return (
    <div className={`propertyFilterCard ${showTransactionSort?'block':'hidden'}`}>
  <div className='flex justify-between items-start border-lightgray  border-b '>
    <p className='text-base font-bold text-blue'>Sort Buy</p>
    <div className='flex gap-2 items-center'>
    <p className='text-sm  text-gray cursor-pointer' onClick={handleClearAllSort}>Clear all</p>
    <IoIosClose  className='cursor-pointer' onClick={handleCloseSprtPopup} />
    </div>

  </div>


  <div className=' text-black flex flex-col pt-4'>
  <div className='flex flex-row justify-between'>
  <div className='text-[16px] font-medium text-start'>Token Sale Amount</div>
  <div className='flex flex-col justify-center'>
    <div className='clearButton text-xs text-start font-normal -mr-1 flex flex-col justify-center rounded-lg py-0.5 px-2 h-5 cursor-pointer'
    onClick={handleClearSortByTokenAmount}
    >Clear</div>
  </div>

  </div>
    <div className=' flex flex-row flex-wrap gap-2 p-2 -ml-2'>
   
     

    <button
        className={`propertyPhasesButton  text-black text-sm w-24 h-7 ${
            props.sortTokenSaleAmount===("desc") ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => {props.setsortTokenSaleAmount("desc");props.setsortSoldShares("")}}
      >
       Maximum 
      </button>
      <button
        className={`propertyPhasesButton  text-black text-sm w-20 h-7  whitespace-nowrap ${
            props.sortTokenSaleAmount===("asc") ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => {props.setsortTokenSaleAmount("asc");props.setsortSoldShares("")}}
      >
       Minimum
      </button>
     

     

    
     
      
    </div>
  </div>


  <div className=' text-black flex flex-col pt-4'>
  <div className='flex flex-row justify-between'>
  <div className='text-[16px] font-medium text-start'>Total Tokens </div>
  <div className='flex flex-col justify-center'>
    <div className='clearButton text-xs text-start font-normal -mr-1 flex flex-col justify-center rounded-lg py-0.5 px-2 h-5 cursor-pointer'
    onClick={handleClearSortByTokenNumber}
    >Clear</div>
  </div>

  </div>
    <div className=' flex flex-row flex-wrap gap-2 p-2 -ml-2'>
   
     

    <button
        className={`propertyPhasesButton  text-black text-sm w-24 h-7 ${
            props.sortSoldShares===("desc") ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => {props.setsortSoldShares("desc");props.setsortTokenSaleAmount("")}}
      >
       Maximum
      </button>
      <button
        className={`propertyPhasesButton  text-black text-sm w-20 h-7  whitespace-nowrap ${
            props.sortSoldShares===("asc") ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => {props.setsortSoldShares("asc");props.setsortTokenSaleAmount("")}}
      >
       Minimum
      </button>
           
    </div>
  </div>


</div>
  )
}
