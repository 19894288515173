import React, { useEffect, useState } from 'react'
import "../floorplans.css"
import { getPropertyDetails } from '../../../Api/PropertyAddApi'
import { ApiBaseUrl } from '../../../Utils/urls'
import FloatingLoader from '../../Loader/FloatingLoader'
import SuccessAlert from '../../Alert/SuccessAlert'
import ErrorAlert from '../../Alert/ErrorAlert'


import { useParams } from 'react-router-dom'
import ViewFloorPlanCard from './ViewFloorPlanCard'


export default function ViewFloorPlans() {

  const accessToken=localStorage.getItem("accesstoken")
  const adminId=localStorage.getItem("adminId")
  const {id:propertyId}=useParams()
   const [floorPlans, setFloorPlans] = useState([]);
   const [isLoading,setLoading]=useState(false)
   const [alertMsg,setAlertMsg]=useState("")


    const fetchPropDetails=()=>{
      getPropertyDetails(
        undefined,
        propertyId
        ,adminId,
        accessToken,
        undefined,
        undefined,
        undefined,
        setFloorPlans,
        undefined,
        undefined,
        undefined
      )
    }
useEffect(()=>{
  if(propertyId){
    fetchPropDetails()
  }
  
},[])




   
  
    const handleFloorPlanChange = (index, field, value) => {
      const newFloorPlans = [...floorPlans];
      newFloorPlans[index][field] = value;
      setFloorPlans(newFloorPlans);
    };
   
  


const [propImgErr, setPropImgErr] = useState('');

const handlePropImageChange = (e, index) => {
  const file = e.target.files[0];

  if (file) {
    const fileTypeParts = file.type.split('/');
    const fileExtension = fileTypeParts.length === 2 ? fileTypeParts[1] : '';
    const maxSize = 5 * 1024 * 1024;

    if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
      setPropImgErr('Only JPG or PNG formats are allowed');
    } else {
      setPropImgErr('');
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target.result;

        const newFloorPlans = [...floorPlans];
        newFloorPlans[index].floor_image = {
          extension: fileExtension,
          file_type: file.type,
          base64: base64String,
        };
        setFloorPlans(newFloorPlans);
      };
      reader.readAsDataURL(file);
    }
  }
};






  return (

    <>

   {/* image popup  */}


    <div className='flex flex-col gap-3 relative'>
    {
  isLoading && (
    <FloatingLoader/>
  )
}

<div className='flex justify-between items-center'>
       <p className='text-lg font-medium text-black'>
           
       Floor Plans
       <span style={{color:'#bc2222'}}>*</span>
       </p>

       <div className='flex gap-5 items-center'>
       <button className='propSaveBtn bg-lightgray text-gray' disabled  >
       Add Floor Plan 
       </button>
       <button className={`propSaveBtn bg-lightgray text-gray`} disabled>Save Details
       
       </button>
  
       </div>
   </div>



<div className='flex gap-4 overflow-x-auto whitespace-nowrap'>
{
   floorPlans && floorPlans.map((plan, index) => (
<ViewFloorPlanCard
 key={index}
 index={index}
 plan={plan}
 handleFloorPlanChange={handleFloorPlanChange}
 handlePropImageChange={handlePropImageChange}
 propImgErr={propImgErr}

/>
   ))
}


</div>








    </div>

    </>
  )
}
