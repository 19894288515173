import React,{useState,useEffect, useRef} from 'react'
import AdminContainer from '../AdminContainer/AdminContainer'
import { IoIosSearch } from "react-icons/io";
import "./user-style.css"
import { MdCall, MdOutlineFileDownload, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight, MdOutlineSort } from 'react-icons/md';

import User from '../Cards/UserCard/User';
import UserFilter from '../UserFilter/UserFilter';

import { MdOutlineFilterAlt } from "react-icons/md";
import { useUserFilters } from '../../Utils/UserAccountFilter';

import { BeatLoader } from 'react-spinners'
import { ApiBaseUrl, PropertImageBaseUrl } from '../../Utils/urls';
import avatar from "../../Assets/images/avatar.png"
import ReactPaginate from 'react-paginate';
import { useLocation, useNavigate } from 'react-router-dom';
import useDebounce from '../../Utils/Debounce';
import UserSort from '../UserFilter/UserSort';
import { RxDotFilled } from 'react-icons/rx';


export default function UserManagement() {
  const [clearFilterSelection, setclearFilterSelection] = useState(false);
  const [isOpenMob, setIsOpenMob] = useState(false);
  const [showSortPopUp,setShowSortPopUp]=useState(false)
  const [userData, setuserData] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [isLoading,setLoading]=useState(false)
  const [isOneSecondOver, setisOneSecondOver] = useState(false);
  const [UserCount,setUserCount]=useState(0)
  const [toTalPages,setTotalPages]=useState(0)
  const [dateRange, setDateRange] = useState({ start: null, end: null });
  const [isSearchActive, setIsSearchActive] = useState(false);//for search result serial no

  const [UserSelectDate,setUserSelectDate]=useState("")
  const [UserReportLink,setUserRepostLink]=useState("")

  const navigate=useNavigate()
const location=useLocation()
const debounce = useDebounce(); 

const queryParams = new URLSearchParams(location.search);
const initialPage = parseInt(queryParams.get('page')) || 1;

const source = queryParams.get('fromPage');

const adminId=localStorage.getItem("adminId")

  const togglePopup = () => {
    setIsOpenMob(!isOpenMob);
  };

  const toggleSortPopup = () => {
    setShowSortPopUp(!showSortPopUp);
  };
  const { UserFilters, setUserFilters,setPage } = useUserFilters();

  useEffect(() => {
    setPage(initialPage); 
    if(source){
      navigate(`/user?page=${initialPage}&source=${source}`)
    }
    else{
      navigate(`/user?page=${initialPage}`)
    }
    
  }, [initialPage]);
  
 


 
//pagination
const [currentPage, setCurrentPage] = useState(initialPage);


let pageCount



  const [selectedUser, setSelectedUser] = useState(null);
  const [filtersChanged, setFiltersChanged] = useState(false);
  const accessToken=localStorage.getItem("accesstoken")
 
  const formatDate = (date, time = '00:00:00') => {
    if (!date) return '';
    return (
      date.getFullYear() +
      '-' +
      String(date.getMonth() + 1).padStart(2, '0') +
      '-' +
      String(date.getDate()).padStart(2, '0') +
      ' ' +
      time
    );
  };

  const dateParamFromLs = localStorage.getItem("dateParam");

  useEffect(()=>{
    
      fetchData(accessToken)

    
  },[UserFilters])


  useEffect(() => {
    // Set dateRange based on the source and dateParam
   
    const currentDate = new Date();
    let startDate, endDate;

    switch (dateParamFromLs) {
      case 'All Time':
        startDate = null;
        endDate = new Date(currentDate);
        break;
      case 'Today':
        startDate = new Date(currentDate);
        endDate = new Date(currentDate);
        break;
      case 'Yesterday':
        startDate = new Date(currentDate-1);
        startDate.setDate(currentDate.getDate() - 1);
        endDate = startDate;
        break;
      case 'Past 7 Days':
        startDate = new Date(currentDate);
        startDate.setDate(currentDate.getDate() - 6);
        endDate = new Date(currentDate);
        break;
      case 'Past 30 Days':
        startDate = new Date(currentDate);
        startDate.setDate(currentDate.getDate() - 29);
        endDate = new Date(currentDate);
        break;
      case 'Past 365 Days':
        startDate = new Date(currentDate);
        startDate.setDate(currentDate.getDate() - 364);
        endDate = new Date(currentDate);
        break;
      default:
        startDate = null;
        
    }

   
    setDateRange({
      start: formatDate(startDate, '00:00:00')||null, 
      end: formatDate(endDate, '23:59:59'),
    });


  }, [UserSelectDate ]);
  

//UserFilters,dateRange,dateParamFromLs

const requestCountRef = useRef(0); //
  const fetchData = async (accessToken) => {
    requestCountRef.current += 1; // To keep track of the number of API calls
    const currentReqCount = requestCountRef.current;// Increment the request count
   
    try {
      setLoading(true);
      const pageToFetch = filtersChanged ? 1 : initialPage; 
      const response = await fetch(`${ApiBaseUrl}/fr-admin/get-users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': accessToken
        },
        body: JSON.stringify({
          ...UserFilters,
         
          filters: {
            ...UserFilters.filters,
            createdAt:{
              from: dateRange.start,
              to: dateRange.end
            }},
        
          page:pageToFetch, 
          admin_id:adminId,
        }),
      });
      const responseData = await response.json();
       // Only update the state if this is the most recent request
      if (responseData.success && currentReqCount === requestCountRef.current) {
        setuserData(responseData.data.users);
        setResponseData(responseData.data.users);
        setSelectedUser(responseData.data.users[0]);
        setUserCount(responseData.data.total_users)
        setTotalPages(responseData.data.total_pages)
        setLoading(false);
      } else {
       
      }
    } catch (error) {
      setLoading(false);
     
    }
  };
//console.log(toTalPages)

//console.log(dateRange)
  // useEffect(() => {
  //   const fetchData = async (accessToken) => {
  //     try {
  //       setLoading(true);
  //       const pageToFetch = filtersChanged ? 1 : initialPage; 
  //       const response = await fetch(`${ApiBaseUrl}/fr-admin/get-users`, {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'Authorization': accessToken
  //         },
  //         body: JSON.stringify({
  //           ...UserFilters,
           
  //           filters: {
  //             ...UserFilters.filters,
  //             createdAt:{
  //               from: dateRange.start,
  //               to: dateRange.end
  //             }},
          
  //           page:pageToFetch, 
  //           admin_id:adminId,
  //         }),
  //       });
  //       const responseData = await response.json();
  //       if (responseData.success) {
  //         setuserData(responseData.data.users);
  //         setResponseData(responseData.data.users);
  //         setSelectedUser(responseData.data.users[0]);
  //         setUserCount(responseData.data.total_users)
  //         setTotalPages(responseData.data.total_pages)
  //         setLoading(false);
  //       } else {
         
  //       }
  //     } catch (error) {
  //       setLoading(false);
       
  //     }
  //   };
   
      
  //       fetchData(accessToken);
  
   
  // }, [UserFilters,dateRange,dateParamFromLs]);


  //set the select tag value to the value coming from overview page /localstorage
  useEffect(()=>{
    let dateFromLs=localStorage.getItem("dateParam")
    setUserSelectDate(dateFromLs) 
  },[])





  const handleDebounceSearch=debounce((value)=>{
    
    setFiltersChanged(true)
    setIsSearchActive(value !== ''); 
    setUserFilters((prev) => ({
      ...prev,
      page: 1,
      filters: {
        ...prev.filters,
        searchInput: value, 
      },
    }));
  },1000)
  
  const handleSearchChange = (e) => {
    const value = e.target.value;
   
    setSearchInput(value); 
    handleDebounceSearch(value); 
  };
  





  const handleSearchInputChange = (event) => {
    const input = event.target.value;
    setSearchInput(input);
  };





 const getKycStatusLabel = (kycStatus) => {
  switch (kycStatus) {
    case 'Verified':
      return 'Verified';
    case 'In Review':
      return 'In Review';
    case 'Rejected':
      return 'Rejected';
    case 'Not Verified':
      return 'Not Verified';
      
    default:
      return 'Not Started';
  }
};


const getKycStatusClass = (kycStatus) => {
  switch (kycStatus) {
    case 'Verified':
      return 'verifiedKyc';
    case 'In Review':
      return 'reviewKyc';
    case 'Rejected':
      return 'rejectedKyc';
    case 'Not Verified':
      return 'notstartedKyc';
    default:
      return 'notstartedKyc';
  }
};


const handlePageChange = (data) => {
  setFiltersChanged(false);

  if (currentPage === 1 && data.selected === 0) {
    // Prevent navigation if already on the first page
    return;
  }
  const newPage = data.selected + 1; 
  
  setPage(newPage); 

  setCurrentPage(newPage);
  
    // Update the current page
    navigate(`/user?page=${newPage}`); 
   
};

const handleDateRangeChange = (event) => {
  const value = event.target.value;
localStorage.setItem("dateParam",value)
setUserSelectDate(value)
  const currentDate = new Date();
  let startDate,endDate;

 
  switch (value) {
    case 'Today':
      startDate = currentDate;
      endDate = new Date(currentDate);
      break;
    case 'Yesterday':
      startDate = new Date(currentDate-1);
      startDate.setDate(currentDate.getDate() - 1);
      endDate = startDate;
      break;
    case 'Past 7 Days':
      startDate = new Date(currentDate);
      startDate.setDate(currentDate.getDate() - 6);
      endDate = new Date(currentDate);
      break;
    case 'Past 30 Days':
      startDate = new Date(currentDate);
      startDate.setDate(currentDate.getDate() - 29);
      endDate = new Date(currentDate);
      break;
    case 'Past 365 Days':
      startDate = new Date(currentDate);
      startDate.setDate(currentDate.getDate() - 364);
      endDate = new Date(currentDate);
      break;
    default:
      startDate = null;
  }

  setDateRange({
    start: formatDate(startDate, '00:00:00'), 
    end: formatDate(endDate, '23:59:59'),
  });

  setUserFilters({
    
      ...UserFilters,
     
      filters: {
        ...UserFilters.filters,
        createdAt:{
          from: formatDate(startDate, '00:00:00'),
          to: formatDate(endDate, '23:59:59')
        }},
    
      
    
  })
};


const fetchUserReportLink=()=>{

  
  fetch(`${ApiBaseUrl}/fr-admin/download-users-report`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken
      },
      body: JSON.stringify({
    
        ...UserFilters,
       
        filters: {
          ...UserFilters.filters,
          createdAt:{
            from: dateRange.start,
            to: dateRange.end
          }},
    })
    }
  )
      .then(response => {
      return response.json();
      })
      .then(resdata => {
       if(resdata.success){
        setUserRepostLink(resdata.data.report_url)
        downloadCSV(resdata.data.report_url);
       }
       
      })
      .catch(error => {
       
        
      });
}
const downloadCSV = (url) => {
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = url.split('/').pop(); 
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};
//download report
const getUserReportLatestLink=()=>{
  fetchUserReportLink()
  }


  return (
    <AdminContainer pageTitle="User Account" subtitle="User Management">
        <div className='container font-nunito'>

      <div className='w-full flex gap-3'>

    

            <div className=' flex flex-row justify-between gap-2 userlist pd-4' >

            <div className='flex flex-col' style={{width:"100%"}}>



            <div className='flex flex-row w-full justify-between p-4 gap-7 rounded-[10px] bg-gray2'>


            {/* searchbar */}
            <div className='flex flex-row gap-[40px]'>
              {/* <div className='userGuideText'>User Account</div> */}
              <div>
              <div className="relative text-gray-600">
          
              <button type="submit" className="absolute left-0 top-0 mt-3 ml-4">
                      <IoIosSearch className="h-4 w-4 fill-current" />
                    </button>
                    <input
                      type="search"
                      name="search"
                      value={searchInput}
                      onChange={handleSearchChange}
                      placeholder="Search By User name, mobile no, email"
                      className="bg-white h-10  px-5 pl-10 rounded-2xl text-sm focus:outline-none userSearchBar"
                    />
                    
                  </div>
                  
              </div>
            </div>
 
          
            
            <div className='flex gap-5' > 

                   <button onClick={getUserReportLatestLink} className=' flex gap-2.5 items-center justify-center text-[#322F37]'>
                   
                    <p className='text-xs font-bold font-nunito'>Download Report </p>
                    <MdOutlineFileDownload className='text-2xl' />
                    </button>
                    {/* <button className='bg-pink rounded-lg flex gap-2.5 py-2.5 px-4 items-start justify-center text-white'>
                    <MdOutlineFileDownload className='' />
                    <p className='text-sm font-bold font-nunito'>+ Add User Account</p>
                    </button> */}
            
            
          
            </div>
            
                           
            
            
            
                           
                        </div>


                        <div className='flex flex-row justify-between items-center gap-3 mt-[25px]'>
<div className='flex gap-2.5 items-center'>
    <select  name="dateRange" id="dateRange" value={UserSelectDate} className="selectClassNew" onChange={handleDateRangeChange}>
                  <option value="All Time">All Time</option>
                  <option value="Today">Today</option>
                    <option value="Yesterday">Yesterday</option>
                    <option value="Past 7 Days">Past 7 Days</option>
                    
                    <option value="Past 30 Days">Past 30 Days</option>
                    <option value="Past 365 Days">Past 365 Days</option>
     </select>

     <div className='flex gap-1 items-center justify-center'>
              <RxDotFilled  className='text-2xl text-green'/>
              <p className='text-xs '>Active User</p>

     </div>

     <div className='flex gap-1 items-center justify-center'>
              <RxDotFilled  className='text-2xl text-red'/>
              <p className='text-xs '>Inactive User</p>

     </div>
</div>

                      


<div className='flex gap-3 items-center '>
<div><MdOutlineSort onClick={toggleSortPopup} style={{height:"25px",width:"25px"}} /></div>
                        <div><MdOutlineFilterAlt  onClick={togglePopup} style={{height:"25px",width:"25px"}} /></div>
                    
</div>

                    
                      </div>           





                      <div className={ `rounded-xl sortFilterWidth popup-containerBookedFilter   ${showSortPopUp ? 'active' : ''}`} style={{ boxShadow: '0px 4px 30px 0px #00000026' }}>
                      <UserSort clearFilterSelection={clearFilterSelection}  isOpenMob={showSortPopUp} setIsOpenMob={setShowSortPopUp} setFiltersChanged={setFiltersChanged}/>
                     </div>
                      



                      <div className={ `rounded-xl  sortFilterWidth popup-containerBookedFilter  ${isOpenMob ? 'active' : ''}`} style={{ boxShadow: '0px 4px 30px 0px #00000026' }}>
                      <UserFilter clearFilterSelection={clearFilterSelection}  isOpenMob={isOpenMob} setIsOpenMob={setIsOpenMob} setFiltersChanged={setFiltersChanged}/>
                     </div>
                      



                <div className='mt-[20px] ml-2'>
                  <div className='flex flex-row gap-4 text-sm' style={{width:"100%",height:"29px"}}>
                    <div className=' userSerailNo'>S. No</div>
                    <div className='userNameHead'>User Name</div>
                    <div className='userMobNo'>Mobile Number</div>
                    <div className='UserEmail '>Email Address</div>
                    <div className=' userKycStat'>KYC Status</div>
                    <div className='UserCreatedCol'>Created At</div>
                    <div className='actionMenuUser'></div>
                   
                  </div>
                </div>
            

                {isLoading ? (
                  <div className="alertBar" style={{height:"100vh"}}> 
                    <BeatLoader color='#D382A7'/>
                  </div>
                ) : userData.length === 0 ? (
                  searchInput === '' ? (
                    <div className='noPropContainer'>
                      <div className='noPropImg'></div>
                      <p className='noPropText mt-12 text-black'>No User </p>
                    </div>
                  ) : (
                    <div className='noPropContainer'>
                      <div className='noPropImg'></div>
                      <p className='noPropText text-gray'>No User is Found </p>
                    </div>
                  )
                ) : (
                  <div className='userListScroll'>

                  <div className='propertyContainer  bg-white flex-wrap gap-5 mt-2'>
                    {userData.map((user, index) => (
                       <div
                       key={index}
                       className={`user-card ${selectedUser?.user_id === user.user_id ? 'selected' : ''}`}
                       onClick={() => setSelectedUser(user)}
                     >
                      <User 
                        key={index}
                        
                        serialNo={index + 1}
                        firstName={user.first_name}
                        mobile={user.mobile}
                        email={user.email}
                        dob={user.dob}
                        wallet={user.wallet}
                        address={user.address}
                        pincode={user.pincode}
                        countryCode={user.country_code}
                        country={user.country}
                        city={user.city}
                        state={user.state}
                        countryShortCode={user.country_short_code}
                        gender={user.gender}
                        profilePic={user.profile_pic ? user.profile_pic.Key : null}
                        userId={user.user_id}
                        kycId={user.kyc_id}
                        kycStatus={user.kyc_status}
                        createdAt={user.created_at}
                        stateShortCode={user.state_short_code}
                        active={user.active}
                        isSearchActive={isSearchActive} 
                        
                      />
                      </div>
                    ))}











                  </div>
                  </div>
                )}


<div className="pagination-container py-2">
            <ReactPaginate
              previousLabel={<MdOutlineKeyboardArrowLeft/>}
              nextLabel={<MdOutlineKeyboardArrowRight />}
              breakLabel={'...'}
              breakClassName={'pagination-break'}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={toTalPages}
              pageClassName={`pagination-button`}
              onPageChange={handlePageChange}
              forcePage={currentPage-1}
              containerClassName={'pagination flex justify-end items-center gap-1.5'} 
              activeClassName={'pagination-active border border-pink text-black  rounded'} 
              previousClassName={`pagination-prev ${
                currentPage === 1 ? 'pageinateDisable ' : ''
              }`} 
              nextClassName={'pagination-prev '} 
            />




        </div>





            
          </div>

          
            


            
      
      
          </div>

      
       

      

       <div className='userRightSection flex flex-col gap-5'>


                  <div className='bg-gray2 rounded-lg px-2 py-2 flex flex-col gap-1.5'>
                   <p className='text-xs'>Total Number of Users</p>
                   <p className='text-lg font-bold text-pink'>{UserCount && UserCount.toLocaleString()}</p>
                  </div>

           


                

                  {selectedUser ? (
                      <div className='userProfile  rounded-lg shadow '>

                <div className='flex flex-col gap-4'>
                  
                  <div className='flex flex-col gap-4'>
                    {
                      !selectedUser.profile_pic?.Key ?
                      (
                        <div>
                          <img className='userAvatarImg border border-lightgray' src={avatar} alt="User" />
                    
                        </div>

                        
                      )
                      :
                      (
                        <img className='userProfileImg' src={`${PropertImageBaseUrl}/${selectedUser.profile_pic?.Key}`} alt="User Profile" />
                      )
                    }
                       

                        <p className='text-[20px] text-pink font-semibold'>{selectedUser.first_name || "User"}</p>

                        <p className='text-xs  font-medium'>
                          USER ID <span className='text-gray'>{selectedUser.user_id}</span> 
                        </p>
                      

                  </div>


                  <div className='flex flex-col gap-6'>

                  <div className='flex flex-col gap-8'>
                      <p className='text-blue text-lg font-semibold'>KYC Status</p>
                      <button className={`kycStatusBtn ${getKycStatusClass(selectedUser.kyc_status)}`}>
                      {getKycStatusLabel(selectedUser.kyc_status)}
                      
                      
                      </button>
                  </div>
{
  selectedUser.mobile && (
<div className='flex flex-col gap-3 '>
<p className='text-lg font-medium text-gray text-center'>Contact Info</p>

<div className='flex gap-2.5 items-center justify-center'>
<MdCall className='text-pink' />
<p className='text-blue text-md font-medium'>{selectedUser.mobile} </p>
</div>

</div>
  )
}



            </div>


                </div>


</div>
        ) : (
          <p>No user selected.</p>
        )}


</div>










      </div>


        </div>
    </AdminContainer>
  )
}
