import React, { useEffect, useState } from 'react'
import { CiEdit } from 'react-icons/ci'
import { FaCircleCheck, FaRegCircleUser } from 'react-icons/fa6'
import { PropertImageBaseUrl } from '../../../Utils/urls'
import { MdOutlineCameraAlt, MdOutlineErrorOutline } from 'react-icons/md'
import Switch from 'react-switch'; 
import { countries } from "countries-list"
import ReactFlagsSelect from 'react-flags-select'
import "./admin-edit.css"
import profileAvatar from "../../../Assets/Icons/profile_avatar.svg"
import { changeDateFormatAdmin } from '../../../Utils/dateFormat'
import { useParams } from 'react-router-dom'
import useAdminAccess from '../../AdminAccessContext/Useadminaccess'
import { HiOutlineEye, HiOutlineEyeOff } from 'react-icons/hi'


export default function EditAdminDetails(
  {adminData,
    handleInputChange,
    fileError,
    handleProfilePicChange,
    setAdminData,
    setSelected,
    selected,
    customLabels,
    selectedProfilePic,
    adminAccess,
    isLoading,

    isLengthValid,
    hasSymbolOrNumber,
    hasUpperCase,
    changeConfirmPass ,
confirmpassword,
isConfirmPassCorrect,
passwordMismatch
  
  }) {

    const {id:staffId}=useParams()
    const [activateAdminChecked, setActivateAdminChecked] = useState(adminData.profile?.active);
  
 
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isSuperAdmin,setIsSuperAdmin]=useState(false)

    
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };
    
    const toggleConfirmPasswordVisibility = () => {
      setShowConfirmPassword(!showConfirmPassword);
    };
    

const handleActivateAccnt = (checked) => {
  setActivateAdminChecked(checked);
  setAdminData(prevState => ({
    ...prevState,
    profile: {
        ...prevState.profile,
        active: checked
    }
}));
}




useEffect(() => {
  const permissions = adminData.profile?.permissions?.add_admin;
  setIsSuperAdmin(permissions && Object.values(permissions).every(value => value === 1));
}, [adminData]);









  return (
    <div className='propCard'>

    {/* sec-1 */}
    
      <div className='flex justify-between items-center'>
        <p className='text-2xl font-medium text-gray'>{isSuperAdmin?'Super Admin':'Sub Admin'}</p>
        {/* <button className='propSaveBtn bg-lightgray text-gray'>Save</button> */}
      </div>
    
    {/* sec-2 */}
      <div className='flex justify-between'>
    
        <div className='flex flex-col gap-4 adminDetails'>
    
    <div className='flex justify-between items-center'>


<div  className='flex gap-7'>
<div className='flex gap-2 py-3  items-center'>
<span className="material-symbols-outlined">
    admin_panel_settings
    </span>
             <p className='text-gray text-lg font-medium'>Admin ID {staffId && staffId}</p>
          </div>

         
</div>
  




    </div>

          
    
          <div className='flex justify-between'>

              <div className='flex flex-col gap-2.5 items-start adminInputText'>
                  <p className='text-sm text-blue'>User Name <span style={{color:"#af2525"}}>*</span> </p>
                  <input type="text" 
                  name="name" 
               
                  placeholder='E.g John Deo'
                  data-section="profile"
                  className={`${adminAccess?.edit_admin?'propInputText':'propDisableInputText'}  `}
                  disabled={!adminAccess?.edit_admin}
                  value={adminData.profile?.name}
                  onChange={handleInputChange}
                   />
              </div>
    
  
           


       

       <div className='flex flex-col gap-2.5 items-start adminInputText'>
                  <p className='text-sm text-blue'>Mobile <span style={{color:"#af2525"}}>*</span> </p>

               <div className='flex gap-2.5  w-full'>

               <ReactFlagsSelect

selectButtonClassName="menu-flags-button "
className="menu-flags"
    selected={selected}
    onSelect={(code) => setSelected(code)}
    searchable
    searchPlaceholder="Search countries"
    showSelectedLabel={false}
    customLabels={customLabels}
    showSecondarySelectedLabel={false}

  />
 <input type="text" 
                  name="mobile" 
             
                  placeholder='E.g 1234556890'
                  data-section="profile"
                  value={adminData.profile?.mobile}
                  onChange={handleInputChange}
                  maxLength={10}
                  className={`${adminAccess?.edit_admin?'propInputText':'propDisableInputText'}  `}
                  disabled={!adminAccess?.edit_admin}
                   />


                </div> 
                
                
                
                 
            

</div>



    
</div>
    
    
<div className='flex justify-between'>


<div className='flex flex-col gap-2.5 items-start adminInputText'>
                  <p className='text-sm text-blue'>Email <span style={{color:"#af2525"}}>*</span> </p>
                  <input type="email" 
                  name="email" 
                  className={`${adminAccess?.edit_admin?'propInputText':'propDisableInputText'}  `}
                  disabled={!adminAccess?.edit_admin}
                  placeholder='E.g JohnDeo@gmail.com  '
                  data-section="profile"
                  value={adminData.profile?.email}
                  onChange={handleInputChange}
                   />
              </div>
    


  



    <div className='flex flex-col gap-2.5 items-start adminInputText'>
                  <p className='text-sm text-blue'>Gender </p>
                 <select name="gender" 
                  value={adminData.profile?.gender} 
                  onChange={handleInputChange} 
                  className={`${adminAccess?.edit_admin?'propInputText':'propDisableInputText'}  `}
                  disabled={!adminAccess?.edit_admin}
                   data-section="profile">

                  <option disabled selected>Select</option>
                  <option value="Female" >Female</option>
                  <option value="Male" >Male</option>
                  <option value="Other" >Other</option>
                 </select>
              </div>
    
</div>
    

<div className='flex justify-between'>


<div className='flex flex-col gap-2.5 items-start adminInputText'>
                  <p className='text-sm text-blue'>Password <span style={{color:"#af2525"}}>*</span> </p>
                  <div className='relative w-full'>
      <input
        type={showPassword ? "text" : "password"}
        name="password"
        className='propInputText'
        placeholder='E.g Shubhankar456#'
        data-section="profile"
        value={adminData.profile?.password}
         onChange={handleInputChange}
      />
      <div className='absolute right-2 top-3 cursor-pointer' 
      onClick={togglePasswordVisibility}
      >
        {showPassword ? <HiOutlineEye className='text-pink' /> : <HiOutlineEyeOff className='text-pink'/>}
      </div>
    </div>

<div className='flex flex-col gap-2'>
<div className='flex gap-2 items-center'>
  <FaCircleCheck  className={isLengthValid ? 'text-green' : 'text-gray'} />
  <p className={isLengthValid ? 'text-blue' : 'text-gray'}>At least 8 characters</p>
  </div>

  <div className='flex gap-2 items-center'>
  <FaCircleCheck className={hasUpperCase ? 'text-green' : 'text-gray'} />
  <p className={hasUpperCase ? 'text-blue' : 'text-gray'}>Contains uppercase letters</p>
  </div>

  <div className='flex gap-2 items-center'>
  <FaCircleCheck className={hasSymbolOrNumber ? 'text-green' : 'text-gray'} />
  <p className={hasSymbolOrNumber ? 'text-blue' : 'text-gray'}>Contains a symbol or a number</p>
  </div>
</div>

              </div>
    

              <div className='flex flex-col gap-2.5 items-start adminInputText'>
                  <p className='text-sm text-blue'>Confirm Password <span style={{color:"#af2525"}}>*</span> </p>
                  <div className='relative w-full'>
      <input
        //type={showPassword ? "text" : "password"}
        name="Confirm-Password*"
        className={`${passwordMismatch?'border-red':'border-[#dbdbdb]'} border confirmPassInput`}
        placeholder='E.g Shubhankar456#'
        data-section="profile"
       value={confirmpassword}
         onChange={changeConfirmPass}
      />
      <div className='absolute right-2 top-3 cursor-pointer' 
      
      >

    <FaCircleCheck className={isConfirmPassCorrect?'text-green':'text-gray'} />
      </div>
      
    </div>

    {passwordMismatch && (
         <div className='flex gap-2 items-center'>
         <MdOutlineErrorOutline  className={'text-red'} />
         <p className={''}>Password Mismatch</p>
         </div>
  
  )} 

              </div>
    

  
    
</div>
    






   <div className='flex justify-between'>
   <div className='flex flex-col gap-2.5 items-start adminInputText'>
                  <p className='text-sm text-blue'>Date  of Birth</p>
                  <input type="date" 
                  name="dob" 
                  data-section="profile"
                
                  value={adminData && adminData.profile ? changeDateFormatAdmin(adminData.profile.dob) : ""}
                  onChange={handleInputChange}
                  className={`${adminAccess?.edit_admin?'propInputText':'propDisableInputText'}  `}
                  disabled={!adminAccess?.edit_admin}
                  placeholder='01/02/2004'
    
                   />
              </div>

    
</div> 
    



        </div>
    
    
    {/* prop image main */}
        <div className='propImage flex flex-col gap-5'> 
        
        <div className='photoContainer'>

        <div class="profilepic-container">



{/* {adminData.profile?.profile_pic?.base64 ? (
                <img src={adminData.profile?.profile_pic?.base64} alt="Avatar" className="profilePic" />
              ) : (
                <img src={profileAvatar} alt=""  className="ProfileAvatar" />
             
              )} */}

{
      adminData.profile?.profile_pic?.base64 ? (
        <img src={adminData.profile?.profile_pic?.base64} alt="Avatar" className="profilePic" />
      ) : (
        adminData.profile?.profile_pic?.Key ? (
          <img className="profilePic" src={`${PropertImageBaseUrl}/${adminData.profile?.profile_pic?.Key}`} alt="Avatar" />
        ) : (
          <img className="ProfileAvatar" src={profileAvatar} alt="Avatar" />
        )
      )
    }        


              <input
                type="file"
                className="profileImgFile"
        
              
                name="profile_pic"
                id="file-upload"
                accept="image/jpeg,image/png"
                onChange={handleProfilePicChange}
                disabled={adminAccess?.edit_admin==0}
              /> 
 





</div>

<label for="file-upload" class="upload-icon">
        <MdOutlineCameraAlt />
    </label>


    
</div>
    

<div>
{fileError && <p style={{ color: 'red',fontSize:"12px" }}>{fileError}</p>}
</div>
 
    
    
    {/* property-activation toggle */}
    
    <div className='flex items-center justify-center gap-2.5'>
    
          <p className='text-gray text-sm'>Deactivate Account   </p>
       
          <Switch
             checked={adminData.profile?.active}
             onChange={handleActivateAccnt}
            offColor="#888" 
            onColor="#419E6A"
            uncheckedIcon={false}  
            checkedIcon={false}
            width={36}
            height={20}
            handleDiameter={13.5}
            disabled={adminAccess?.edit_admin==0}
            
          />
    
    
    </div>
    
    
    
        </div>
    
    
      </div>
    
    
    
   
    
   
    
    
    
    
    </div>
  )
}
