import React, { createContext, useContext, useState } from 'react';
const TransactionFilterContext = createContext();
export const useTransactionFilters = () => useContext(TransactionFilterContext);

const currentDate = new Date();
const sixtyDaysAgo = new Date(currentDate);
sixtyDaysAgo.setDate(sixtyDaysAgo.getDate() - 60);
sixtyDaysAgo.setHours(sixtyDaysAgo.getHours() + 5);
sixtyDaysAgo.setMinutes(sixtyDaysAgo.getMinutes() + 30);
export const fromDateString = sixtyDaysAgo.toISOString().slice(0, 19).replace('T', ' ');

currentDate.setHours(currentDate.getHours() + 5); 
currentDate.setMinutes(currentDate.getMinutes() + 30); 
export const toDateString = currentDate.toISOString().slice(0, 19).replace('T', ' ');
const adminId=localStorage.getItem("adminId")

export const initialPropertyFiltersTransaction = {
 "admin_id":localStorage.getItem("adminId"),
    "page": 1,
    "pageSize":5,
    "filtersByString": {
      "sale_type": null //["Public Sale","Resale","Presale"]
    },
    "filterByStatus": [], //["COMPLETED","PENDING","CANCEL"]
    "purchased_date": {
        "from": fromDateString,
        "to": toDateString
      },
      "transaction_type":["Purchase Transactions"],
      "filters":{
        "searchInput": ""// Search input to match any field
        },
        "sortBy": {
          "transaction_date": "desc", // 'desc', 'asc' by default: null
          "token_price": null // 'desc', 'asc' by default: null
      },
    "filtersByPlaces": [], //[{"city_id": 6, "locations": []}],
    "filtersByBuilders": [], //[8]
    "filtersByProperties":[]
  };
  
export const TransactionFiltersProvider = ({ children }) => {
  const [TransactionpropertyFilters, setTransactionPropertyFilters] = useState(initialPropertyFiltersTransaction);
  const setPage = (page) => {
    setTransactionPropertyFilters((prev) => ({
      ...prev,
      page
    }));
  };

  return (
    <TransactionFilterContext.Provider value={{ TransactionpropertyFilters, setTransactionPropertyFilters,setPage }}>
      {children}
    </TransactionFilterContext.Provider>
  );
};



