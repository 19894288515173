import React from 'react'
import { IoMdClose } from 'react-icons/io'
import PopUp from '../PopUp/PopUp'
import FloatingLoader from '../Loader/FloatingLoader'

export default function AddLocationPopup(
{
    addlocLoading,
    isLocAddButtonEnabled,
    handleAddLocationTodB,
    handleCloseAddLocPopUp,
    selectedCityId,
    handleCityChange,
    cityLocationData,
    newLocation,
    setNewLocation,
    locationList,
    handleAddLocation,
    handleRemoveLocation
}
) {
  return (
    <PopUp maxWidth={'max-w-xl'}>
      {
         addlocLoading && (
      <FloatingLoader/>
         )
      }
      
               
      <div className='flex flex-col gap-4'>
      <div className='flex justify-between'>
            <p className='text-blue text-lg font-bold' >Add Location*</p>
      
            <div className='flex gap-4 items-center'>
               <button 
               disabled={!isLocAddButtonEnabled}
              className={`${!isLocAddButtonEnabled?'text-gray bg-lightgray':'text-white bg-pink'} rounded-lg text-xs px-2 py-1`}
              onClick={handleAddLocationTodB}
                > 
                Save Details
                </button>
               <IoMdClose className='text-2xl cursor-pointer' onClick={handleCloseAddLocPopUp} />
            </div>
      
         </div>
         <hr className='prophrClass' />
      
         <div className='flex gap-5'>
                    <div className='flex flex-col gap-2.5 items-start popUpinput'>
                        <p className='text-sm text-blue'>City <span style={{color:"#af2525"}}>*</span> </p>
                        <select name=""  id="" value={selectedCityId} onChange={handleCityChange} className='propSelectInput'>
                  <option value="" selected disabled>Select</option>
                 {cityLocationData && cityLocationData.map(city => (
            <option key={city.city_id} value={city.city_id}>{city.city_name}</option>
          ))}
                
                 </select>
                    </div>
      
                   
          
                    <div className='flex flex-col gap-2.5 items-start popUpinput'>
      
      <div className='flex w-full justify-between items-center'>
      <p className='text-sm text-blue'>Location  <span style={{color:"#af2525"}}>*</span> </p>
        <button className='bg-blue text-white rounded text-xs px-2 cursor-pointer' onClick={handleAddLocation}>Add</button>
      </div>
       
        
         <input type="text" 
         name="" 
         value={newLocation}
         onChange={(e) => setNewLocation(e.target.value)}
         className='propInputText'
         placeholder='Brookefield Mall'

          />
     </div>
          
          
                </div>
      
      
               
      
      
                <div className='flex gap-5 h-24'>
                    <div className='cityTagDiv'>
                 
                    {locationList && locationList.map((location, index) => (
                        <div key={index} className='locationBadge'>
                          <p>{location}</p>
                          <IoMdClose className='cursor-pointer' onClick={() => handleRemoveLocation(index)} />
                        </div>
                      ))}
    
                      
      
      
     
      
                    </div>
                  
                   
          
          
                </div>
      </div>
      
      
        
      </PopUp>
  )
}
