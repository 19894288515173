import React, { useState } from 'react'
import { IoIosClose } from 'react-icons/io'
import { usePropertyFilter } from '../../Utils/PropertyFilter'

export default function PropertyFilter(props) {
const {setShowPropertyFilter,showPropertyFilter}=props
const handleCloseFilter=()=>{
    setShowPropertyFilter(false)
}

const { PropFilters, setPropFilters,setPage } = usePropertyFilter();
const [isFilterActive, setIsFilterActive] = useState(false);
const [propPhase, setPropPhase] = useState([]);  
const [saleType, setSaleType] = useState([]); 


const handlePropertyPhase = (phase) => {
  const updatedPropPhase = propPhase.includes(phase)
    ? propPhase.filter((type) => type !== phase)
    : [...propPhase, phase];

    setPropPhase(updatedPropPhase);
  setIsFilterActive(true);
  setPropFilters((prevFilters) => ({
    ...prevFilters,
    filters: {
      ...prevFilters.filters,
      property_phase: updatedPropPhase,
    },
  }));
};


const handleSaleTypeClick = (saleT) => {
  const updatedSaleType = saleType.includes(saleT)
    ? saleType.filter((type) => type !== saleT)
    : [...saleType, saleT];

    setSaleType(updatedSaleType);
  setIsFilterActive(true);
  setPropFilters((prevFilters) => ({
    ...prevFilters,
    filters: {
      ...prevFilters.filters,
      property_sale_type: updatedSaleType,
    },
  }));
};


const handlePropPhaseClearClick = () => {
  setPropPhase([]);
  setPropFilters((prevFilters) => ({
    ...prevFilters,
    filters: {
      ...prevFilters.filters,
      property_phase: [],
    },
  }));
};


const handlePropSaleTypeClear = () => {
  setSaleType([]);
  setPropFilters((prevFilters) => ({
    ...prevFilters,
    filters: {
      ...prevFilters.filters,
      property_sale_type: [],
    },
  }));
};

const handleclearAll=()=>{
  setSaleType([]);
  setPropPhase([]);

   setPropFilters((prevFilters) => ({
    ...prevFilters,
    filters: {
      ...prevFilters.filters,
      property_sale_type: [],
      property_phase: [],
    },
  }));
}

  return (
    <div className={`propertyFilterCard ${showPropertyFilter?'block':'hidden'}`}>
  <div className='flex justify-between items-start border-lightgray  border-b '>
    <p className='text-base font-bold text-blue'>Filter</p>
    <div className='flex gap-2 items-center'>
    <p className='text-sm  text-gray cursor-pointer' onClick={handleclearAll}>Clear all</p>
    <IoIosClose  className='cursor-pointer' onClick={handleCloseFilter} />
    </div>
   

    
  </div>


  <div className=' text-black flex flex-col pt-4'>
  <div className='flex flex-row justify-between'>
  <div className='text-[16px] font-medium text-start'>Sale Type</div>
  <div className='flex flex-col justify-center'>
    <div className='clearButton text-xs text-start font-normal -mr-1 flex flex-col justify-center rounded-lg py-0.5 px-2 h-5 cursor-pointer'
   onClick={handlePropSaleTypeClear}
    >Clear</div>
  </div>

  </div>
    <div className=' flex flex-row flex-wrap gap-2 p-2 -ml-2'>
   
     

<button
        className={`propertyPhasesButton  text-black text-sm w-20 h-7  whitespace-nowrap ${
          saleType.includes('Resale') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handleSaleTypeClick('Resale')}
      >
        Resale
      </button>
     

      <button
        className={`propertyPhasesButton  text-black text-sm w-20 h-7  whitespace-nowrap ${
          saleType.includes('Launching') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handleSaleTypeClick('Launching')}
      >
        Launching
      </button>
      <button
        className={`propertyPhasesButton  text-black text-sm w-20 h-7  whitespace-nowrap ${
          saleType.includes('Open') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handleSaleTypeClick('Open')}
      >
        Open
      </button>
     
      <button
        className={`propertyPhasesButton  text-black text-sm w-20 h-7  whitespace-nowrap ${
          saleType.includes('Sold out') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handleSaleTypeClick('Sold out')}
      >
        Sold out
      </button>
     
      
    </div>
  </div>



  <div className=' text-black flex flex-col pt-4'>
  <div className='flex flex-row justify-between'>
  <div className='text-[16px] font-medium text-start'>Property Phases</div>
  <div className='flex flex-col justify-center'>
    <div className='clearButton text-xs text-start font-normal -mr-1 flex flex-col justify-center rounded-lg py-0.5 px-2 h-5 cursor-pointer'
   onClick={handlePropPhaseClearClick}
    >Clear</div>
  </div>

  </div>
    <div className=' flex flex-row flex-wrap gap-2 p-2 -ml-2'>
   
     

<button
        className={`propertyPhasesButton  text-black text-sm w-20 h-7  whitespace-nowrap ${
          propPhase.includes('Public Sale') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handlePropertyPhase('Public Sale')}
      >
        Public Sale
      </button>
     

      <button
        className={`propertyPhasesButton  text-black text-sm w-20 h-7  whitespace-nowrap ${
          propPhase.includes('Presale') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handlePropertyPhase('Presale')}
      >
        Presale
      </button>
     
     
     
     
      
    </div>
  </div>




</div>
  )
}
