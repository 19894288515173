import React, { createContext, useContext, useState } from 'react';
const UserFilterContext = createContext();
export const useUserFilters = () => useContext(UserFilterContext);
const adminId=localStorage.getItem("adminId")
export const initialUserFilter = {
  "admin_id":adminId,
    "filters": {
      "accountStatus": [],
      "kycStatus": [],
      "sortBy": "Newest",
      "searchInput": "",
      "createdAt":{
        "from":"",
        "to":""
      }
    },
    "pageSize": 10,
    "page": 1,
  };
  
export const UserFiltersProvider = ({ children }) => {

  
  const [UserFilters, setUserFilters] = useState(initialUserFilter);
  //const [currentPage,setCurrentpage]=useState(1)
  // update page
  const setPage = (page) => {
    setUserFilters((prev) => ({
      ...prev,
      page
    }));
  };


 



  return (
    <UserFilterContext.Provider value={{ UserFilters, setUserFilters,setPage }}>
      {children}
    </UserFilterContext.Provider>
  );
};



