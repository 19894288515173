import React from 'react'
import {BsThreeDots} from "react-icons/bs";
function OverviewCard(props) {
 
  return (
    <div>
    <div className='flex flex-row justify-between h-[54px] rounded-xl pr-[20px] highlightOverviewCard '>
    <div className='w-[30px] flex flex-col justify-center'>{props.serialNo}</div>
    <div className='w-[340px] flex flex-col justify-center'>{props.propertyName}</div>
    <div className='w-[100px] flex flex-col justify-center'>{props.soldShares}/{props.totalShares}</div>
    <div className='w-[100px] flex flex-col justify-center'>{props.tokenSaleAmount}</div>
    <div className='flex flex-col justify-center'>
{props.property_phase==="Presale"? 
<div className='w-[100px] flex flex-col justify-center bg-[#D3E1FE] h-[32px] rounded-2xl text-[#7EA5F8] text-[12px] font-medium'>Presale</div>:
props.property_phase==="Public Sale"?
<div className='w-[100px] flex flex-col justify-center bg-[#DDF3DE] h-[32px] rounded-2xl text-[#3A974C] text-[12px] font-medium'>Public Sale</div>:""
}
    </div>
    <div style={{display:"none"}} className=' text-[12px] font-normal flex flex-col justify-center'><BsThreeDots style={{height:"25px",width:"25px",color:"#838383"}} /></div>
  </div>
    </div>
  )
}

export default OverviewCard
