export function formatDate(inputDate) {
   
    const date = new Date(inputDate);
   const day = String(date.getDate()).padStart(2, '0'); // Day as two digits
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month as two digits
    const year = date.getFullYear(); // Year as four digits
  
    // Format "DD/MM/YYYY" 
    const formattedDate = `${day}/${month}/${year}`;
  
    return formattedDate;
  }
  
  export function formatTime(inputDate){
    const date = new Date(inputDate);
    const hours = String(date.getUTCHours()).padStart(2, '0'); // UTC hours
const minutes = String(date.getUTCMinutes()).padStart(2, '0'); 
const formattedTime=`${hours}:${minutes}`
return formattedTime
  }


  export function formatTimeWithAMPM(inputDate) {
    const date = new Date(inputDate);
    let hours = date.getUTCHours(); 
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  
    // Determine AM or PM
    const ampm = hours >= 12 ? 'PM' : 'AM';
  
   
    hours = hours % 12;
    hours = hours ? hours : 12; 
  
    
    const formattedHours = String(hours).padStart(2, '0');
  
    
    const formattedTime = `${formattedHours}:${minutes} ${ampm}`;
  
    return formattedTime;
  }
  
  
//for admin fetch edit
  export const changeDateFormatAdmin=(dateString)=>{

    const date = new Date(dateString);
    
    
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so we add 1
    const day = String(date.getDate()).padStart(2, '0');
    
    // Format the date as "YYYY-MM-DD"
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate
    }
    

export const formatDateWithMonthName=(isoDateString)=>{

const date = new Date(isoDateString);


const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

// Extract the day, month, and year
const day = date.getUTCDate();
const month = monthNames[date.getUTCMonth()];
const year = date.getUTCFullYear();

// Format the date as "1-Jan-2024"
const formattedDate = `${day}-${month}-${year}`;
return formattedDate

}
 



