import { ApiBaseUrl } from "../Utils/urls";

export const fetchPaymentSchedule=
(setLoading,
    propertyId,
    setPaymentSchedule,
    setPayments,
    setPaymentNote,
    setPaymentConditions
)=>{
    setLoading(true)
    fetch(`${ApiBaseUrl}/fr-admin/get-payment-schedule-info?property_id=${propertyId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
       
      }
     
    }
  )
      .then(response => {
      
     return response.json();
      })
      .then(resdata => {
       
       //console.log('Response from server:', resdata);
       setLoading(false)
      if(resdata.success)
         {
          setPaymentSchedule(resdata.data)
          setPayments(resdata.data.payment_schedules)
          setPaymentNote(resdata.data.payment_schedule_note)
          if(resdata.data.payment_schedule_conditions)
            {
              setPaymentConditions(resdata.data.payment_schedule_conditions)
        
            }
            else{
              setPaymentConditions([""])
            }
          }
  
      })
      .catch(error => {
       
        console.error('error details:', error);
      });
  }
  