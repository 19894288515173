import React, { useEffect, useRef, useState } from 'react'
import { PropertImageBaseUrl } from '../../../Utils/urls'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { MdDelete, MdOutlineRemoveRedEye } from 'react-icons/md'
import { BiSolidFilePdf } from 'react-icons/bi'
import { Link } from 'react-router-dom'

export default function EditDocCard(prop) {

  
    const [showOption,setShowOption]=useState(false)
    const [fileErr,setFileErr]=useState("")



const {title,description,docs,ind,
  updateDocument, 
  categoryIndex, documentIndex,
  setPdfArray,
  pdfArray,
  setDocumentsList,
  documentsList,
  deleteDocument
}=prop

const optionsRef = useRef(null);
const handleShowOption=()=>{
   setShowOption(true)
}



useEffect(() => {
   const handleClickOutside = (event) => {
     if (optionsRef.current && !optionsRef.current.contains(event.target)) {
       setShowOption(false);
     }
   };

   document.addEventListener('mousedown', handleClickOutside);
   return () => {
     document.removeEventListener('mousedown', handleClickOutside);
   };
 }, []);


 const handleTitleChange = (e) => {
  updateDocument(categoryIndex, documentIndex, 'title', e.target.value)
}
 const handleDeleteDocument = () => {
        deleteDocument(documentIndex);
    };
// const handleDescriptionChange = (e) => {
//   updateDocument(categoryIndex, documentIndex, 'description', e.target.value)
// }



const handleDocFileChange = (e) => {
  const file = e.target.files[0];

  if (file) {
    const fileTypeParts = file.type.split('/');
    const fileExtension = fileTypeParts.length === 2 ? fileTypeParts[1] : '';
    const maxSize = 5 * 1024 * 1024;

    if (file.type !== 'application/pdf') {
      setFileErr('Only PDF format is allowed');
    } else {
      setFileErr('');
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target.result;

        // const newFloorPlans = [...floorPlans];
        // newFloorPlans[index].floor_image = {
        //   extension: fileExtension,
        //   file_type: file.type,
        //   base64: base64String,
        // };

        const newDocument = {
          "base64": base64String,
          "file_type": file.type,
          "extension": fileExtension
        };
       
        updateDocument(categoryIndex, documentIndex, 'docs', [newDocument])
        //setPdfArray(updatedDocuments);
      };
      reader.readAsDataURL(file);
    }
  }
};








  return (

  
<>

<div className='flex flex-col gap-2'>
<p className='text-start text-sm text-gray' >Document Name</p>

<div className='flex justify-between  items-center gap-3'>
    

    <input type="text" 
    className='amenInputText' 
    value={title && title}
     placeholder='E.g Due Diligence Report '
     onChange={handleTitleChange}
     />
  
  
  <div className='flex gap-2.5 items-center'>
  



     <div class="docLogoSec">
                 <label class="docLogolabel" htmlFor={`file-${categoryIndex}-${documentIndex}`}>
                    <span class="material-symbols-outlined"> publish</span>
                 </label>
              <input  id={`file-${categoryIndex}-${documentIndex}`} type="file" onChange={handleDocFileChange} />



{
  docs && docs.length>0 && (
<div className='docLogopdf'>
<BiSolidFilePdf className=" text-red" />
</div>
  )
}


             
              {/* <img className="profile-img" src="" alt="Avatar" /> */}
            


              </div>
    


  
  <div className='relative'>
  <BsThreeDotsVertical className='cursor-pointer' onClick={handleShowOption} />
      
  {
     showOption && (
  <div ref={optionsRef}  className='flex flex-col gap-3  z-50 shadow absolute top-4 rounded-xl p-2.5 bg-white'>
  {
    ind!=0 && (
<div className='delBtnTenant' onClick={handleDeleteDocument}>
  
  <p className='text-delred  flex gap-2 items-center text-sm' >
  <MdDelete />Delete</p>
  
  </div>
    )
  }
  
  <Link to={`${PropertImageBaseUrl}/${docs[0]?.Key}`}>
  <div className='delBtnTenant cursor-pointer' style={{backgroundColor:"#f5deea"}} >
  
  <p className='text-pink  flex gap-2 items-center text-sm' >
  <MdOutlineRemoveRedEye />View</p>
  
  </div>

  </Link>
  </div>
  
  
  
     )
  }
  
  
  
  </div>
  
  </div>
  
  
  
  </div>


  <p className='text-xs text-red'>{fileErr && fileErr}</p>
  
</div>


 

</>
  )
}
