import React, { createContext, useState, useContext } from 'react';
import { ApiBaseUrl } from '../../Utils/urls';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [AdminDetails, setAdminDetails] = useState({});
  const [accesstoken,setAccessToken]=useState("")
  const [isLoading,setLoading]=useState(false)
  const [alertMsg,setAlertMsg]=useState("")
  const [showAlert,setShowAlert]=useState(false)
  const [isnetworkErr,setNetworkErr]=useState(false)

  const navigate=useNavigate()

  const login = (email, password) => {
    setLoading(true)
    return fetch(`${ApiBaseUrl}/fr-admin/login-with-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    })
      .then((response) => {
        
        return response.json();
      })
      .then((res) => {
        if(res.success){
            setLoading(false)
             setAdminDetails(res.data.AdminDetails);
            setAccessToken(res.data.AuthenticationResult.AccessToken)
            
            localStorage.setItem("accesstoken",res.data.AuthenticationResult.AccessToken)
           localStorage.setItem("adminId",res.data.AdminDetails.staff_id)
           localStorage.setItem("AdminName",res.data.AdminDetails.name)
           localStorage.setItem("AdmnProfilePic",JSON.stringify(res.data.AdminDetails.profile_pic))
            navigate("/dashboard")
        }
        else{
            setLoading(false)
            setAlertMsg(res.data)
            setShowAlert(true)

            setTimeout(() => {
                setAlertMsg("")
                setShowAlert(false)
            }, 4000);
        }
       
      })
      .catch((error) => {
        setLoading(false)
        // setNetworkErr(true)
        console.error('Login error:', error);
       
      });
  };

  const logout = () => {
    localStorage.removeItem("accesstoken")
    localStorage.removeItem("adminId")
    localStorage.removeItem("AdminName")
    localStorage.removeItem("dateParam")
    localStorage.removeItem("AdmnProfilePic")
    navigate("/")
  };
//   {"data":"Token has expired","success":false}
  return (
    <AuthContext.Provider value={{ AdminDetails, login, logout,accesstoken,isLoading,alertMsg,showAlert }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuth };
