import React from 'react'
import loader from "../../Assets/Icons/Loader.gif"
import './loader-style.css'
export default function FloatingLoader() {
  return (
    <div className="FloatingLoaderSection" > 
            
    
    <img src={loader} alt="" className='floatingloaderCls' />
    </div>
  )
}
