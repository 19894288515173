import React, { useEffect, useRef, useState } from 'react'
import NomineeCard from './NomineeCard';
import noNominee from "../../Assets/images/NoTransaction.svg"


export default function UserNominee({isLoading, nomineeDetails}) {

    const [nomineeCards, setNomineeCards] = useState([1]);
    const scrollRef=useRef(null)
   
    const handleAddNominee = () => {
        setNomineeCards([...nomineeCards, nomineeCards.length + 1]);
        // if (scrollRef.current) {
        //     scrollRef.current.scrollLeft = scrollRef.current.scrollWidth;
        //   }
         
    };

    useEffect(() => {
        if (scrollRef.current) {
          // Adjust the scroll position based on the container's width
          const { scrollWidth, clientWidth } = scrollRef.current;
          scrollRef.current.scrollLeft = scrollWidth - clientWidth;
        }
      }, [nomineeCards]); 

  return (
    <div className='propCard'>

{/* <div className='actionMenu'>
        <div className='actionBtn viewBtnP'>
            <MdOutlineRemoveRedEye />
            <p>View</p> 
        </div>
        <div className='actionBtn editBtnP'>
            <RiEdit2Fill />
            <p>Edit</p> 
        </div>
        <div className='actionBtn deleteBtnP'>
            <MdDelete />
            <p>Delete</p>
          
        </div>
      </div> */}
  
    {/* sec-1 */}
    
      <div className='flex justify-between items-center'>
        <p className='text-2xl font-medium '>Nominee Details</p>

        <button className='propSaveBtn bg-lightgray text-gray' 
        onClick={handleAddNominee} disabled >Add Nominee</button>

      </div>

      <div className='overflow-x-auto userNomineeScroll' ref={scrollRef}>
      <div className='flex  gap-4' style={{ whiteSpace: 'nowrap' }}>
{
nomineeDetails && nomineeDetails.length==0?

<div className='w-full flex flex-col gap-8 justify-center'>

<img src={noNominee} alt="" className='w-14 mx-auto' />
  <p className='text-gray'>  No Nominee is added yet</p>

  
  </div>
:
    nomineeDetails.map((el,id)=>(
        <div key={id} className='' >
                <NomineeCard  {...el} />
        </div>
    ))
}
</div>
</div>

</div>
  )
}
