import React from "react";
import ReactPaginate from "react-paginate";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight
} from "react-icons/md";
import { BeatLoader } from "react-spinners";

const Table = ({
  columns,
  data,
  isLoading,
  currentPage,
  totalPages,
  onPageChange,
  emptyStateMessage,
  loadingHeight = "100vh",
  renderRow,
  adminAccess,
  enablePagination = true,
  actions = false
}) => {
  return (
    <div className="table-container">
      {/* Table Head */}
      <div className="flex w-full gap-2 justify-center text-center tableHead pt-3 pb-2 py-2">
        {columns.map((col, index) => (
          <div key={index} className={col.className}>
            {col.label}
          </div>
        ))}
        {actions && (
          <div className="actionCls">
            <BiDotsHorizontalRounded />
          </div>
        )}
      </div>
      <hr className="prophrClass" />

      {/* Table Content */}
      {isLoading ? (
        <div className="alertBar" style={{ height: loadingHeight }}>
          <BeatLoader />
        </div>
      ) : data.length === 0 ? (
        <div className="noPropContainer">
          <div className="noPropImg"></div>
          <p className="noPropText text-gray">{emptyStateMessage}</p>
        </div>
      ) : (
        <div className="propertyContainer">
          {data.map((item, index) => renderRow(item, index, adminAccess))}
        </div>
      )}
      <br />
      {/* Pagination */}
      {enablePagination && (
        <div className="pagination-container">
          <ReactPaginate
            previousLabel={<MdOutlineKeyboardArrowLeft />}
            nextLabel={<MdOutlineKeyboardArrowRight />}
            breakLabel="..."
            breakClassName="pagination-break"
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={totalPages}
            pageClassName="pagination-button"
            onPageChange={onPageChange}
            forcePage={currentPage - 1}
            containerClassName="pagination flex justify-end items-center gap-1.5"
            activeClassName="pagination-active border border-pink text-black rounded"
            previousClassName={`pagination-prev ${
              currentPage === 1 ? "pageinateDisable" : ""
            }`}
            nextClassName="pagination-next"
          />
        </div>
      )}
    </div>
  );
};

export default Table;
