import React, { createContext, useContext, useState } from 'react';
const PropertyFilterContext = createContext();
export const usePropertyFilter = () => useContext(PropertyFilterContext);
const adminId=localStorage.getItem("adminId")
export const initialPropFilter = {
  "admin_id": adminId,
  "filters": {
      "property_sale_type": [],  //["Resale","Launching","Open","Sold out"]
      "property_phase":[], //["Public Sale", "Presale"]
      "searchInput": "",
      "listing_date":{
          "from":"",
          "to":""
      }
  },
  "sortBy": {
    "listing_date":null, //'desc','asc' by default: null
    "price_per_token":null      //'desc','asc' by default: null
  },
  "pageSize": 5,
  "page": 1
}
  
export const PropertyFiltersProvider = ({ children }) => {

  
  const [PropFilters, setPropFilters] = useState(initialPropFilter);
  //const [currentPage,setCurrentpage]=useState(1)
  // update page
  const setPage = (page) => {
    setPropFilters((prev) => ({
      ...prev,
      page
    }));
  };

 



  return (
    <PropertyFilterContext.Provider value={{ PropFilters, setPropFilters,setPage }}>
      {children}
    </PropertyFilterContext.Provider>
  );
};



