import React, { useEffect, useState } from 'react'
import '../taxes-charges.css'
import { IoIosClose, IoMdClose } from 'react-icons/io'

import { useParams } from 'react-router-dom'
import { ApiBaseUrl } from '../../../Utils/urls'
import SuccessAlert from '../../Alert/SuccessAlert'
import ErrorAlert from '../../Alert/ErrorAlert'
import FloatingLoader from '../../Loader/FloatingLoader'
import TaxesChargesCard from '../Card/TaxesChargesCard'
import PopUp from '../../PopUp/PopUp'


export default function ViewTaxesCharges() {

   const [showAddChargesPopup,setShowAddChargesPopUp]=useState(false) 
   const adminId=localStorage.getItem('adminId')
   const accessToken=localStorage.getItem("accesstoken")
   const [isLoading,setLoading]=useState(false)
   

    const [taxesData,setTaxesData]=useState([])
   
const {id:propertyId}=useParams()


const [taxesState,setTaxesState]=useState(
  
          {
              "wallet": "",
              "bank_account": "",
              "invoice_name": "",
              "invoice_price":"",
              "is_percentage": true
          }
    )



const fetchPropertyDetails=()=>{
    fetch(`${ApiBaseUrl}/fr-admin/get-property-details?property_id=${propertyId}&admin_id=${adminId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken
      },
      
    }
  )
      .then(response => {
        return response.json();
      })
      .then(resdata => {
       
       //console.log('Response from server:', resdata);
    
       setLoading(false)
          if(resdata.success)
          {
         
           
          setTaxesData(resdata.data.invoice_info[0].invoice_details)
          
          }
          else if(!resdata.success)
             {
               
             }
  
      })
      .catch(error => {
       
        console.error('error details:', error);
      });
}


useEffect(()=>{
    fetchPropertyDetails()
},[])


  return (

    <>

   



    <div className='propCard'>
   
    <div className='flex justify-between items-center'>
        <p className='text-lg font-medium text-black'>Taxes and Charges 
       </p>
       <div className='flex gap-4'>
       <button
       disabled
       className="propSaveBtn bg-lightgray text-gray"

     
       >Add Charges</button>


        <button
        disabled
         className={`propSaveBtn bg-lightgray text-gray`}
      
       >Save Details</button>
       </div>
      
    </div>

<div className='flex gap-4'>

    <div className='flex flex-col gap-2.5 w-full'>
        <p className='font-bold text-blue text-lg text-left'>Additional Charges*</p>


<div className='bg-lightgray flex gap-3 rounded-md px-4 py-3 w-full'>
{
    taxesData && taxesData.length==0?
    <p>No Taxes is added yet</p>
    :
    taxesData && taxesData.map((el,index)=>(
        <TaxesChargesCard key={index} {...el}   />
    ))
}


</div>

    </div>

             
    
</div>


</div>
</>
  )
}
