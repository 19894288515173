import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import './Filter.css'
import { DefaultFilter } from '../../Utils/urls';
import {  useTransactionFilters } from '../../Utils/Transactionfilters';
import { ApiBaseUrl } from '../../Utils/urls';
import { RxCross2 } from "react-icons/rx";
import { useUserTransactionFilters } from '../../Utils/UserTransactionfilters';
import { useParams } from 'react-router-dom';





function CityButton({ city, onClick, selectedCities }) {
  const isClicked = selectedCities.includes(city.city_name);

  const handleClick = () => {
    onClick(city.city_name);
  };

  return (
    <button
      className={`cityButton text-black text-sm h-7 pl-2 pr-2 ${isClicked ? 'clickedButton' : ''}`}
      onClick={handleClick}
    >
      {city.city_name}
    </button>
  );
}


  
function BuilderButton({ builder, onClick, selectedBuilders }) {
  if (!builder) {
    return null;
  }
  const isClicked = selectedBuilders.includes(builder.builder_name);
  const handleClick = () => {
    onClick(builder.builder_name);
  };

  return (
    <button
      className={`BuilderButton text-black text-sm h-7 pl-2 pr-2 ${isClicked ? 'clickedButton' : ''}`}
      onClick={handleClick}
    >
      {builder.builder_name}
    </button>
  );
}

function AssetButton({ name, onClick, assetType }) {
  const isClicked = assetType.includes(name);

  const handleClick = () => {
    onClick(name);
  };
    return (
      <div className="flex flex-row gap-4 w-full" onClick={handleClick}>
        <div className={`checkBox  text-black ${isClicked ? 'checked' : ''}`} />
        <button
          className=" text-black text-sm font-normal"
        >
          {name}
        </button>
      </div>
    );
  }
  
  function PriceRangeButton({ name, onClick, priceRange }) {
    const isClicked = priceRange[name];
  
    const handleClick = () => {
      onClick(name);
    
    };

 
    return (
      <div className="flex flex-row   text-sm font-normal items-center gap-2 w-full" onClick={handleClick}>
        <div className={`priceRangeRadio text-black ${isClicked ? 'priceRangeselected' : ''}`} />
        <input type='radio' name='price' className="text-black text-sm font-normal" style={{display:'none'}} />
        {name}
      </div>
    );
  }
function UserTransactionFilter(props) {
    
  const [propertyPhases, setPropertyPhases] = useState([]);
  const [saleTypes, setSaleTypes] = useState([]);
  const [propStatus, setpropStatus] = useState([]);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [isClearButtonActive, setIsClearButtonActive] = useState(false);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedBuilders, setSelectedBuilders] = useState([]);
  const [assetType, setAssetType] = useState([]);
  const [priceRange, setPriceRange] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [anyFilterSelected, setanyFilterSelected] = useState(0);
  
  const [placeFilters, setPlaceFilters] = useState([]);
  const [builderFilters, setBuilderFilters] = useState([]);
  const [currencyFilters, setCurrencyFilters] = useState([]);

const {id}=useParams()
const currentDate = new Date();
const sixtyDaysAgo = new Date(currentDate);
sixtyDaysAgo.setDate(sixtyDaysAgo.getDate() - 60);
sixtyDaysAgo.setHours(sixtyDaysAgo.getHours() + 5);
sixtyDaysAgo.setMinutes(sixtyDaysAgo.getMinutes() + 30);
const fromDateString = sixtyDaysAgo.toISOString().slice(0, 19).replace('T', ' ');

currentDate.setHours(currentDate.getHours() + 5); 
currentDate.setMinutes(currentDate.getMinutes() + 30); 
const toDateString = currentDate.toISOString().slice(0, 19).replace('T', ' ');
const [boughtData,setboughtData]=useState([])
const adminId=localStorage.getItem("adminId")
const {UserTransactionFilters,setUserTransactionFilters}=props
//const [UserTransactionFilters,setUserTransactionFilters]=useState(initialUserTransactionFilter)

  //const { UserTransactionFilters, setUserTransactionFilters } = useUserTransactionFilters();

  const initialUserTransactionFilter={
    "admin_id": adminId,
    "user_id": id,
    "filtersByString": {
        "sale_type": null //["Public Sale","Resale","Presale"]
    },
    "filterByStatus": [
        
    ], //["COMPLETED","PENDING","CANCEL"],
    // Object containing filters based on string values.
    "purchased_date": {
        "from": fromDateString,
        "to": toDateString
    },
    "filtersByPlaces": [], //[{"city_id": 6, "locations": []}],
    //  Array of objects containing filters based on city and location.
    "filtersByBuilders": [], //[8]
    // Array of builder IDs for filtering properties.
    "filtersByPrice": {
        "token_price":null
    },
    "page":1,
    "pageSize":10,
    
"sortBy": {transaction_date: "desc"}
}


  // useEffect(() => {
  //   if (id) {
  //     setUserTransactionFilters((prevFilters) => ({
  //       ...prevFilters,
  //       user_id: id,
  //     }));

  //     fetchData()
  //   }
  // }, [id]);

  // const fetchData = async (id) => {
  //   try {

  //     const requestData = {
  //       ...UserTransactionFilters,
  //       user_id: id, // Ensure this is set correctly
  //     };
  
  //     const response = await fetch(`${ApiBaseUrl}/fr-admin/get-user-purchase-transactions`,
  //     {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(requestData),
  //     }

  //     );
  //     if (!response.ok) {
  //       throw new Error('Failed to fetch data');
  //     }
  //     const data1 = await response.json();
      
  //     // if (data1 && data1.data && data1.data.place_filters) {
  //     //   const places = data1.data.place_filters.map(city => ({
  //     //     city_id: city.city_id,
  //     //     city_name: city.city_name
  //     //   }));
  //     //   setPlaceFilters(places);
  //     // }
  
  //     // if (data1 && data1.data && data1.data.builder_filters) {
  //     //   const builders = data1.data.builder_filters.map(builder => ({
  //     //     builder_id: builder.builder_id,
  //     //     builder_name: builder.builder_name
  //     //   }));
  //     //   setBuilderFilters(builders);
  //     // }
  // if(response.success)
  //   {
  //     setboughtData(response.data)
  //   }
     
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };
  // useEffect(() => {
  //   if (id) {
  //  fetchData(id);
  // }
  // }, [props.isOpenMob,id,UserTransactionFilters]);


  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleSaleTypeClick = (saleType) => {
    let updatedSaleTypes;
    if (saleTypes.includes(saleType)) {
      updatedSaleTypes = saleTypes.filter((type) => type !== saleType);
    } else {
      updatedSaleTypes = [...saleTypes, saleType];
    }
  
    setSaleTypes(updatedSaleTypes);
    setIsFilterActive(true);
  
    // Update property_filters with the new sale types
    let updatedPropertySaleTypes;
    if (updatedSaleTypes.length === 0) {
      updatedPropertySaleTypes = null;
    } else if (updatedSaleTypes.includes('all')) {
      updatedPropertySaleTypes = ['Public Sale', 'Resale','Presale'];
    } else {
      updatedPropertySaleTypes = updatedSaleTypes;
    }
  
    setUserTransactionFilters((prevFilters) => ({
      ...prevFilters,
      user_id: id,
      filtersByString: {
        ...prevFilters.filtersByString,
        sale_type: updatedPropertySaleTypes,
      },
    }));

    

  };


  const handlePropertyPhaseClick = (propertyPhaseType) => {
    setIsFilterActive(1);
    if (propertyPhases.includes(propertyPhaseType)) {
      setPropertyPhases(propertyPhases.filter((phase) => phase !== propertyPhaseType));
    } else {
      setPropertyPhases([...propertyPhases, propertyPhaseType]);
    }
    setIsFilterActive(true);
  };

  const handleAssetTypeClick = (assetTypeName) => {
    let updatedAssetTypes;
    if (assetType.includes(assetTypeName)) {
        updatedAssetTypes = assetType.filter((type) => type !== assetTypeName);
    } else {
        updatedAssetTypes = [...assetType, assetTypeName];
    }


    setAssetType(updatedAssetTypes);
    let updatedPropertyType;
    if (updatedAssetTypes.length === 0) {
        updatedPropertyType = null;
    } else if (updatedAssetTypes.includes("All")) {
        updatedPropertyType = ["Commercial", "Residence", "Plots"];
    } else {
        updatedPropertyType = updatedAssetTypes.map(typeName => {
            if (typeName === "Residential") {
                return "Residence";
            }
            return typeName;
        });
    }
    setUserTransactionFilters(prevFilters => ({
        ...prevFilters,
        filtersByString: {
          user_id: id,
            ...prevFilters.filtersByString,
            property_type: updatedPropertyType
        }
    }));
};

const handlepropStatusClick = (propStatusLocal) => {
    let updatedpropStatus;
    if (propStatus.includes(propStatusLocal)) {
      updatedpropStatus = propStatus.filter((type) => type !== propStatusLocal);
    } else {
      updatedpropStatus = [...propStatus, propStatusLocal];
    }
  

    setpropStatus(updatedpropStatus);
  
    // Now, update the filtersByStatus field in TransactionpropertyFilters
    const updatedTransactionPropertyFilters = {
      ...UserTransactionFilters,
      user_id: id,
      filterByStatus: updatedpropStatus,
    };
  
    setUserTransactionFilters(updatedTransactionPropertyFilters);
  };
  

  const handleCityClick = (cityName) => {
    let updatedCities;
    if (selectedCities.includes(cityName)) {
        updatedCities = selectedCities.filter((city) => city !== cityName);
    } else {
        updatedCities = [...selectedCities, cityName];
    }
  
    setSelectedCities(updatedCities);
    const updatedFiltersByPlaces = updatedCities.map((cityName) => {
        const selectedCity = placeFilters.find(city => city.city_name === cityName);
        return { city_id: selectedCity.city_id, locations: []  };
    });
  

    const updatedTransactionPropertyFilters = { ...UserTransactionFilters,  user_id: id, filtersByPlaces: updatedFiltersByPlaces };
    setUserTransactionFilters(updatedTransactionPropertyFilters);
  
    setIsFilterActive(true);
};


const handlePriceRangeClick = (name) => {
  // Update the state with the new price range name
  setPriceRange(prevPriceRange => {
    const updatedPriceRange = { ...prevPriceRange };
    Object.keys(updatedPriceRange).forEach(key => {
      updatedPriceRange[key] = false; // Deselect all other options
    });
    updatedPriceRange[name] = true; // Select the clicked option
    return updatedPriceRange;
  });
  // Update property_filters with the new price range
  let filtersByPrice = {};

  if (name === "Upto 50000") {
    filtersByPrice = { from: "0", to: "50000" };
  } else if (name === "50,000-1,00,000") {
    filtersByPrice = { from: "50000", to: "100000" };
  } else if (name === "Above 1,00,000") {
    filtersByPrice = { from: "100000", to: "10000000" };
  } else {
    // If no price range is selected, set to null
    filtersByPrice = null;
  }

  setUserTransactionFilters((prevFilters) => ({
    ...prevFilters,
    filtersByPrice: { token_price: filtersByPrice },
  }));
};

  const handleBuilderClick = (builderName) => {
    let updatedBuilders;

    if (selectedBuilders.includes(builderName)) {
        updatedBuilders = selectedBuilders.filter((builder) => builder !== builderName);
    } else {
        updatedBuilders = [...selectedBuilders, builderName];
    }

    setSelectedBuilders(updatedBuilders);

    const updatedFiltersByBuilders = updatedBuilders.map((builderName) => {
        const selectedBuilder = builderFilters.find(builder => builder.builder_name === builderName);
        return selectedBuilder ? selectedBuilder.builder_id : null;
    }).filter(id => id !== null);
    const updatedTransactionPropertyFilters = { ...UserTransactionFilters, filtersByBuilders: updatedFiltersByBuilders };
    setUserTransactionFilters(updatedTransactionPropertyFilters);

    setIsFilterActive(true);
};


  const handlePhaseClearClick = () => {
    setPropertyPhases([]);
  }

  const handleSaleTypeClearClick = () => {
    setSaleTypes([]);
  }

  const handleCityClearClick = () => {
    setSelectedCities([]);
    const updatedFiltersByPlaces = [];
    const updatedTransactionPropertyFilters = { ...UserTransactionFilters, filtersByPlaces: updatedFiltersByPlaces };
    setUserTransactionFilters(updatedTransactionPropertyFilters);
  }
  const handlePropStatusClearClick = () => {

    setpropStatus([]);
    const updatedTransactionPropertyFilters = {
        ...UserTransactionFilters,
        filterByStatus: [],
      };
      setUserTransactionFilters(updatedTransactionPropertyFilters);
  }
  const handleBuilderClearClick = () => {

    setSelectedBuilders([]);
     const updatedFiltersByBuilders = [];
     const updatedTransactionPropertyFilters = { ...UserTransactionFilters, filtersByBuilders: updatedFiltersByBuilders };
     setUserTransactionFilters(updatedTransactionPropertyFilters);
  }
  const handleAssetTypeClearClick = () => {
    setAssetType([]);
    const updatedFilters = { ...UserTransactionFilters };
    updatedFilters.filtersByString.property_type = null; // Reset asset type filter
    setUserTransactionFilters(updatedFilters);
  }
  const handlePriceRangeClearClick = () => {
    setPriceRange([]);
    const updatedFilters = { ...UserTransactionFilters };
    updatedFilters.filtersByPrice.price_per_share = null; // Reset price range filter
    setUserTransactionFilters(updatedFilters);
  }
  const handlePriceClear=()=>{
    setPriceRange({})
    setUserTransactionFilters((prevFilters) => ({
      ...prevFilters,
      filtersByPrice: { token_price: null }
    }));
  }

  const handleClearClick = () => {
    setSaleTypes([]);
    setpropStatus([]);
    setSelectedCities([]);
    setSelectedBuilders([]);
    setAssetType([]);
    setPriceRange([]);
    setStartDate('');
    setEndDate('');
    setIsFilterActive(false);
    setIsClearButtonActive(false);

    setUserTransactionFilters(initialUserTransactionFilter)

  };


  useEffect(() => {
    setPropertyPhases([]);
    setSaleTypes([]);
    setSelectedCities([]);
    setSelectedBuilders([]);
    setAssetType([]);
    setPriceRange([]);
    setStartDate('');
    setEndDate('');
    setIsFilterActive(false);
    setIsClearButtonActive(false);

    setUserTransactionFilters(initialUserTransactionFilter)
  }, [props.clearFilterSelection])
  




  const [leftPaneHeight, setLeftPaneHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setLeftPaneHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <>

    <div className='px-4 py-4 '>
    <div className='text-black flex flex-row justify-between mt-2   '>
    <div className='text-black text-[16px] font-bold'>Filter </div>
    <div className='flex flex-row gap-2 pr-2' >
    {/* 
    <button
    className={`text-xs w-16 font-medium h-5 flex flex-row justify-center ${
      isFilterActive ? 'ButtonCollect' : 'ButtonBeforeCollect'
    }`}
    disabled={!isFilterActive}
  >
  <div className='flex flex-col justify-center'>Apply</div>
  </button>
  */}
  <button
  onClick={handleClearClick}
  className={`clearButton text-xs text-start font-normal -mr-3 flex flex-col justify-center rounded-lg py-0.5 px-2 h-5 cursor-pointer ${
    // Check if all filters are cleared
    !propStatus.length &&!propertyPhases.length && !saleTypes.length && !selectedCities.length && !selectedBuilders.length && !assetType.length && !priceRange.length ? 'ButtonBeforeCollect' : 'ButtonCollect'
  }`}
      >
        Clear
      </button>
<div className='ml-3' onClick={()=>props.setIsOpenMob(false)}><RxCross2 /></div>
</div> 


  </div>

    <div className='flex flex-row justify-center enquiryParent mt-1'>
      <hr style={{ color: 'rgba(217, 217, 217, 1)', width: '100%' }}></hr>
    </div>

  <div className=' text-black pt-4 flex flex-col' style={{display:"none"}}>
  <div className='flex flex-row justify-between'>
  <div className='text-[16px] font-medium text-start'>Property Type</div>
  <div className='flex flex-col justify-center'>
    <div className='clearButton text-xs text-start font-normal -mr-1 flex flex-col justify-center rounded-lg py-0.5 px-2 h-5 cursor-pointer'
    onClick={handleAssetTypeClearClick}>Clear</div>
  </div>

  </div>
    <div className=' text-lg font-bold text-start flex flex-row p-2 gap-3 -ml-2'>
      <div className=' text-black flex flex-row gap-4 ' >
        <AssetButton name="All" onClick={handleAssetTypeClick} assetType={assetType}  />
      </div>
      <div className=' text-black flex flex-row gap-4'>
        
        <AssetButton name="Commercial" onClick={handleAssetTypeClick} assetType={assetType} />
      </div>
      <div className=' text-black flex flex-row gap-4'>
      
        <AssetButton name="Residential" onClick={handleAssetTypeClick} assetType={assetType} />
      </div>
      <div className=' text-black flex flex-row gap-4'>
        <AssetButton name="Plots" onClick={handleAssetTypeClick} assetType={assetType} />
      </div>
    </div>
  </div>



  <div className=' text-black flex flex-col pt-4'>
  <div className='flex flex-row justify-between'>
  <div className='text-[16px] font-medium text-start'>Transaction Status</div>
  <div className='flex flex-col justify-center'>
    <div className='clearButton text-xs text-start font-normal -mr-1 flex flex-col justify-center rounded-lg py-0.5 px-2 h-5 cursor-pointer'
    onClick={handlePropStatusClearClick}
    >Clear</div>
  </div>

  </div>
    <div className=' flex flex-row flex-wrap gap-2 p-2 -ml-2'>
   
      <button
        className={`propertyPhasesButton  text-black text-sm w-24 h-7 ${
          propStatus.includes('COMPLETED') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handlepropStatusClick('COMPLETED')}
      >
        Completed
      </button>
      <button
        className={`propertyPhasesButton  text-black text-sm w-20 h-7  whitespace-nowrap ${
          propStatus.includes('PENDING') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handlepropStatusClick('PENDING')}
      >
        Pending
      </button>
      <button
        className={`propertyPhasesButton  text-black text-sm w-24 h-7 whitespace-nowrap ${
          propStatus.includes('CANCEL') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handlepropStatusClick('CANCEL')}
      >
        Cancel
      </button>
      
    </div>
  </div>

  <div className=' text-black flex flex-col pt-4'>
  <div className='flex flex-row justify-between'>
  <div className='text-[16px] font-medium text-start'>Sales Type</div>
  <div className='flex flex-col justify-center'>
    <div className='clearButton text-xs text-start font-normal -mr-1 flex flex-col justify-center rounded-lg py-0.5 px-2 h-5 cursor-pointer'
    onClick={handleSaleTypeClearClick}
    >Clear</div>
  </div>

  </div>
    <div className=' flex flex-row flex-wrap gap-2 p-2 -ml-2'>
      <button
        className={`propertyPhasesButton  text-black text-sm w-14 h-7 ${
          saleTypes.includes('all') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handleSaleTypeClick('all')}
      >
        All
      </button>
      <button
        className={`propertyPhasesButton  text-black text-sm w-20 h-7  whitespace-nowrap ${
          saleTypes.includes('Presale') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handleSaleTypeClick('Presale')}
      >
        Presale
      </button>
      <button
        className={`propertyPhasesButton  text-black text-sm w-24 h-7 whitespace-nowrap ${
          saleTypes.includes('Public Sale') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handleSaleTypeClick('Public Sale')}
      >
        Public Sale
      </button>
      <button
        className={`propertyPhasesButton  text-black text-sm w-20 h-7 ${
          saleTypes.includes('Resale') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handleSaleTypeClick('Resale')}
      >
        Resale
      </button>
    </div>
  </div>









  <div className=' text-black flex flex-col pt-4' style={{display:"none"}}>
  <div className='flex flex-row justify-between'>
  <div className=' text-[16px] font-medium text-start'>Property Phases</div>
  <div className='flex flex-col justify-center'>
    <div className='clearButton text-xs text-start font-normal -mr-1 flex flex-col justify-center rounded-lg py-0.5 px-2 h-5 cursor-pointer' 
    onClick={handlePhaseClearClick} 
    >Clear</div>
  </div>

  </div>
    <div className=' flex flex-row flex-wrap gap-2 p-2 -ml-2'>
      <button
        className={`propertyPhasesButton  text-black text-sm w-32 h-7 ${
          propertyPhases.includes('launchingsoon') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handlePropertyPhaseClick('launchingsoon')}
      >
        Launching Soon
      </button>
      <button
        className={`propertyPhasesButton  text-black text-sm w-14 h-7 ${
          propertyPhases.includes('open') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handlePropertyPhaseClick('open')}
      >
        Open
      </button>
      <button
        className={`propertyPhasesButton  text-black text-sm w-14 h-7 ${
          propertyPhases.includes('resale') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handlePropertyPhaseClick('resale')}
      >
        Resale
      </button>
      <button
        className={`propertyPhasesButton  text-black text-sm w-24 h-7 ${
          propertyPhases.includes('soldout') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handlePropertyPhaseClick('soldout')}
      >
        Sold out
      </button>
    </div>
  </div>


  {/* <div className=' text-black flex flex-col pt-4'>
  <div className='flex flex-row justify-between'>
    <div className='text-[16px] font-medium text-start'>City</div>
    <div className='flex flex-col justify-center'>
      <div
        className='clearButton text-xs text-start font-normal -mr-1 flex flex-col justify-center rounded-lg py-0.5 px-2 h-5 cursor-pointer'
        onClick={handleCityClearClick}
      >
        Clear
      </div>
    </div>
  </div>
  <div className=' flex flex-row flex-wrap gap-2 p-2 -ml-2'>
    {placeFilters.map(city => (
      <CityButton
        key={city.city_id}
        city={city}
        onClick={handleCityClick}
        selectedCities={selectedCities}
      />
    ))}
  </div>
</div> */}

{/* <div className=' text-black flex flex-col pt-4'>
<div className='flex flex-row justify-between'>
  <div className='text-[16px] font-medium text-start'>Builder's Name</div>
  <div className='flex flex-col justify-center'>
    <div
      className='clearButton text-xs text-start font-normal -mr-1 flex flex-col justify-center rounded-lg py-0.5 px-2 h-5 cursor-pointer'
      onClick={handleBuilderClearClick}
    >
      Clear
    </div>
  </div>
</div>
<div className=' flex flex-row flex-wrap gap-2 p-2 -ml-2'>
  {builderFilters.map(builder => (
    <BuilderButton
      key={builder.builder_id}
      builder={builder}
      onClick={handleBuilderClick}
      selectedBuilders={selectedBuilders}
    />
  ))}
</div>
</div> */}


{/* price range */}
{/* <div className=' text-black pt-4 flex flex-col mb-12'>
  <div className='flex flex-row justify-between'>
  <div className='text-[16px] font-medium text-start'>Price Range</div>
  <div className='flex flex-col justify-center'>
    <div className='clearButton text-xs text-start font-normal -mr-1 flex flex-col justify-center rounded-lg px-2 h-5 cursor-pointer'
   onClick={handlePriceClear}
   >Clear</div>
  </div>

  </div>
    <div className='text-lg font-bold text-start flex flex-row p-2 gap-3 -ml-2'>
      <div className=' text-black flex flex-row gap-4 ' >
        <PriceRangeButton name="Upto 50000" priceRange={priceRange} onClick={handlePriceRangeClick} />
     
     
     
     
      </div>
      <div className=' text-black flex flex-row gap-4'>
        
        <PriceRangeButton name="50,000-1,00,000" onClick={handlePriceRangeClick} priceRange={priceRange} />
      </div>
      <div className=' text-black flex flex-row gap-4'>
      
        <PriceRangeButton name="Above 1,00,000" onClick={handlePriceRangeClick} priceRange={priceRange} />
      </div>
      
     
    </div>
  </div> */}


  
  </div>

  </>
  );
}



export default UserTransactionFilter
