import React from 'react'
import { SideNavbar } from '../Sidebar/SideNavbar'
import Header from '../Header/Header'

export default function AdminContainer({children,
  subtitle,
  pageTitle,
  backLinkPath,
  showDeactivateButton,
  handleUpdatedPropStatus ,
  propStatus,
  propertyId,
  showEditPageDeactivateButton,
  showViewDeactivateButton
}) {
  return (

    <>
    {/* <div className='container '>
        <div className='flex'>
            <SideNavbar/>

            <div className='w-full flex flex-col'>

                <div >
                    topbar
                </div>

<div>
{children}
</div>
          
            </div>

        </div>
    </div> */}









    <div className="dark:bg-boxdark-2 dark:text-bodydark">
      {/* <!-- ===== Page Wrapper Start ===== --> */}
      <div className="flex h-screen overflow-hidden">
       
        <SideNavbar/>
      

        {/* <!-- ===== Content Area Start ===== --> */}
        <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
          {/* <!-- ===== Header Start ===== --> */}
         <Header 
         pageTitle={pageTitle} 
         subtitle={subtitle} 
         backLinkPath={backLinkPath} 
         showDeactivateButton={showDeactivateButton}
         showEditPageDeactivateButton={showEditPageDeactivateButton}
         showViewDeactivateButton={showViewDeactivateButton}
         handleUpdatedPropStatus={handleUpdatedPropStatus}
         propStatus={propStatus}
         propertyId={propertyId}
         />
          {/* <!-- ===== Header End ===== --> */}

          {/* <!-- ===== Main Content Start ===== --> */}
          <main className=''>


            <div className="relative mx-auto max-w-screen-2xl p-4 md:p-5 2xl:p-10">
              {children}
            </div>




         
          </main>
          {/* <!-- ===== Main Content End ===== --> */}
        </div>
        {/* <!-- ===== Content Area End ===== --> */}
      </div>
      {/* <!-- ===== Page Wrapper End ===== --> */}

     
    </div>







    </>
  )
}
