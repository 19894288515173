import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import {
  fetchAlllCurrencies,
  getPropertyDetails
} from "../../../Api/PropertyAddApi";
import { ApiBaseUrl } from "../../../Utils/urls";
import SuccessAlert from "../../Alert/SuccessAlert";
import FloatingLoader from "../../Loader/FloatingLoader";
import ErrorAlert from "../../Alert/ErrorAlert";
import CustomInput from "../CustomInput";
import CustomButton from "../../Common/CustomeButton";

export default function EditTokenInfo(props) {
  const adminId = localStorage.getItem("adminId");
  const accessToken = localStorage.getItem("accesstoken");
  const [currencies, setCurrencies] = useState([]);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [istokeninfoAdded, setTokenInfoAdded] = useState(false);
  const [iseTokenAddErr, setTokenAddErr] = useState(false);
  const [propInfo, setPropertyInfo] = useState({});
  const { id: propertyId } = useParams();
  const { MintedToken } = props;
  const [tokenInfoState, setTokenInfoState] = useState({
    admin_id: adminId,
    property_id: propertyId,
    price_per_share: null,
    property_price: null,
    total_shares: null,
    lock_in_period: null,
    target_IRR: null,
    gross_yield: null,
    capital_gain: null,
    escrow_name: "",
    escrow_id: "",
    limit_per_share: null,
    base_currency_id: null
  });
  const formatNumber = (value) => {
    if (value == null || value === "") return "";
    return Number(value).toLocaleString();
  };

  const fetchPropertyDetails = () => {
    getPropertyDetails(
      undefined,
      propertyId,
      adminId,
      accessToken,
      undefined,
      setPropertyInfo,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      setTokenInfoState
    );
  };

  useEffect(() => {
    fetchPropertyDetails();
  }, []);

  const parseNumber = (value) => {
    // return value.replace(/,/g, '');

    return value.replace(/[^\d.]/g, "");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    //const numericValue = name === 'escrow_id' ? value : parseNumber(value);
    setTokenInfoState((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const getAllCurrency = () => {
    fetchAlllCurrencies(setCurrencies);
  };

  useEffect(() => {
    getAllCurrency();
  }, []);

  useEffect(() => {
    const requiredFields = [
      "property_price",
      "total_shares",
      "price_per_share",
      "lock_in_period",
      "target_IRR",
      "gross_yield",
      "capital_gain",
      "escrow_name",
      "limit_per_share",
      "base_currency_id"
    ];
    const allFilled = requiredFields.every((field) => tokenInfoState[field]);
    setIsSaveEnabled(allFilled);
  }, [tokenInfoState]);
  //  automatically calculate price_per_share (per token value)
  useEffect(() => {
    const { property_price, total_shares } = tokenInfoState;
    if (
      property_price &&
      total_shares &&
      !isNaN(property_price) &&
      !isNaN(total_shares) &&
      total_shares > 0
    ) {
      const pricePerShare =
        parseFloat(property_price) / parseFloat(total_shares);
      setTokenInfoState((prevState) => ({
        ...prevState,
        price_per_share: pricePerShare //.toFixed(2)
      }));
    }
  }, [tokenInfoState.property_price, tokenInfoState.total_shares]);

  const handleAddTokenInfo = () => {
    const numericState = Object.fromEntries(
      Object.entries(tokenInfoState).map(([key, value]) => {
        if (key === "escrow_name" || key === "admin_id") {
          return [key, value];
        }
        return [key, parseFloat(value)];
      })
    );

    setLoading(true);
    fetch(`${ApiBaseUrl}/fr-admin/add-property-token-info`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken
      },
      body: JSON.stringify(numericState)
    })
      .then((response) => {
        return response.json();
      })
      .then((resdata) => {
        //console.log('Response from server:', resdata);
        setLoading(false);

        if (resdata.success) {
          setAlertMsg(resdata.data);
          setTokenInfoAdded(true);
          fetchPropertyDetails();
          setTimeout(() => {
            setAlertMsg("");
            setTokenInfoAdded(false);
          }, 3000);
        } else if (!resdata.success) {
          setAlertMsg(resdata.data);
          setTokenAddErr(true);
          setTimeout(() => {
            setAlertMsg("");
            setTokenAddErr(false);
          }, 3000);
        }
      })
      .catch((error) => {
        console.error("error details:", error);
      });
  };

  return (
    <>
      <div className="propCard">
        {isLoading && <FloatingLoader />}
        {istokeninfoAdded && <SuccessAlert message={alertMsg} />}
        {iseTokenAddErr && <ErrorAlert message={alertMsg} />}

        <div className="flex justify-between items-center">
          <p className="text-lg font-medium text-black">Tokens Information</p>

          <CustomButton
            onClick={handleAddTokenInfo}
            isDisabled={MintedToken > 0 || !isSaveEnabled}
            buttonText={"Save Details"}
            disabledMessage="Editing is no longer available. The token has already been minted"
            className="propSaveBtn"
            position="top"
            MintedToken={MintedToken}
          />
        </div>

        <div className="grid grid-cols-4 gap-8">
          <div className="flex flex-col gap-2.5 items-start tokenInputDiv">
            <p className="text-sm text-blue">
              Property Value<span style={{ color: "#af2525" }}>*</span>{" "}
            </p>

            <CustomInput
              value={tokenInfoState.property_price}
              name="property_price"
              onChange={handleChange}
              onKeyDown
              placeholder="E.g ₹70,00,000"
              className={"propInputText"}
            />
          </div>

          <div className="flex flex-col gap-2.5 items-start tokenInputDiv">
            <p className="text-sm text-blue">
              Total Tokens<span style={{ color: "#af2525" }}>*</span>{" "}
            </p>

            <CustomInput
              value={tokenInfoState.total_shares}
              name="total_shares"
              onChange={handleChange}
              onKeyDown
              placeholder="E.g ₹70,00,000"
              className={"propInputText"}
            />
          </div>

          <div className="flex flex-col gap-2.5 items-start tokenInputDiv">
            <p className="text-sm text-blue">
              Per Token Price<span style={{ color: "#af2525" }}>*</span>{" "}
            </p>

            <CustomInput
              name="price_per_share"
              value={tokenInfoState.price_per_share}
              onChange={handleChange}
              onKeyDown
              placeholder="E.g ₹70,00,000"
              className={"propInputText"}
            />
          </div>

          <div className="flex flex-col gap-2.5 items-start tokenInputDiv">
            <p className="text-sm text-blue">
              Lockin Period (Days)<span style={{ color: "#af2525" }}>*</span>{" "}
            </p>

            <CustomInput
              name="lock_in_period"
              value={tokenInfoState.lock_in_period}
              onChange={handleChange}
              onKeyDown // Optional
              placeholder="E.g ₹70,00,000"
              className={"propInputText"}
            />
          </div>
        </div>

        <div className="grid grid-cols-4 gap-8">
          <div className="flex flex-col gap-2.5 items-start tokenInputDiv">
            <p className="text-sm text-blue">
              Yield (%)<span style={{ color: "#af2525" }}>*</span>{" "}
            </p>

            <CustomInput
              name="gross_yield"
              value={tokenInfoState.gross_yield}
              onChange={handleChange}
              onKeyDown // Optional
              placeholder="E.g ₹70,00,000"
              className={"propInputText"}
            />
          </div>

          <div className="flex flex-col gap-2.5 items-start tokenInputDiv">
            <p className="text-sm text-blue">
              Target
              {propInfo?.property_phase === "Presale" ? " ARR" : " IRR"}
              (%)<span style={{ color: "#af2525" }}>*</span>{" "}
            </p>

            <CustomInput
              name="target_IRR"
              value={tokenInfoState.target_IRR}
              onChange={handleChange}
              onKeyDown
              placeholder="E.g 10"
              className={"propInputText"}
            />
          </div>

          <div className="flex flex-col gap-2.5 items-start tokenInputDiv">
            <p className="text-sm text-blue">
              Potential Gain(%)<span style={{ color: "#af2525" }}>*</span>{" "}
            </p>

            <CustomInput
              name="capital_gain"
              value={tokenInfoState.capital_gain}
              onChange={handleChange}
              onKeyDown
              placeholder="E.g 2"
              className={"propInputText"}
            />
          </div>

          <div className="flex flex-col gap-2.5 items-start tokenInputDiv">
            <p className="text-sm text-blue">
              Currency Type<span style={{ color: "#af2525" }}>*</span>{" "}
            </p>
            <select
              name="base_currency_id"
              id=""
              value={tokenInfoState.base_currency_id}
              onChange={handleChange}
              className="propSelectInput"
            >
              <option value="" disabled selected>
                Select
              </option>
              {currencies &&
                currencies.map((el) => (
                  <option key={el.currency_id} value={el.currency_id}>
                    {el.fiat_currency}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div className="grid grid-cols-4 gap-8">
          <div className="flex flex-col gap-2.5 items-start tokenInputDiv">
            <p className="text-sm text-blue">
              Limit Per User<span style={{ color: "#af2525" }}>*</span>{" "}
            </p>

            <CustomInput
              name="limit_per_share"
              value={tokenInfoState.limit_per_share}
              onChange={handleChange}
              onKeyDown
              placeholder="E.g 12"
              className={"propInputText"}
            />
          </div>

          <div className="flex flex-col gap-2.5 items-start tokenInputDiv">
            <p className="text-sm text-blue">
              Escrow Name<span style={{ color: "#af2525" }}>*</span>{" "}
            </p>

            <CustomInput
              name="escrow_name"
              value={tokenInfoState.escrow_name}
              onChange={handleChange}
              className={"propInputText"}
              placeholder="E.g Prop1230645Ad"
            />
          </div>

          <div className="flex flex-col gap-2.5 items-start tokenInputDiv">
            <p className="text-sm text-blue">Escrow Id</p>

            <CustomInput
              name="escrow_id"
              value={tokenInfoState.escrow_id}
              //onChange={handleChange}
              disabled
              className={"propDisableInputText"}
              placeholder="E.g Prop1230645Ad"
            />
          </div>
        </div>
      </div>
    </>
  );
}
