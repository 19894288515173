

import { useRef } from 'react';

export default function useDebounce() {
  const timeoutRef = useRef(null); 


  const debounce = (func, delay) => {
    return (...args) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current); // Clear existing timeout
      }

      timeoutRef.current = setTimeout(() => {
        func(...args); // Execute function after delay
      }, delay); 
    };
  };

  return debounce; 
}



  