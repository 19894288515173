import { ApiBaseUrl } from "../Utils/urls";


export const FetchUserDetails=(
    setLoading,
    id,
    setUserDetails,
    setUserAml,
    setBankDetails,
    setNomineeDetails,
  accessToken,
  adminId
  )=>{

    setLoading(true)
    fetch(`${ApiBaseUrl}/fr-admin/get-user-details`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken
      },
      body: JSON.stringify({
        "user_id":id,
        "admin_id":adminId
      })
    }
  )
      .then(response => {
      
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
       
        return response.json();
      })
      .then(resdata => {
       
       //console.log('Response from server:', resdata);
       
          if(resdata.success)
          {
            setLoading(false)
            setUserDetails(resdata.data)
            setUserAml(resdata.data.aml_details)
            setBankDetails(resdata.data?.bank_accounts)
            setNomineeDetails(resdata.data.nominees)
          }
  
      })
      .catch(error => {
       
        console.error('error details:', error);
      });
  }


 