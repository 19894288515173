import React, { useEffect, useRef, useState } from 'react'
import { BiDotsHorizontalRounded } from 'react-icons/bi'
import { MdDelete, MdOutlineRemoveRedEye } from 'react-icons/md'
import { RiEdit2Fill } from 'react-icons/ri'
import { formatDate } from '../../Utils/dateFormat'

export default function NomineeCard(props) {
const {
  aadhar_number,
  dob,
  gender,
  mobile_no,
  nominee_name,
  nominee_id,
  percentage_of_investment,
  relation
}
=props


    const [isActionMenuOpen, setIsActionMenuOpen] = useState(false); 
    const actionMenuRef=useRef(null)

  
    const toggleActionMenu = (e) => {
        e.stopPropagation(); 
        setIsActionMenuOpen((prev) => !prev); 
      };
    
      const handleClickOutside = (event) => {
        if (actionMenuRef.current && !actionMenuRef.current.contains(event.target)) {
           
          setIsActionMenuOpen(false);
        }
      };
      useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, []);





  return (
    <div className='flex flex-col gap-2 p-2.5 rounded-lg border border-lightgray nomineeCard'>
        
    <div className='flex justify-end relative'>
      <BiDotsHorizontalRounded className='cursor-pointer' onClick={toggleActionMenu} />


{
   isActionMenuOpen && (
<div className='NomineeActionMenu' ref={actionMenuRef}>
       
       <div className='actionBtn editBtnP cursor-pointer'>
           <RiEdit2Fill />
           <p>Edit</p> 
       </div>
       <div className='actionBtn deleteBtnP cursor-pointer'>
           <MdDelete />
           <p>Delete</p>
         
       </div>
     </div>
   ) 
}


      

      </div>




      <div className='flex gap-11 p-2.5'>

      <div className='flex flex-col gap-4 w-1/2'>

<div className='flex flex-col w-full justify-start gap-1.5'>
   <p className='text-sm text-blue text-start'>Name </p>
    <input type="text" 
     name="" 
    className='propDisableInputText'
    placeholder='Shyam Kumar'
    disabled
    value={nominee_name && nominee_name}
    />
 </div>


 <div className='flex flex-col w-full justify-start gap-1.5'>
   <p className='text-sm text-blue text-start'>Gender</p>
    <input type="text" 
     name="" 
     value={gender && gender}
    className='propDisableInputText'
    disabled
    placeholder='Male'
    />
 </div>


 <div className='flex flex-col w-full justify-start gap-1.5'>
   <p className='text-sm text-blue text-start'>Date of Birth</p>
    <input type="text" 
    value={dob && formatDate(dob)}
     name="" 
    className='propDisableInputText'
    disabled
    placeholder='Male'
    />
 </div>

 <div className='flex flex-col w-full justify-start gap-1.5'>
   <p className='text-sm text-blue text-start'>Dedicated percentage *</p>
   <p className='text-xs text-gray text-left'>{percentage_of_investment && percentage_of_investment} % </p>
   <input 
                        type="range"
                        min="1"
                        max="100"
                        name='percentage_of_investment'
                        value={percentage_of_investment && percentage_of_investment} 
                        disabled
                        className='nomineeRangeSlider'
                        //ref={sliderRef}
                      />
 </div>

    </div>

        <div className='flex flex-col gap-4 w-1/2'>

        <div className='flex flex-col w-full justify-start gap-1.5'>
        <p className='text-sm text-blue text-start'>Mobile Number</p>
            <input type="text" 
            name="" 
            value={mobile_no && mobile_no}
            className='propDisableInputText'
            placeholder='1234567890'
            disabled
            />
        </div>


        


        <div className='flex flex-col w-full justify-start gap-1.5'>
        <p className='text-sm text-blue text-start'>Relationship <span style={{color:"red"}}>*</span> </p>
            <input type="text" 
            name="" 
            value={relation && relation}
            className='propDisableInputText'
            placeholder='Brother'
            disabled
            />
        </div>

        <div className='flex flex-col w-full justify-start gap-1.5'>
        <p className='text-sm text-blue text-start'>Aadhar Number <span style={{color:"red"}}>*</span></p>
            <input type="text" 
            name="" 
            value={aadhar_number && aadhar_number}
            className='propDisableInputText'
            placeholder='1231-1234-1234'
            disabled
            />
        </div>

        </div>

      </div>

 

   </div>
  )
}
