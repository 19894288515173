import React, { useState } from 'react'
import { IoCheckmarkSharp } from 'react-icons/io5';
import { MdOutlineContentCopy } from 'react-icons/md';
import { useLocation } from 'react-router-dom';

export default function SingleTransaction(props) {

  const{
    property_name,
    transaction_id,
    total_price,
    no_of_tokens,
    transaction_status,
    index


  }=props
  const [isCopied, setIsCopied] = useState(false);
  const location=useLocation()

const getStatusButtonClass = () => {
  switch (transaction_status) {
      case 'CANCEL':
          return 'cancelBtn';
      case 'COMPLETED':
          return 'CompletedBtn';
      case 'PENDING':
          return 'PendingBtn';
      default:
          return '';
  }
}
const getTTransacrionStatus = () => {
  switch (transaction_status) {
      case 'CANCEL':
          return 'Cancelled';
      case 'COMPLETED':
          return 'Completed';
      case 'PENDING':
          return 'Pending';
      default:
          return '';
  }
}
const copyToClipboard = () => {
  navigator.clipboard.writeText(transaction_id);
  setIsCopied(true);
  setTimeout(() => {
      setIsCopied(false); 
  }, 3000);
};



const queryParams = new URLSearchParams(location.search);
const pageFromParam = parseInt(queryParams.get('page')) || 1;

const returnSerialNo = (pageFromParam) => {
  if (props.isSearchActive) {
    return props.serialNo; // Reset serial number during search
  } else {
    if (pageFromParam > 1) {
      return (props.serialNo + ((pageFromParam - 1) * 5));
    } else {
      return props.serialNo;
    }
  }
};


  return (
    <div className='flex py-4 text-sm rounded-[10px] transacRow'>
    <div className='slNO text-sm text-gray'>{returnSerialNo(pageFromParam)}</div>
          <div className='propNamediv '>{property_name && property_name}</div>
          <div className='transIddiv flex gap-2 items-center relative'>

          {isCopied && (
                                        <div className="transactiontooltip">
                                            Transaction ID is copied!
                                        </div>
                                    )}
                
            <p>{transaction_id.substring(0, 8)}..</p>
            
            <button className="copyButton" onClick={() => copyToClipboard(transaction_id)}>
                                            {
                                               isCopied? (<IoCheckmarkSharp />):  <MdOutlineContentCopy />
                                            }
                                      
                                        
                                        </button>
            </div>
          <div className='TokenPriceDiv '>
            {total_price && total_price.toLocaleString()}</div>
          <div className='noOfTokenDiv '>{no_of_tokens && no_of_tokens}</div>
          <div className={`StatusDiv statusebtn ${ getStatusButtonClass()}`}>
          {getTTransacrionStatus()}
            </div>
    </div>
  )
}
