import React, { useEffect, useRef, useState } from "react";
import "../property-image.css";

import { BiDotsHorizontalRounded } from "react-icons/bi";
import { MdDelete, MdOutlineRemoveRedEye } from "react-icons/md";

import { useParams } from "react-router-dom";
import {
  ApiBaseUrl,
  FileUploadURL,
  PropertImageBaseUrl
} from "../../../Utils/urls";
import FloatingLoader from "../../Loader/FloatingLoader";
import { getPropertyDetails } from "../../../Api/PropertyAddApi";
import PopUp from "../../PopUp/PopUp";
import CustomButton from "../../Common/CustomeButton";

export default function EditPropertyImage(props) {
  const [propImage, setpropImage] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [propImageErr, setPropImgErr] = useState("");
  const [showDeleteIndex, setShowDeleteIndex] = useState(-1);
  const deleteButtonRef = useRef(null);
  const accessToken = localStorage.getItem("accesstoken");
  const adminId = localStorage.getItem("adminId");
  const [isLoading, setLoading] = useState(false);
  //const {propertyId,setPropertyId}=props
  const { id: propertyId } = useParams();
  const [currentImage, setCurrentImage] = useState(null);
  const [showImagePopUp, setShowImagePopUp] = useState(false);
  const { MintedToken } = props;
  //const propertyId=9

  const handleDotsClick = (index) => {
    setShowDeleteIndex(index);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        deleteButtonRef.current &&
        !deleteButtonRef.current.contains(event.target)
      ) {
        setShowDeleteIndex(-1);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [deleteButtonRef]);

  //const isSaveBtnEnabled=uploadedImages[0]?.base64!==undefined

  const isSaveBtnEnabled = propImage.length > 0 || uploadedImages.length > 0;

  const fetchPropDetails = () => {
    getPropertyDetails(
      undefined,
      propertyId,
      adminId,
      accessToken,
      undefined,
      undefined,
      undefined,
      undefined,
      setpropImage
    );
  };

  useEffect(() => {
    if (propertyId) {
      fetchPropDetails();
    }
  }, []);

  //  const handlePropImageChange = (e) => {

  //      const file = e.target.files[0];
  //     // const size = file.size;

  //     if(file)
  //     {
  //      const fileTypeParts = file.type.split('/');
  //      const fileExtension = fileTypeParts.length === 2 ? fileTypeParts[1] : '';

  //      const maxSize = 5 * 1024 * 1024;

  //      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {

  //         setPropImgErr('Only JPG or PNG formats are allowed');

  //       }  else {
  //             setPropImgErr('');

  //          const reader = new FileReader();
  //          reader.onload = (event) => {
  //            const base64String = event.target.result;

  //            setpropImage(
  //              {
  //                extension:fileExtension,
  //                file_type:file.type,
  //                base64:base64String

  //               }
  //              );

  //          };

  //           reader.readAsDataURL(file);

  //        }

  //    }

  //  };

  const handlePropImageChange = (e) => {
    const files = Array.from(e.target.files);

    const maxSize = 5 * 1024 * 1024;

    files.forEach((file) => {
      const fileTypeParts = file.type.split("/");
      const fileExtension = fileTypeParts.length === 2 ? fileTypeParts[1] : "";

      // if (file.size > maxSize) {
      //   setPropImgErr('File size exceeds the maximum limit of 5MB');
      //   return;
      // }

      if (file.type !== "image/jpeg" && file.type !== "image/png") {
        setPropImgErr("Only JPG or PNG formats are allowed");
        return;
      }

      setPropImgErr("");

      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target.result;

        setUploadedImages((prevImages) => [
          ...prevImages,
          {
            extension: fileExtension,
            file_type: file.type,
            base64: base64String
          }
        ]);
      };
      reader.readAsDataURL(file);
    });
  };
  const handleUploadPropImage = () => {
    setLoading(true);
    fetch(`${FileUploadURL}/image/upload`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken
      },
      body: JSON.stringify({
        admin_id: adminId,
        folder: "img",
        property_id: propertyId,
        images: uploadedImages
      })
    })
      .then((response) => {
        return response.json();
      })
      .then((resdata) => {
        console.log("Response from server:", resdata);

        if (resdata.success) {
          updatePropertyImages(resdata.data);
          setUploadedImages([]);
        } else if (!resdata.success) {
        }
      })
      .catch((error) => {
        console.error("error details:", error);
      });
  };

  const updatePropertyImages = (imageArray) => {
    fetch(`${ApiBaseUrl}/fr-admin/update-property-images`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken
      },
      body: JSON.stringify({
        admin_id: adminId,
        property_id: propertyId,
        property_images: [...propImage, ...imageArray]
      })
    })
      .then((response) => response.json())
      .then((resdata) => {
        // console.log('Response from update property images:', resdata);
        if (resdata.success) {
          setLoading(false);
          fetchPropDetails();
        } else {
        }
      })
      .catch((error) => {
        console.error("error details:", error);
      });
  };

  const combinedImages = [...propImage, ...uploadedImages];

  const handleDeleteImage = (index) => {
    if (index < propImage.length) {
      // Deleting an image from the propImage array
      setpropImage((prevImages) => prevImages.filter((_, i) => i !== index));
    } else {
      // Deleting an image from the uploadedImages array
      const uploadIndex = index - propImage.length;
      setUploadedImages((prevImages) =>
        prevImages.filter((_, i) => i !== uploadIndex)
      );
    }
    setShowDeleteIndex(-1); // Close the delete option
  };

  const handleShowImagePopUp = (image) => {
    setCurrentImage(image);
    setShowImagePopUp(true);
  };

  const handleCloseImagePopUp = () => {
    setShowImagePopUp(false);
    setCurrentImage(null);
  };

  return (
    <div className="propCard">
      {isLoading && <FloatingLoader />}

      <div className="flex justify-between items-center">
        <p className="text-lg font-medium text-black">
          Edit Property Images
          <span className="text-sm text-gray">
            &nbsp;&nbsp;&nbsp; Upload mages* (Format : PNG, JPG)
          </span>
        </p>

        <CustomButton
          onClick={handleUploadPropImage}
          isDisabled={MintedToken > 0 || !isSaveBtnEnabled}
          buttonText={"Save Details"}
          disabledMessage="Editing is no longer available. The token has already been minted"
          className="propSaveBtn"
          position="top"
          MintedToken={MintedToken}
        />
      </div>

      <div
        className="flex gap-4 propImageScrollbar"
        style={{
          whiteSpace: "nowrap",
          overflowX: "auto",
          paddingBottom: "10px"
        }}
      >
        {/* {
                propImage.length > 0 && propImage.map((image, index) => (

                  <div className='addedPropImg'>
<img key={index} className="propeImgPreview" src={`${PropertImageBaseUrl}/${image.Key}`} alt="Avatar" />

</div>
                  
                ))
              }

{
                propImage.length > 0 && propImage.map((image, index) => (

                  <div className='addedPropImg'>
<img key={index} className="propeImgPreview" src={image.base64} alt="Avatar" />

</div>
                  
                ))
              } */}

        {combinedImages &&
          combinedImages.length > 0 &&
          combinedImages.map((image, index) => (
            <div className="addedPropImg" key={index}>
              <div
                className="flex justify-end relative"
                style={{ width: "170px" }}
              >
                <BiDotsHorizontalRounded
                  className="cursor-pointer"
                  onClick={() => handleDotsClick(index)}
                />
                {showImagePopUp && currentImage && (
                  <PopUp
                    maxWidth={"max-w-xl"}
                    showCloseIcon={true}
                    closeModal={handleCloseImagePopUp}
                  >
                    <img
                      src={
                        currentImage.base64
                          ? currentImage.base64
                          : `${PropertImageBaseUrl}/${currentImage?.Key}`
                      }
                      alt="Selected"
                    />
                  </PopUp>
                )}

                {showDeleteIndex === index && (
                  <div className="ImageOption shadow-md" ref={deleteButtonRef}>
                    <div
                      className="bg-[#f7b8ee91] py-1 px-1.5 rounded-md text-pink flex cursor-pointer gap-2 items-center"
                      onClick={() => handleShowImagePopUp(image)}
                    >
                      <MdOutlineRemoveRedEye />
                      <p>View</p>
                    </div>
                    <div
                      className="bg-delBg py-1 px-1.5 rounded-md text-delred flex cursor-pointer gap-2 items-center"
                      onClick={() => handleDeleteImage(index)}
                    >
                      <MdDelete />
                      <p>Delete</p>
                    </div>
                  </div>
                )}
              </div>

              <img
                className="propeImgPreview"
                src={
                  image.base64
                    ? image?.base64
                    : `${PropertImageBaseUrl}/${image?.Key}`
                }
                alt="Avatar"
              />
            </div>
          ))}

        <div>
          <div class="propImageSec">
            <label class="propImagelabel" htmlFor="prop-image">
              <span class="material-symbols-outlined"> publish</span>
              {/* {
                propImage.length > 0 && propImage.map((image, index) => (
                  <img key={index} className="property-img" src={image.base64} alt="Avatar" />
                ))
              } */}

              {propImage && propImage.base64 && (
                <img
                  className="property-img"
                  src={propImage.base64}
                  alt="Avatar"
                />
              )}
            </label>
            <input
              id="prop-image"
              type="file"
              multiple
              onChange={handlePropImageChange}
            />
            {/* <img src={`${PropertImageBaseUrl}/${builder_logo && builder_logo.Key}`} alt="Builder" /> */}
          </div>
          {propImageErr !== "" && (
            <p className="text-xs text-red">{propImageErr}</p>
          )}
        </div>
      </div>
    </div>
  );
}
