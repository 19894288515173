import React, { useEffect, useRef, useState } from "react";
import { formatDate } from "../../../Utils/dateFormat";
import { FaClipboard, FaClipboardCheck } from "react-icons/fa6";

export default function MintTableCard(props) {
  const { index, transaction_id, no_of_tokens_minted, mint_date, admin_name } =
    props;
  const [isCopied, setIsCopied] = useState(false);

  const accesstoken = localStorage.getItem("accesstoken");
  const adminId = localStorage.getItem("adminId");

  const handleCopy = () => {
    navigator.clipboard.writeText(transaction_id).then(() => {
      setIsCopied(true);

      setTimeout(() => setIsCopied(false), 2000);
    });
  };

  return (
    <div className="flex w-full gap-2 tableHead  justify-center  propRow">
      <div className="slNoMIntM">{index + 1}</div>

      <div className="MintDateclsM">{mint_date && formatDate(mint_date)} </div>
      <div className="MintIdM">
        {transaction_id && (
          <div className="flex items-center gap-2">
            <a
              href={`https://amoy.polygonscan.com/tx/${transaction_id}`}
              target="_blank"
              rel="noreferrer"
              className="text-blue"
            >
              {transaction_id && transaction_id.length > 10
                ? `${transaction_id.slice(0, 10)}...`
                : transaction_id}
            </a>

            <div onClick={handleCopy} className="cursor-pointer text-lg">
              {isCopied ? <FaClipboardCheck /> : <FaClipboard />}
            </div>
          </div>
        )}
      </div>
      <div className="mintedTokenclsM">
        {no_of_tokens_minted && no_of_tokens_minted}
      </div>
      <div className="adminClsM">{admin_name && admin_name}</div>
    </div>
  );
}
