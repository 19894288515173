import { useState } from "react";

const CustomButton = ({
  onClick,
  isDisabled,
  buttonText,
  disabledMessage,
  className = "",
  position = "top",
  MintedToken
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const showTooltip = MintedToken > 0;

  return (
    <div
      className="relative inline-block"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <button
        className={`${
          isDisabled ? "bg-lightgray text-gray" : "bg-pink text-white"
        } ${className} `}
        disabled={isDisabled}
        onClick={onClick}
      >
        {buttonText}
      </button>

      {isHovered && isDisabled && disabledMessage && showTooltip && (
        <div
          className={`tooltip ${position} absolute ${
            position === "top" ? "bottom-full mb-2" : "top-full mt-2"
          } z-[1000] left-1/2 transform -translate-x-1/2 max-w-[300px] w-full px-3 py-2 bg-black text-white rounded text-sm whitespace-normal`}
        >
          {disabledMessage}
        </div>
      )}
    </div>
  );
};

export default CustomButton;
