import React, { useEffect, useRef, useState } from 'react'
import { AiFillEdit } from 'react-icons/ai';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { GoPlus } from 'react-icons/go';

import { MdDelete } from 'react-icons/md';


export default function ProjectSpecificationCard(props) {


    const [showOption,setShowOption]=useState(false)

    // const handleDeleteClick = () => {
    //     deleteAmenity(amenity_id);
    //   };
const {projectspec,handleProjectSpecChange,index,handleDeleteProjectSpec}=props
 
const optionsRef = useRef(null);
const handleShowOption=()=>{
   setShowOption(true)
}

useEffect(() => {
   const handleClickOutside = (event) => {
     if (optionsRef.current && !optionsRef.current.contains(event.target)) {
       setShowOption(false);
     }
   };

   document.addEventListener('mousedown', handleClickOutside);
   return () => {
     document.removeEventListener('mousedown', handleClickOutside);
   };
 }, []);

 const handleInputChange = (e) => {
  handleProjectSpecChange(index, e.target.value)
}

const handleDeleteClick = () => {
  handleDeleteProjectSpec(index)
}

  return (
    <div className='flex justify-between  items-center gap-5'>
    

    <input type="text" 
    className='amenInputText' 
    value={projectspec}
    onChange={handleInputChange}
     placeholder='E.g Swimming Pool' />
  
  
  <div className='flex gap-2.5 items-center'>
  

  
 
  <div className='relative'>
  <BsThreeDotsVertical   className='cursor-pointer' onClick={handleShowOption} />
  {
     showOption && (
  <div ref={optionsRef}  className='flex flex-col gap-3  z-50 shadow absolute top-0 right-0 rounded-xl p-2.5 bg-white'>
  

  {/* <div className='projectSpecBtn bg-addbg' >
  
  <p className='text-green flex gap-2 items-center text-sm' >
  <GoPlus />Add</p>
  
  </div> */}


  


  
  <div className='delBtnTenant cursor-pointer' onClick={handleDeleteClick}>
  
  <p className='text-delred  flex gap-2 items-center text-sm' >
  <MdDelete />Delete</p>
  
  </div>

 


  </div>
  
  
  
     )
  }
  
  
  
  </div>
  
  </div>
  
  
  
  </div>
  )
}
