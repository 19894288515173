import React, { useEffect, useState } from 'react'
import { IoEyeOutline } from 'react-icons/io5';
import Switch from 'react-switch';
import CustomeCheckbox from '../CustomeCheckbox';
import { MdOutlineModeEdit } from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';



export default function ViewUserManagementForm({adminData,handleInputChange,setAdminData,adminAccess}) {

   
    const [permissionsChecked, setPermissionsChecked] = useState(false);
   


    const handlePermissionsSwitchChange = (checked) => {
        setPermissionsChecked(checked);

        setAdminData(prevState => ({
            ...prevState,
            profile: {
                ...prevState.profile,
                permissions: {
                    ...prevState.profile.permissions,
                    view_user_profile: checked,
                    edit_user_profile: checked,
                    create_new_user: checked,
                    delete_user: checked
                }
            }
        }));
        
    


      };

      useEffect(() => {
        const anyUnchecked =
            adminData.profile?.permissions?.view_user_profile ||
            adminData.profile?.permissions?.edit_user_profile ||
            adminData.profile?.permissions?.create_new_user ||
            adminData.profile?.permissions?.delete_user;
        setPermissionsChecked(anyUnchecked);
    }, [adminData.profile?.permissions]);


  return (
    <div className='UserManageformSect font-nunito'>
<div className='flex justify-between items-center'>
<p className='text-2xl font-medium'>User management</p>
<Switch
             checked={permissionsChecked}
             onChange={handlePermissionsSwitchChange}
            offColor="#888" 
            onColor="#419E6A"
            uncheckedIcon={false}  
            checkedIcon={false}
            width={36}
            height={20}
            handleDiameter={13.5}
            disabled
            
          />
</div>

<p className='font-bold text-lg text-left'>Profile Section Permission</p>

<div className='flex justify-between items-center'>
    <div className='flex flex-col'>
        <div className='flex gap-2 items-center text-viewblue font-medium'>
            <IoEyeOutline />
            <p className='text-lg '>View Users Profile</p>
        </div>
        <p className='text-gray text-xs'>Access and view stored data 
within the dashboard.</p>
    </div>

   

        <CustomeCheckbox 
      
        onChange={handleInputChange}
        dataSection="permissions" 
        name="view_user_profile"
        checked={Boolean(adminData.profile?.permissions?.view_user_profile)}
        disabled
        />
       
</div>



<div className='flex justify-between items-center'>
    <div className='flex flex-col'>
        <div className='flex gap-2 items-center text-edityellow font-medium'>
        <MdOutlineModeEdit />
            <p className='text-lg '>Edit User Profile</p>
        </div>
        <p className='text-gray text-xs'>Users can modify content 
 in the dashboard.</p>
    </div>

   

        <CustomeCheckbox  
        onChange={handleInputChange}
        dataSection="permissions" 
        name="edit_user_profile"
        checked={Boolean(adminData.profile?.permissions?.edit_user_profile) }
        disabled
        />
    
</div>




<div className='flex justify-between items-center'>
    <div className='flex flex-col'>
        <div className='flex gap-2 items-center text-gray font-medium'>
        <span className="material-symbols-outlined">
admin_panel_settings
</span>
            <p className='text-lg '>Create New User Profile</p>
        </div>
        <p className='text-gray text-xs'>Add new data or 
Profile to the dashboard.</p>
    </div>

   

        <CustomeCheckbox 
         onChange={handleInputChange}
        dataSection="permissions" 
        name="create_new_user"
        checked={Boolean(adminData.profile?.permissions?.create_new_user)}
        disabled
         />
       
</div>



<div className='flex justify-between items-center'>
    <div className='flex flex-col'>
        <div className='flex gap-2 items-center text-delred font-medium'>
        <RiDeleteBin6Line />
            <p className='text-lg '>Delete</p>
        </div>
        <p className='text-gray text-xs'>Remove data or 
Profile to the dashboard.</p>
    </div>

   

        <CustomeCheckbox  
        onChange={handleInputChange}
        dataSection="permissions" 
        name="delete_user"
        checked={Boolean(adminData.profile?.permissions?.delete_user)}
        disabled
         />
       
</div>


    </div>
  )
}
