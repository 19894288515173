import React, { useState } from 'react'
import { IoIosClose } from 'react-icons/io'
import { usePropertyFilter } from '../../Utils/PropertyFilter'
import { useTransactionFilters } from '../../Utils/Transactionfilters'

export default function TransactionSort(props) {
const {showTransactionSort,setShowTransactionSort}=props
const handleCloseSprtPopup=()=>{
  setShowTransactionSort(false)
}

//const { PropFilters, setPropFilters,setPage } = usePropertyFilter();
const { TransactionpropertyFilters, setTransactionPropertyFilters } = useTransactionFilters();


const [isFilterActive, setIsFilterActive] = useState(false);
const [sortByListingDate, setsortByListingDate] = useState([]);
const [sortBy, setsortBy] = useState([]);

const [sortByTokenPrice,setSortByTokenprice]=useState([])
const [sortToken,setTokenSort]=useState([])


const handleSortByListingDate = (sortByDate) => {
    if (sortByListingDate.includes(sortByDate)) {
      return;
    }
    const updatedSortByStatus = sortByDate === 'desc' ? ['desc'] : ['asc'];
  
    setsortByListingDate(updatedSortByStatus);
    setTransactionPropertyFilters((prevFilters) => ({
      ...prevFilters,
      sortBy: {
        ...prevFilters.sortBy,
        transaction_date:sortByDate
        
      },
    }));
  };


  const handleSortByTokenprice = (sortByTPrice) => {
   
    if (sortByTokenPrice.includes(sortByTPrice)) {
      return;
    }
    const updatedSortByToken = sortByTPrice === 'desc' ? ['desc'] : ['asc'];
  
    setSortByTokenprice(updatedSortByToken);
    setTransactionPropertyFilters((prevFilters) => ({
      ...prevFilters,
      sortBy: {
        ...prevFilters.sortBy,
        token_price:sortByTPrice
        
      },
    }));
  };

  const handleClearSortlisting = () => {
    setsortByListingDate([]);
    setTransactionPropertyFilters((prevFilters) => ({
      ...prevFilters,
      sortBy: {
        ...prevFilters.sortBy,
        transaction_date:"desc"
        
      },
    }));
  };


  const handleClearSortByTokenprice = () => {
    setSortByTokenprice([]);
    setTransactionPropertyFilters((prevFilters) => ({
      ...prevFilters,
      sortBy: {
        ...prevFilters.sortBy,
        token_price:null
        
      },
    }));
  };


const handleClearAllSort=()=>{
    setsortByListingDate([]);
    setSortByTokenprice([]);
    setTransactionPropertyFilters((prevFilters) => ({
        ...prevFilters,
        sortBy: {
          transaction_date:"desc",
          token_price:null
          
        },
      }));
}
//${showPropertySort?'block':'hidden'
  return (
    <div className={`propertyFilterCard ${showTransactionSort?'block':'hidden'}`}>
  <div className='flex justify-between items-start border-lightgray  border-b '>
    <p className='text-base font-bold text-blue'>Sort By</p>
    <div className='flex gap-2 items-center'>
    <p className='text-sm  text-gray cursor-pointer' onClick={handleClearAllSort}>Clear all</p>
    <IoIosClose  className='cursor-pointer' onClick={handleCloseSprtPopup} />
    </div>
   

    
  </div>


  <div className=' text-black flex flex-col pt-4'>
  <div className='flex flex-row justify-between'>
  <div className='text-[16px] font-medium text-start'>Transactions Date</div>
  <div className='flex flex-col justify-center'>
    <div className='clearButton text-xs text-start font-normal -mr-1 flex flex-col justify-center rounded-lg py-0.5 px-2 h-5 cursor-pointer'
 onClick={handleClearSortlisting}
    >Clear</div>
  </div>

  </div>
    <div className=' flex flex-row flex-wrap gap-2 p-2 -ml-2'>
   
     

    <button
        className={`propertyPhasesButton  text-black text-sm w-24 h-7 ${
            sortByListingDate.includes('desc') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handleSortByListingDate('desc')}
      >
       Newest 
      </button>
      <button
        className={`propertyPhasesButton  text-black text-sm w-20 h-7  whitespace-nowrap ${
            sortByListingDate.includes('asc') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handleSortByListingDate('asc')}
      >
       Oldest
      </button>
     

    
     
      
    </div>
  </div>



  <div className=' text-black flex flex-col pt-4'>
  <div className='flex flex-row justify-between'>
  <div className='text-[16px] font-medium text-start'>Token Price </div>
  <div className='flex flex-col justify-center'>
    <div className='clearButton text-xs text-start font-normal -mr-1 flex flex-col justify-center rounded-lg py-0.5 px-2 h-5 cursor-pointer'
    onClick={handleClearSortByTokenprice}
    >Clear</div>
  </div>

  </div>
    <div className=' flex flex-row flex-wrap gap-2 p-2 -ml-2'>
   
     

    <button
        className={`propertyPhasesButton  text-black text-sm w-24 h-7 ${
            sortByTokenPrice.includes('desc') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handleSortByTokenprice('desc')}
      >
       Maximum
      </button>
      <button
        className={`propertyPhasesButton  text-black text-sm w-20 h-7  whitespace-nowrap ${
            sortByTokenPrice.includes('asc') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handleSortByTokenprice('asc')}
      >
       Minimum
      </button>
     

    
     
      
    </div>
  </div>

 




</div>
  )
}
