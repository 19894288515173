import React from 'react'
import { MdInfoOutline } from 'react-icons/md'

export default function BankDetails({bankDetails,isLoading}) {


let bankdetails
if(bankDetails.length!=0)
  {
    bankdetails=bankDetails[0]
  }
  else{
    bankdetails=bankDetails
  }

  return (
    <div className='propCard'>

    {/* sec-1 */}
    
      <div className='flex justify-between items-center'>
        <p className='text-2xl font-medium '>Bank Details</p>
        <button className='propSaveBtn bg-lightgray text-gray'>Edit</button>
      </div>

      <div className='flex justify-between'>
    


    
    <div className='flex flex-col gap-4 UserDetailsSec'>



<div className='flex justify-between'>


        {/* <div className='flex flex-col gap-2.5 items-start propInputDiv'>
              <p className='text-sm text-blue'>Bank Name </p>
              <input type="text" 
                  name="" 
                  value={bankdetails?.bank_name && bankdetails?.bank_name}
                  className='propInputText'
                  placeholder='Punjab National bank'
                  />
          
          </div> */}


          <div className='flex flex-col gap-2.5 items-start UserInputDiv'>
                  <p className='text-sm text-blue'>Account no</p>
                  <input type="text" 
                   value={bankdetails?.account_number && bankdetails?.account_number}
                  name="" 
                  className='propDisableInputText'
                  placeholder='E.g- 000000000002929'
                  disabled
                   />
              </div>

              <div className='flex flex-col gap-2.5 items-start UserInputDiv'>
                  <p className='text-sm text-blue'>IFSC Code</p>
                  <input type="text" 
                   value={bankdetails?.ifsc_code && bankdetails?.ifsc_code}
                  name="" 
                  className='propDisableInputText'
                  placeholder='E.g - PUNB000008932'
                  disabled
                   />
              </div>

</div>


<div className='flex justify-between'>

   

              <div className='flex flex-col gap-2.5 items-start '>
                  <p className='text-sm text-blue'>Primary account</p>
                 
                 <div className='text-blue flex items-center gap-1  font-medium  py-2.5'>
                    Satus: <span className={`${bankdetails?.is_active?"text-green":"text-red"}`}> &nbsp; {bankDetails.is_active?"Active":"Inactive"}</span>
                   {
                  bankdetails && bankdetails.is_active==0 && (
                    <>
                    <MdInfoOutline style={{color:"#FFC35A"}} />
                    <p  style={{color:"#f6b33f"}} > {bankdetails && bankdetails.note}</p>
                  
                    </>

                  )
                   }
                    
                 </div>
              </div>

</div>







    </div>




  </div>






      </div>
  )
}
