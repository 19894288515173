import React, { useState, useEffect, useRef } from 'react';

import { FaSalesforce } from 'react-icons/fa';
import { getPropertyDetails } from '../../../Api/PropertyAddApi';
import { ApiBaseUrl } from '../../../Utils/urls';
import SuccessAlert from '../../Alert/SuccessAlert';
import ErrorAlert from '../../Alert/ErrorAlert';
import FloatingLoader from '../../Loader/FloatingLoader';

import { useParams } from 'react-router-dom';
import ViewTenantCard from './ViewTenantCard';



export default function ViewTenant() {
  const [propertyinfo,setPropertyInfo]=useState({})
  const [tenantCards, setTenantCards] = useState(propertyinfo?.tenants?propertyinfo?.tenants.length : 0);
  const [tenants, setTenants] = useState([]);

  const [isLoading,setLoading]=useState(false)
  const [isTenantLoading,setTenatLoading]=useState(false)
  const [istenantAdded,setTenanatAdded]=useState(false)
  const [alertMsg,setAlertMsg]=useState("")
  const [isTenantErr,setTenantErr]=useState(false)
const [propRes,setPropResp]=useState({})

const containerRef = useRef(null); 
const lastCardRef = useRef(null);
const [editingIndex, setEditingIndex] = useState(null); //edit

const accesstoken=localStorage.getItem("accesstoken")
const adminId=localStorage.getItem("adminId")
const {id:propId}=useParams()

const isAllFieldsFilled =  tenants?.every(tenant =>
  tenant.tenant_name && tenant.lease_period && tenant.lock_in_period && tenant.price_per_sqft
);



useEffect(()=>{
  if(propId)
    {
      getPropertyDetails(setLoading,propId,adminId,accesstoken,setPropResp,setPropertyInfo)
    }

},[])


  useEffect(() => {
    setTenantCards(tenants.length);
  }, [tenants]);



  

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedTenants = [...tenants];
    updatedTenants[index] = {
      ...updatedTenants[index],
      [name]: value
    };
    setTenants(updatedTenants);
  };
  



  useEffect(()=>{
if(propertyinfo.tenants?.length>0){
  setTenants(propertyinfo.tenants)
}
else{
  setTenants([{
    tenant_name: '',
    lease_period: '',
    lock_in_period: '',
    price_per_sqft: ''
  }])
}
  },[propertyinfo.tenants])




  return (
    <>


  


      <div className='flex justify-between items-center'>
        <p className='text-lg font-medium text-black'>
          Tenant Overview
          <span style={{ color: '#bc2222' }}>*</span>
        </p>
        <div className='flex gap-5 items-center'>
          <button className='propSaveBtn bg-lightgray text-gray' disabled>
            Add Tenant
          </button>
          <button className={`bg-lightgray text-gray propSaveBtn `}
          
          disabled >
            
            Save Details</button>
        </div>
      </div>

      <div ref={containerRef} className='flex gap-4 overflow-x-auto relative' style={{ whiteSpace: 'nowrap' }}>
        {/* Render TenantCard components for existing tenants */}

      
    {
      isTenantLoading && (
        <FloatingLoader/>
      )
    }
        {tenants && tenants.map((tenant, index) => (
          <div key={index}  ref={index === tenants.length - 1 ? lastCardRef : null}>


            <ViewTenantCard
              {...tenant}
              index={index}
              handleInputChange={handleInputChange}
             isEditing={index === editingIndex} 
             // cardRef={index === tenants.length - 1 ? lastCardRef : null}
            />
          </div>
        ))}
       
      </div>
    </>
  );
}
