import React, { useEffect, useState } from 'react'
import ProjectSpecificationCard from './Card/ProjectSpecificationCard';
import "./project-spec.css"
import { getPropertyDetails } from '../../Api/PropertyAddApi';
import { ApiBaseUrl } from '../../Utils/urls';
import FloatingLoader from '../Loader/FloatingLoader';
import SuccessAlert from '../Alert/SuccessAlert';
import ErrorAlert from '../Alert/ErrorAlert';
import DocumentUpload from './DocumentUpload';
import PaymentSchedule from './PaymentSchedule';

export default function PropertyInfoTab(props) {

  const [activeTab, setActiveTab] = useState('Overview'); 
  const [aboutProp,setAboutProp]=useState("")
  const [projectSpec,setProjectSpec]=useState([""])

  //project spec states
  const [isProjectSpecLoading,setProjectSpecLoading]=useState(false)
  const [isProjectSpecAdded,setProjectSpecAdded]=useState(false)
  const [isProjectSpecErr,setProjectSpecErr]=useState(false)
  const [projectSpecAlertMsg,setProjectSpecAlertMsg]=useState("")
  const accesstoken=localStorage.getItem("accesstoken")
  const adminId=localStorage.getItem("adminId")
  const [propertyinfo,setPropertyInfo]=useState({})
  const {propertyId}=props
 
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };


  const fetchPropertDetails=()=>{
    getPropertyDetails(
      undefined,
      propertyId,
      adminId,
      accesstoken,
      undefined,
      setPropertyInfo,
      undefined,
      undefined,
      undefined,
      setAboutProp,
      setProjectSpec)
  }

  useEffect(()=>{
    if(propertyId)
      {
        fetchPropertDetails()
      }
   
  },[propertyId])


  const handleAboutChange=(e)=>{
    setAboutProp(e.target.value)
  }

  const handleAddprojctSpec=()=>{
    setProjectSpec([...projectSpec,""])
  }

  const handleProjectSpecChange = (index, value) => {
    const updatedSpecs = [...projectSpec]
    updatedSpecs[index] = value
    setProjectSpec(updatedSpecs)
  }


  const handleDeleteProjectSpec = (index) => {
    const updatedSpecs = projectSpec.filter((_, i) => i !== index)
    setProjectSpec(updatedSpecs)
  }



const isSaveBtnEnabled=aboutProp!=="" && projectSpec[0]!==""

  const handleAddProjectSpectoServer=()=>{
    setProjectSpecLoading(true)
    fetch(`${ApiBaseUrl}/fr-admin/add-property-overview-info`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accesstoken
      },
      body: JSON.stringify({
        
    "admin_id":adminId,
    "property_id": Number(propertyId),
    "project_specs": projectSpec,
    "property_description": aboutProp
      })
    }
  )
      .then(response => {
      
       
       
        return response.json();
      })
      .then(resdata => {
       
       //console.log('Response from server:', resdata);
       setProjectSpecLoading(false)
          if(resdata.success)
          {

              setProjectSpecAlertMsg(resdata.data)
            setProjectSpecAdded(true)
           fetchPropertDetails()
            setTimeout(() => {
              setProjectSpecAlertMsg("")
            setProjectSpecAdded(false)
            
             }, 3000);
          }
          else if(!resdata.success)
             {
                 setProjectSpecAlertMsg(resdata.data)
                setProjectSpecErr(true)
               setTimeout(() => {
                setProjectSpecErr(false)
                 setProjectSpecAlertMsg("")
                
 
               
                }, 3000);
             }
  
      })
      .catch(error => {
       
        console.error('error details:', error);
      });
  }
  console.log(propertyinfo)

  return (
    <>
<div className='flex justify-between items-center'>
        <p className='text-lg font-medium text-black'>Property Information</p>
        
    </div>

    <div className='flex gap-10 text-lg  tabBorder'>
      <div 
      onClick={() => handleTabClick('Overview')}
      className={`propTabbtn cursor-pointer ${activeTab==='Overview'?'propActiveTab':''}`}
      
      >
        Overview
        </div>

      <div onClick={() => handleTabClick('Documents')}
       className={`propTabbtn cursor-pointer ${activeTab==='Documents'?'propActiveTab':''}`}
      >Documents</div>
{
  propertyinfo.property_phase==="Presale"  && (
    <div onClick={() => handleTabClick('PaymentScedule')}
       className={`propTabbtn cursor-pointer ${activeTab==='PaymentScedule'?'propActiveTab':''}`}
      >Payment Schedule
      </div>
  )
  
}






    </div>


   
    {activeTab === 'Overview' &&
    <div className='flex flex-col gap-5'>
 
   <div className='flex justify-end items-center'>
     
     <button className={`propSaveBtn  ${isSaveBtnEnabled?'bg-pink text-white':'bg-lightgray text-gray'} `}
     disabled={!isSaveBtnEnabled}
     onClick={handleAddProjectSpectoServer}>Save Details</button>
 </div>


<div className='flex gap-5 relative'>
{
  isProjectSpecLoading && (
<FloatingLoader/>
  )
}

{
  isProjectSpecAdded && (
<SuccessAlert message={projectSpecAlertMsg} />
  )
}

{
  isProjectSpecErr && (
<ErrorAlert message={projectSpecAlertMsg} />
  )
}



<div className='flex flex-col gap-2.5 w-1/2 items-start tokenInputDiv'>
                  <p className='text-lg text-blue font-bold'>About Property<span style={{color:"#af2525"}}>*</span> </p>
                  <textarea name="" 
                  value={aboutProp} 
                  onChange={handleAboutChange}
                 
                  cols="30" rows="6"
                  disabled={!propertyId}
                  className= {propertyId?"propInputText":"propDisableInputText"}
                  //className='propInputText'
                  
                  ></textarea>
              </div>
    










              <div className='flex w-1/2 flex-col gap-2.5 items-start'>

 <div className='flex justify-between w-full'>
 <p className='text-start text-lg text-blue font-bold' >Project Specifications*</p>
 
<button 
disabled={!propertyId}
className= {`${propertyId?"bg-pink text-white":"bg-lightgray text-black"} text-xs rounded-md px-3`}
 onClick={handleAddprojctSpec}>Add More</button>


 </div>

 


<div className='flex flex-col w-full gap-1.5 projectSpecList'>

  {
    projectSpec && projectSpec.map((projectspec,index)=>(
<ProjectSpecificationCard 
projectspec={projectspec}
index={index}
handleProjectSpecChange={handleProjectSpecChange}
handleDeleteProjectSpec={handleDeleteProjectSpec}

/>
    ))
  }



</div>





</div>





</div>



    </div>
  
     }

{activeTab === 'Documents' &&
     <div>

<DocumentUpload propertyId={propertyId}/>

     </div>
     }


{activeTab === 'PaymentScedule' &&
     <div>

<PaymentSchedule propertyId={propertyId}/>

     </div>
     }
    

</>
  )
}
