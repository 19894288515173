import React from 'react'
import { Navigate } from 'react-router-dom';

export default function PrivateRoute({children}) {
    const isAccesTokenExist=localStorage.getItem("accesstoken")

 return  isAccesTokenExist ? children : <Navigate to="/" />;


}
