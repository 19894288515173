import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import './UserFilter.css'
import { DefaultFilter } from '../../Utils/urls';
import { ApiBaseUrl } from '../../Utils/urls';
import { RxCross2 } from "react-icons/rx";
import { initialUserFilter,useUserFilters } from '../../Utils/UserAccountFilter';


  
function UserSort(props) {
  const [kycStatus, setkycStatus] = useState([]);
  const [activeStatus, setactiveStatus] = useState([]);
  const [sortByStatus, setsortByStatus] = useState([]);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [isClearButtonActive, setIsClearButtonActive] = useState(false);
  const [sortBy, setsortBy] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');


const { UserFilters, setUserFilters } = useUserFilters();






  const handleSortBy = (sortByName) => {
    if (sortByStatus.includes(sortByName)) {
      return;
    }
    const updatedSortByStatus = sortByName === "Oldest" ? ["Oldest"] : ["Newest"];
  
    setsortByStatus(updatedSortByStatus);
    setUserFilters((prevFilters) => ({
      ...prevFilters,
      filters: {
        ...prevFilters.filters,
        sortBy: sortByName,
      },
    }));
  };
  


  const handleSortByClearClick = () => {
    setsortByStatus([]);
    setUserFilters((prevFilters) => ({
      ...prevFilters,
      filters: {
        ...prevFilters.filters,
        sortBy: 'Newest',
      },
    }));
  };
  


  const handleClearClick = () => {
    // setkycStatus([]);
    // setactiveStatus([]);
    setsortByStatus([]);
    // setStartDate('');
    // setEndDate('');
    setIsFilterActive(false);
    setIsClearButtonActive(false);
    setUserFilters(initialUserFilter)

  };


  useEffect(() => {
    setkycStatus([]);
    setsortBy([]);
    setIsFilterActive(false);
    setIsClearButtonActive(false);
    setUserFilters(initialUserFilter)
  }, [props.clearFilterSelection])
  

  const [leftPaneHeight, setLeftPaneHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setLeftPaneHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <>

    <div className='px-4 py-4'>
    <div className='text-black flex flex-row justify-between mt-2   '>
    <div className='text-black text-[16px] font-bold'>User Account</div>
    <div className='flex flex-row gap-2 pr-2' >
  <button
  onClick={handleSortByClearClick}
  className={`clearButton text-xs text-start font-normal -mr-3 flex flex-col justify-center rounded-lg py-0.5 px-2 h-5 cursor-pointer ${
    // Check if all filters are cleared
    !activeStatus.length && !kycStatus.length && !sortBy.length? 'ButtonBeforeCollect' : 'ButtonCollect'
  }`}
      >
        Clear
      </button>
<div className='ml-3' onClick={()=>props.setIsOpenMob(false)}><RxCross2 /></div>
</div> 


  </div>

    <div className='flex flex-row justify-center enquiryParent mt-1'>
      <hr style={{ color: 'rgba(217, 217, 217, 1)', width: '100%' }}></hr>
    </div>



  {/* <div className=' text-black flex flex-col pt-4'>
  <div className='flex flex-row justify-between'>
  <div className='text-[16px] font-medium text-start'>Account Status</div>
  <div className='flex flex-col justify-center'>
    <div className='clearButton text-xs text-start font-normal -mr-1 flex flex-col justify-center rounded-lg py-0.5 px-2 h-5 cursor-pointer'
    onClick={handleactiveStatusClearClick}
    >Clear</div>
  </div>

  </div>
    <div className=' flex flex-row flex-wrap gap-2 p-2 -ml-2'>
   
      <button
        className={`propertyPhasesButton  text-black text-sm w-24 h-7 ${
          activeStatus.includes('Inactive') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handleactiveStatusClick('Inactive')}
      >
        Inactive
      </button>
      <button
        className={`propertyPhasesButton  text-black text-sm w-20 h-7  whitespace-nowrap ${
          activeStatus.includes('Active') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handleactiveStatusClick('Active')}
      >
        Active
      </button>

      
    </div>
  </div> */}

  {/* <div className=' text-black flex flex-col pt-4'>
  <div className='flex flex-row justify-between'>
  <div className='text-[16px] font-medium text-start'>KYC Status</div>
  <div className='flex flex-col justify-center'>
    <div className='clearButton text-xs text-start font-normal -mr-1 flex flex-col justify-center rounded-lg py-0.5 px-2 h-5 cursor-pointer'
    onClick={handleKycStatusClearClick}
    >Clear</div>
  </div>

  </div>
    <div className=' flex flex-row flex-wrap gap-2 p-2 -ml-2'>

      <button
        className={`propertyPhasesButton  text-black text-sm w-20 h-7  whitespace-nowrap ${
          kycStatus.includes('Verified') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handlekycStatus('Verified')}
      >
        Verified
      </button>
      <button
        className={`propertyPhasesButton  text-black text-sm w-24 h-7 whitespace-nowrap ${
          kycStatus.includes('In Review') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handlekycStatus('In Review')}
      >
        In Review
      </button>
      <button
        className={`propertyPhasesButton  text-black text-sm w-20 h-7 ${
          kycStatus.includes('Rejected') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handlekycStatus('Rejected')}
      >
        Rejected
      </button>
      <button
        className={`propertyPhasesButton  text-black text-sm w-28 h-7 ${
          kycStatus.includes('Not Verified') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handlekycStatus('Not Verified')}
      >
        Not Verified
      </button>
    </div>
  </div> */}


  <div className=' text-black flex flex-col pt-4'>
  <div className='flex flex-row justify-between'>
  <div className='text-[16px] font-medium text-start'>Sort By</div>
  <div className='flex flex-col justify-center'>
    {/* <div className='clearButton text-xs text-start font-normal -mr-1 flex flex-col justify-center rounded-lg py-0.5 px-2 h-5 cursor-pointer'
    onClick={handleSortByClearClick}
    >Clear</div> */}
  </div>

  </div>
    <div className=' flex flex-row flex-wrap gap-2 p-2 -ml-2'>
   
      <button
        className={`propertyPhasesButton  text-black text-sm w-24 h-7 ${
            sortByStatus.includes('Oldest') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handleSortBy('Oldest')}
      >
        Oldest
      </button>
      <button
        className={`propertyPhasesButton  text-black text-sm w-20 h-7  whitespace-nowrap ${
            sortByStatus.includes('Newest') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handleSortBy('Newest')}
      >
        Newest
      </button>

      
    </div>
  </div>



  </div>

  </>
  );
}



export default UserSort
