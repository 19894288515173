import React from 'react';

const CustomInput = ({ value, onChange, onKeyDown, placeholder,name,disabled,className }) => {
  const handleKeyDown = (event) => {
    if (onKeyDown) {
      // Allow only numbers and specific control characters
      const allowedKeyCodes = [8, 9, 13, 37, 39]; // Backspace, tab, enter, left arrow, right arrow
      const allowedChars = /[0-9.]/; // Allow only numbers
      if (!allowedKeyCodes.includes(event.keyCode) && !allowedChars.test(event.key)) {
        event.preventDefault();
      }
    }
  };

  return (
    <input
      type="text"
      value={value}
      name={name}
      onChange={onChange}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      className={className}
      disabled={disabled}
    />
  );
};

export default CustomInput;
