import React, { useEffect, useRef, useState } from 'react'
import PopUp from '../../PopUp/PopUp'

import { MdDelete, MdOutlineClose } from 'react-icons/md'
import { PropertImageBaseUrl } from '../../../Utils/urls'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { RiEdit2Fill } from 'react-icons/ri'

export default function EditFloorPlanCard({ 
   index,
   plan, 
   handleFloorPlanChange, 
   handlePropImageChange, 
   propImgErr,
   handleDeleteFloorPlan
 }) {
  
   const [showImagePopUp,setShowImagePopUp]=useState(false)
   const [showOptions,setShowOptions]=useState(false)
   const optionsRef =useRef(null)
const CloseImagePopup=()=>{
   setShowImagePopUp(false)
}
const handleshowImagePopup=()=>{
   setShowImagePopUp(true)
}
const handleShowopt=()=>{
   setShowOptions(true)
}

useEffect(() => {
   const handleClickOutside = (event) => {
     if (optionsRef.current && !optionsRef.current.contains(event.target)) {
      setShowOptions(false);
     }
   };

   document.addEventListener('mousedown', handleClickOutside);
   return () => {
     document.removeEventListener('mousedown', handleClickOutside);
   };
 }, []);

   return (

      <>

      {
         showImagePopUp && (
            <PopUp maxWidth={'max-w-xl'}>

                     <div className='flex justify-end text-xl' >
                     <MdOutlineClose  onClick={CloseImagePopup} className='cursor-pointer' />
                     </div>

                     <div>
                     {
   plan && plan.floor_image.base64 && (
      <img src={plan.floor_image.base64} id="output" width="200" alt='floor_plans_img' className='floorPlanPopupImg'/>

   )
}
{
   plan && plan.floor_image?.Key && (
      <img src={`${PropertImageBaseUrl}/${plan.floor_image?.Key}`} id="output" width="200" alt='floor_plans_img' className='floorPlanPopupImg'/>

   )
}
                     </div>

            </PopUp>
         )
      }
     

    <div className='flex flex-col gap-3 border border-lightgray p-3 rounded-md' style={{width:"900px"}}>
   

    <div className='flex gap-11  p-2.5 justify-between  rounded-lg border border-lightgray floorPlanSection'>
 
 <div className='flex  gap-4 w-2/3 '>
 
     <div className='flex flex-col w-full justify-start gap-1.5'>
        <p className='text-sm text-blue text-start'>Enter Floor Plan Details </p>
         <input type="text" 
          name="" 
          value={plan.title}
              onChange={(e) => handleFloorPlanChange(index, 'title', e.target.value)}
         className='propInputText'
         placeholder='E.g Saleable Area'
         />
      </div>
 
 
      <div className='flex flex-col w-full justify-start gap-1.5'>
        <p className='text-sm text-blue text-start'>Enter Amount Details</p>
         <input type="text" 
          name="" 
         className='propInputText'
         placeholder='E.g 21,000 Sq Ft'
         value={plan.value}
         onChange={(e) => handleFloorPlanChange(index, 'value', e.target.value)}
         />
      </div>
 
 
 
 </div>
 
 
 
 <div className='flex gap-6 items-center'>
 
 <div className="floorlPlanImg">
                  <label className="floorlPlanLabel" htmlFor={`file-${index}`}>
                     <span className="material-symbols-outlined"> publish</span>
                  </label>
                  <input
              id={`file-${index}`}
              type="file"
              onChange={(e) => handlePropImageChange(e, index)}
            />
{
   plan && plan.floor_image.base64 && (
      <img src={plan.floor_image.base64} id="output" width="200" />

   )
}
            {propImgErr && <p className='text-red-500 text-xs mt-1'>{propImgErr}</p>}
                 
          </div>
 
 <button className='border border-lightgray previewBtn' onClick={handleshowImagePopup}>Preview</button>
 
 <div className='relative'>
 
 <BsThreeDotsVertical onClick={handleShowopt} className='cursor-pointer' />


 {
   showOptions && (
      <div ref={optionsRef}  className='flex flex-col gap-3 shadow absolute top-4 rounded-xl p-2.5 bg-white'>

      {/* <div className='editBtntenant'>
      <p className='text-viewblue  flex gap-2 items-center text-sm'>
         <RiEdit2Fill />Edit</p>
      </div> */}

      {
         index!=0 && (
            <div className='delBtnTenant' onClick={()=>handleDeleteFloorPlan(index)}>
      
            <p className='text-delred  flex gap-2 items-center text-sm'
            
             >
            <MdDelete />Delete</p>
            
            </div>
         )
      }
     
      
      
      </div>
   )
 }


 </div>


 </div>
 
 
 </div>
 
 
 
 <div className='flex flex-col gap-2.5 items-start '>
                   <p className='text-sm text-blue'>Add Description <span style={{color:"#af2525"}}>*</span> </p>
                  
                  <textarea name="" id="" cols="30" rows="4" 
                   value={plan.description}
                   onChange={(e) => handleFloorPlanChange(index, 'description', e.target.value)}
                  
                  className='propInputText'></textarea>
               </div>
 
     </div>

     </>
  )
}
