import React, { useState } from 'react'
import { IoIosClose } from 'react-icons/io'


export default function OverviewFilter(props) {

const handleCloseFilterPopup=()=>{
  props.setIsOpenMob(false);
}


const handleClearAllFilter =()=>{
    props.setpropertyPhase([]);
    props.setPropertyType([]);
}



const handleClearFilter =()=>{
    props.setpropertyPhase([]);
}


    
    const handleClick = (value) => {
        props.setpropertyPhase((prevPhases) => {
          if (prevPhases.includes(value)) {
            // If the value is already in the array, remove it
            return prevPhases.filter(phase => phase !== value);
          } else {
            // If the value is not in the array, add it
            return [...prevPhases, value];
          }
        });
      };

      const handlePropertyTypeClick = (value) => {
        props.setPropertyType((prevType) => {
          if (prevType.includes(value)) {
            // Remove the value if it already exists in the array
            return prevType.filter(item => item !== value);
          } else {
            // Add the value to the array if it does not exist
            return [...prevType, value];
          }
        });
      };
      

      
     
      // setPropertyType

  return (
<div className={`p-[10px]`}>
  <div className='flex justify-between items-start border-lightgray  border-b '>
    <p className='text-base font-bold text-blue'>Filter</p>
    <div className='flex gap-2 items-center'>
    <p className='text-sm  text-gray cursor-pointer' onClick={handleClearAllFilter}>Clear all</p>
    <IoIosClose  className='cursor-pointer' onClick={handleCloseFilterPopup} />
    </div>

  </div>


  <div className=' text-black flex flex-col pt-4'>
  <div className='flex flex-row justify-between'>
  <div className='text-[16px] font-medium text-start'>Phase Type</div>


  </div>
    <div className=' flex flex-col  gap-2 p-2 -ml-2'>
   
     
    <div className='flex flex-row gap-2'>
        <div className={`${
            props.propertyPhase.includes("Public Sale") ? 'propertyFilterButton' : 'propertyFilterButtonBefore'
        } w-[20px] h-[20px]`} onClick={() => handleClick("Public Sale")}></div>
        <button
        className={`propertyPhasesButton  text-black text-sm w-24 h-7`}
        onClick={() => handleClick("Public Sale")}
      >
       Public Sale
      </button>

    </div>
   

    <div className='flex flex-row gap-2'>
      <div className={`${
        props.propertyPhase.includes("Presale") ? 'propertyFilterButton' : 'propertyFilterButtonBefore'
    } w-[20px] h-[20px]`}
    onClick={() => handleClick("Presale")}
    ></div>
      <button
        className={`propertyPhasesButton  text-black text-start pl-3 text-sm w-24 h-7  whitespace-nowrap`}
        onClick={() => handleClick("Presale")}
      >
       Presale
      </button>
    </div>
     

    
     
      
    </div>
  </div>



  <div className=' text-black flex flex-col pt-4'>
  <div className='flex flex-row justify-between'>
  <div className='text-[16px] font-medium text-start'>Property Type</div>


  </div>
    <div className=' flex flex-col  gap-2 p-2 -ml-2'>
   
     
    <div className='flex flex-row gap-2'>
        <div className={`${
            props.propertyType.includes("Residence") ? 'propertyFilterButton' : 'propertyFilterButtonBefore'
        } w-[20px] h-[20px]`} onClick={() => handlePropertyTypeClick("Residence")}></div>
        <button
        className={`propertyPhasesButton  text-black text-sm w-24 h-7`}
        onClick={() => handlePropertyTypeClick("Residence")}
      >
       Residential
      </button>

    </div>
   

    <div className='flex flex-row gap-2'>
      <div className={`${
        props.propertyType.includes("Commercial") ? 'propertyFilterButton' : 'propertyFilterButtonBefore'
    } w-[20px] h-[20px]`}
    onClick={() => handlePropertyTypeClick("Commercial")}
    ></div>
      <button
        className={`propertyPhasesButton  text-black text-start pl-3 text-sm w-24 h-7  whitespace-nowrap`}
        onClick={() => handlePropertyTypeClick("Commercial")}
      >
       Commercial
      </button>
    </div>


    <div className='flex flex-row gap-2'>
      <div className={`${
        props.propertyType.includes("Plots") ? 'propertyFilterButton' : 'propertyFilterButtonBefore'
    } w-[20px] h-[20px]`}
    onClick={() => handlePropertyTypeClick("Plots")}
    ></div>
      <button
        className={`propertyPhasesButton  text-black text-start pl-3 text-sm w-24 h-7  whitespace-nowrap`}
        onClick={() => handlePropertyTypeClick("Plots")}
      >
       Plots
      </button>
    </div>
     

    
     
      
    </div>
  </div>



</div>
  )
}
