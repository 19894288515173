import React,{useState,useEffect, useRef} from 'react'
import { FaCaretDown } from "react-icons/fa";
import { HiDotsHorizontal } from "react-icons/hi";
import View from '../../../Assets/images/eyeAdmin.svg'
import Edit from '../../../Assets/images/editAdmin.svg'
import Delete from '../../../Assets/images/deleteAdmin.svg'
import CardImage from '../../../Assets/images/manTrans.svg'
import {PropertImageBaseUrl} from '../../../Utils/urls'
import './User.css'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { formatDate, formatTimeWithAMPM } from '../../../Utils/dateFormat';
import { RxDotFilled } from 'react-icons/rx';
function User(props) {
    const [showopt, setshowopt] = useState(false);
    const [imageUrl, setimageUrl] = useState("")
   

    const location=useLocation()

const queryParams = new URLSearchParams(location.search);
const pageFromParam = parseInt(queryParams.get('page')) || 1;
const optionsRef = useRef(null);

    useEffect(() => {
        setimageUrl(props.profilePic);
    }, []);

    const handleShowActionOpitons=()=>{
      setshowopt(true)
    }
    const returnSerialNo = (pageFromParam) => {
      if (props.isSearchActive) {
        return props.serialNo; // Reset serial number during search
      } else {
        if (pageFromParam > 1) {
          return (props.serialNo + ((pageFromParam - 1) * 10));
        } else {
          return props.serialNo;
        }
      }
    };
    

    useEffect(() => {
      const handleClickOutside = (event) => {
          if (optionsRef.current && !optionsRef.current.contains(event.target)) {
              setshowopt(false);
          }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
          document.removeEventListener('mousedown', handleClickOutside);
      };
  }, []);
   
  return (
    <div>
      
    <div className={`flex flex-row gap-4 cardParentTransaction ${showopt?"":"overflowHid"} `}>
    <div className=' flex flex-col justify-center userSerailNo'>{returnSerialNo(pageFromParam)}</div>
    <div className=' flex flex-row gap-2  items-center  userNameD'>
      {
        props.active?
<RxDotFilled className='text-2xl text-green'/>
:
<RxDotFilled className='text-2xl text-red'/>
      }
    
      <p  className='text-sm'>{props.firstName || "New User"}</p>
    </div>
    <div className='flex flex-col justify-center  userMobNo  text-sm ' style={{color:"#79767D"}}>{props.mobile}</div>


    <div className=' flex flex-row  UserEmail  text-xs'>
    
    {props?.email}
      
    
  </div>



    <div className='flex flex-col justify-center  userKycStat'>
      <div className='verifiedCard flex flex-col justify-center items-center'  style={{}}>
        {(props.kycStatus==="Not Verified" &&
            <div className='notInitiated'>
            Not Started
            </div>
        )}
        {(props.kycStatus=="Verified" &&
            <div className='kycVerified'>
           Verified
            </div>
        )}

        {(props.kycStatus==="In Review" &&
            <div className='inReviewKyc'>
            In Review
            </div>
        )}
        
        {(props.kycStatus==="Rejected" &&
            <div className='rejectedKyc'>
            Rejected
            </div>
        )}
        
        


    </div>
    </div>


<div className='flex flex-col justify-center UserCreatedCol text-xs'>
  {formatDate(props.createdAt)} <br/>
{formatTimeWithAMPM(props.createdAt)}
</div>

  

    <div className='flex flex-col justify-center   actionMenuUser' >
    {
      /*
      Add below once menu is functional
      onClick={()=>setshowopt(!showopt)} 
      */
    }
    

    <div className='relative flex justify-center'>

    
      <div  >
        <HiDotsHorizontal style={{fontSize:"24px"}}  onClick={handleShowActionOpitons}/>
        </div>

      
      {showopt&&
        <div className='bg-white flex flex-col gap-[5px] userOptionMenu' ref={optionsRef}>
         <Link to={`/user/${props.userId}`}>
          <div className='text-start flex flex-row items-center' style={{paddingLeft:"9px",height:"45px",borderRadius:"5px",backgroundColor:"#FEF9F1"}}>

              <div className='flex flex-col justify-center'>
              <div className='flex flex-col justify-center' ><img src={View} style={{height:"100%",width:"100%"}}/></div>
              </div>
              <div className='ml-[5px] viewCard flex flex-col justify-center'>View</div>

          </div>
          </Link> 

        
          {/* <div className='text-start flex flex-row cursor-pointer' style={{paddingLeft:"9px",height:"45px",borderRadius:"5px",backgroundColor:"#F7FAFF"}}>

              <div className='flex flex-col justify-center'>
              <div className='flex flex-col justify-center' style={{height:"12px",width:"12px"}}><img src={Edit} style={{height:"100%",width:"100%"}}/></div>
              </div>
              <div className='ml-[5px] editCard flex flex-col justify-center'>Edit</div>

          </div>
 
          <div className='text-start flex flex-row ' style={{paddingLeft:"9px",height:"45px",borderRadius:"5px",backgroundColor:"#FEF4F5"}}>

              <div className='flex flex-col justify-center'>
              <div className='flex flex-col justify-center' style={{height:"12px",width:"12px"}}><img src={Delete} style={{height:"100%",width:"100%"}}/></div>
              </div>
              <div className='ml-[5px] deleteCard flex flex-col justify-center'>Delete</div>

          </div> */}

        </div>
      }
     
     </div>

      
    </div>
    


  </div>
    </div>
  )
}

export default User
