import React, { useState } from 'react'
import { CiEdit } from 'react-icons/ci';
import { FaRegCircleUser, FaUser } from 'react-icons/fa6';
import { MdOutlineCameraAlt } from 'react-icons/md';
import Switch from 'react-switch'; 
import { indianStatesCode } from '../../Utils/isoStateCode';
import { PropertImageBaseUrl } from '../../Utils/urls';
import { formatDate, formatTime, formatTimeWithAMPM } from '../../Utils/dateFormat';

export default function UserAccount({isLoading,userDetails}) {
    const [checked, setChecked] = useState(false);

    // Toggle function to change the state
    const handleChange = (checked) => {
      setChecked(checked); // Update the state
    };

//console.log(userDetails)

const getKycStatus = (userDetails) => {
  switch (userDetails?.kyc?.kyc_status) {
      case 'Verified':
          return 'Verified';
      case 'In Review':
          return 'In Review';
      case 'Rejected':
          return 'Rejected';
      default:
          return 'Not Started';
  }
}




const getKycStatusButtonClass = (userDetails) => {
  switch (userDetails?.kyc?.kyc_status) {
    case 'Verified':
      return 'verifiedKyc';
  case 'In Review':
      return 'reviewKyc';
  case 'Rejected':
      return 'rejectedKyc';
  default:
      return 'notstartedKyc';
  }
}

console.log(userDetails)
  return (
    <div className='propCard'>

    {/* sec-1 */}
    
      <div className='flex justify-between items-center'>
        <p className='text-2xl font-medium text-gray'>User Account</p>
        <button className='propSaveBtn bg-lightgray text-gray'>Edit</button>
      </div>
    
    {/* sec-2 */}
      <div className='flex justify-between'>
    
        <div className='flex flex-col gap-4 UserDetailsSec'>
    
    <div className='flex justify-between items-center'>


<div  className='flex gap-7'>
<div className='flex gap-2 py-3  items-center'>
             <FaRegCircleUser className='text-gray' />
             <p className='text-gray text-lg font-medium'>USER ID {userDetails && userDetails.profile?.user_id}</p>
          </div>

          <div className='flex gap-2 py-3  items-center'>
             <span class="material-symbols-outlined text-gray">badge</span>
             <p className='text-gray text-lg font-medium'>KYC Status:</p>
             <button className={`kycBtnStat ${userDetails && getKycStatusButtonClass(userDetails)}`}>
               {userDetails && getKycStatus(userDetails)}
             </button>

          </div>
</div>
  

  <div>
    <p className='text-xs text-gray'>Last Updated {formatDate(userDetails && userDetails.profile?.updated_at)} - {formatTimeWithAMPM(userDetails && userDetails.profile?.updated_at)}</p>
</div>



    </div>

          
    
          <div className='flex justify-between'>

              <div className='flex flex-col gap-2.5 items-start UserInputDiv'>
                  <p className='text-sm text-blue'>User Name <span style={{color:"#af2525"}}>*</span> </p>
                  <input type="text" 
                  name="" 
                  disabled
                  className='propDisableInputText'
                  placeholder='E.g John Deo'
                  value={userDetails?.profile?.first_name||"User"}
                   />
              </div>
    
    <div className='relative flex flex-col gap-2.5 items-start UserInputDiv'>
      <p className='text-sm text-blue'>
        Mobile <span style={{ color: '#af2525' }}>*</span>
      </p>
      <div className='relative w-full'> 
        <input
          type='text'
          disabled
          value={userDetails?.profile?.mobile}
            className='w-full pr-10 pl-2 py-2  rounded propDisableInputText' 
          placeholder='E.g John Deo'
        />
        {/* <CiEdit  className='absolute right-2 top-1/2 transform -translate-y-1/2  text-pink cursor-pointer' /> 
     */}
    
      </div>
    </div>
</div>
    
    
<div className='flex justify-between'>
 <div className='relative flex flex-col gap-2.5 items-start UserInputDiv'>
            <p className='text-sm text-blue'>
            Email <span style={{ color: '#af2525' }}>*</span>
            </p>
        <div className='relative w-full'> 
        <input
        type='text'
        name=''
        disabled
        value={userDetails?.profile?.email}
        className='w-full pr-10 pl-2 py-2  rounded propDisableInputText' 
        placeholder='E.g JohnDeo@gmail.com  '
        />
        <CiEdit  className='absolute right-2 top-1/2 transform -translate-y-1/2  text-pink cursor-pointer' /> 
        </div>
    </div>


    <div className='flex flex-col gap-2.5 items-start UserInputDiv'>
                  <p className='text-sm text-blue'>Gender </p>
                 <select name=""  
                 value={userDetails?.profile?.gender} 
                 disabled
                 className='propDisableInputText'>

                  <option disabled selected>Select</option>
                  <option value="Female" >Female</option>
                  <option value="Male" >Male</option>
                  <option value="Others" >Others</option>
                 </select>
              </div>
    
</div>
    


   <div className='flex justify-between'>
   <div className='flex flex-col gap-2.5 items-start UserInputDiv'>
                  <p className='text-sm text-blue'>Date  of Birth</p>
                  <input type="text" 
                  name="" 
                  disabled
                  value={!userDetails?.profile?.dob ?"":
                    formatDate(userDetails?.profile?.dob)}  
                  className='propDisableInputText'
                  placeholder='01/02/2004'
    
                   />
              </div>

    <div className='flex flex-col gap-2.5 items-start UserInputDiv'>
                  <p className='text-sm text-blue'>State </p>
                 <select name="" id="" disabled value={userDetails?.profile?.state_short_code} className='propDisableInputText'>
                  <option disabled selected>Select</option>
                  {
              indianStatesCode && indianStatesCode.map((el)=>(
                <option value={el.code}>{el.label}</option>
              ))
            }
                 
                 </select>
              </div>
    
</div> 
    

<div className='flex justify-between'>

<div className='flex flex-col gap-2.5 items-start UserInputDiv'>
                  <p className='text-sm text-blue'>Current Address <span style={{color:"#af2525"}}>*</span> </p>
                 
                 <textarea name="" disabled value={userDetails?.profile?.address} cols="30" rows="4" className='propDisableInputText'></textarea>
              </div>
</div>


        </div>
    
    
    {/* prop image main */}
        <div className='UserImage flex flex-col gap-5'> 
        
        <div className='photoContainer'>

        <div class="avatar-container">

        {
  (userDetails?.profile?.profile_pic == null || Object.keys(userDetails?.profile?.profile_pic).length == 0) ? (
   
      <FaUser />
  
  ) : (
    <img src={`${PropertImageBaseUrl}/${userDetails?.profile?.profile_pic.Key}`} alt="" />
  )
}

           
 

    <input type="file"  className='profileImgFile' disabled  name="profile_pic" id="file-upload" accept="image/jpeg,image/png" />



</div>

<label for="file-upload" class="upload-icon">
        <MdOutlineCameraAlt />
    </label>
</div>
    
 
    
    
    {/* property-activation toggle */}
    
    <div className='flex items-center justify-center gap-2.5'>
    
          <p className='text-gray text-sm'>Deactivate Account   </p>
       
          <Switch
            checked={userDetails && userDetails.profile?.active}
            offColor="#888" 
            onColor="#419E6A"
            uncheckedIcon={false}  
            checkedIcon={false}
            width={36}
            height={20}
            handleDiameter={13.5}
            disabled
            
          />
    
    
    </div>
    
    
    
        </div>
    
    
      </div>
    
    
    
   
    
   
    
    
    
    
    </div>
  )
}
