import React, { useEffect } from 'react'
import AdminContainer from '../AdminContainer/AdminContainer'
import "./property-style.css"

import { useState } from 'react';
import PropertyInfo from './PropertyInfo';
import TokenInfo from './TokenInfo';
import PropertyInfoTab from './PropertyInfoTab';
import Amenities from './Amenities';
import Tenant from './Tenant';
import FloorPlans from './FloorPlans';
import { ApiBaseUrl } from '../../Utils/urls';
import { useParams } from 'react-router-dom';
import PropertyImage from './PropertyImage';
import TaxesCharges from './TaxesCharges';

export default function AddProperty() {
const {id}=useParams()
const [isLoading,setLoading]=useState(false)
const [propRes,setPropResp]=useState({})
const [propertyinfo,setPropertyInfo]=useState({})
const [propStatus,setPropSatus]=useState(false)

const accesstoken=localStorage.getItem("accesstoken")
const adminId=localStorage.getItem("adminId")
const [propertyId,setPropertyId]=useState(null)
  const fetchPropertyDetails=()=>{

    setLoading(true)
    fetch(`${ApiBaseUrl}/fr-admin/get-property-details?property_id=${propertyId}&admin_id=${adminId}`,{
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accesstoken
      }
    })
      .then(response => {
      
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
       
        return response.json();
      })
      .then(resdata => {
       
       //console.log('Response from server:', resdata);
       
          if(resdata.success)
          {
            setLoading(false)
           setPropResp(resdata.data)
           setPropertyInfo(resdata.data.property_info)
           setPropSatus(resdata.data.property_info.is_active)
          }
  
      })
      .catch(error => {
       
        console.error('error details:', error);
      });
  }

  useEffect(()=>{
    if(propertyId)
      {
        fetchPropertyDetails()
      }
    
  },[])

const handleUpdatedPropStatus=()=>{
  fetch(`${ApiBaseUrl}/fr-admin/update-property-status`,
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': accesstoken
    },
    body: JSON.stringify({
     
      
        "admin_id": adminId,
        "property_id": propertyId,
        "active": !propStatus 
    
    })
  }
)
    .then(response => {
    
     
     
      return response.json();
    })
    .then(resdata => {
     
     //console.log('Response from server:', resdata);
     
        if(resdata.success)
          
        {
          fetchPropertyDetails()
        
        }

    })
    .catch(error => {
     
     
    });
}

  return (
    <AdminContainer 
    pageTitle={'Add Property'} 
    subtitle="Property Management /" 
    backLinkPath="/property" 
    showDeactivateButton={true}
    handleUpdatedPropStatus={handleUpdatedPropStatus}
    propStatus={propStatus}
    propertyId={propertyId}
    >


<div className='container font-nunito'>


  <div className='flex flex-col p-5 gap-2.5'>


{/* property primary details */}
<PropertyInfo 
propertyId={propertyId} 
setPropertyId={setPropertyId}
/>
{/* property primary details end*/}


<PropertyImage 
propertyId={propertyId} 
setPropertyId={setPropertyId}
/>

<TokenInfo 
propertyId={propertyId}
 />
<TaxesCharges propertyId={propertyId}/>
<div className='propCard'>

<PropertyInfoTab propertyId={propertyId} propertyinfo={propertyinfo}/>
<hr className='addhrClass' />

<Amenities propertyId={propertyId} />

<hr  className='addhrClass'/>

<Tenant
 propertyId={propertyId} 
 propertyinfo={propertyinfo}
 />


<hr  className='addhrClass'/>
<FloorPlans propertyId={propertyId} />

</div>
  </div>



</div>



        
        </AdminContainer>
  )
}
