import React, { useState } from 'react'
import logo from "../../Assets/logo/logo2.svg"
import { HiOutlineEye, HiOutlineEyeOff, HiOutlineMail } from 'react-icons/hi'
import "./login-style.css"
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext/AuthContext';
import loader from '../../Assets/Icons/Loader.gif'
import ErrorAlert from '../Alert/ErrorAlert';
import { IsValidateEmailFormat } from '../../Utils/IsEmailValid';

export default function Login() {
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const navigate=useNavigate()

const {
   AdminDetails,
   login,
    logout,
    accesstoken,
    isLoading,
    alertMsg,
    showAlert
   
  } = useAuth()


  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  const handleLoginSubmit=(e)=>{
    e.preventDefault()
   
    login(email, password)
      .then(() => {
        
      })
      .catch((error) => {
        console.error('Login error:', error);
      });
  }

  const isFormValid = email.trim() !== '' && password.trim() !== '' && emailError==='';

  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value;
    setEmail(enteredEmail);
    if (enteredEmail.trim() !== '' && !IsValidateEmailFormat(enteredEmail)) {
      setEmailError('Please enter a valid email address.');
    } else {
      setEmailError('');
    }
  };


  return (

<>
{/* {
   isnetworkErr && (
    alert("Network error")
   )
} */}

    <div className="flex h-screen font-nunito">
     


      <div
        className="flex-1 flex justify-center items-center bg-blue"
        style={{  backgroundSize: 'cover' }}
      >
        <img src={logo} alt="Logo" className="w-1/2" />
      </div>

 {/*Login Form */}

      <div className="flex-1 flex-col flex justify-center items-center  p-6 relative">

       {
        showAlert && (
          <ErrorAlert message={alertMsg} />
        )
       }
      <h2 className="text-4xl font-bold mb-6 text-center text-pink">Admin Login</h2>
        <form onSubmit={handleLoginSubmit}  className="bg-white  flex flex-col gap-4 2xl:max-w-md  lg:max-w-sm  w-full">
         
        
        <div className="relative flex flex-col items-center">
      
      <div className="absolute left-3 flex items-center" style={{height:"100%"}}>
      <HiOutlineMail className='text-2xl' />
      </div>

     
      <input
        type="text"
        placeholder="Email Address"
        className="w-full pl-10 pr-3 py-2  loginInput"
        value={email}
         onChange={handleEmailChange}
      />
       
    </div>

    {emailError && <p className="text-red  text-sm">{emailError}</p>}
    <div className="relative flex items-center">
      
      <div className="absolute left-3">
      
              {isPasswordVisible ?
               <HiOutlineEye className='text-2xl cursor-pointer' onClick={togglePasswordVisibility} /> :
               <HiOutlineEyeOff className='text-2xl cursor-pointer' onClick={togglePasswordVisibility}/>}
            
      </div>

     
      <input
       type={isPasswordVisible ? 'text' : 'password'}
        placeholder="Password"
        className="w-full pl-10 pr-3 py-2  loginInput"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />

      
    </div>
         
    <button 
    disabled={!isFormValid || isLoading}
    className={`loginBtn ${isFormValid?'bg-pink  text-white':'bg-lightgray text-black'}  font-semibold`}>
        {isLoading?'Logging In...':'Login'}
        </button>

  
         
         
        </form>

{
  isLoading && (
    <img src={loader} alt=""  className='LoginLoader' />
  )
}

       
      </div>

     
    
    </div>

    </>
  )
}
