import React, { useEffect, useState } from "react";

import "../amenities.css";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoIosSearch } from "react-icons/io";

import { GoPlus } from "react-icons/go";
import { AiOutlineCheck } from "react-icons/ai";

import { useParams } from "react-router-dom";
import { ApiBaseUrl } from "../../../Utils/urls";
import {
  AddPropertyAmenities,
  getPropertyDetails
} from "../../../Api/PropertyAddApi";
import SuccessAlert from "../../Alert/SuccessAlert";
import ErrorAlert from "../../Alert/ErrorAlert";
import FloatingLoader from "../../Loader/FloatingLoader";

import EditAmenitiesCard from "./EditAmenitiesCard";
import CustomButton from "../../Common/CustomeButton";

export default function EditAmenities(props) {
  const [amenitiesList, setAmenities] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [propAmenities, setpropAmenities] = useState([]);
  const [addedAmenityIds, setAddedAmenityIds] = useState([]);
  const [isAddPropAmenitiesLoading, seAddPropAmenitiestLoading] =
    useState(false);
  const [isAmenitiesAdded, setAmenitiesAdded] = useState(false); //property amenities add
  const [isAmenAddErr, setAmenAddErr] = useState(false); //property amenities add error
  const [amenAddAlertMsg, setAmenAddAlertMsg] = useState(""); //property amenities add msg
  const [propDetailsLoading, setPropDetailsLoading] = useState(false);
  const [amenitiesTitle, setAmenitiesTitle] = useState("");

  const [isUploadAmenitiesLoading, seUploadAmenitiesLoading] = useState(false);
  const [isAmenitiesUploaded, setAmenitiesUploaded] = useState(false); // amenities icon upload
  const [isAmenUploadErr, setAmenUploadErr] = useState(false); // amenities icon upload error
  const [amenUploadAlertMsg, setAmenUploadAlertMsg] = useState("");

  const [amenitiIcon, setAmenityIcon] = useState({});
  const [propImageErr, setPropImgErr] = useState("");
  const isAmenityAddbtnEnabled =
    amenitiesTitle !== "" && amenitiIcon.base64 !== undefined;

  const accessToken = localStorage.getItem("accesstoken");
  const adminId = localStorage.getItem("adminId");
  const { id: propertyId } = useParams();
  const { MintedToken } = props;

  const getAmenities = () => {
    fetch(`${ApiBaseUrl}/fr-admin/get-all-amenities`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        return response.json();
      })
      .then((resdata) => {
        //console.log('Response from server:', resdata);

        if (resdata.success) {
          setAmenities(resdata.data);
        }
      })
      .catch((error) => {
        console.log(3);
        console.error("error details:", error);
      });
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchInput(value);
    setShowDropdown(value.length > 0);
  };
  const handleAddAmenity = (amenity) => {
    setpropAmenities((prev) => [...prev, amenity]);
    setAddedAmenityIds((prev) => [...prev, amenity.amenity_id]);
    //setShowDropdown(false);
  };

  const filteredAmenities = amenitiesList.filter((amenity) =>
    amenity.title.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleDeleteAllAmenities = () => {
    setpropAmenities([]);
    setAddedAmenityIds([]);
  };

  useEffect(() => {
    getAmenities();
  }, []);

  useEffect(() => {
    if (propAmenities.length > 0) {
      // Extracting amenity IDs from propAmenities and setting addedAmenityIds
      const amenityIds = propAmenities.map((amenity) => amenity.amenity_id);
      setAddedAmenityIds(amenityIds);
    }
  }, [propAmenities]);

  const handleDeleteAmenity = (amenityId) => {
    setpropAmenities((prev) =>
      prev.filter((amenity) => amenity.amenity_id !== amenityId)
    );
    setAddedAmenityIds((prev) => prev.filter((id) => id !== amenityId));
  };

  const handleAddPropertyAmenities = () => {
    AddPropertyAmenities(
      seAddPropAmenitiestLoading,
      accessToken,
      adminId,
      propertyId,
      addedAmenityIds,
      setAmenitiesAdded,
      setAmenAddAlertMsg,
      setAmenAddErr
    );
  };

  const fetchPropertyDetails = () => {
    getPropertyDetails(
      setPropDetailsLoading,
      propertyId,
      adminId,
      accessToken,
      undefined,
      undefined,
      setpropAmenities
    );
  };

  useEffect(() => {
    fetchPropertyDetails();
  }, []);

  const handlePropImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const fileTypeParts = file.type.split("/");
      const fileExtension = fileTypeParts.length === 2 ? fileTypeParts[1] : "";

      const maxSize = 5 * 1024 * 1024;

      if (file.type !== "image/png" && file.type !== "image/svg+xml") {
        setPropImgErr("Only  PNG or SVG formats are allowed");
      } else {
        setPropImgErr("");

        const reader = new FileReader();
        reader.onload = (event) => {
          const base64String = event.target.result;

          setAmenityIcon({
            extension: fileExtension,
            file_type: file.type,
            base64: base64String
          });
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleUploadAmenity = () => {
    seUploadAmenitiesLoading(true);
    fetch(`${ApiBaseUrl}/fr-admin/add-amenity`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken
      },
      body: JSON.stringify({
        admin_id: adminId,
        amenity_name: amenitiesTitle,
        amenity_icon: amenitiIcon
      })
    })
      .then((response) => {
        return response.json();
      })
      .then((resdata) => {
        console.log("Response from server:", resdata);
        seUploadAmenitiesLoading(false);
        if (resdata.success) {
          setAmenitiesUploaded(true);
          setAmenitiesTitle("");
          setAmenityIcon({});
          getAmenities();
          setTimeout(() => {
            setAmenitiesUploaded(false);
          }, 3000);
        } else if (!resdata.success) {
          setAmenUploadErr(true);
          setAmenUploadAlertMsg(resdata.data);
          setTimeout(() => {
            setAmenUploadErr(false);
            setAmenUploadAlertMsg("");
          }, 3000);
        }
      })
      .catch((error) => {
        console.error("error details:", error);
      });
  };

  return (
    <>
      {isAmenitiesAdded && <SuccessAlert message={amenAddAlertMsg} />}

      {isAmenAddErr && <ErrorAlert message={amenAddAlertMsg} />}

      <div className="flex gap-4 flex-col justify-between">
        <div className="flex justify-between items-center">
          <p className="text-lg font-medium text-black">
            Amenities <span style={{ color: "#bc2222" }}>*</span>
          </p>
          <div className="flex gap-4">
            <div className="relative ">
              <div className="flex items-center border border-lightgray rounded-2xl bg-white shadow-sm overflow-hidden">
                <span className="px-2 text-gray-500">
                  <IoIosSearch className="text-gray" />
                </span>
                <input
                  type="text"
                  value={searchInput}
                  onChange={handleSearchChange}
                  placeholder="Search Amenities Name, etc."
                  className="flex-1 p-2 w-52 border-0 outline-none focus:ring-0 text-xs"
                />
              </div>

              {/* absolute  mt-1 w-full bg-white border border-lightgray rounded shadow-lg z-10 */}

              {showDropdown && (
                <div className="amenitiiesDropdown shadow-lg w-full">
                  {filteredAmenities.length > 0 ? (
                    filteredAmenities.map((amenity) => (
                      <div
                        key={amenity.amenity_id}
                        className="p-2 amenitiesOption flex items-center justify-between hover:bg-gray-100"
                      >
                        <p>{amenity.title}</p>

                        {addedAmenityIds.includes(amenity.amenity_id) ? (
                          <AiOutlineCheck />
                        ) : (
                          <GoPlus
                            onClick={() => handleAddAmenity(amenity)}
                            className="cursor-pointer"
                          />
                        )}
                      </div>
                    ))
                  ) : (
                    <div className="p-2 text-gray-500">No amenities found</div>
                  )}
                </div>
              )}
            </div>

            <CustomButton
              onClick={handleAddPropertyAmenities}
              isDisabled={MintedToken > 0 || !propertyId}
              buttonText={"Save Details"}
              disabledMessage="Editing is no longer available. The token has already been minted"
              className="propSaveBtn"
              position="top"
              availableMintedToken={MintedToken}
            />
          </div>
        </div>

        <div className="flex gap-10">
          <div className="amenityUpload relative rounded-lg border  border-lightgray py-4 px-5 flex flex-col gap-4">
            {isAmenitiesUploaded && (
              <SuccessAlert message="Amenities is added/updated successfully" />
            )}

            {isAmenUploadErr && <ErrorAlert message={amenUploadAlertMsg} />}

            {isUploadAmenitiesLoading && <FloatingLoader />}

            <p className="text-gray text-sm text-left">
              Upload Amenity Icon <span className="text-red">*</span> (Format :
              PNG, SVG)
            </p>

            <div className="amenityImageSec">
              <label className="amenityImagelabel" for="amenity-image">
                <span className="material-symbols-outlined"> publish</span>
                <p className="text-xs">Browse your files here</p>

                {amenitiIcon && amenitiIcon.base64 && (
                  <img
                    className="amenity-img"
                    src={amenitiIcon.base64}
                    alt="Avatar"
                  />
                )}
              </label>

              <input
                id="amenity-image"
                type="file"
                disabled={!propertyId}
                onChange={handlePropImageChange}
              />
            </div>
            {propImageErr !== "" && (
              <p className="text-xs text-red">{propImageErr && propImageErr}</p>
            )}

            <p className="text-left">
              Amenity Name <span className="text-red">*</span>{" "}
            </p>
            <input
              type="text"
              name=""
              className="propInputText"
              placeholder="E.g Swimming Pool"
              disabled={!propertyId}
              onChange={(e) => setAmenitiesTitle(e.target.value)}
            />

            <CustomButton
              onClick={handleUploadAmenity}
              isDisabled={MintedToken > 0 || !isAmenityAddbtnEnabled}
              buttonText={"Add Amenity"}
              disabledMessage="Minting finished for all tokens: No further edits allowed."
              className="rounded-md py-2 w-full"
              position="top"
              MintedToken={MintedToken}
            />
          </div>

          {/* sec 1 */}
          <div className="amenitiesForm flex flex-col gap-3">
            {isAddPropAmenitiesLoading && <FloatingLoader />}

            <div className="flex flex-col rounded-lg border  border-lightgray py-4 px-5">
              <div className="flex gap-2.5 justify-end">
                <button
                  className="bg-gray2 px-2.5 py-1.5 rounded-[4px] text-gray text-xs"
                  onClick={handleDeleteAllAmenities}
                >
                  Delete All
                </button>
              </div>

              <div className="flex flex-col gap-2.5">
                <div className="flex flex-col gap-1.5">
                  <p className="text-start">Amenity Name & Icon</p>

                  {/* added amenities */}

                  <div className="flex flex-col gap-1.5 addedList max-h-[300px] overflow-x-auto">
                    {propAmenities &&
                      propAmenities.map((amenity, index) => (
                        <EditAmenitiesCard
                          key={index}
                          {...amenity}
                          deleteAmenity={handleDeleteAmenity}
                        />
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
