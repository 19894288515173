import React, { useState } from 'react'

import Switch from 'react-switch';



export default function ViewTransactionOverview({adminData,handleInputChange,setAdminData,adminAccess}) {


    const [permissionsCheckedTrans, setPermissionsCheckedTrans] = useState(adminData.profile?.permissions?.transactions_access);
    const [permissionsCheckedOverview, setPermissionsCheckedOverview] = useState(adminData.profile?.permissions?.overview_access);
  


    const handleTransPermissionsSwitchChange = (checked) => {
        setPermissionsCheckedTrans(checked);

      
        

        setAdminData(prevState => ({
          ...prevState,
          profile: {
              ...prevState.profile,
              permissions: {
                  ...prevState.profile.permissions,
                  transactions_access: checked,
                 
              }
          }
      }));

      };

      const handleOverviewPermissionsSwitchChange = (checked) => {
        setPermissionsCheckedOverview(checked);

       
        setAdminData(prevState => ({
          ...prevState,
          profile: {
              ...prevState.profile,
              permissions: {
                  ...prevState.profile.permissions,
                  overview_access: checked,
                 
              }
          }
      }));
        
      };
   
    //   console.log(adminData)
  return (

    <div className='flex justify-between'>
        {/* transaction */}
    <div className='UserManageformSect font-nunito'>
<div className='flex justify-between items-center'>
<p className='text-2xl font-medium'>Transactions</p>
<Switch
             checked={adminData.profile?.permissions?.transactions_access}
             onChange={handleTransPermissionsSwitchChange}
            offColor="#888" 
            onColor="#419E6A"
            uncheckedIcon={false}  
            checkedIcon={false}
            width={36}
            height={20}
            handleDiameter={13.5}
            disabled
            
          />
</div>

<p className='font-bold text-lg text-left'>Transactions Section Permission</p>



    </div>

{/* overview */}
<div className='UserManageformSect font-nunito'>
<div className='flex justify-between items-center'>
<p className='text-2xl font-medium'>Overview</p>
<Switch
             checked={adminData.profile?.permissions?.overview_access}
             onChange={handleOverviewPermissionsSwitchChange}
            offColor="#888" 
            onColor="#419E6A"
            uncheckedIcon={false}  
            checkedIcon={false}
            width={36}
            height={20}
            handleDiameter={13.5}
            disabled
            
          />
</div>

<p className='font-bold text-lg text-left'>Overview Section Permission</p>



    </div>

    </div>
  )
}
