import React, { useEffect, useState } from 'react'

import "../project-spec.css"

import { useParams } from 'react-router-dom';
import { getPropertyDetails } from '../../../Api/PropertyAddApi';
import { ApiBaseUrl } from '../../../Utils/urls';
import FloatingLoader from '../../Loader/FloatingLoader';
import SuccessAlert from '../../Alert/SuccessAlert';
import ErrorAlert from '../../Alert/ErrorAlert';
import ProjectSpecificationCard from '../Card/ProjectSpecificationCard';
import ViewDocumentUpload from './ViewDocumentUpload';
import ViewPaymentSchedule from './ViewPaymentSchedule';
import ViewProjectSpecificationCard from './ViewProjectSpecificationCard';


export default function ViewPropertyInfoTab() {

  const [activeTab, setActiveTab] = useState('Overview'); 
  const [aboutProp,setAboutProp]=useState("")
  const [projectSpec,setProjectSpec]=useState([""])

  //project spec states
  const [isProjectSpecLoading,setProjectSpecLoading]=useState(false)
  const [isProjectSpecAdded,setProjectSpecAdded]=useState(false)
  const [isProjectSpecErr,setProjectSpecErr]=useState(false)
  const [projectSpecAlertMsg,setProjectSpecAlertMsg]=useState("")
  const accesstoken=localStorage.getItem("accesstoken")
  const [propertyinfo,setPropertyInfo]=useState({})
  const adminId=localStorage.getItem("adminId")

 const {id:propertyId}=useParams()
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };


  const fetchPropertDetails=()=>{
    getPropertyDetails(
      undefined,
      propertyId,
      adminId,
      accesstoken,
      undefined,
      setPropertyInfo,
      undefined,
      undefined,
      undefined,
      setAboutProp,
      setProjectSpec)
  }

  useEffect(()=>{
    fetchPropertDetails()
  },[])


  const handleAboutChange=(e)=>{
    setAboutProp(e.target.value)
  }



 




  return (
    <>
<div className='flex justify-between items-center'>
        <p className='text-lg font-medium text-black'>Property Information</p>
        
    </div>

    <div className='flex gap-10 text-lg  tabBorder'>
      <div 
      onClick={() => handleTabClick('Overview')}
      className={`propTabbtn cursor-pointer ${activeTab==='Overview'?'propActiveTab':''}`}
      
      >
        Overview
        </div>

      <div onClick={() => handleTabClick('Documents')}
       className={`propTabbtn cursor-pointer ${activeTab==='Documents'?'propActiveTab':''}`}
      >Documents</div>



{
  propertyinfo?.property_phase==="Presale"  && (
    <div onClick={() => handleTabClick('PaymentScedule')}
       className={`propTabbtn cursor-pointer ${activeTab==='PaymentScedule'?'propActiveTab':''}`}
      >Payment Schedule
      </div>
  )
  
}



    </div>

   

   
    {activeTab === 'Overview' &&
    <div className='flex flex-col gap-5'>
 
   <div className='flex justify-end items-center'>
     
     <button className={`propSaveBtn  bg-lightgray text-gray `}
     disabled
     >Save Details</button>
 </div>


<div className='flex gap-5 relative'>



<div className='flex flex-col gap-2.5 w-1/2 items-start tokenInputDiv'>
                  <p className='text-lg text-blue font-bold'>About Property<span style={{color:"#af2525"}}>*</span> </p>
                  <textarea name="" 
                  value={aboutProp} 
                  onChange={handleAboutChange}
                  disabled
                  cols="30" rows="6" className='propDisableInputText'></textarea>
              </div>
    










              <div className='flex w-1/2 flex-col gap-2.5 items-start'>

 <div className='flex justify-between w-full'>
 <p className='text-start text-lg text-blue font-bold' >Project Specifications*</p>
<button className='bg-lightgray text-gray text-xs rounded-md px-3' disabled>Add More</button>
 </div>

 


<div className='flex flex-col w-full gap-1.5 projectSpecList'>

  {
    projectSpec && projectSpec.map((projectspec,index)=>(
<ViewProjectSpecificationCard 
projectspec={projectspec}
index={index}


/>
    ))
  }



</div>





</div>





</div>



    </div>
  
     }

{activeTab === 'Documents' &&
     <div>

<ViewDocumentUpload/>

     </div>
     }
{
  

  activeTab === 'PaymentScedule' &&
     <div>


<ViewPaymentSchedule/>

     </div>
}

</>
  )
}
