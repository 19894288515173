import React, { useEffect, useState } from 'react'
import "../document-style.css"
import { getPropertyDetails } from '../../../Api/PropertyAddApi'
import { ApiBaseUrl } from '../../../Utils/urls'
import FloatingLoader from '../../Loader/FloatingLoader'
import SuccessAlert from '../../Alert/SuccessAlert'
import ErrorAlert from '../../Alert/ErrorAlert'

import { useParams } from 'react-router-dom'
import ViewDocCategoryCard from './ViewDocCategoryCard'



export default function ViewDocumentUpload() {
    const accessToken=localStorage.getItem("accesstoken")
    const adminId=localStorage.getItem("adminId")
    const {id:propertyId}=useParams()
    const [pdfArray,setPdfArray]=useState([])
    const [isDocLoading,setDocLoading]=useState(false)
    const [isDocUpdated,setDocUpdated]=useState(false)
    const [isDocErr,setDocErr]=useState(false)
    const [alertMsg,setAlertMsg]=useState("")
    const [isLoading,setLoading]=useState(false)
const [documentsList,setDocumentsList]=useState([{
    "category": "",
    "documents": [
        {
            "docs": [],
            "title": "",
            "description": null
        }
    ]
}])


function extractDocs(array) {
    
    return array.map(item => item.documents.map(doc => doc.docs)).flat(2);
}





// console.log(pdfArray)

useEffect(()=>{
    if(documentsList)
        {
            let extractedpdf=extractDocs(documentsList)
            setPdfArray(extractedpdf)
        }
},[documentsList])


useEffect(()=>{

    if(propertyId)
        {
            fetchPropDetails()
        }
   
},[])

const fetchPropDetails=()=>{
    getPropertyDetails(
      setLoading,
      propertyId
      ,adminId,
      accessToken,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      setDocumentsList

    )
  }






  return (

<>




    <div className='flex flex-col gap-5 relative'>
 {
    isDocLoading && (
<FloatingLoader/>
    )
 }


    <div className='flex justify-between items-center'>
      <p className='text-gray text-lg font-medium'>Documents</p>

      <div className='flex gap-5'>


      <button 
      className={`propSaveBtn bg-lightgray text-gray`}
    
   disabled
      >
        Add Category
        </button>


      <button 
      className={`propSaveBtn bg-lightgray text-gray`}
      disabled
     
      >
        Save Details</button>
      </div>
   
  </div>



<div className='flex gap-5 documentBox'>


    {
        documentsList && documentsList.map((el,index)=>(
            <ViewDocCategoryCard
            key={index} 
            index={index} 
            {...el} 
            setDocumentsList={setDocumentsList} 
            documentsList={documentsList}
            setPdfArray={setPdfArray}
            pdfArray={pdfArray}
            />
        ))
    }


</div>



  </div>

  </>
  )
}
