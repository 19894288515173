import React, { useEffect, useRef, useState } from 'react'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { MdDelete } from 'react-icons/md'

export default function PaymentNoteCard({ condition, index, handleDelete,handleChange }) {


    const [showOption,setShowOption]=useState(false)
    const optionsRef = useRef(null);
    const handleShowOption=()=>{
       setShowOption(true)
    }
    
    useEffect(() => {
       const handleClickOutside = (event) => {
         if (optionsRef.current && !optionsRef.current.contains(event.target)) {
           setShowOption(false);
         }
       };
    
       document.addEventListener('mousedown', handleClickOutside);
       return () => {
         document.removeEventListener('mousedown', handleClickOutside);
       };
     }, []);


  return (
    <div className='flex justify-between  items-center gap-5'>
    

    <input type="text" 
    className='amenInputText' 
    value={condition}
    onChange={(e) => handleChange(e.target.value)}
     placeholder='E.g 1 Registration, stamp duty & GST are indicative, however it will changed at actuals.' />
  
  
  <div className='flex gap-2.5 items-center'>
  

  
 
  <div className='relative'>
  <BsThreeDotsVertical   className='cursor-pointer' onClick={handleShowOption} />
  {
     showOption && (
  <div ref={optionsRef}  className='flex flex-col gap-3  z-50 shadow absolute top-0 right-0 rounded-xl p-2.5 bg-white'>
  

  

  


  
  <div className='delBtnTenant cursor-pointer' onClick={() => handleDelete(index)}>
  
  <p className='text-delred  flex gap-2 items-center text-sm' >
  <MdDelete />Delete</p>
  
  </div>

 


  </div>
  
  
  
     )
  }
  
  
  
  </div>
  
  </div>
  
  
  
  </div>
  )
}
