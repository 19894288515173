import React, { useEffect, useState } from "react";
import { BsDot } from "react-icons/bs";
import CustomInput from "../CustomInput";
import { ApiBaseUrl } from "../../../Utils/urls";
import { useParams } from "react-router-dom";
import SuccessAlert from "../../Alert/SuccessAlert";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { FetchMinToken, MinToken } from "../../../Api/PropertyAddApi";
// import "./minting-style.css";

import Table from "../../Common/Table";
import MintTableCard from "../EditProperty/MintTableCard";

export default function ViewTokenMinting() {
  const adminId = localStorage.getItem("adminId");
  const { id } = useParams();
  const propertyId = id;
  const accessToken = localStorage.getItem("accesstoken");

  const [totalToken, setTotalToken] = useState("");
  const [availableToken, setAvailableToken] = useState("");
  const [mintedToken, setMintedToken] = useState("");
  const [mintValue, setmintValue] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const [activeBtn, setActiveBtn] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [mintedHistory, setMintedHistory] = useState([]);
  const [totalPages, setTotalPages] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const getMinToken = () => {
    FetchMinToken(
      accessToken,
      propertyId,
      adminId,
      currentPage,
      setTotalToken,
      setAvailableToken,
      setMintedToken,
      setMintedHistory,
      setTotalPages
    );
  };

  useEffect(() => {
    getMinToken();
  }, [currentPage]);

  const handleChange = (e) => {
    let value = Number(e.target.value);
    setmintValue(value);
    if (value > Number(availableToken)) {
      setShowWarning(true);
      setActiveBtn(false);
    } else {
      setShowWarning(false);
      if (value > 0) {
        setActiveBtn(true);
      } else {
        setActiveBtn(false);
      }
    }
  };

  const handleMinToken = () => {
    MinToken(
      accessToken,
      adminId,
      propertyId,
      mintValue,
      setmintValue,
      setShowAlert,
      setAlertMsg,
      getMinToken,
      setActiveBtn
    );
  };

  const columns = [
    { label: "No.", className: "slNoMInt" },
    { label: "Date", className: "MintDatecls" },
    { label: "Mint ID", className: "MintId" },
    { label: "Number of Tokens Minted", className: "mintedTokencls" },
    { label: "Admin Name", className: "adminCls" }
  ];
  const renderRow = (mintedHistory, index, adminAccess) => (
    <MintTableCard
      {...mintedHistory}
      key={index}
      index={index}
      adminAccess={adminAccess}
    />
  );
  const handlePageChange = (selectedPage) => {
    const newPage = selectedPage.selected + 1;
    setCurrentPage(newPage);
  };

  return (
    <>
      {showAlert && <SuccessAlert message={alertMsg} />}
      <div className="propCard">
        <div className="flex justify-between items-center">
          <p className="text-lg font-medium text-black">
            Token Minting Overview
          </p>
          <button
            className={`propSaveBtn ${
              activeBtn ? "bg-pink text-white" : "bg-lightgray text-gray"
            }`}
            disabled
          >
            Mint Tokens
            {}
          </button>
        </div>

        <div className="flex gap-5">
          <div className="flex items-center gap-1 bg-[#D5E6FF] border border-viewblue rounded-lg px-3 py-2 ">
            <p className="text-sm">Total Tokens</p>
            <p className="text-lg text-[#007AFF] font-medium">
              {totalToken && totalToken}
            </p>
          </div>

          <div className="flex items-center gap-1 bg-[#DDF3DE] border border-activegreen rounded-lg px-3 py-2 ">
            <p className="text-sm">Available Tokens</p>
            <p className="text-lg text-[#419e6a] font-medium">
              {availableToken && availableToken}
            </p>
          </div>
          <div className="flex items-center gap-1 bg-[#FFF5D5] border border-edityellow rounded-lg px-3 py-2 ">
            <p className="text-sm">Tokens Minted</p>
            <p className="text-lg text-[#ffc35a] font-medium">
              {mintedToken && mintedToken}
            </p>
          </div>
        </div>

        <hr style={{ color: "#ddd" }} />
        <div className="border border-lightgray flex flex-col rounded-xl">
          <div className="bg-lightgray px-2 py-3 rounded-t-xl">
            <p className="text-lg text-left">Token Minting History</p>
          </div>
          <Table
            columns={columns}
            data={mintedHistory}
            //  isLoading={isLoading}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            emptyStateMessage="No tokens minted yet"
            renderRow={renderRow}
            // adminAccess={adminAccess}
            enablePagination={true}
          />
        </div>
      </div>
    </>
  );
}
