import React from 'react';
import Checkbox from 'react-custom-checkbox';
import { FaCheck } from 'react-icons/fa';

const CustomeCheckbox = ({ checked, onChange, name, dataSection ,disabled}) => {
  const handleCheckboxChange = (isChecked) => {
    onChange({
      target: {
        name: name,
        checked: isChecked,
        type: 'checkbox',
        dataset: { section: dataSection }
      }
    });
  };

  return (
    <Checkbox
      checked={checked}
      onChange={handleCheckboxChange}
      icon={<FaCheck style={{ color: 'green' }} />}
      borderColor={checked ? 'green' : 'gray'}
      borderWidth="2px"
      borderRadius="3px"
      style={{ cursor: 'pointer', padding: "3px" }}
      size={25}
      disabled={disabled}
    />
  );
};

export default CustomeCheckbox;
