import React, { useEffect, useRef, useState } from 'react'

import { BsThreeDotsVertical } from 'react-icons/bs';


import { MdDelete } from 'react-icons/md';


export default function ViewProjectSpecificationCard(props) {


    const [showOption,setShowOption]=useState(false)

    // const handleDeleteClick = () => {
    //     deleteAmenity(amenity_id);
    //   };
const {projectspec,handleProjectSpecChange,index,handleDeleteProjectSpec}=props
 
const optionsRef = useRef(null);
const handleShowOption=()=>{
   setShowOption(true)
}

useEffect(() => {
   const handleClickOutside = (event) => {
     if (optionsRef.current && !optionsRef.current.contains(event.target)) {
       setShowOption(false);
     }
   };

   document.addEventListener('mousedown', handleClickOutside);
   return () => {
     document.removeEventListener('mousedown', handleClickOutside);
   };
 }, []);

 const handleInputChange = (e) => {
  handleProjectSpecChange(index, e.target.value)
}

const handleDeleteClick = () => {
  handleDeleteProjectSpec(index)
}

  return (
    <div className='flex justify-between  items-center gap-5'>
    

    <input type="text" 
    className='amenInputText' 
    value={projectspec}
    onChange={handleInputChange}
     placeholder='E.g Swimming Pool' />
  
  
  <div className='flex gap-2.5 items-center'>
  

  

  
  </div>
  
  
  
  </div>
  )
}
