import React, { useEffect, useState } from 'react'
import { ApiBaseUrl, PropertImageBaseUrl } from '../../../Utils/urls';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { MdOutlineContentCopy, MdOutlineFileDownload, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight, MdSort } from 'react-icons/md';
import { IoIosSearch } from 'react-icons/io';
import { TfiFilter } from 'react-icons/tfi';
import UserTransactionFilter from '../../UserTransactionFilter/UserTransactionFilter';
import { BsThreeDots } from 'react-icons/bs';
import "./user-trans.css"
import SingleTransaction from './SingleTransaction';
import NoTransaction from './NoTransaction';
import { BeatLoader } from 'react-spinners';
import UserTransactionSort from '../../UserTransactionFilter/UserTransactionSort';
import useDebounce from '../../../Utils/Debounce';
import { ISODate } from '../../../Utils/ISODate';
import ReactPaginate from 'react-paginate';
import { FaUser } from 'react-icons/fa';


export default function UserTransaction({userDetails}) {
    const {id}=useParams()
    const navigate=useNavigate()
    const [activeTab,setactiveTab]=useState("buy")
   
    const [isOpenMob, setIsOpenMob] = useState(false);

    const [clearFilterSelection, setclearFilterSelection] = useState(false);
    const [boughtData,setBoughtData]=useState([])
    const [isLoading,setLoading]=useState(false)
    const adminId=localStorage.getItem("adminId")
    const accessToken=localStorage.getItem("accesstoken")
    const [toDate,setToDate]=useState("")
  const [fromDate,setFromDate]=useState("")

  //pagination
  const [toTalPages,setTotalPages]=useState(0)
 
const location=useLocation()
  const queryParams = new URLSearchParams(location.search);
  const initialPage = parseInt(queryParams.get('page')) || 1;
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [isSearchActive, setIsSearchActive] = useState(false);
  
 


    const [showTransactionSort,setShowTransactionSort]=useState(false)
    const handleShowTransSort=()=>{
      setShowTransactionSort(true)
    }
useEffect(()=>{

},[])

  const togglePopup = () => {
    setIsOpenMob(!isOpenMob);
  };
  const [searchInput, setSearchInput] = useState('');
  const debounce = useDebounce(); 
  const handleDebounceSearch=debounce((value)=>{
      
    //setFiltersChanged(true)
    
    setUserTransactionFilters((prev) => ({
      ...prev,
      page: 1,
      filters: {
        ...prev.filters,
        searchInput: value, 
      },
    }));
  },1000)
  
  
  
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setIsSearchActive(value !== ''); 
    setSearchInput(value); 
    handleDebounceSearch(value); 
  };


  const initialUserTransactionFilter={
    "user_id": id,
    "admin_id": adminId,
    "filtersByString": {
        "sale_type": null //["Public Sale","Resale","Presale"]
    },
    "filterByStatus": [
        
    ], //["COMPLETED","PENDING","CANCEL"],
    // Object containing filters based on string values.
    "purchased_date": {
        "from": "2023-03-12 00:00:00",
        "to": "2024-09-19 23:59:59"
    },
    "filtersByPlaces": [], //[{"city_id": 6, "locations": []}],
    //  Array of objects containing filters based on city and location.
    "filtersByBuilders": [], //[8]
    // Array of builder IDs for filtering properties.
    "filtersByPrice": {
        "token_price":null
    },
    "transaction_type":["Purchase Transactions"],
    "page":1,
    "pageSize":10
}

  const [UserTransactionFilters,setUserTransactionFilters]=useState(initialUserTransactionFilter)
const fetchTransaction=()=>{
  setLoading(true)
    fetch(`${ApiBaseUrl}/fr-admin/get-user-purchase-transactions`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken
      
      },
      body: JSON.stringify(UserTransactionFilters)
    }
  )
      .then(response => {
      
       
        return response.json();
      })
      .then(resdata => {
       
       //console.log('Response from server:', resdata);
       
          if(resdata.success)
          {
            setLoading(false)
            setBoughtData(resdata.data.transactions)
            setTotalPages(resdata.data.total_pages)
          }
  
      })
      .catch(error => {
       
        console.error('error details:', error);
      });
}

//pagination page change
const setPage = (page) => {
  setUserTransactionFilters((prev) => ({
    ...prev,
    page
  }));
};
useEffect(() => {
setPage(initialPage);
navigate(`/user/${id}?page=${initialPage}`)
}, [initialPage]);

useEffect(()=>{
    fetchTransaction()
},[UserTransactionFilters,id])


const handleFromChange = (e) => {
  const newFromDate = e.target.value;

  if (newFromDate) {
    const isoFormattedDate = ISODate(newFromDate);

    setFromDate(newFromDate);

    setUserTransactionFilters((prev) => ({
      ...prev,
      
        purchased_date: {
          ...prev.purchased_date,
          from:ISODate(e.target.value)
        
      },
    }));
  };
  }



const handleToChange=(e)=>{
  setToDate(e.target.value)
  
  setUserTransactionFilters((prev) => ({
    ...prev,
    
      purchased_date: {
        ...prev.purchased_date,
        to:ISODate(e.target.value)
      
    },
  }));
  
  }

  const handlePageChange = (data) => {
    //setFiltersChanged(false);
  
    if (currentPage === 1 && data.selected === 0) {
      // Prevent navigation if already on the first page
      return;
    }
    const newPage = data.selected + 1; 
    
    setPage(newPage); 
  
    setCurrentPage(newPage);
    
      // Update the current page
      navigate(`/user/${id}?page=${newPage}`); 
     
  };


  return (
    <div className='p-5 flex flex-col gap-5'>


 <div className='flex justify-between items-center'>
        <p className='text-2xl font-medium '>Transactions</p>
       
      </div>


     <div className='flex flex-row w-full justify-between p-4 gap-7 rounded-[10px] bg-gray2'>


{/* searchbar */}
<div className='flex items-center flex-row gap-2.5'>
{
  !userDetails?.profile?.profile_pic?.Key?
<div  className='bg-lightgray rounded-full w-8 h-8 flex justify-center items-center'>
<FaUser className='text-white' />
</div>
:
<img src={`${PropertImageBaseUrl}/${userDetails?.profile?.profile_pic.Key}`} alt=""  className='UserImgSmall'/>  
}
  
  <p>{userDetails && (userDetails.profile?.first_name|| "User")}</p>
</div>

{
    activeTab==="buy" && (
<div className='flex gap-5'> 
       
       
       <div className="relative text-gray-600">
       
       <button type="submit" className="absolute left-0 top-0 mt-3 ml-4">
               <IoIosSearch className="h-4 w-4 fill-current" />
             </button>
             <input
               type="search"
               name="search"
               value={searchInput}
        onChange={handleSearchChange}
               placeholder="Search By Property Name"
               className="bg-white h-10  px-5 pl-10 rounded-2xl text-sm focus:outline-none"
               style={{width:"250px"}}
             />
             
           </div>
       
       {/* <button className='bg-lightgray  rounded-lg flex gap-2.5 py-2.5 px-4 items-start justify-center text-white' disabled>
             
               <p className='text-sm font-bold font-nunito text-white'> Download Report</p>
               <MdOutlineFileDownload />
               </button> */}
       
               
       
               <button className='bg-white rounded-lg flex  py-1.5 px-3 items-start justify-center text-black'>
                 
                 <input type="date" name="" id=""  value={fromDate}  onChange={handleFromChange}/>
              
                 </button>


                 <button className='bg-white rounded-lg flex gap-2.5 py-1.5 px-3 items-start justify-center text-black'>
                
                 <input type="date" name="" id="" value={toDate} onChange={handleToChange} />
                 </button>
       
       
       </div>

    )}






</div>

<div className='flex justify-between items-center' >
    <div className='flex py-4 px-4 gap-4 transactabScroll' >
        <button  onClick={()=>setactiveTab("buy")} 
         className={`px-4 py-2.5 text-xs sm:text-sm  transacMobiTabBtn rounded-lg border border-lightgray  ${activeTab==="buy"?'bg-lightgray text-black':''} `}>
            Buying Transactions
        </button>

        <button onClick={()=>setactiveTab("sell")} 
        className={`px-4 py-2.5 text-xs sm:text-sm  transacMobiTabBtn rounded-lg border border-lightgray ${activeTab==="sell"?'bg-lightgray text-black':'border border-lightgray'} `}>
        Selling Transactions
        </button>

        <button onClick={()=>setactiveTab("rental")} 
        className={`px-4 py-2.5 text-xs sm:text-sm transacMobiTabBtn rounded-lg border border-lightgray ${activeTab==="rental"?'bg-lightgray text-black':''} `}>
        Rental Transaction
        </button>

    </div>
    
    {/* {
      clearAll &&(
        <div>Clear</div>
      )
    } */}
    
    {/* {
      activeTab==="buy" && UsersAllBoughtProp && UsersAllBoughtProp.length!=0 && (
<div className='mr-[21px] hidden sm:block cursor-pointer' onClick={togglePopup}><TfiFilter style={{ height: "35px" }} /></div>
   
      )
    } */}

   {
    activeTab==="buy" && (
    <div className='flex items-center gap-3'>
      <MdSort style={{height:"25px",width:"25px"}} onClick={handleShowTransSort} className='cursor-pointer' />
      <div className='mr-[21px] hidden sm:block cursor-pointer' onClick={togglePopup}><TfiFilter style={{ height: "35px" }} /></div>
 
    
    </div>
 
    )
   } 



<div className={ `rounded-xl popup-containerBookedFilter   ${isOpenMob ? 'active' : ''}`} style={{ boxShadow: '0px 4px 30px 0px #00000026' ,zIndex:"99999"}}>
<UserTransactionFilter 
 setUserTransactionFilters={ setUserTransactionFilters} 
 clearFilterSelection={clearFilterSelection}  
 isOpenMob={isOpenMob} 
 setIsOpenMob={setIsOpenMob}
 UserTransactionFilters={UserTransactionFilters}
 />




                     </div>

                     
<UserTransactionSort 
setShowTransactionSort={setShowTransactionSort}
 showTransactionSort={showTransactionSort}
 setUserTransactionFilters={setUserTransactionFilters}
 />



     </div>
{
  activeTab==="buy" && (
<div className='flex flex-col'>
 
 <div className='flex py-2'>
   <div className='slNO text-xs'>No.</div>
   <div className='propNamediv text-xs'>Property Name</div>
   <div className='transIddiv text-xs'>Transaction ID</div>
   <div className='TokenPriceDiv text-xs'>Token Price</div>
   <div className='noOfTokenDiv text-xs'>Number of tokens</div>
   <div className='StatusDiv text-xs'>Status</div>
   {/* <div><BsThreeDots /></div> */}
 </div>
<hr className='transhrClass' />

<div  className='flex flex-col gap-2 transactionBox' >

{isLoading ? (
  <p><BeatLoader color='#d82bb8'/></p>
) : boughtData && boughtData.length === 0 ? (
  <div className='my-7'>
    {searchInput !== "" ? (
     
      <NoTransaction message={"No such transaction found."}/>
    ) : (
      <NoTransaction message={"No transaction has been recorded yet"}/>
    )}
  </div>
) : (
  boughtData && boughtData.map((item, index) => (
    <SingleTransaction key={index} {...item} index={index} 
    isSearchActive={isSearchActive} 
    serialNo={index + 1}
    />
  ))
)}



</div>
<div className="pagination-container">
    <ReactPaginate
              previousLabel={<MdOutlineKeyboardArrowLeft/>}
              nextLabel={<MdOutlineKeyboardArrowRight />}
              breakLabel={'...'}
              breakClassName={'pagination-break'}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={toTalPages}
              pageClassName={`pagination-button`}
              onPageChange={handlePageChange}
              forcePage={currentPage-1}
              containerClassName={'pagination flex justify-end items-center gap-1.5'} 
              activeClassName={'pagination-active border border-pink text-black  rounded'} 
              previousClassName={`pagination-prev ${
                currentPage === 1 ? 'pageinateDisable ' : ''
              }`} 
              nextClassName={'pagination-prev '} 
            />




        </div>



</div>
  )
}




{
  activeTab==="sell" && (
<div className='flex flex-col'>
 
 <div className='flex py-2'>
   <div className='slNO text-xs'>No.</div>
   <div className='propNamediv text-xs'>Property Name</div>
   <div className='transIddiv text-xs'>Transaction ID</div>
   <div className='TokenPriceDiv text-xs'>Token Price</div>
   <div className='noOfTokenDiv text-xs'>Number of tokens</div>
   <div className='StatusDiv text-xs'>Status</div>
   {/* <div><BsThreeDots /></div> */}
 </div>
<hr className='transhrClass' />

<div  className='flex flex-col gap-2 transactionBox' >
<div className='my-7'>
       <NoTransaction/>
     </div>



</div>




</div>
  )
}


{
  activeTab==="rental" && (
<div className='flex flex-col'>
 
 <div className='flex py-2'>
   <div className='slNO text-xs'>No.</div>
   <div className='propNamediv text-xs'>Property Name</div>
   <div className='transIddiv text-xs'>Transaction ID</div>
   <div className='TokenPriceDiv text-xs'>Token Price</div>
   <div className='noOfTokenDiv text-xs'>Number of tokens</div>
   <div className='StatusDiv text-xs'>Status</div>
   {/* <div><BsThreeDots /></div> */}
 </div>
<hr className='transhrClass' />

<div  className='flex flex-col gap-2 transactionBox' >
<div className='my-7'>
       <NoTransaction/>
     </div>



</div>




</div>
  )
}

    </div>
  )
}
