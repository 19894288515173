import React, { useRef, useState } from 'react'

import ViewDocCard from './ViewDocCard'


export default function ViewDocCategoryCard(props) {


const{index,
    category,
    documents,
    
    documentsList,
    setDocumentsList,
    setPdfArray,
    pdfArray,
    
    }=props

    const addNewDocument = () => {
        const newDocument = {
            "docs": [],
            "title": "",
            "description": null
        }
        const updatedCategories = [...documentsList]
        updatedCategories[index].documents.push(newDocument)
        setDocumentsList(updatedCategories)
    }

 






  return (
    <div className='border rounded-lg border-lightgray p-3 documentCategoryCard'>

    <div className='flex flex-col gap-2.5' style={{width:"430px"}}>
    
    <div className='flex flex-col gap-2'>
        <div className='flex justify-between items-center'>


        <p className='text-start text-sm text-gray' >Document Category Name</p>
    

        <div className='flex justify-end relative' >
 


</div>
        </div>

    
        
    
        <input type="text" 
        disabled
        className='propDisableInputText'
        value={category && category}

        onChange={(e) => {
            const updatedCategories = [...documentsList]
            updatedCategories[index].category = e.target.value
            setDocumentsList(updatedCategories)
        }} 
         placeholder='E.g Property Documents' />
    </div>
    
    
    <div className='flex gap-5'>
    
    <div className='documentForm flex flex-col gap-3'>
    
    
    
    
    <div className='flex flex-col rounded-lg border  border-lightgray py-4 px-5'>
    
    <div  className='flex gap-2.5 justify-end'>
          <button  
          disabled 
          className='bg-lightgray px-2.5 py-1.5 rounded-[4px] text-gray text-xs' >
            Delete All
            </button>
            <button 
            disabled
            onClick={addNewDocument} 
            className='bg-lightgray px-2.5 py-1.5 rounded-[4px] text-gray text-xs' >
           Add More
            </button>
          
    </div>
    
    
    
    <div className='flex flex-col gap-2.5'>
    
    <div className='flex flex-col gap-1.5'>
    
      
    
    {/* added amenities */}
    
    <div className='flex flex-col gap-1.5 addedList'>
    
     {
        documents && documents.map((el,ind)=>(
            <ViewDocCard
               key={ind}
                {...el} 
                ind={ind}
             
                categoryIndex={index} 
                documentIndex={ind} 
                setPdfArray={setPdfArray}
                pdfArray={pdfArray}

                documentsList={documentsList}
                setDocumentsList={setDocumentsList}
               
                />
        ))
     }
    
   
    
    
    
    
    </div>
    
    
    
    
    
    </div>
    
    
    </div>
    
    
    </div>
    
          </div>
    
    
    
    
    
    
    </div>
    
    
    
    
    
    </div>
    
    </div>
  )
}
