import React from 'react'
import { Link } from 'react-router-dom'
import "./header-style.css"
import { IoMdArrowBack } from 'react-icons/io'
import { IoArrowBackOutline } from 'react-icons/io5'
import { PropertImageBaseUrl } from '../../Utils/urls'
import avatar from "../../Assets/images/avatar.png"

export default function Header({pageTitle,subtitle,
  backLinkPath,
  showDeactivateButton,
  handleUpdatedPropStatus,
  propStatus,propertyId,
  showEditPageDeactivateButton,
  showViewDeactivateButton
}) {

const AdminName=  localStorage.getItem("AdminName")
const AdminProfilePic=JSON.parse(localStorage.getItem("AdmnProfilePic"))



  return (
    <header className="sticky top-0 font-nunito flex w-full bg-white  drop-shadow-1 "  style={{zIndex:"100"}}>
      <div className="flex flex-grow justify-between px-5 py-5 bg-white " style={{zIndex:"100"}}>

        <div className='flex flex-col text-left '>
                <p className='text-gray text-xs'>{subtitle}</p>
                <div className='flex gap-2 items-center'>
                
                {/* user this backLinkPath for back arrow */}
                {
                  backLinkPath && (
                  <Link to={backLinkPath}>
                  <IoArrowBackOutline />
                  </Link>
                  )
                }
                  
               
               
                <p className='text-blue text-2xl font-bold'>{pageTitle}</p>

                {showDeactivateButton && (
                             <button 
                             disabled={!propertyId}
                            
                             onClick={handleUpdatedPropStatus}
                             className= {`${propStatus?"bg-activegreen text-white":"bg-lightgray text-gray"} ml-5  propSaveBtn rounded-md text-md`}
                             >{propStatus?'Deactivate':'Activate'}</button>
            )}

{showEditPageDeactivateButton && (
                             <button 
                             disabled={!propertyId}
                            
                             onClick={handleUpdatedPropStatus}
                             className= {`${propStatus?"bg-activegreen text-white":"bg-lightgray text-gray"} ml-5  propSaveBtn rounded-md text-md`}
                             >{propStatus?'Deactivate':'Activate'}</button>
            )}

{showViewDeactivateButton && (
                             <button 
                             disabled
                            
                             onClick={handleUpdatedPropStatus}
                             className= {`${propStatus?"bg-activegreen text-white":"bg-lightgray text-gray"} ml-5  propSaveBtn rounded-md text-md`}
                             >{propStatus?'Deactivate':'Activate'}</button>
            )}


                </div>
                {/* <p className='text-blue text-2xl font-bold'>{pageTitle}</p> */}
        </div>

        <div className='flex gap-7 items-center justify-center'>
                    <div className='flex gap-7 items-center justify-center'>
                    {/* <span className="material-symbols-outlined text-gray">notifications_unread</span> */}

        <div className='flex gap-5'>
                <div className='flex flex-col text-left'>
                        <p className='text-blue text-sm '>
                            Hello, <span className='font-bold'>{AdminName}</span>
                        </p>
                        <p className='text-gray text-xs'>Super Admin</p>
                </div>
                <div>
                    {/* <div className='bg-lightgray w-10 h-10 rounded-[50%]'></div> */}
                   {
                        AdminProfilePic?.Key  ?
                        <img src={`${PropertImageBaseUrl}/${AdminProfilePic.Key}`} className='AdminProfile' alt="" />
               :
               <div>
               <img className='AdminAvatarImg border border-lightgray' src={avatar} alt="User" />
         
             </div>
               
                   }
                </div>
        </div>
</div>

      {/* <div >
          <span class="material-symbols-outlined text-white w-10 h-10 rounded-[50%] bg-blue flex justify-center items-center">
                settings
              </span>
     </div> */}
     
        </div>

      </div>
    </header>
  )
}
