export const ISODate = (inputDate) => {
  const date = new Date(inputDate);

  // Ensure the date is in UTC and at the end of the day (23:59:59)
  const formattedDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59));

  // Convert to ISO string format
  const IsoDate = formattedDate.toISOString();

  return IsoDate;
}


