import React, { useEffect, useRef, useState } from 'react'

import { MdDelete } from 'react-icons/md';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { changeDateFormatAdmin } from '../../../Utils/dateFormat';

export default function EditPaymentSchCard(props) {

const { payment_schedule_id,
property_id,
amount,
schedule_order,
due_date,
is_immediate,
amount_percent,
description,

index,
handleInputChange,
handleDelete
}=props

const [showOption,setShowOption]=useState(false)
const optionsRef = useRef(null);
const handleShowOption=()=>{
   setShowOption(true)
}

useEffect(() => {
   const handleClickOutside = (event) => {
     if (optionsRef.current && !optionsRef.current.contains(event.target)) {
       setShowOption(false);
     }
   };

   document.addEventListener('mousedown', handleClickOutside);
   return () => {
     document.removeEventListener('mousedown', handleClickOutside);
   };
 }, []);



  

  return (
    <div className='flex justify-between'>
    
    <div className='flex flex-col gap-4 propDetails '>

 

      <div className='flex gap-3 relative'>
          <div className='flex flex-col gap-2.5 items-start propInputDiv'>
              <p className='text-sm text-blue'>Description <span style={{color:"#af2525"}}>*</span> </p>
             <textarea name="" id=""  className='propInputText'  
             placeholder='E.g Vascon Tulips Gold Coimbatore'
             value={description && description}
             onChange={(e) => handleInputChange(index, 'description', e.target.value)}
             ></textarea>


          </div>

          <div className='flex flex-col gap-2.5 items-start propInputDiv'>
              <p className='text-sm text-blue'>Amount (IN ₹) <span style={{color:"#af2525"}}>*</span> </p>
             

             {

             }
             
             
              <input type="text" 
              name="" 
              value={amount && amount.toLocaleString()}
             disabled={schedule_order==1}
             onChange={(e) => handleInputChange(index, 'amount', e.target.value)}
              className='propInputText'
              placeholder='E.g Vascon Tulips'
             />
          </div>


          <div className='flex flex-col gap-2.5 items-start propInputDiv'>
              <p className='text-sm text-blue'>Amount (IN %) <span style={{color:"#af2525"}}>*</span> </p>
             
             
             
              <input type="text" 
              name="" 
              value={amount_percent && amount_percent}
             
             onChange={(e) => handleInputChange(index, 'amount_percent', e.target.value)}
              className='propInputText'
              placeholder='E.g Vascon Tulips'
             />
          </div>




          <div className='flex flex-col gap-2.5 items-start propInputDiv'>
              <p className='text-sm text-blue'>Collection Dates<span style={{color:"#af2525"}}>*</span> </p>
             
{
    schedule_order==1 ?
    (
        <input type="text" 
        name="" 

        value={due_date && due_date}
       
        className='propInputText'
        placeholder='E.g Immediatly on Purchase'
       />

    )
    :
    (
        <input  
        name="" 
        type="date" 
        value={due_date && changeDateFormatAdmin(due_date)}
        onChange={(e) => handleInputChange(index, 'due_date', e.target.value)}
        className='propInputText'
        placeholder='E.g Immediatly on Purchase'
       />

     

    )
}


             </div>


<div  className='cursor-pointer text-lg flex items-center'>

<BsThreeDotsVertical   onClick={handleShowOption} />
  {
     showOption && (
  <div ref={optionsRef}  className='flex flex-col gap-3  z-50 shadow absolute top-12 right-0 rounded-xl p-2.5 bg-white'>
  
{
  index!=0 && (
    <div className='delBtnTenant cursor-pointer'
    onClick={() => handleDelete(index)}
   >
   
   <p className='text-delred  flex gap-2 items-center text-sm' >
   <MdDelete />Delete</p>
   
   </div>
  )
}
 

 


  </div>
  
  
  
     )
  }
</div>



      </div>



    



    </div>





  </div>
  )
}
