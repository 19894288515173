import React, { useEffect, useRef, useState } from 'react'
import PopUp from '../../PopUp/PopUp'

import { MdDelete, MdOutlineClose } from 'react-icons/md'
import { PropertImageBaseUrl } from '../../../Utils/urls'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { RiEdit2Fill } from 'react-icons/ri'

export default function ViewFloorPlanCard({ 
   index,
   plan, 
 
 }) {
  
   const [showImagePopUp,setShowImagePopUp]=useState(false)
   const [showOptions,setShowOptions]=useState(false)
   const optionsRef =useRef(null)
const CloseImagePopup=()=>{
   setShowImagePopUp(false)
}
const handleshowImagePopup=()=>{
   setShowImagePopUp(true)
}


useEffect(() => {
   const handleClickOutside = (event) => {
     if (optionsRef.current && !optionsRef.current.contains(event.target)) {
      setShowOptions(false);
     }
   };

   document.addEventListener('mousedown', handleClickOutside);
   return () => {
     document.removeEventListener('mousedown', handleClickOutside);
   };
 }, []);

   return (

      <>

      {
         showImagePopUp && (
            <PopUp maxWidth={'max-w-xl'}>

                     <div className='flex justify-end text-xl' >
                     <MdOutlineClose  onClick={CloseImagePopup} className='cursor-pointer' />
                     </div>

                     <div>
                     {
   plan && plan.floor_image.base64 && (
      <img src={plan.floor_image.base64} id="output" width="200" alt='floor_plans_img' className='floorPlanPopupImg'/>

   )
}
{
   plan && plan.floor_image?.Key && (
      <img src={`${PropertImageBaseUrl}/${plan.floor_image.Key}`} id="output" width="200" alt='floor_plans_img' className='floorPlanPopupImg'/>

   )
}
                     </div>

            </PopUp>
         )
      }
     

   







     <div className='rounded-xl border border-lightgray p-5 flex flex-col gap-3.5' style={{width:"500px"}}>
  <div>
  {
   plan && plan.floor_image?.Key && (
      <img src={`${PropertImageBaseUrl}/${plan.floor_image.Key}`} id="output" width="200" alt='floor_plans_img' className='floorPlanPopupImg'/>

   )
}
     
  </div>

  <div className='flex flex-col gap-7'> 

<div className='flex gap-4 '>

  <div className='flex flex-col gap-2.5'>
    <div className='text-left flex gap-5 justify-between'>
      <p>Floor Plan Details</p>
      <p><b>{plan  && plan.title}</b></p>
    </div>

    <div className='text-left flex gap-5 justify-between'>
      <p>Amount Details</p>
      <p><b>  {plan && plan.value}</b></p>
    </div>

  </div>

  <div >
  <button className='text-white previewBtn bg-pink' onClick={handleshowImagePopup}>Preview</button>
    </div>

</div>


<div className='flex text-gray font-bold  text-wrap text-start w-full' >
   <p>{plan && plan.description}</p>

</div>

  </div>

</div>
     </>
  )
}
