import React, { useEffect, useState } from "react";
import AdminContainer from "../../AdminContainer/AdminContainer";
import AdminDetails from "./EditAdminDetails";
import UserManagementForm from "./EditUserManagementForm";
import "./admin-edit.css";
import PropertyManage from "./EditPropertyManage";
import TransactionOverview from "./EditTransactionOverview";
import { countries } from "countries-list";
import { ApiBaseUrl } from "../../../Utils/urls";
import loader from "../../../Assets/Icons/Loader.gif";
import SuccessAlert from "../../Alert/SuccessAlert";
import ErrorAlert from "../../Alert/ErrorAlert";
import { useNavigate, useParams } from "react-router-dom";
import EditAdminDetails from "./EditAdminDetails";
import EditUserManagementForm from "./EditUserManagementForm";
import EditPropertyManage from "./EditPropertyManage";
import EditTransactionOverview from "./EditTransactionOverview";
import { changeDateFormatAdmin } from "../../../Utils/dateFormat";
import { getAdminProfileDetails } from "../../../Api/AdminApi";

export default function AdminEditPage() {
  const accessToken = localStorage.getItem("accesstoken");
  const adminId = localStorage.getItem("adminId");

  //profile img
  const [selectedProfilePic, setSelectedProfilePic] = useState({});
  const [fileError, setFileError] = useState("");
  const [proflePic, setProfilePic] = useState("");
  const [previousProfilePic, setPreviousProfilePic] = useState("");

  const [enableSaveBtn, setEnableSaveBtn] = useState(false);

  const [isLoading, setLoading] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [showSuccessAlert, setShowSucAlert] = useState(false);
  const [showErrAlert, setShowErrAlert] = useState(false);

  const [isEditloading, setEditLoading] = useState(false);

  const navigate = useNavigate();
  const { id: staffId } = useParams();

  const [adminAccess, setAdminAccess] = useState({});
  const [adminData, setAdminData] = useState({});

  const [isAdminLoading, seAdmintLoading] = useState(false);
  const [isNetworkError, setError] = useState(false);

  //password

  const [isLengthValid, setIsLengthValid] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasSymbolOrNumber, setHasSymbolOrNumber] = useState(false);
  const [confirmpassword, setConfirmPass] = useState("");
  const [isConfirmPassCorrect, setConfPassCorrect] = useState(false);
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [isPassValid, setPassValid] = useState(false);

  useEffect(() => {
    fetchAdminAccess();
  }, []);

  //password

  const validatePassword = (password) => {
    setIsLengthValid(password.length >= 8);
    setHasUpperCase(/[A-Z]/.test(password));
    setHasSymbolOrNumber(/[\d!@#$%^&*(),.?":{}|<>]/.test(password));
  };

  useEffect(() => {
    if (
      isLengthValid &&
      hasSymbolOrNumber &&
      hasUpperCase &&
      confirmpassword === adminData.profile?.password
    ) {
      setPassValid(true);
    } else {
      setPassValid(false);
    }
  }, [
    hasSymbolOrNumber,
    isLengthValid,
    hasUpperCase,
    confirmpassword,
    adminData
  ]);

  const changeConfirmPass = (e) => {
    const { value } = e.target;
    setConfirmPass(value);
    validateConfirmPass(value);
  };

  const validateConfirmPass = (value) => {
    if (adminData.profile?.password === value) {
      setConfPassCorrect(true);
      setPasswordMismatch(false);
    } else {
      setConfPassCorrect(false);
      setPasswordMismatch(true);
    }
  };

  const fetchAdminAccess = () => {
    getAdminProfileDetails(
      seAdmintLoading,
      accessToken,
      adminId,
      undefined,
      setAdminAccess,
      setError
    );
    console.log(3);
  };
  const customLabels = {};

  for (const countryCode in countries) {
    const countryData = countries[countryCode];
    const primary = countryData.name;
    const secondary = "+" + countryData.phone[0];

    customLabels[countryCode] = { primary, secondary };
  }

  const [selected, setSelected] = useState("");

  function getKeyBySecondaryValue(value) {
    for (const key in customLabels) {
      if (customLabels[key].secondary === value) {
        return key;
      }
    }
    return null; // Return null if no match is found
  }

  const handleInputChange = (e) => {
    const {
      name,
      value,
      type,
      checked,
      dataset: { section }
    } = e.target;
    const finalValue = type === "checkbox" ? checked : value;

    setAdminData((prevState) => {
      if (section === "profile" || section === "permissions") {
        // Handle updates to profile or permissions within profile

        //password
        if (name === "password") {
          validatePassword(value);

          if (confirmpassword !== "") {
            if (value !== confirmpassword) {
              setPasswordMismatch(true);
            } else {
              setPasswordMismatch(false);
            }
          }
          if (confirmpassword === value) {
            setConfPassCorrect(true);
          } else {
            setConfPassCorrect(false);
          }
        }

        return {
          ...prevState,
          profile: {
            ...prevState.profile,
            [section === "permissions" ? "permissions" : name]:
              section === "permissions"
                ? {
                    ...prevState.profile.permissions,
                    [name]: finalValue
                  }
                : finalValue
          }
        };
      } else {
        // Handle other sections if any

        return {
          ...prevState,
          [section]: {
            ...prevState[section],
            [name]: finalValue
          }
        };
      }
    });
  };

  const handleProfilePicChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const fileTypeParts = file.type.split("/");
      const fileExtension = fileTypeParts.length === 2 ? fileTypeParts[1] : "";

      const maxSize = 5 * 1024 * 1024;

      if (file.type !== "image/jpeg" && file.type !== "image/png") {
        setFileError("Only JPG or PNG formats are allowed");
      } else if (file.size > maxSize) {
        setFileError("File size exceeds 5 MB limit");
      } else {
        setFileError("");
        const reader = new FileReader();
        reader.onload = (event) => {
          const base64String = event.target.result;
          setSelectedProfilePic({
            extension: fileExtension,
            file_type: file.type,
            base64: base64String
          });

          setAdminData((prevState) => ({
            ...prevState,
            profile: {
              ...prevState.profile,
              profile_pic: {
                extension: fileExtension,
                file_type: file.type,
                base64: base64String
              }
            }
          }));
        };
        reader.readAsDataURL(file);
      }
    }
  };

  useEffect(() => {
    if (
      adminData.profile?.mobile !== "" &&
      adminData.profile?.email !== "" &&
      adminData.profile?.name !== "" &&
      adminData.profile?.gender !== "" &&
      adminData.profile?.password !== "" && ///password
      isPassValid
    ) {
      setEnableSaveBtn(true);
    } else {
      setEnableSaveBtn(false);
    }
  }, [adminData, isPassValid]); ///password

  const fetchAdminDetails = () => {
    fetch(
      `${ApiBaseUrl}/fr-admin/get-admin-details?staff_id=${staffId}&admin_id=${adminId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: accessToken
        }
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((resdata) => {
        //console.log('Response from server:', resdata);
        setLoading(false);
        if (resdata.success) {
          setAdminData(resdata.data);
          validatePassword(resdata.data.profile.password);
          setProfilePic(resdata.data.profile.profile_pic.Key);
          setPreviousProfilePic(resdata.data.profile.profile_pic.Key);
          setSelected(
            getKeyBySecondaryValue(resdata.data.profile.country_code)
          );
        }
      })
      .catch((error) => {
        console.error("error details:", error);
      });
  };

  useEffect(() => {
    fetchAdminDetails();
  }, []);

  const handleEditAdmin = () => {
    setLoading(true);
    setEnableSaveBtn(false);
    fetch(`${ApiBaseUrl}/fr-admin/edit-admin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken
      },
      body: JSON.stringify({
        ...adminData,
        admin_id: adminId,
        profile: {
          ...adminData.profile,
          country_code: customLabels[selected].secondary,
          staff_id: staffId,
          dob: changeDateFormatAdmin(adminData.profile.dob)
        }
      })
    })
      .then((response) => {
        return response.json();
      })
      .then((resdata) => {
        //console.log('Response from server:', resdata);

        if (resdata.success) {
          setLoading(false);
          setEnableSaveBtn(true);

          setAlertMsg(resdata.data);
          setShowSucAlert(true);
          setTimeout(() => {
            setAlertMsg("");
            setShowSucAlert(false);
            // navigate("/adminlist")
          }, 2000);
        } else if (!resdata.success) {
          setLoading(false);
          setEnableSaveBtn(true);
          setAlertMsg(resdata.data);
          setShowErrAlert(true);
          setTimeout(() => {
            setAlertMsg("");
            setShowErrAlert(false);
          }, 3000);
        }
      })
      .catch((error) => {
        console.error("error details:", error);
      });
  };

  return (
    <AdminContainer
      pageTitle="Edit  Admin"
      subtitle="Admin Management/"
      backLinkPath="/adminlist"
    >
      {isLoading && (
        <div className="adminLoaderContainer">
          <img src={loader} alt="" className="adminloader" />
        </div>
      )}

      {showSuccessAlert && <SuccessAlert message={alertMsg} />}

      {showErrAlert && <ErrorAlert message={alertMsg} />}

      <div className="container font-nunito">
        <div className="flex flex-col p-5 gap-2.5">
          <div className="flex justify-end">
            <button
              className={`propSaveBtn ${
                enableSaveBtn && adminAccess?.edit_admin == 1
                  ? "bg-pink text-white"
                  : "bg-lightgray text-gray"
              } `}
              disabled={!enableSaveBtn || adminAccess?.edit_admin == 0}
              onClick={handleEditAdmin}
            >
              {isLoading ? "Saving...." : "Edit"}
            </button>
          </div>

          <EditAdminDetails
            adminData={adminData}
            handleInputChange={handleInputChange}
            fileError={fileError}
            handleProfilePicChange={handleProfilePicChange}
            setAdminData={setAdminData}
            setSelected={setSelected}
            selected={selected}
            customLabels={customLabels}
            selectedProfilePic={selectedProfilePic}
            adminAccess={adminAccess}
            isLoading={isLoading}
            isLengthValid={isLengthValid}
            hasSymbolOrNumber={hasSymbolOrNumber}
            hasUpperCase={hasUpperCase}
            changeConfirmPass={changeConfirmPass}
            confirmpassword={confirmpassword}
            isConfirmPassCorrect={isConfirmPassCorrect}
            passwordMismatch={passwordMismatch}
          />

          <div className="flex justify-between">
            <EditUserManagementForm
              adminAccess={adminAccess}
              adminData={adminData}
              setAdminData={setAdminData}
              handleInputChange={handleInputChange}
            />
            <EditPropertyManage
              adminData={adminData}
              adminAccess={adminAccess}
              setAdminData={setAdminData}
              handleInputChange={handleInputChange}
            />
          </div>

          <EditTransactionOverview
            adminData={adminData}
            adminAccess={adminAccess}
            setAdminData={setAdminData}
            handleInputChange={handleInputChange}
          />
        </div>
      </div>
    </AdminContainer>
  );
}
