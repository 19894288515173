export const indianStatesCode = [
    { code: "AN", label: "Andaman and Nicobar Islands" },
    { code: "AP", label: "Andhra Pradesh" },
    { code: "AR", label: "Arunachal Pradesh" },
    { code: "AS", label: "Assam" },
    { code: "BR", label: "Bihar" },
    { code: "CH", label: "Chandigarh" },
    { code: "CG", label: "Chhattisgarh" },
    { code: "DL", label: "Delhi" },
    { code: "DH", label: "Dadra and Nagar Haveli and Daman and Diu" },
    { code: "GA", label: "Goa" },
    { code: "GJ", label: "Gujarat" },
    { code: "HR", label: "Haryana" },
    { code: "HP", label: "Himachal Pradesh" },
    { code: "JK", label: "Jammu and Kashmir" },
    { code: "JH", label: "Jharkhand" },
   
    { code: "KA", label: "Karnataka" },
    { code: "KL", label: "Kerala" },
    { code: "LA", label: "Ladakh" },
    { code: "LD", label: "Lakshadweep" },
    { code: "MP", label: "Madhya Pradesh" },
    { code: "MH", label: "Maharashtra" },
    { code: "MN", label: "Manipur" },
    { code: "ML", label: "Meghalaya" },
   
  
    { code: "MZ", label: "Mizoram" },
    { code: "NL", label: "Nagaland" },
    { code: "OD", label: "Odisha" },
    { code: "PY", label: "Puducherry" },
    { code: "PB", label: "Punjab" },
  
    { code: "RJ", label: "Rajasthan" },
    { code: "SK", label: "Sikkim" },
    { code: "TN", label: "Tamil Nadu" },
    { code: "TS", label: "Telangana" },
   
    { code: "TR", label: "Tripura" },
    { code: "UP", label: "Uttar Pradesh" },
    { code: "UK", label: "Uttarakhand" },
    { code: "WB", label: "West Bengal" }
  ];
  

  