import React, { useEffect, useState } from "react";
import { FaRegCircleUser } from "react-icons/fa6";
import Switch from "react-switch";

import { IoMdClose } from "react-icons/io";

import SuccessAlert from "../../Alert/SuccessAlert";
import ErrorAlert from "../../Alert/ErrorAlert";
import PopUp from "../../PopUp/PopUp";
import FloatingLoader from "../../Loader/FloatingLoader";
import { ApiBaseUrl, PropertImageBaseUrl } from "../../../Utils/urls";
import AddCityPopUp from "../AddCityPopUp";
import EditCityPopUp from "../EditCityPopUp";
import AddLocationPopup from "../AddLocationPopup";
import {
  AddCity,
  AddLocationTodB,
  AddPropertyBasics,
  getCityLocation,
  getPropertyDetails
} from "../../../Api/PropertyAddApi";
import CustomButton from "../../Common/CustomeButton";

export default function EditPropertyInfo(props) {
  const [checked, setChecked] = useState(false);
  const adminId = localStorage.getItem("adminId");
  const { propertyId, MintedToken } = props;

  //const [propertyId,setPropertyId]=useState("7")
  //const propertyId=7
  const [propertyInfo, setPropertyInfo] = useState({});

  // Toggle function to change the state
  const handleChange = (checked) => {
    setChecked(checked); // Update the state
  };
  const [showAddCityPopUp, setShowAddCityPopUp] = useState(false);
  const handleShowAddCityPopup = () => {
    setShowAddCityPopUp(true);
  };
  const handleCloseAddCityPopUp = () => {
    setShowAddCityPopUp(false);
  };
  //edit city states
  const [showeditCityPopup, setShowEditCityPopup] = useState(false);
  const handleShowEditCityPopup = () => {
    setShowEditCityPopup(true);
  };
  const handleCloseEditCityPopUp = () => {
    setShowEditCityPopup(false);
  };

  //add location
  const [showAddLocPopup, setShowAddLocPopup] = useState(false);
  const handleShowAddLocPopup = () => {
    setShowAddLocPopup(true);
  };
  const handleCloseAddLocPopUp = () => {
    setShowAddLocPopup(false);
  };
  //edit location

  const [showEditLocationPopUp, setShowEditLocationPopUp] = useState(false);
  const handleShowEditLocPopup = () => {
    setShowEditLocationPopUp(true);
  };
  const handleCloseEditLocPopup = () => {
    setShowEditLocationPopUp(false);
  };

  //add city
  const [cityLocationData, setCityLocationData] = useState([]);
  const [selectedCityId, setselectedCityId] = useState("");
  const [selectedCityLabel, setSelectedCityLabel] = useState(""); //for edit city
  const [selectedCityIconKey, setSelectedCityIconKey] = useState(""); //for edit city icon
  const [selectedCityBucket, setSelectedCityBucket] = useState(""); //for edit city icon

  const [locations, setLocations] = useState([]);

  const [cityName, setCityName] = useState("");

  const [newLocation, setNewLocation] = useState("");
  const [locationList, setLocationList] = useState([]);

  const [cityIcon, setCityIcon] = useState({});
  const [cityIconError, setCityIconError] = useState("");
  const accessToken = localStorage.getItem("accesstoken");

  const [isCityLoading, setCityLoading] = useState(false);
  const [cityAlertMsg, setCityAlertMsg] = useState("");
  const [cityAdded, setCityAdded] = useState(false); //alert  for success city add
  const [cityAddErr, setCityAddErr] = useState(false); //alert for error city add

  //edit city states
  const [isCityEditLoading, setCityEditLoading] = useState(false);
  const [CityEditAlertMsg, setCityEditAlertMsg] = useState("");
  const [cityEdited, setCityEdited] = useState(false);
  const [cityEditErr, setCityEditErr] = useState(false);

  //add location
  const [addlocLoading, setAddLocLoading] = useState(false);
  const [locationAdded, setlocationAdded] = useState(false);
  const [locAddAlertMsg, setLocAddAlertMsg] = useState("");
  const [locAddErr, setLocAddErr] = useState(false);

  //edit location
  const [selectedlocationId, setSelectedlocationId] = useState("");
  const [selectedlocation, setSelectedLocation] = useState({});
  const [editedLocName, setEditedLocName] = useState("");
  const [editLocloading, setEditLocLoading] = useState(false);
  const [editLocAlertMsg, setEditLocAlertMsg] = useState("");
  const [locationedited, setLocationEdited] = useState(false);
  const [locationEditErr, setLocEditErr] = useState(false);
  const [fetchedcityIcon, setFetchedCityIcon] = useState(""); //for initial edit city popup without onChange event

  const [propNameOnly, setPropNameOnly] = useState("");
  const [floorNo, setFloorNo] = useState(null);
  const [blockNo, setblockNo] = useState(null);
  const [unitNo, setUnitNo] = useState(null);
  const [direction, setDirection] = useState(null);
  const [configuration, setConfig] = useState(null);

  const handleLocationChange = (e) => {
    const selectedLocId = e.target.value;
    setSelectedlocationId(selectedLocId);
    const selectedLocation = locations.find(
      (el) => el.location_id === parseInt(selectedLocId)
    );

    setSelectedLocation(selectedLocation);
  };

  const fetchCityLocation = () => {
    getCityLocation(setCityLocationData);
  };

  useEffect(() => {
    fetchCityLocation();
  }, []);

  useEffect(() => {
    const fetchedCity = cityLocationData?.find(
      (city) => city.city_id === parseInt(selectedCityId)
    );

    setFetchedCityIcon(fetchedCity?.city_icon);
    setCityName(fetchedCity?.city_name);
  }, [selectedCityId]);

  const handleCityChange = (e) => {
    setCityIcon({});
    const selectedCityId = e.target.value;
    setselectedCityId(selectedCityId);

    const selectedCity = cityLocationData.find(
      (city) => city.city_id === parseInt(selectedCityId)
    );

    setSelectedCityLabel(selectedCity.city_name);
    setSelectedCityIconKey(selectedCity.city_icon?.Key);
    setSelectedCityBucket(selectedCity.city_icon.Bucket);
    setLocations(selectedCity ? selectedCity.locations : []);
  };

  const handleAddLocation = () => {
    if (newLocation) {
      setLocationList([...locationList, newLocation]);
      setNewLocation("");
    }
  };

  const handleRemoveLocation = (index) => {
    const newList = [...locationList];
    newList.splice(index, 1);
    setLocationList(newList);
  };

  const handleCityIconChange = (e) => {
    const file = e.target.files[0];
    // const size = file.size;

    if (file) {
      const fileTypeParts = file.type.split("/");
      const fileExtension = fileTypeParts.length === 2 ? fileTypeParts[1] : "";

      const maxSize = 5 * 1024 * 1024;

      if (file.type !== "image/jpeg" && file.type !== "image/png") {
        setCityIconError("Only JPG or PNG formats are allowed");
      } else if (file.size > maxSize) {
        setCityIconError("File size exceeds 5 MB limit");
      } else {
        setCityIconError("");

        const reader = new FileReader();
        reader.onload = (event) => {
          const base64String = event.target.result;

          setCityIcon({
            extension: fileExtension,
            file_type: file.type,
            base64: base64String
          });
          setSelectedCityIconKey("");
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const isSaveButtonDisabled =
    !cityName || !cityIcon.base64 || locationList.length === 0;
  const isEditSaveBtnEnabled =
    cityName &&
    (cityIcon.base64 || selectedCityIconKey || fetchedcityIcon?.Key);
  const isLocAddButtonEnabled = !selectedCityLabel || locationList.length !== 0;
  const isEditLocBtnEnabled = selectedlocationId !== "";
  const isEditLocSaveBtnEnabled = editedLocName !== "";
  //add city
  const handleAddCity = () => {
    AddCity(
      setCityLoading,
      accessToken,
      adminId,
      cityName,
      locationList,
      cityIcon,
      setCityAlertMsg,
      setCityAdded,
      setShowAddCityPopUp,
      setCityName,
      setLocationList,
      setCityAddErr,
      setCityIcon,
      fetchCityLocation
    );
  };

  //edit city
  const handleEditCity = () => {
    let editCityPayload;

    //initial stage,when we are not selecting city (not hitting change event)
    if (Object.keys(cityIcon).length === 0 && !selectedCityIconKey) {
      editCityPayload = {
        admin_id: adminId,
        city_id: selectedCityId,
        city_name: cityName || selectedCityLabel,
        city_icon: fetchedcityIcon
      };
    }
    //when not uploading city icon(no base64)
    //but selecting city (hitting change event) ,updating name
    else if (Object.keys(cityIcon).length === 0 && selectedCityIconKey) {
      editCityPayload = {
        admin_id: adminId,
        city_id: selectedCityId,
        city_name: cityName || selectedCityLabel,
        city_icon: {
          Key: selectedCityIconKey,
          Bucket: selectedCityBucket
        }
      };
    }
    //while uploding icon (base64)
    else if (Object.keys(cityIcon).length !== 0) {
      editCityPayload = {
        admin_id: adminId,
        city_id: selectedCityId,
        city_name: cityName || selectedCityLabel,
        city_icon: cityIcon
      };
    }

    setCityEditLoading(true);
    fetch(`${ApiBaseUrl}/fr-admin/edit-city`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken
      },
      body: JSON.stringify(editCityPayload)
    })
      .then((response) => {
        return response.json();
      })
      .then((resdata) => {
        //console.log('Response from server:', resdata);
        setCityEditLoading(false);

        if (resdata.success) {
          fetchCityLocation();

          setCityEditAlertMsg(resdata.data);
          setCityEdited(true);
          setTimeout(() => {
            setCityEditAlertMsg("");

            setCityEdited(false);
            setShowEditCityPopup(false);

            setCityName("");
            setCityIcon({});
            // setLocationList([])
            // setCityAddErr({})
          }, 3000);
        } else if (!resdata.success) {
          setCityEditAlertMsg(resdata.data);
          setCityEditErr(true);
          setTimeout(() => {
            setCityEditAlertMsg("");
            setCityEditErr(false);
          }, 3000);
        }
      })
      .catch((error) => {
        console.error("error details:", error);
      });
  };

  //add location
  const handleAddLocationTodB = () => {
    AddLocationTodB(
      adminId,
      selectedCityId,
      selectedCityLabel,
      locationList,
      selectedCityBucket,
      selectedCityIconKey,
      setAddLocLoading,
      accessToken,
      setLocAddAlertMsg,
      setlocationAdded,
      setShowAddLocPopup,
      fetchCityLocation,
      setLocationList,
      setLocAddErr
    );
  };

  useEffect(() => {
    if (propertyId) {
      fetchPropertryDetails();
    }
  }, [cityLocationData]);

  //console.log(cityIcon ,"cityIcon")
  // console.log(fetchedcityIcon,selectedCityIconKey,selectedCityBucket)

  //add property basics
  const [propertyName, setPropertyName] = useState("");
  const [propertyType, setPropertyType] = useState("");

  const [builderName, setBuilderName] = useState("");
  const [builderLogo, setBuilderLogo] = useState({});
  const [builderIconErr, setBuildericonErr] = useState("");
  const [propSaleType, setPropSaleType] = useState("");
  const [propPhase, setPropPhase] = useState("");
  const [propBaseAddLoading, setpropBaseAddLoading] = useState(false);
  const [propBaseAlertMsg, setPropBaseAlertMsg] = useState("");
  const [propBaseAdded, setPropBaseAdded] = useState(false);
  const [propBaseErr, setPropBaseErr] = useState(false);
  const [isLoading, setLoading] = useState(false); //property details fetch loader

  const fetchPropertryDetails = () => {
    getPropertyDetails(
      setLoading,
      propertyId,
      adminId,
      accessToken,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      setPropertyName,
      setPropertyType,
      setBuilderName,
      setselectedCityId,
      setSelectedlocationId,
      setLocations,
      cityLocationData,
      setBuilderLogo,
      setPropSaleType,
      setPropPhase,
      undefined,
      undefined,
      setFloorNo,
      setblockNo,
      setUnitNo,
      setDirection,
      setConfig,
      setPropNameOnly
    );
  };

  //builder Icon change
  const handleBuilderIconChange = (e) => {
    const file = e.target.files[0];
    // const size = file.size;

    if (file) {
      const fileTypeParts = file.type.split("/");
      const fileExtension = fileTypeParts.length === 2 ? fileTypeParts[1] : "";

      const maxSize = 5 * 1024 * 1024;

      if (file.type !== "image/jpeg" && file.type !== "image/png") {
        setBuildericonErr("Only JPG or PNG formats are allowed");
      } else if (file.size > maxSize) {
        setBuildericonErr("File size exceeds 5 MB limit");
      } else {
        setBuildericonErr("");

        const reader = new FileReader();
        reader.onload = (event) => {
          const base64String = event.target.result;
          // console.log((base64String));

          setBuilderLogo({
            extension: fileExtension,
            file_type: file.type,
            base64: base64String
          });
        };
        reader.readAsDataURL(file);
      }
    }
  };
  const isPropertyBasicSaveBtnEnabled =
    propertyName !== "" &&
    propertyType !== "" &&
    builderName !== "" &&
    propSaleType !== "" &&
    propPhase !== "" &&
    selectedCityId !== "" &&
    selectedlocationId !== "" &&
    (builderLogo.base64 !== undefined || builderLogo?.Key !== "");

  const handleAddPropertyBasics = () => {
    AddPropertyBasics(
      adminId,
      propertyName,
      propertyType,
      builderName,
      builderLogo,
      propSaleType,
      propPhase,
      selectedCityId,
      selectedlocationId,
      setpropBaseAddLoading,
      accessToken,
      setPropBaseAlertMsg,
      setPropBaseAdded,
      setPropBaseErr,
      propertyId,
      undefined,
      propNameOnly,
      floorNo,
      blockNo,
      unitNo,
      direction,
      configuration
    );
  };

  const handleEditLocation = () => {
    const editLocationBody = {
      admin_id: adminId,
      location_id: selectedlocationId,
      location_name: editedLocName
    };
    setEditLocLoading(true);
    fetch(`${ApiBaseUrl}/fr-admin/edit-location`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken
      },
      body: JSON.stringify(editLocationBody)
    })
      .then((response) => {
        return response.json();
      })
      .then((resdata) => {
        //console.log('Response from server:', resdata);

        setEditLocLoading(false);
        if (resdata.success) {
          fetchCityLocation();

          setEditLocAlertMsg(resdata.data);
          setLocationEdited(true);
          setShowEditLocationPopUp(false);
          setTimeout(() => {
            setLocationEdited(false);
            setEditLocAlertMsg("");
            setEditedLocName("");
          }, 3000);
        } else if (!resdata.success) {
          setEditLocAlertMsg(resdata.data);
          setLocEditErr(true);
          setTimeout(() => {
            setLocEditErr(false);
            setEditLocAlertMsg("");
          }, 3000);
        }
      })
      .catch((error) => {
        console.error("error details:", error);
      });
  };
  console.log(MintedToken);

  return (
    <>
      {cityAdded && <SuccessAlert message={cityAlertMsg} />}

      {cityAddErr && <ErrorAlert message={cityAlertMsg} />}

      {/*************  add city pop up ********************/}
      {showAddCityPopUp && (
        <AddCityPopUp
          cityIcon={cityIcon}
          cityIconError={cityIconError}
          cityName={cityName}
          handleAddCity={handleAddCity}
          handleAddLocation={handleAddLocation}
          handleCityIconChange={handleCityIconChange}
          handleCloseAddCityPopUp={handleCloseAddCityPopUp}
          handleRemoveLocation={handleRemoveLocation}
          isCityLoading={isCityLoading}
          isSaveButtonDisabled={isSaveButtonDisabled}
          locationList={locationList}
          newLocation={newLocation}
          setCityName={setCityName}
          setNewLocation={setNewLocation}
        />
      )}

      {/****************  edit city pop up ********************/}

      {cityEdited && <SuccessAlert message={CityEditAlertMsg} />}

      {cityEditErr && <ErrorAlert message={CityEditAlertMsg} />}

      {showeditCityPopup && (
        <EditCityPopUp
          isCityEditLoading={isCityEditLoading}
          isEditSaveBtnEnabled={isEditSaveBtnEnabled}
          handleEditCity={handleEditCity}
          handleCloseEditCityPopUp={handleCloseEditCityPopUp}
          selectedCityLabel={selectedCityLabel}
          handleCityIconChange={handleCityIconChange}
          cityIcon={cityIcon}
          selectedCityIconKey={selectedCityIconKey}
          cityIconError={cityIconError}
          cityName={cityName}
          setCityName={setCityName}
          cityLocationData={cityLocationData}
          selectedCityId={selectedCityId}
          fetchedcityIcon={fetchedcityIcon}
        />
      )}

      {/***************  add-location popoup *******************/}
      {locationAdded && <SuccessAlert message={locAddAlertMsg} />}

      {locAddErr && <ErrorAlert message={locAddAlertMsg} />}

      {showAddLocPopup && (
        <AddLocationPopup
          addlocLoading={addlocLoading}
          isLocAddButtonEnabled={isLocAddButtonEnabled}
          handleAddLocationTodB={handleAddLocationTodB}
          handleCloseAddLocPopUp={handleCloseAddLocPopUp}
          selectedCityId={selectedCityId}
          handleCityChange={handleCityChange}
          cityLocationData={cityLocationData}
          newLocation={newLocation}
          setNewLocation={setNewLocation}
          locationList={locationList}
          handleAddLocation={handleAddLocation}
          handleRemoveLocation={handleRemoveLocation}
        />
      )}

      {/*******  edit location ***************/}
      {locationedited && <SuccessAlert message={editLocAlertMsg} />}

      {locationEditErr && <ErrorAlert message={editLocAlertMsg} />}

      {showEditLocationPopUp && (
        <PopUp maxWidth={"max-w-xl"}>
          {editLocloading && <FloatingLoader />}

          <div className="flex flex-col gap-4">
            <div className="flex justify-between">
              <p className="text-blue text-lg font-bold">Edit Location*</p>

              <div className="flex gap-4 items-center">
                <button
                  className={`${
                    isEditLocSaveBtnEnabled
                      ? " text-white bg-pink"
                      : "text-gray bg-lightgray"
                  } rounded-lg text-xs px-2 py-1`}
                  disabled={!isEditLocSaveBtnEnabled}
                  onClick={handleEditLocation}
                >
                  Save Details
                </button>
                <IoMdClose
                  className="text-2xl cursor-pointer"
                  onClick={handleCloseEditLocPopup}
                />
              </div>
            </div>
            <hr className="prophrClass" />

            <div className="flex gap-5">
              <div className="flex flex-col gap-2.5 items-start popUpinput">
                <p className="text-sm text-blue">
                  Location <span style={{ color: "#af2525" }}>*</span>{" "}
                </p>

                {!selectedlocation.location_id ? (
                  <p className="text-blue font-bold">
                    {
                      locations.find(
                        (el) => el.location_id === selectedlocationId
                      ).location_name
                    }
                  </p>
                ) : (
                  <p className="text-blue font-bold">
                    {selectedlocation && selectedlocation.location_name}
                  </p>
                )}
              </div>
            </div>

            <div className="flex gap-5">
              <div className="flex flex-col gap-2.5 items-start popUpinput">
                <p className="text-sm text-blue">
                  New Location <span style={{ color: "#af2525" }}>*</span>{" "}
                </p>

                <input
                  type="text"
                  name=""
                  value={editedLocName}
                  onChange={(e) => setEditedLocName(e.target.value)}
                  className="propInputText"
                  placeholder="Brookefield Mall"
                />
              </div>
            </div>
          </div>
        </PopUp>
      )}

      <div className="propCard">
        {/* sec-1 */}
        {propBaseAddLoading && <FloatingLoader />}

        {/* fetch property details loader */}
        {isLoading && <FloatingLoader />}

        {propBaseAdded && <SuccessAlert message={propBaseAlertMsg} />}

        {propBaseErr && <ErrorAlert message={propBaseAlertMsg} />}

        <div className="flex justify-between items-center">
          <p className="text-2xl font-medium text-gray">Property</p>

          <CustomButton
            onClick={handleAddPropertyBasics}
            isDisabled={MintedToken > 0 || !isPropertyBasicSaveBtnEnabled}
            buttonText={"Save Details"}
            disabledMessage="Editing is no longer available. The token has already been minted"
            className="propSaveBtn"
            position="top"
            MintedToken={MintedToken}
          />
        </div>

        {/* sec-2 */}
        <div className="flex justify-between">
          <div className="flex flex-col gap-4 propDetails">
            {/* <div className='flex gap-2 py-3  items-center'>
             <FaRegCircleUser className='text-gray' />
             <p className='text-gray text-lg font-medium'>PROPERTY ID </p>
          </div> */}

            <div className="flex justify-between">
              <div className="flex flex-col gap-2.5 items-start propInputDiv">
                <p className="text-sm text-blue">
                  Property Display Name{" "}
                  <span style={{ color: "#af2525" }}>*</span>{" "}
                </p>
                <input
                  type="text"
                  name=""
                  value={propertyName}
                  onChange={(e) => setPropertyName(e.target.value)}
                  className="propInputText"
                  placeholder="E.g BLK 7-1005, Vascon Tulips Gold"
                />
              </div>

              <div className="flex flex-col gap-2.5 items-start propInputDiv">
                <p className="text-sm text-blue">
                  Property Type <span style={{ color: "#af2525" }}>*</span>{" "}
                </p>
                <select
                  name=""
                  id=""
                  className="propSelectInput"
                  value={propertyType}
                  onChange={(e) => setPropertyType(e.target.value)}
                >
                  <option disabled selected value="">
                    Select
                  </option>

                  <option value="Commercial">Commercial</option>
                  <option value="Residence">Residential</option>
                  <option value="Plots">Plots</option>
                </select>
              </div>

              <div className="flex flex-col gap-2.5 items-start propInputDiv">
                <p className="text-sm text-blue">
                  Builder Name <span style={{ color: "#af2525" }}>*</span>{" "}
                </p>
                <input
                  type="text"
                  name=""
                  value={builderName}
                  onChange={(e) => setBuilderName(e.target.value)}
                  className="propInputText"
                  placeholder="E.g Vascon Tulips"
                />
              </div>
            </div>

            <div className="flex justify-end">
              <div className="flex flex-col gap-2.5 items-start propInputDiv">
                <div className="flex gap-2.5 items-center">
                  <div className="builderLogoSec">
                    <label className="builderLogolabel" for="file">
                      <span className="material-symbols-outlined">
                        {" "}
                        publish
                      </span>
                    </label>
                    <input
                      id="file"
                      type="file"
                      onChange={handleBuilderIconChange}
                    />
                    {/* <img src={`${PropertImageBaseUrl}/${builder_logo && builder_logo.Key}`} alt="Builder" /> */}
                    {builderLogo && builderLogo?.base64 && (
                      <img
                        className="profile-img"
                        src={builderLogo.base64}
                        alt="Avatar"
                      />
                    )}

                    {builderLogo && builderLogo?.Key && (
                      <img
                        className="profile-img"
                        src={`${PropertImageBaseUrl}/${builderLogo?.Key}`}
                        alt="Avatar"
                      />
                    )}
                  </div>

                  <p className="text-sm text-blue">
                    Builder Logo <span style={{ color: "#000" }}>*</span>
                  </p>
                </div>
                <p>{builderIconErr !== "" && builderIconErr}</p>
              </div>
            </div>
          </div>
        </div>

        {/* sec-3 */}

        <div className="flex flex-col gap-4 propDetails">
          <div className="flex justify-between">
            <div className="flex flex-col gap-2.5 items-start propInputDiv">
              <p className="text-sm text-blue">
                Property Name <span style={{ color: "#af2525" }}>*</span>{" "}
              </p>
              <input
                type="text"
                name=""
                value={propNameOnly}
                onChange={(e) => setPropNameOnly(e.target.value)}
                className="propInputText"
                placeholder="E.g Vascon Tulips Gold"
              />
            </div>

            <div className="flex flex-col gap-2.5 items-start propInputDiv">
              <div className="flex w-full justify-between">
                <p className="text-sm text-blue">
                  City<span style={{ color: "#af2525" }}>*</span>{" "}
                </p>
                <div className="flex gap-2.5">
                  <button
                    className={`bg-white rounded text-xs px-1 flex items-center`}
                    onClick={handleShowEditCityPopup}
                    disabled={selectedCityId === ""}
                  >
                    <span
                      className={`${
                        selectedCityId === "" ? " text-gray" : " text-pink"
                      } material-symbols-outlined`}
                    >
                      edit
                    </span>
                  </button>

                  <button
                    className="bg-white  rounded text-xs px-1 flex items-center"
                    onClick={handleShowAddCityPopup}
                  >
                    <span className="material-symbols-outlined text-pink">
                      add_circle
                    </span>
                  </button>
                </div>
              </div>

              <select
                name=""
                onChange={handleCityChange}
                value={selectedCityId}
                id=""
                className="propSelectInput"
              >
                <option value="" selected disabled>
                  Select
                </option>
                {cityLocationData &&
                  cityLocationData.map((city) => (
                    <option key={city.city_id} value={city.city_id}>
                      {city.city_name}
                    </option>
                  ))}
              </select>
            </div>

            <div className="flex flex-col gap-2.5 items-start propInputDiv">
              <div className="flex w-full justify-between">
                <p className="text-sm text-blue">
                  Location<span style={{ color: "#af2525" }}>*</span>{" "}
                </p>
                <div className="flex gap-2.5">
                  <button
                    className={`bg-white  rounded text-xs px-1 flex items-center`}
                    disabled={!isEditLocBtnEnabled}
                    onClick={handleShowEditLocPopup}
                  >
                    <span
                      className={`${
                        !isEditLocBtnEnabled ? "text-gray" : " text-pink"
                      } material-symbols-outlined`}
                    >
                      edit
                    </span>
                  </button>
                  <button
                    className={`bg-white  rounded text-xs px-1 flex items-center`}
                    disabled={selectedCityId === ""}
                    onClick={handleShowAddLocPopup}
                  >
                    <span
                      className={`${
                        selectedCityId === "" ? "text-gray" : " text-pink"
                      }  material-symbols-outlined `}
                    >
                      add_circle
                    </span>
                  </button>
                </div>
              </div>

              <select
                name=""
                id=""
                className="propSelectInput"
                value={selectedlocationId}
                onChange={handleLocationChange}
              >
                <option disabled selected value="">
                  Select
                </option>
                {locations &&
                  locations.map((el) => (
                    <option key={el.location_id} value={el.location_id}>
                      {el.location_name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>

        <div className=" propDetails">
          <div className="grid grid-cols-3 gap-5">
            <div className="flex flex-col gap-2.5 items-start ">
              <p className="text-sm text-blue">
                Property Sale Type<span style={{ color: "#af2525" }}>*</span>{" "}
              </p>
              <select
                name=""
                id=""
                value={propSaleType}
                onChange={(e) => setPropSaleType(e.target.value)}
                className="propSelectInput"
              >
                <option disabled selected value="">
                  Select
                </option>
                <option value="OPEN">Open</option>
                <option value="LAUNCHING">Launching</option>
                <option value="SOLD OUT">Sold out</option>
                <option value="RESALE">Resale </option>
              </select>
            </div>

            <div className="flex flex-col gap-2.5 items-start ">
              <p className="text-sm text-blue">
                Property Phase<span style={{ color: "#af2525" }}>*</span>{" "}
              </p>
              <select
                name=""
                id=""
                value={propPhase}
                onChange={(e) => setPropPhase(e.target.value)}
                className="propSelectInput"
              >
                <option disabled selected value="">
                  Select
                </option>
                <option value="Presale">Presale</option>
                <option value="Public Sale">Public Sale</option>
              </select>
            </div>

            {(propertyType === "Commercial" ||
              propertyType === "Residence") && (
              <div className="flex flex-col gap-2.5 items-start ">
                <p className="text-sm text-blue">Floor Number </p>
                <input
                  type="text"
                  name=""
                  value={floorNo}
                  onChange={(e) => setFloorNo(e.target.value)}
                  className="propInputText"
                  placeholder="E.g 1"
                />
              </div>
            )}

            {(propertyType === "Commercial" ||
              propertyType === "Residence") && (
              <div className="flex flex-col gap-2.5 items-start ">
                <p className="text-sm text-blue">Block Number </p>
                <input
                  type="text"
                  name=""
                  value={blockNo}
                  onChange={(e) => setblockNo(e.target.value)}
                  className="propInputText"
                  placeholder="E.g 1"
                />
              </div>
            )}

            {(propertyType === "Commercial" ||
              propertyType === "Residence" ||
              propertyType === "Plots") && (
              <div className="flex flex-col gap-2.5 items-start ">
                <p className="text-sm text-blue">Unit Number </p>
                <input
                  type="text"
                  name=""
                  value={unitNo}
                  onChange={(e) => setUnitNo(e.target.value)}
                  className="propInputText"
                  placeholder="E.g 105"
                />
              </div>
            )}

            {propertyType === "Residence" && (
              <div className="flex flex-col gap-2.5 items-start ">
                <p className="text-sm text-blue">Configuration </p>
                <input
                  type="text"
                  name=""
                  value={configuration}
                  onChange={(e) => setConfig(e.target.value)}
                  className="propInputText"
                  placeholder="E.g BHK 1"
                />
              </div>
            )}

            <div className="flex flex-col gap-2.5 items-start ">
              <p className="text-sm text-blue">Direction</p>
              <select
                value={direction}
                onChange={(e) => setDirection(e.target.value)}
                className="propSelectInput"
              >
                <option disabled selected value="">
                  Select
                </option>
                <option value="north">North (N)</option>
                <option value="north-east">North East (NE)</option>
                <option value="east">East (E)</option>
                <option value="south-east">South East (SE) </option>
                <option value="south">South (S)</option>
                <option value="south-west">South West (SW)</option>
                <option value="west">West (W)</option>
                <option value="north-west">North West (NW)</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
