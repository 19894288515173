import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext/AuthContext';

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const { accesstoken } = useAuth();
//if admin is already loggedin redirect to dahsboard page else login page
  useEffect(() => {
    if (localStorage.getItem("accesstoken")) {
      navigate("/dashboard");
    } else {
      navigate("/");
    }
  }, [navigate, accesstoken]);

  return children;
};

export default ProtectedRoute;
