import React from 'react'
import { IoIosClose } from 'react-icons/io'

export default function TaxesChargesCard(props) {

const {wallet,bank_account,invoice_name,invoice_price,onDelete}=props

  return (
    <div className='chargescard'>
    <div className='flex justify-end text-2xl cursor-pointer'>
    <IoIosClose className='text-gray ' onClick={onDelete} />
    </div>
    <div className='flex flex-col gap-3 text-left'>
        <p className='text-xs text-gray'>Name : <span className='font-bold'>
            {invoice_name && invoice_name}</span> </p>

            <p className='text-xs text-gray'>Price : <span className='font-bold'>
                {invoice_price && invoice_price}%</span> </p>

                <p className='text-xs text-gray'>Wallet :
                 <span className='font-bold'>{wallet && wallet}</span> </p>

                 <p className='text-xs text-gray'>Account :
                 <span className='font-bold'>{bank_account && bank_account}</span> </p>
    </div>

</div>
  )
}
