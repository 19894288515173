import React,{useState,useEffect, useRef} from 'react'
import CardImage from '../../../Assets/images/manTrans.svg'
import { HiDotsHorizontal } from "react-icons/hi";
import View from '../../../Assets/images/eyeAdmin.svg'
import Edit from '../../../Assets/images/editAdmin.svg'
import Delete from '../../../Assets/images/deleteAdmin.svg'
import { MdOutlineContentCopy, MdOutlineRemoveRedEye } from 'react-icons/md';
import { IoCheckmarkSharp } from 'react-icons/io5';
import {PropertImageBaseUrl} from '../../../Utils/urls'
import './Transaction.css'
import { FaUser } from 'react-icons/fa6';
import { Link, useLocation } from 'react-router-dom';
import { TruncatedText } from '../../../Utils/TruncatedString';


function TransactionCard(props) {
    const [showopt, setshowopt] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    const [imageUrl, setimageUrl] = useState("")
    const location=useLocation()

    const queryParams = new URLSearchParams(location.search);
    const pageFromParam = parseInt(queryParams.get('page')) || 1;

    useEffect(() => {
        setimageUrl(props.image);
    }, []);


    const copyToClipboard = () => {
        try {
          navigator.clipboard.writeText(props.transactionId);
          setIsCopied(true);
          setTimeout(() => {
            setIsCopied(false); 
          }, 3000);
        } catch (error) {
          console.error('Error copying to clipboard:', error);
          // Handle the error here, e.g., show a message to the user
        }
      };
      
      

  
      const handleShowActionOpitons=()=>{
        setshowopt(!showopt)
      }
      
      const optionsRef = useRef(null);
      
      useEffect(() => {
         const handleClickOutside = (event) => {
           if (optionsRef.current && !optionsRef.current.contains(event.target)) {
            setshowopt(false);
           }
         };
      
         document.addEventListener('mousedown', handleClickOutside);
         return () => {
           document.removeEventListener('mousedown', handleClickOutside);
         };
       }, []);



      
       const returnSerialNo = (pageFromParam) => {
        if (props.isSearchActive) {
          return props.serialNumber; // Reset serial number during search
        } else {
          if (pageFromParam > 1) {
            return (props.serialNumber + ((pageFromParam - 1) * 5));
          } else {
            return props.serialNumber;
          }
        }
      };


  return (
    <div>
      
    <div className={`flex flex-row justify-between cardParentTransaction ${showopt?"":""} `} style={{zIndex:"99"}}>
    <div className=' flex flex-col justify-center  w-3'>{returnSerialNo(pageFromParam)}.</div>

    <div className=' flex flex-col justify-center  w-24' style={{color:"#79767D"}}>  {props.transactionDate ? props.transactionDate.substring(0, 10) : ""}</div>

    <div className=' flex flex-col justify-center  w-32   ' style={{color:"#79767D"}}>
    <div className='flex gap-2 items-center '>

  


    <p className='text-sm font-semibold'>
    {/* <div className="transactiontooltip">
            Transaction ID is copied!
        </div> */}
  
    {isCopied ? (
      
        <div className="transactiontooltip">
            Transaction ID is copied!
        </div>
    ) : (
        <p>{props.transactionId.substring(0, 10)}..</p>
    )}
    </p>
    <button className="copyButton" onClick={() => copyToClipboard(props.transactionId)}>
        {
           isCopied? (
           <div className='flex gap-2 items-center'><IoCheckmarkSharp /> 
            <p className='text-xs'> Transaction ID is copied!</p> </div>
            ):  <MdOutlineContentCopy />
        }
  
    
    </button>


</div></div>

    <div className=' flex flex-col justify-center  w-60' style={{color:"#79767D"}}>{props.propertyName}</div>

    <div className=' flex flex-col justify-center pl-2  w-25  text-center' style={{color:"#79767D"}}>₹{Number(props.totalPrice).toLocaleString()}</div>

    <div className=' flex flex-row gap-2 w-48 pl-6 '>
      <div className='flex flex-col justify-center '>
       <div className=' photoTransCard'>
       {props.profilePic?.Key ? (
        <img
          src={`${PropertImageBaseUrl}/${props.profilePic?.Key}`}
          className='cardImageCollect w-full h-full cover '
        />
      )
      :

    
      <FaUser />
    
    }
       </div>
      </div>
      <div className=' flex flex-col justify-center'>{(props.firstName && TruncatedText(props.firstName,12)) || "User"}</div>
    </div>



    <div className='flex flex-col justify-center  w-24 '>
      <div className={`verifiedCard flex flex-col justify-center ${props.transactionStatus==="COMPLETED"? "completedStatus":
      props.transactionStatus==="PENDING"?"pendingStatus":
      props.transactionStatus==="CANCEL"?"cancelStatus":""
    }`}  style={{height:"32px",width:"90px"}}> {props.transactionStatus}</div>
    </div>






    <div className='flex flex-col justify-center relative ' style={{width:"50px",height:""}}>
     {/* 
     Add this onClick once, menu is functional.
      onClick={()=>setshowopt(!showopt)}
      */}
      <div className={`relative `}  onClick={()=>setshowopt(!showopt)}
      ><HiDotsHorizontal style={{width:"30px",height:"30px",}}/>
      
      {
        showopt && (
<div ref={optionsRef} className='absolute bg-white flex flex-col gap-[5px] shadow-md p-2 rounded-md z-[9999]'>
<Link to={`/transaction/${props.id}`}>
  <div className='actionBtn viewBtnP '>
            <MdOutlineRemoveRedEye />
            <p>View</p> 
        </div>
  </Link>
      </div>
        )
      }
      
    </div>
     
    </div>
    


  </div>

    </div>
  )
}

export default TransactionCard
