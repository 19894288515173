import React, { useRef, useState } from 'react'
import DocumentCard from './DocumentCard'
import { BiDotsHorizontalRounded } from 'react-icons/bi'
import { MdDelete } from 'react-icons/md'
import { useEffect } from 'react'

export default function DocumentCategoryCard(props) {


const{index,
    category,
    documents,
    
    documentsList,
    setDocumentsList,
    setPdfArray,
    pdfArray,
    
    }=props

    const addNewDocument = () => {
        const newDocument = {
            "docs": [],
            "title": "",
            "description": null
        }
        const updatedCategories = [...documentsList]
        updatedCategories[index].documents.push(newDocument)
        setDocumentsList(updatedCategories)
    }

    const updateDocument = (categoryIndex, documentIndex, field, value) => {
        const updatedCategories = [...documentsList]
        updatedCategories[categoryIndex].documents[documentIndex][field] = value
        setDocumentsList(updatedCategories)
    }

    const deleteDocument = (documentIndex) => {
        const updatedCategories = [...documentsList];
        updatedCategories[index].documents.splice(documentIndex, 1);
        setDocumentsList(updatedCategories);
    };

    const deleteAllDocuments = () => {
        const updatedCategories = [...documentsList];
        const documents = updatedCategories[index].documents;
        const lastDocument = documents[documents.length - 1];
        documents.splice(0, documents.length - 1); // Remove all but keep the last one
        documents[0] = {  // Reset the values of the last document
            "docs": [],
            "title": "",
            "description": null
        };
        setDocumentsList(updatedCategories);
    };


    const [showOption,setShowOption]=useState(false)
const optionsRef = useRef(null);
const handleShowOption=()=>{
   setShowOption(true)
}

useEffect(() => {
   const handleClickOutside = (event) => {
     if (optionsRef.current && !optionsRef.current.contains(event.target)) {
       setShowOption(false);
     }
   };

   document.addEventListener('mousedown', handleClickOutside);
   return () => {
     document.removeEventListener('mousedown', handleClickOutside);
   };
 }, []);

 const deleteCategoryCard = () => {
  const updatedCategories = [...documentsList];
  updatedCategories.splice(index, 1);
  setDocumentsList(updatedCategories);
};


// console.log(documents)
  return (
    <div className='border rounded-lg border-lightgray p-3 documentCategoryCard'>

    <div className='flex flex-col gap-2.5' style={{width:"430px"}}>
    
    <div className='flex flex-col gap-2'>
        <div className='flex justify-between items-center'>


        <p className='text-start text-sm text-gray' >Document Category Name</p>
    

        <div className='flex justify-end relative' >
   <div onClick={handleShowOption}  className='cursor-pointer p-1'>
   <BiDotsHorizontalRounded />
   </div>

{
   showOption && index!=0 && (
<div ref={optionsRef} className='flex flex-col gap-3 shadow absolute top-4 rounded-xl p-2.5 bg-white'>


<div className='delBtnTenant' onClick={deleteCategoryCard}>

<p className='text-delred  flex gap-2 items-center text-sm'
 >
<MdDelete />Delete</p>

</div>


</div>

   )
}


</div>
        </div>

    
        
    
        <input type="text" 
        className='amenInputText' 
        value={category && category}

        onChange={(e) => {
            const updatedCategories = [...documentsList]
            updatedCategories[index].category = e.target.value
            setDocumentsList(updatedCategories)
        }} 
         placeholder='E.g Property Documents' />
    </div>
    
    
    <div className='flex gap-5'>
    
    <div className='documentForm flex flex-col gap-3'>
    
    
    
    
    <div className='flex flex-col rounded-lg border  border-lightgray py-4 px-5'>
    
    <div  className='flex gap-2.5 justify-end'>
          <button  onClick={deleteAllDocuments} className='bg-pink px-2.5 py-1.5 rounded-[4px] text-white text-xs' >
            Delete All
            </button>
            <button onClick={addNewDocument} className='bg-pink px-2.5 py-1.5 rounded-[4px] text-white text-xs' >
           Add More
            </button>
          
    </div>
    
    
    
    <div className='flex flex-col gap-2.5'>
    
    <div className='flex flex-col gap-1.5'>
    
      
    
    {/* added amenities */}
    
    <div className='flex flex-col gap-1.5 addedList'>
    
     {
        documents && documents.map((el,ind)=>(
            <DocumentCard
               key={ind}
                {...el} 
                ind={ind}
                updateDocument={updateDocument}
                categoryIndex={index} 
                documentIndex={ind} 
                setPdfArray={setPdfArray}
                pdfArray={pdfArray}

                documentsList={documentsList}
                setDocumentsList={setDocumentsList}
                deleteDocument={deleteDocument}
                />
        ))
     }
    
   
    
    
    
    
    </div>
    
    
    
    
    
    </div>
    
    
    </div>
    
    
    </div>
    
          </div>
    
    
    
    
    
    
    </div>
    
    
    
    
    
    </div>
    
    </div>
  )
}
