import React, { useEffect, useState } from "react";
import "../taxes-charges.css";
import { IoIosClose, IoMdClose } from "react-icons/io";

import { useParams } from "react-router-dom";
import { ApiBaseUrl } from "../../../Utils/urls";
import SuccessAlert from "../../Alert/SuccessAlert";
import ErrorAlert from "../../Alert/ErrorAlert";
import FloatingLoader from "../../Loader/FloatingLoader";
import TaxesChargesCard from "../Card/TaxesChargesCard";
import PopUp from "../../PopUp/PopUp";
import CustomButton from "../../Common/CustomeButton";

export default function EditTaxesCharges(props) {
  const [showAddChargesPopup, setShowAddChargesPopUp] = useState(false);
  const adminId = localStorage.getItem("adminId");
  const accessToken = localStorage.getItem("accesstoken");
  const [isLoading, setLoading] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [isTaxesAdded, setTaxesAdded] = useState(false);
  const [isTaxAddErr, setTaxAddErr] = useState(false);

  const [taxesData, setTaxesData] = useState([]);

  const { id: propertyId } = useParams();
  const { MintedToken } = props;

  const handleShowPopup = () => {
    setShowAddChargesPopUp(true);
  };
  const handleClosePopup = () => {
    setShowAddChargesPopUp(false);
  };

  const [taxesState, setTaxesState] = useState({
    wallet: "",
    bank_account: "",
    invoice_name: "",
    invoice_price: "",
    is_percentage: true
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setTaxesState((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleAddTaxFunction = (processedData) => {
    setLoading(true);
    fetch(`${ApiBaseUrl}/fr-admin/add-invoice-fr-property`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken
      },
      body: JSON.stringify(processedData)
    })
      .then((response) => {
        return response.json();
      })
      .then((resdata) => {
        console.log("Response from server:", resdata);

        setLoading(false);
        if (resdata.success) {
          setAlertMsg(resdata.data.msg);
          setTaxesAdded(true);
          setShowAddChargesPopUp(false);
          fetchPropertyDetails();
          setTimeout(() => {
            setTaxesAdded(false);
            setAlertMsg("");
            fetchPropertyDetails();
          }, 3000);
        } else if (!resdata.success) {
          setAlertMsg(resdata.data);
          setTaxAddErr(true);
          setTimeout(() => {
            setTaxAddErr(false);
            setAlertMsg("");
          }, 3000);
        }
      })
      .catch((error) => {
        console.error("error details:", error);
      });
  };

  const handleAddTaxCharges = () => {
    const processedState = [...taxesData, taxesState];

    setTaxesData(processedState);

    setShowAddChargesPopUp(false);
  };

  const handleAddTaxChargestoServer = () => {
    const processedData = {
      admin_id: adminId,
      property_id: Number(propertyId),
      invoice_details: taxesData.map((item) => ({
        ...item,
        invoice_price: Number(item?.invoice_price)
      }))
    };

    handleAddTaxFunction(processedData);
    setShowAddChargesPopUp(false);
  };

  const handleDeleteTaxCharge = (index) => {
    const updatedTaxesData = taxesData.filter((_, i) => i !== index);
    setTaxesData(updatedTaxesData);
  };

  const fetchPropertyDetails = () => {
    fetch(
      `${ApiBaseUrl}/fr-admin/get-property-details?property_id=${propertyId}&admin_id=${adminId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: accessToken
        }
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((resdata) => {
        //console.log('Response from server:', resdata);

        setLoading(false);
        if (resdata.success) {
          setTaxesData(resdata.data.invoice_info[0].invoice_details);
        } else if (!resdata.success) {
        }
      })
      .catch((error) => {
        console.error("error details:", error);
      });
  };

  useEffect(() => {
    fetchPropertyDetails();
  }, []);

  return (
    <>
      {isTaxesAdded && <SuccessAlert message={alertMsg} />}

      {isTaxAddErr && <ErrorAlert message={alertMsg} />}

      {showAddChargesPopup && (
        <PopUp maxWidth={"max-w-xl"}>
          {isLoading && <FloatingLoader />}

          <div className="flex flex-col gap-4">
            <div className="flex justify-between">
              <p className="text-blue text-lg font-bold">Additional Charges*</p>

              <div className="flex gap-4 items-center">
                <button
                  //className={`${isEditLocSaveBtnEnabled?' text-white bg-pink':'text-gray bg-lightgray'} rounded-lg text-xs px-2 py-1`}
                  onClick={handleAddTaxCharges}
                >
                  Add Details
                </button>
                <IoMdClose
                  className="text-2xl cursor-pointer"
                  onClick={handleClosePopup}
                />
              </div>
            </div>
            <hr className="prophrClass" />

            <div className="flex gap-5">
              <div className="flex flex-col gap-2.5 items-start popUpinput">
                <p className="text-sm text-blue">
                  Name <span style={{ color: "#af2525" }}>*</span>{" "}
                </p>

                <input
                  type="text"
                  name="invoice_name"
                  value={taxesState.invoice_name}
                  onChange={handleChange}
                  className="propInputText"
                  placeholder="GST"
                />
              </div>

              <div className="flex flex-col gap-2.5 items-start popUpinput">
                <p className="text-sm text-blue">
                  Price <span style={{ color: "#af2525" }}>*</span>{" "}
                </p>

                <input
                  type="text"
                  name="invoice_price"
                  value={taxesState.invoice_price}
                  onChange={handleChange}
                  className="propInputText"
                  placeholder="E.g 2.5"
                />
              </div>
            </div>

            <div className="flex gap-5">
              <div className="flex flex-col gap-2.5 items-start popUpinput">
                <p className="text-sm text-blue">
                  Account <span style={{ color: "#af2525" }}>*</span>{" "}
                </p>

                <input
                  type="text"
                  name="bank_account"
                  value={taxesState.bank_account}
                  onChange={handleChange}
                  className="propInputText"
                  placeholder="Brookefield Mall"
                />
              </div>

              <div className="flex flex-col gap-2.5 items-start popUpinput">
                <p className="text-sm text-blue">
                  Wallet <span style={{ color: "#af2525" }}>*</span>{" "}
                </p>

                <input
                  type="text"
                  name="wallet"
                  value={taxesState.wallet}
                  onChange={handleChange}
                  className="propInputText"
                  placeholder="E.g 2.5"
                />
              </div>
            </div>
          </div>
        </PopUp>
      )}

      <div className="propCard">
        <div className="flex justify-between items-center">
          <p className="text-lg font-medium text-black">Taxes and Charges</p>
          <div className="flex gap-4">
            <CustomButton
              onClick={handleShowPopup}
              isDisabled={MintedToken > 0}
              buttonText={"Add Charges"}
              disabledMessage="Editing is no longer available. The token has already been minted"
              className="propSaveBtn"
              position="top"
              MintedToken={MintedToken}
            />

            <button
              //disabled={!isSaveBtnEnabled}
              //className={`propSaveBtn ${isSaveBtnEnabled ? 'bg-pink text-white' : 'bg-lightgray text-gray'}`}
              onClick={handleAddTaxChargestoServer}
            >
              Save Details
            </button>
          </div>
        </div>

        <div className="flex gap-4">
          <div className="flex flex-col gap-2.5 w-full">
            <p className="font-bold text-blue text-lg text-left">
              Additional Charges*
            </p>

            <div className="bg-lightgray flex gap-3 rounded-md px-4 py-3 w-full">
              {taxesData && taxesData.length == 0 ? (
                <p>No Taxes is added yet</p>
              ) : (
                taxesData &&
                taxesData.map((el, index) => (
                  <TaxesChargesCard
                    key={index}
                    {...el}
                    onDelete={() => handleDeleteTaxCharge(index)}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
