import React, { useEffect, useState } from 'react'
import AdminContainer from '../AdminContainer/AdminContainer'
import UserAccount from './UserAccount'
import "./single-user.css"
import UserAml from './UserAml'
import BankDetails from './BankDetails'
import UserNominee from './UserNominee'
import { ApiBaseUrl } from '../../Utils/urls'
import {  useParams } from 'react-router-dom'
import { FetchUserDetails } from '../../Api/UserApi'
import UserTransaction from './UserTransaction/UserTransaction'

export default function SingleUser() {
  const [userDetails,setUserDetails]=useState({})
  const [userAml,setUserAml]=useState([])
  const [isLoading,setLoading]=useState(false)
  const [bankDetails,setBankDetails]=useState([])
  const [nomineeDetails,setNomineeDetails]=useState([])
  const accesstoken=localStorage.getItem('accesstoken')
  const adminId=localStorage.getItem("adminId")
const {id}=useParams()

  const getUserDetails=()=>{

    FetchUserDetails(
      setLoading,
      id,
      setUserDetails,
      setUserAml,
      setBankDetails,
      setNomineeDetails,
      accesstoken,
      adminId
    )
  }

  useEffect(()=>{
    getUserDetails()
  },[id])

  return (
    <AdminContainer 
    pageTitle={userDetails?.profile?.first_name || "User" } 
    subtitle="User Management / Users Account" 
    backLinkPath={`/user`}
    >



<div className='container font-nunito'>


  <div className='flex flex-col p-5 gap-2.5'>
    <UserAccount isLoading={isLoading} userDetails={userDetails} />

<UserAml isLoading={isLoading} userAmlDetails={userAml}/>

<BankDetails bankDetails={bankDetails} isLoading={isLoading}/>

<UserNominee isLoading={isLoading} nomineeDetails={nomineeDetails}/>
<UserTransaction userDetails={userDetails}/>

  </div>



</div>



        
        </AdminContainer>
  )
}
