import React, { useEffect, useRef, useState } from 'react'

import { BsThreeDotsVertical } from 'react-icons/bs'
import { MdDelete } from 'react-icons/md'
import { PropertImageBaseUrl } from '../../../Utils/urls'

export default function EditAmenitiesCard(prop) {

    const {amenity_id,title,icon_url,deleteAmenity}=prop
    const [showOption,setShowOption]=useState(false)

    const handleDeleteClick = () => {
        deleteAmenity(amenity_id);
      };
const optionsRef = useRef(null);
const handleShowOption=()=>{
   setShowOption(true)
}

useEffect(() => {
   const handleClickOutside = (event) => {
     if (optionsRef.current && !optionsRef.current.contains(event.target)) {
       setShowOption(false);
     }
   };

   document.addEventListener('mousedown', handleClickOutside);
   return () => {
     document.removeEventListener('mousedown', handleClickOutside);
   };
 }, []);
  return (

  
    <div className='flex justify-between  items-center gap-5'>
    

  <input type="text" 
  className='amenInputText' 
  value={title}
   placeholder='E.g Swimming Pool' />


<div className='flex gap-2.5 items-center'>


{/* <div class="amenitiesImg">
           <label class="amenImglabel" for="file">
              <span class="material-symbols-outlined"> publish</span>
           </label>
        <input id="file" type="file"/>
           
   </div> */}

<div className='noAmenImg'>
<img src={`${PropertImageBaseUrl}/${icon_url?.Key}`} alt="" />
</div>

<div className='relative'>
<BsThreeDotsVertical className='cursor-pointer' onClick={handleShowOption} />
    
{
   showOption && (
<div ref={optionsRef}  className='flex flex-col gap-3  z-50 shadow absolute top-4 rounded-xl p-2.5 bg-white'>
<div className='delBtnTenant'>

<p className='text-delred  flex gap-2 items-center text-sm' onClick={handleDeleteClick}>
<MdDelete />Delete</p>

</div>
</div>



   )
}



</div>

</div>



</div>
  )
}
