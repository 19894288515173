import React from 'react';
import { FaWindowClose } from 'react-icons/fa';
import "./AlertStyle.css"

const ErrorAlert = ({ message }) => {
  return (
    
     
        <div className='error-alert'>
          <div className='custom-alert' role='alert'>
            <div className='flex gap-2 items-center'>
              <FaWindowClose style={{ color: '#F44336', fontSize: '24px' }} />
              <p>{message}</p>
            </div>
          </div>
        </div>
      
    
  );
};

export default ErrorAlert;
