import React, { useEffect, useState } from 'react'
import AdminContainer from '../../AdminContainer/AdminContainer'
import AdminDetails from './AdminDetails'
import UserManagementForm from './UserManagementForm'
import "./admin-create.css"
import PropertyManage from './PropertyManage'
import TransactionOverview from './TransactionOverview'
import { countries } from 'countries-list'
import { ApiBaseUrl } from '../../../Utils/urls'
import loader from "../../../Assets/Icons/Loader.gif"
import SuccessAlert from '../../Alert/SuccessAlert'
import ErrorAlert from '../../Alert/ErrorAlert'
import { useNavigate } from 'react-router-dom'

export default function AdminCreate() {

const accessToken=localStorage.getItem("accesstoken")
const adminId=localStorage.getItem("adminId")

//profile img
const [selectedProfilePic, setSelectedProfilePic] = useState({});
const [fileError, setFileError] = useState('');

const [enableSaveBtn,setEnableSaveBtn]=useState(false)
const [selected, setSelected] = useState("IN");
const [isLoading,setLoading]=useState(false)
const [alertMsg,setAlertMsg]=useState("")
const [showSuccessAlert,setShowSucAlert]=useState(false)
const [showErrAlert,setShowErrAlert]=useState(false)

const [isLengthValid, setIsLengthValid] = useState(false);
const [hasUpperCase, setHasUpperCase] = useState(false);
const [hasSymbolOrNumber, setHasSymbolOrNumber] = useState(false);
const [confirmpassword,setConfirmPass]=useState("")
const [isConfirmPassCorrect,setConfPassCorrect]=useState(false)
const [passwordMismatch, setPasswordMismatch] = useState(false);
const [isPassValid,setPassValid]=useState(false)

const [adminData, setAdminData] = useState({   
  "admin_id":adminId,
 "profile":{
     "country_code":"",
     "mobile":"",
     "email":"",
     "name": "",
     "dob": null, //"2003-06-09", else null
     "gender":null, //"Male", "Female","Other",
     "active":false,//true,false
     "profile_pic":{},
     "password":""
 },
 "permissions":{
         "view_user_profile": false,
         "edit_user_profile":false,
         "create_new_user": false,
         "delete_user": false,
         "view_property":false,
         "edit_property":false,
         "create_new_property":false,
         "delete_property":false,
         "transactions_access":false,
         "overview_access": false,
         "add_admin":false,
         "edit_admin":false,
         "delete_admin": false,
         "view_admin":false
 }
});


const navigate=useNavigate()

const validatePassword = (password) => {
  setIsLengthValid(password.length >= 8);
  setHasUpperCase(/[A-Z]/.test(password));
  setHasSymbolOrNumber(/[\d!@#$%^&*(),.?":{}|<>]/.test(password));
  
};

useEffect(()=>{

if(isLengthValid && hasSymbolOrNumber && hasUpperCase && (confirmpassword===adminData.profile.password))
  {
    setPassValid(true)
  }
  else{
    setPassValid(false)
  }
},[hasSymbolOrNumber,isLengthValid,hasUpperCase,confirmpassword,adminData])

const changeConfirmPass =(e)=>{
  const { value } = e.target;
  setConfirmPass(value);
  validateConfirmPass(value);
  
}


const validateConfirmPass=(value)=>{
  if (adminData.profile.password === value) {
    setConfPassCorrect(true);
    setPasswordMismatch(false);
   
  } else {
    setConfPassCorrect(false);
    setPasswordMismatch(true);
  }
  
}



const customLabels = {};


for (const countryCode in countries) {
  const countryData = countries[countryCode];
  const primary = countryData.name;
  const secondary = '+' + countryData.phone[0]; 
  
  customLabels[countryCode] = { primary, secondary };
}




const handleInputChange = (e) => {
  const { name, value, type, checked, dataset: { section } } = e.target;
  const finalValue = type === 'checkbox' ? checked : value;
  //password
  if (name === 'password') {
    validatePassword(value);

    if(confirmpassword!=="")
      {
        if(value!==confirmpassword){
          setPasswordMismatch(true);
        }
        else{
          setPasswordMismatch(false)
        }
      }
         
      if (confirmpassword === value) {
        setConfPassCorrect(true);
       
       
      } else {
        setConfPassCorrect(false);
        
      }
      
  }

  setAdminData(prevState => {
    return {
      ...prevState,
      [section]: {
        ...prevState[section],
        [name]: finalValue
      }
    };
  });

};


const handleProfilePicChange = (e) => {
  const file = e.target.files[0];

  if (file) {
    const fileTypeParts = file.type.split('/');
    const fileExtension = fileTypeParts.length === 2 ? fileTypeParts[1] : '';

    const maxSize = 5 * 1024 * 1024;

    if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
      setFileError('Only JPG or PNG formats are allowed');
    } else if (file.size > maxSize) {
      setFileError('File size exceeds 5 MB limit');
    } else {
      setFileError('');
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target.result;
        setSelectedProfilePic({
          extension: fileExtension,
          file_type: file.type,
          base64: base64String,
        });

        setAdminData((prevState) => ({
          ...prevState,
          profile: {
            ...prevState.profile,
            profile_pic: {
              extension: fileExtension,
              file_type: file.type,
              base64: base64String,
            },
          },
        }));
      };
      reader.readAsDataURL(file);
    }
  }
};

useEffect(() => {
  if (
    adminData.profile.mobile !== "" && 
    adminData.profile.email !== "" &&
    adminData.profile.name !== "" &&
    adminData.profile.gender !== "" &&
    adminData.profile.password!=="" &&
    isPassValid) {
      
    setEnableSaveBtn(true);
  } else {
    setEnableSaveBtn(false);
  }
}, [adminData,isPassValid]);




const handleAddAdmin=()=>{
  setLoading(true)
  setEnableSaveBtn(false)
  fetch(`${ApiBaseUrl}/fr-admin/add-admin`,
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': accessToken
    },
    body: JSON.stringify({
      ...adminData,
      profile:{
        ...adminData.profile,
        country_code:customLabels[selected].secondary
      }
    })
  }
)
    .then(response => {
    
     
      return response.json();
    })
    .then(resdata => {
     
     console.log('Response from server:', resdata);
     
        if(resdata.success)
        {

          setLoading(false)
          setEnableSaveBtn(true)

          setAlertMsg(resdata.data)
          setShowSucAlert(true)
          setTimeout(() => {
            setAlertMsg("")
            setShowSucAlert(false)
            navigate("/adminlist")
          }, 2000);
        }
        else if(!resdata.success)
          {
            setLoading(false)
          setEnableSaveBtn(true)
          setAlertMsg(resdata.data)
          setShowErrAlert(true)
          setTimeout(() => {
            setAlertMsg("")
            setShowErrAlert(false)
            
          }, 3000);
          }


    })
    .catch(error => {
     
      console.error('error details:', error);
    });
}




  return (
    <AdminContainer 
    pageTitle="Add  Admin" 
    subtitle="Admin Management/" 
    backLinkPath="/adminlist" >
{
  isLoading && (
<div className='adminLoaderContainer'>
  <img src={loader} alt="" className='adminloader' />
</div>
  )

}

{
  showSuccessAlert && (
    <SuccessAlert message={alertMsg}/>
  )
}

{
  showErrAlert && (
    <ErrorAlert message={alertMsg} />
  )
}

<div className='container font-nunito'>


  <div className='flex flex-col p-5 gap-2.5'>

  <div className='flex justify-end'>
  <button 
  className={`propSaveBtn ${enableSaveBtn?'bg-pink text-white':'bg-lightgray text-gray'} `}
   disabled={!enableSaveBtn}
   onClick={handleAddAdmin}
   
   >
    {isLoading?'Saving....':'Save'}
    
    </button>
  </div>
  
  <AdminDetails 
  adminData={adminData} 
  handleInputChange={handleInputChange}
  fileError={fileError} 
  handleProfilePicChange={handleProfilePicChange}
  setAdminData={setAdminData}
  setSelected={setSelected}
  selected={selected}
  customLabels={customLabels}
  isLengthValid={isLengthValid}
  hasSymbolOrNumber={hasSymbolOrNumber}
  hasUpperCase={hasUpperCase}
changeConfirmPass ={changeConfirmPass}
confirmpassword={confirmpassword}
isConfirmPassCorrect={isConfirmPassCorrect}
passwordMismatch={passwordMismatch}
  />

  <div className='flex justify-between'>
    <UserManagementForm adminData={adminData} setAdminData={setAdminData} handleInputChange={handleInputChange}/>
    <PropertyManage adminData={adminData} setAdminData={setAdminData} handleInputChange={handleInputChange}/>

  </div>

  <TransactionOverview adminData={adminData} setAdminData={setAdminData} handleInputChange={handleInputChange}/>


</div>
  </div>   
   </AdminContainer>


  )
}
