import React, { useState } from 'react'
import { IoEyeOutline } from 'react-icons/io5';
import Switch from 'react-switch';
import CustomeCheckbox from '../CustomeCheckbox';
import { MdOutlineModeEdit } from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';


export default function TransactionOverview({adminData,handleInputChange,setAdminData}) {


    const [permissionsCheckedTrans, setPermissionsCheckedTrans] = useState(false);
    const [permissionsCheckedOverview, setPermissionsCheckedOverview] = useState(false);

    const handleTransPermissionsSwitchChange = (checked) => {
        setPermissionsCheckedTrans(checked);

        setAdminData(prevState => ({
            ...prevState,
            permissions: {
                ...prevState.permissions,
                transactions_access: checked,
             }
        }));
        
      };

      const handleOverviewPermissionsSwitchChange = (checked) => {
        setPermissionsCheckedOverview(checked);

        setAdminData(prevState => ({
            ...prevState,
            permissions: {
                ...prevState.permissions,
                overview_access: checked,
             }
        }));
        
      };
   
    //   console.log(adminData)
  return (

    <div className='flex justify-between'>
        {/* transaction */}
    <div className='UserManageformSect font-nunito'>
<div className='flex justify-between items-center'>
<p className='text-2xl font-medium'>Transactions</p>
<Switch
             checked={permissionsCheckedTrans}
             onChange={handleTransPermissionsSwitchChange}
            offColor="#888" 
            onColor="#419E6A"
            uncheckedIcon={false}  
            checkedIcon={false}
            width={36}
            height={20}
            handleDiameter={13.5}
            
            
          />
</div>

<p className='font-bold text-lg text-left'>Transactions Section Permission</p>



    </div>

{/* overview */}
<div className='UserManageformSect font-nunito'>
<div className='flex justify-between items-center'>
<p className='text-2xl font-medium'>Overview</p>
<Switch
             checked={permissionsCheckedOverview}
             onChange={handleOverviewPermissionsSwitchChange}
            offColor="#888" 
            onColor="#419E6A"
            uncheckedIcon={false}  
            checkedIcon={false}
            width={36}
            height={20}
            handleDiameter={13.5}
            
            
          />
</div>

<p className='font-bold text-lg text-left'>Overview Section Permission</p>



    </div>

    </div>
  )
}
