import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import {TransactionFiltersProvider} from './Utils/Transactionfilters'
import { UserFiltersProvider } from './Utils/UserAccountFilter';
import { UserTransactionFiltersProvider } from './Utils/UserTransactionfilters';
import { PropertyFiltersProvider } from './Utils/PropertyFilter';
import { AuthProvider } from './Components/AuthContext/AuthContext';
import { AdminAccessProvider } from './Components/AdminAccessContext/AdminAccessContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

 
     <BrowserRouter>
     
     <AuthProvider>
     
     <PropertyFiltersProvider>
     <UserFiltersProvider>
      {/* <UserTransactionFiltersProvider> */}

     
     <TransactionFiltersProvider>
      
      <App />
    </TransactionFiltersProvider>
    {/* </UserTransactionFiltersProvider> */}
    </UserFiltersProvider>
    </PropertyFiltersProvider>
   
    </AuthProvider>
    </BrowserRouter>


);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
