import React, { useState } from 'react'
import { CiEdit } from 'react-icons/ci'
import { FaRegCircleUser } from 'react-icons/fa6'
import { MdOutlineCameraAlt } from 'react-icons/md'
import Switch from 'react-switch'; 

export default function UserAml({userAmlDetails,isLoading}) {

    const [checked, setChecked] = useState(false);

 
    const handleChange = (checked) => {
      setChecked(checked); 
    };

let amlDetails
if(userAmlDetails!=0)
  {
    amlDetails=userAmlDetails[0]
  }
  else{
    amlDetails=userAmlDetails
  }

  return (
    <div className='propCard'>

    {/* sec-1 */}
    
      <div className='flex justify-between items-center'>
        <p className='text-2xl font-medium'>AML Declaration</p>
        <button className='propSaveBtn bg-lightgray text-gray' disabled>Edit</button>
      </div>

      <div className='flex justify-between'>
    
    <div className='flex flex-col gap-4 UserDetailsSec'>




<div className='flex justify-between'>


<div className='flex flex-col gap-2.5 items-start UserInputDiv'>
              <p className='text-sm text-blue'>Occupation <span style={{color:"#af2525"}}>*</span> </p>
             {/* <select name="" id="" className='propSelectInput'>
              <option disabled selected>Select</option>
              <option value="" >Option1</option>
            
             </select> */}
                 <input type="text" 
                  name="" 
                  disabled
                  className='propDisableInputText'
                  placeholder='E.g- Software Developer'
                  value={amlDetails?.occupation}
                   />
          </div>


          <div className='flex flex-col gap-2.5 items-start UserInputDiv'>
              <p className='text-sm text-blue'>Source of Income <span style={{color:"#af2525"}}>*</span> </p>
             {/* <select name="" id="" className='propSelectInput'>
              <option disabled selected>Select</option>
              <option value="" >Option1</option>
            
             </select> */}

          <input type="text" 
                  name="" 
                  disabled
                  className='propDisableInputText'
                  placeholder='E.g- Salary & Bonus'
                  value={amlDetails?.source_of_income}
                   />
          </div>

</div>






<div className='flex justify-between'>

<div className='flex flex-col gap-2.5 items-start UserInputDiv'>
              <p className='text-sm text-blue'>Are You A Politically Exposed Person (PEP)? </p>
              <Switch
            checked={amlDetails?.is_pep==1}
            //onChange={handleChange}
            offColor="#888" 
            onColor="#419E6A"
            uncheckedIcon={false}  
            checkedIcon={false}
            width={36}
            height={20}
            handleDiameter={13.5}
            disabled
          />


          </div>



<div className='flex flex-col gap-2.5 items-start UserInputDiv'>
              <p className='text-sm text-blue'>Please explain in brief </p>
             
             <textarea 
             value={amlDetails?.pep_info || ""} disabled
             cols="30" rows="4" className='propDisableInputText'></textarea>
          </div>
</div>


    </div>




  </div>






      </div>
  )
}
