import React from 'react'
import "./user-trans.css"

export default function NoTransaction({message}) {
  return (
    <div className='noTransContainer'>
    <hr className='hrTransac'/>
    
    <div className='noTransactionImg'></div>
    <p className='noTransactionText text-gray'>{message}</p>
    
    </div>
  )
}
