import React, { useEffect, useState } from 'react'
import { MdOutlineRemoveRedEye } from 'react-icons/md'

import { IoClose } from 'react-icons/io5';
import "../paymentSchedule-style.css"
import { fetchPaymentSchedule } from '../../../Api/PaymentScheduleApi';
import { changeDateFormatAdmin, formatDateWithMonthName } from '../../../Utils/dateFormat';
import { ApiBaseUrl } from '../../../Utils/urls';
import PopUp from '../../PopUp/PopUp';
import FloatingLoader from '../../Loader/FloatingLoader';
import SuccessAlert from '../../Alert/SuccessAlert';
import ErrorAlert from '../../Alert/ErrorAlert';
import EditPaymentSchCard from './EditPaymentSchCard';
import EditPaymentNoteCard from './EditPaymentNoteCard';
import { useParams } from 'react-router-dom';


export default function EditPaymentSchedule() {
  const accessToken=localStorage.getItem("accesstoken")
  const adminId=localStorage.getItem("adminId")
  const {id:propertyId}=useParams()

  const [isLoading,setLoading]=useState(false)
  const [paymentSchedule,setPaymentSchedule]=useState({})
  const [paymentNote,setPaymentNote]=useState("")
  const [paymentConditions,setPaymentConditions]=useState([""])
  const [showPaymentViewPopup,setShowPaymentViewPopup]=useState(false)
  const [isPaymentAdded,setPaymentAdded]=useState(false)
  const [alertMsg,setAlertMsg]=useState("")
  const [isPaymentErr,setPaymentErr]=useState(false)
  const [payments,setPayments]=useState([ {
    "amount": "",
    "schedule_order": 0,
    "due_date": "Immediately on Purchase",
    "is_immediate": 0,
    "amount_percent": "",
    "description":""
}])

const [paymentAddLoading,setPayemntAddLoading]=useState(false)

const getPaymentSchedule=()=>{
fetchPaymentSchedule(
  setLoading,
  propertyId,
  setPaymentSchedule,
  setPayments,
  setPaymentNote,
  setPaymentConditions)
}

const handleUpdatePaymentSchedule=()=>{
  const updatedPayments = payments.map(payment => {
  //  const updatedDescription = payment.description ? payment.description.replace(/\n/g, '') : '';
    if (payment.schedule_order === 1) {
      return { ...payment,
        amount: parseFloat(payment.amount),
        amount_percent: parseFloat(payment.amount_percent),
        //description: updatedDescription
       }; // Skip changing the date format for schedule_order 1
    } else {
      return {
        ...payment,
        amount: parseFloat(payment.amount),
        amount_percent: parseFloat(payment.amount_percent),
        due_date: changeDateFormatAdmin(payment.due_date) ,
        //description: updatedDescription
      };
    }
  });

  setPayemntAddLoading(true)
  fetch(`${ApiBaseUrl}/fr-admin/add-payment-schedule`,
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': accessToken
    },
    body: JSON.stringify({
     
      "admin_id": adminId,
    "property_id":propertyId,
    "schedules": updatedPayments,
    "payment_schedule_conditions":paymentConditions,
    "payment_schedule_note":paymentNote
    })
  }
)
    .then(response => {
    
     
     
      return response.json();
    })
    .then(resdata => {
     
     //console.log('Response from server:', resdata);
     setPayemntAddLoading(false)
        if(resdata.success)
          
        {
            setPaymentAdded(true)
            getPaymentSchedule()
            setAlertMsg(resdata.data)
          setTimeout(() => {
            setPaymentAdded(false)
            setAlertMsg("")
          }, 3000);
        }
        else if(!resdata.success)
          {
            setPaymentErr(true)
            
            setAlertMsg(resdata.data)
          setTimeout(() => {
            setPaymentErr(false)
            setAlertMsg("")
          }, 3000);
          }

    })
    .catch(error => {
     
      console.error('error details:', error);
      
    });
}

const handleShowPaymentViewPopup=()=>{
  setShowPaymentViewPopup(true)
}
const handleClosePaymentViewPopup=()=>{
  setShowPaymentViewPopup(false)
}

useEffect(()=>{
if(propertyId)
  {
    getPaymentSchedule()
  }
},[])


const addMorePaymentCard = () => {
  setPayments([
    ...payments,
    {
      property_id: propertyId,
      amount: '',
      schedule_order: payments.length + 1,
      due_date: '',
      is_immediate: 0,
      amount_percent: '',
      description: ''
    }
  ]);
};
const handleInputChange = (index, field, value) => {
  const newPayments = [...payments];
  newPayments[index][field] = value;
  setPayments(newPayments);
};


const handleAddPaymentCondition = () => {
  setPaymentConditions([...paymentConditions, ""]);
};


const handleDeletePaymentCondition = (index) => {
  const newPaymentConditions = paymentConditions.filter((_, i) => i !== index);
  setPaymentConditions(newPaymentConditions);
};
const handlePaymentConditionChange = (index, value) => {
  const newPaymentConditions = [...paymentConditions];
  newPaymentConditions[index] = value;
  setPaymentConditions(newPaymentConditions);
};
const handleDeletePaymentSchCard = (index) => {
  const newPayments = [...payments];
  newPayments.splice(index, 1);
  setPayments(newPayments);
};

const calculateFinalTokenPrice = () => {
  let totalAmount = 0;
  payments.forEach(payment => {
      totalAmount += parseFloat(payment.amount || 0); // If amount is undefined or not a number, add 0
  });
  return totalAmount.toLocaleString();
};
const isAllFieldsFilled =  payments?.every(el =>
  el.amount && el.due_date && el.amount_percent  && el.description
);

const handleDeleteAllPayments = () => {
  const resetFirstPayment = {
    ...payments[0],
    amount: payments[0].amount,
    schedule_order: 1,
    due_date: 'Immediately on Purchase',
    is_immediate: 0,
    amount_percent: '',
    description: '',
  };
  setPayments([resetFirstPayment]);
};


  return (
<>

{
  showPaymentViewPopup && (
<PopUp maxWidth={'max-w-3xl'}>
<div  className='flex flex-col gap-3 h-[500px] overflow-y-auto'>
<div className='flex justify-between'>
    <p className='font-bold text-blue text-2xl'>Preview</p>
    <div className='text-black bg-lightgray w-6 h-6 flex justify-center cursor-pointer items-center rounded-full' 
    onClick={handleClosePaymentViewPopup}>
    <IoClose />
    </div>
  
</div>




<div className="flex font-nunito flex-col sm:flex-row gap-3.5 tab-shadow py-5  sm:py-5 sm:px-4 rounded-2xl">
      
      <div className='flex flex-col'>

     
      
      
       <p className='text-lg text-left mb-3'>
        <b>Vascon Tulips Gold Coimbatore</b>  <br />
        <span className='text-sm'>3BHK, {"block"}, {"unit"}</span> 
         </p>





<div className='flex flex-col'>

<div className='paymentTableHead'>
                <div>SNo.</div>
                <div className='descCol'>Description</div>
                {/* <div className='qtyCol'>Qty (BHK)</div>
                <div className='unitCol'>Unit (sqf)</div> */}
                <div className='priceCol'>Amount (IN ₹) </div>
                <div className='collectiondateCol'>Collection Dates</div>
            </div>
           
          

<div className='breakDownDiv'>


{
  payments && payments.map((el,index)=>(

    <>

<div className='breakDownSec pt-2'>
               
               <div className='slNosec'>{index+1}.</div>
               <div  className='descSec' style={{ whiteSpace: 'pre-line' }}>
               {/* <p>1st Payment Schedule</p> */}
              {el.description}
               </div>
            
           
               <div className='priceCol'>₹ {el.amount.toLocaleString()}</div>
           
               <div className='collectiondateCol'>
           
               <div className='flex flex-col'>
                <p>{
                index==0?
                el.due_date:
               formatDateWithMonthName(el.due_date)
                }</p>
               
               </div>
           
               </div>
           
            </div>
                <hr className='paymenthrClass' />
    </>
 
  ))
}


{/* sec-1 */}
           


            </div>



{/* final token section*/}
<div  className='finalTokenSection'>

<div className="finalTitle">
Final Unit Token Price (Approximate)
</div>
<div className="finalAmount">
₹ {paymentSchedule && paymentSchedule.total_unit_price.toLocaleString()}
</div>

</div>



<div className='paymentNoteSec'>
NOTE: { paymentNote && paymentNote}
</div>

<div className='generalCondition'>
<p className='text-gray text-base font-semibold text-left'>Please Note General Conditions :</p>

<div className='conditionsSec'>
    {
      paymentConditions && paymentConditions.map((el,ind)=>(
      <p>{ind+1}. {el}</p>
      ))
    }    


</div>

</div>

</div>

       
            
            
        


           
       

        </div>
        </div>

</div>












</PopUp>
  )
}

   <div className='flex flex-col gap-5 relative'>
    {
      isLoading && (
        <FloatingLoader/>
      )
    }
  {
    paymentAddLoading && (
      <FloatingLoader/>
    )
  }
  {
    isPaymentAdded && (
      <SuccessAlert message={alertMsg}  />
    )
  }

{
    isPaymentErr && (
      <ErrorAlert message={alertMsg}  />
    )
  }




    <div className='flex flex-col gap-5 relative'>


<div className='flex justify-between items-center'>
  <p className='text-gray text-lg font-medium'>Payment Schedule*</p>

  <div className='flex gap-5'>


  <button 
  className={`propSaveBtn flex gap-2 items-center  bg-pink text-white `}
  onClick={handleShowPaymentViewPopup}
 >
  <p>Preview</p>

  <MdOutlineRemoveRedEye className='text-xl' />
    </button>


  <button 
 className={`propSaveBtn  ${isAllFieldsFilled?'bg-pink text-white':'bg-lightgray text-gray'} `}
 disabled={!isAllFieldsFilled}
  onClick={handleUpdatePaymentSchedule}
  >
    Save Details</button>
  </div>

</div>

</div>

<div className='border rounded-lg border-lightgray p-2.5 flex flex-col'>
<div  className='flex gap-3 justify-end mb-4'>
<button className='bg-pink px-2.5 py-1.5 rounded-[4px] text-white text-xs' onClick={handleDeleteAllPayments}>
           Delete all
            </button>
            <button   onClick={addMorePaymentCard} className='bg-pink px-2.5 py-1.5 rounded-[4px] text-white text-xs' >
           Add More
            </button>
          
    </div>
    

    <div className='flex flex-col gap-5'>

{/* payment schedule card */}


{
  payments && payments.map((el,index)=>(
<EditPaymentSchCard
key={index}
{...el}
index={index}
handleInputChange={handleInputChange}
handleDelete={handleDeletePaymentSchCard}
/>
  ))
}




  <div className='flex justify-between'>
    
    <div className='flex flex-col gap-4 propDetails'>

   </div>

 </div>

 </div>
</div>






<div className='flex justify-between'>
    
    <div className='flex flex-col gap-4 propDetails'>

    

      <div className='flex gap-5'>
          <div className='flex flex-col gap-2.5 items-start propInputDiv'>
              <p className='text-sm text-blue'>Final Unit Token Price (Approximate) <span style={{color:"#af2525"}}>*</span> </p>
            
          </div>

          <div className='flex flex-col gap-2.5 items-start propInputDiv'>
             
              <input type="text" 
              name="" 
             
              value={calculateFinalTokenPrice()}
              className='propInputText'
              placeholder='E.g 59,650'
             />
          </div>

         
      </div>



    

      <div className='flex gap-5'>
          <div className='flex flex-col gap-2.5 items-start propInputDiv'>
              <p className='text-sm text-blue'>Note<span style={{color:"#af2525"}}>*</span> </p>
          


          </div>

          <div className='flex flex-col gap-2.5 items-start propInputDiv'>
             
          <textarea name="" id=""  
          value={paymentNote && paymentNote}
          onChange={(e)=>setPaymentNote(e.target.value)}
          rows={5} className='propInputText'  
             placeholder='E.g He above indicated amounts are excluding 
             PropFTX Platform Charges plus Corresponding GST.'
             ></textarea>
          </div>

         
      </div>



      <div className='flex flex-col gap-5 p-3 border border-lightgray rounded-md'>
          <div className='flex justify-between gap-2.5 items-center '>
              <p className='text-sm text-blue font-semibold'>Please Note General Conditions :<span style={{color:"#af2525"}}>*</span> </p>
          
<button className='bg-pink text-white text-sm rounded-md px-5 py-1'
 onClick={handleAddPaymentCondition}>Add
 </button>

          </div>

        
          <div className='flex flex-col w-full gap-1.5 projectSpecList'>
{
  paymentConditions && paymentConditions.map((el,index)=>(
<EditPaymentNoteCard
 key={index}
 condition={el}
 index={index}
handleDelete={handleDeletePaymentCondition}
handleChange={(value) => handlePaymentConditionChange(index, value)}
/>
  ))
}



</div>        
         





     

         
      </div>


    </div>





  </div>


   </div>
   </>

  )
}
