import React from 'react';
import { IoCloseOutline } from 'react-icons/io5';



const PopUp = ({ children, closeModal ,maxWidth ,showCloseIcon }) => {

  //max-w-xl
  return (




<div className="fixed inset-0 flex items-center px-3 bg-modalbg justify-center  overflow-x-hidden overflow-y-auto outline-none focus:outline-none"  
style={{zIndex:999}}
>
<div className={`relative  my-6 w-full mx-auto ${maxWidth}`}>
  {
    showCloseIcon && (
<div className="modal-close-btn absolute top-0 right-0 mt-2 mr-2 cursor-pointer" onClick={closeModal} style={{zIndex:"999"}}>
    <IoCloseOutline style={{color:"#4a4646", fontSize:"24px"}} />
  </div>
    )
  }
  
  <div className="relative p-4 transform overflow-hidden rounded-lg bg-white shadow-xl">
    {children}
  </div>
</div>
</div>


  );
};

export default PopUp;
