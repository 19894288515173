import React,{useState,useMemo,useEffect} from 'react'
import AdminContainer from '../AdminContainer/AdminContainer'
import { IoIosClose, IoIosSearch } from "react-icons/io";
import "./transaction-style.css"
import { MdOutlineFileDownload, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import CardImage from '../../Assets/images/manTrans.svg'
import { FaCaretDown } from "react-icons/fa";
import { HiDotsHorizontal } from "react-icons/hi";
import View from '../../Assets/images/eyeAdmin.svg'
import Edit from '../../Assets/images/editAdmin.svg'
import Delete from '../../Assets/images/deleteAdmin.svg'
import TransactionCard from '../Cards/TransactionCard/Transaction';
import { ApiBaseUrl } from '../../Utils/urls';
import { MdSort } from "react-icons/md";
import { MdOutlineFilterAlt } from "react-icons/md";
import TransactionFilter from '../TransactionFilter/Filter';
import {useTransactionFilters,initialPropertyFiltersTransaction, fromDateString, toDateString} from '../../Utils/Transactionfilters'
import { debounce } from 'lodash';
import { BeatLoader } from 'react-spinners'
import ReactPaginate from 'react-paginate';
import { useLocation, useNavigate } from 'react-router-dom';
import useDebounce from '../../Utils/Debounce';
import TransactionSort from '../TransactionFilter/TransactionSort';
import { ISODate } from '../../Utils/ISODate';


export default function Transaction() {
  const [showopt, setshowopt] = useState(true);
  const [isLoading,setLoading]=useState(false)
  const [clearFilterSelection, setclearFilterSelection] = useState(false);
  const [isOpenMob, setIsOpenMob] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [responseData, setResponseData] = useState([]);
  const [isOneSecondOver, setisOneSecondOver] = useState(false);
  const accessToken=localStorage.getItem("accesstoken")
  const adminId=localStorage.getItem("adminId")
  const [filtersChanged, setFiltersChanged] = useState(false);

  const [toDate,setToDate]=useState("")
  const [fromDate,setFromDate]=useState("")
  const [isSearchActive, setIsSearchActive] = useState(false);

  const [showTransactionSort,setShowTransactionSort]=useState(false)
  //pagination

  const [toTalPages,setTotalPages]=useState(0)
  const togglePopup = () => {
    setIsOpenMob(!isOpenMob);
  };
 
  const { TransactionpropertyFilters, setTransactionPropertyFilters,setPage } = useTransactionFilters();
 
  const navigate=useNavigate()
  const location=useLocation()
  const debounce = useDebounce(); 

  const queryParams = new URLSearchParams(location.search);
  const initialPage = parseInt(queryParams.get('page')) || 1;
useEffect(() => {
  setPage(initialPage); 
  navigate(`/transaction?page=${initialPage}`)
}, [initialPage]);
const [currentPage, setCurrentPage] = useState(initialPage);

  const [TransactionArr, setTransactionArr] = useState(initialPropertyFiltersTransaction);
  

  const handlePageChange = (data) => {
    setFiltersChanged(false);
  
    if (currentPage === 1 && data.selected === 0) {
      // Prevent navigation if already on the first page
      return;
    }
    const newPage = data.selected + 1; 
    
    setPage(newPage); 
  
    setCurrentPage(newPage);
    
      // Update the current page
      navigate(`/transaction?page=${newPage}`); 
     
  };

  useEffect(() => {
    setTransactionArr(TransactionpropertyFilters);
  }, [TransactionpropertyFilters]);

  const [transactionMapper, setTransactionMapper] = useState([]);

  useEffect(() => {
    // Function to make the POST request
    const fetchData = async () => {
      try {
        setLoading(true);
        const pageToFetch = filtersChanged ? 1 : initialPage;
        const response = await fetch(`${ApiBaseUrl}/fr-admin/get-all-purchase-transactions`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': accessToken
          },
          body: JSON.stringify({...TransactionArr,
            admin_id:adminId,
            page:pageToFetch,
           filters:{
            searchInput:searchInput
           }
          
          }),
        });
        const responseData = await response.json();
        if (responseData.success) {
          setTransactionMapper(responseData.data.transactions);
          setResponseData(responseData.data.transactions);
          setTotalPages(responseData.data.total_pages)
          setLoading(false); // Accessing the 'data' property of responseData
        } else {
          console.error('Error fetching data:', responseData.error);
        }
      } catch (error) {
        setLoading(false);
        console.error('Error:', error);
      }
    };

    // Call the function to make the POST request
    fetchData();
  }, [TransactionArr]);

// const handleSearchInputChange = (event) => {
//   const input = event.target.value;
//   setSearchInput(input);
// };

// useEffect(() => {
//   const debouncedSearch = debounce((input) => {
//     const transactionProperties = input.trim() === '' ? responseData : responseData.filter(transaction =>
//       `${transaction.id} ${transaction.transaction_id} ${transaction.transaction_date} ${transaction.total_price} ${transaction.no_of_tokens} ${transaction.property_name} ${transaction.property_city} ${transaction.property_location} ${transaction.property_images} ${transaction.transaction_status} ${transaction.mode_of_payment} ${transaction.first_name} ${transaction.user_id}`
//         .toLowerCase()
//         .includes(input.toLowerCase())
//     );
//     setTransactionMapper(transactionProperties);
//   }, 300);

//   if (searchInput !== '') {
//     debouncedSearch(searchInput);
//   } else {
//     setTransactionMapper(responseData);
//   }

//   return () => {
//     debouncedSearch.cancel();
//   };
// }, [searchInput, responseData]);

const handleDebounceSearch=debounce((value)=>{
    
  setFiltersChanged(true)
  setIsSearchActive(value !== ''); 

  setTransactionArr((prev) => ({
    ...prev,
    page: 1,
    filters: {
      ...prev.filters,
      searchInput: value, 
    },
  }));
},1000)



const handleSearchChange = (e) => {
  const value = e.target.value;
 
  setSearchInput(value); 
  handleDebounceSearch(value); 
};


useEffect(() => {
  const timer = setTimeout(() => {
    setisOneSecondOver(true);
  }, 1500);

  return () => clearTimeout(timer);
}, []); 


const formatDataToCSV = () => {
  //  headers to CSV
  const headers = ['Serial No', 'Date', 'Transaction ID', 'Property Name', 'Price', 'User Name', 'Status'];

  
  // Map transaction data to CSV rows
  const csvRows = transactionMapper.map((transaction, index) => {
    return `${index + 1},${transaction.transaction_date},${transaction.transaction_id},"${transaction.property_name}",${transaction.total_price},${transaction.first_name},${transaction.transaction_status}`;
  });

  // Combine headers + rows
  const csvData = [headers.join(','), ...csvRows].join('\n');

  return csvData;
};


const downloadCSV = () => {
  const csvData = formatDataToCSV();

  // Create Blob object with CSV data
  const blob = new Blob([csvData], { type: 'text/csv' });

  // Create temporary link element
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = 'Transactions_Report.csv';

  // Trigger download
  document.body.appendChild(link);
  link.click();

  // Clean up
  document.body.removeChild(link);
};

const handleShowTransSort=()=>{
  setShowTransactionSort(true)
}


const handleFromChange = (e) => {
  const newFromDate = e.target.value;

  if (newFromDate) {
    const isoFormattedDate = ISODate(newFromDate);

    setFromDate(newFromDate);

    setTransactionArr((prev) => ({
      ...prev,
      
        purchased_date: {
          ...prev.purchased_date,
          from:ISODate(e.target.value)
        
      },
    }));
  };
  }



const handleToChange=(e)=>{
  setToDate(e.target.value)
  
  setTransactionArr((prev) => ({
    ...prev,
    
      purchased_date: {
        ...prev.purchased_date,
        to:ISODate(e.target.value)
      
    },
  }));
  
  }

const isClearButtonenabled=toDate!=="" && fromDate!==""

  const handleClearDate=()=>{
    // setFiltersChanged(true)
    setToDate("")
    setFromDate("")
    setTransactionArr((prev) => ({
      ...prev,
      
        purchased_date: {
         to:toDateString,from:fromDateString
       },
    }));
   }


 

  return (
    <AdminContainer pageTitle="Transactions" subtitle="Finance Management">
        <div className='container font-nunito'>
           <div className=' flex w-full justify-between p-4 gap-7 rounded-[10px] bg-gray2'>


{/* searchbar */}
<div className=" text-gray-600">


<button type="submit" className="border border-black absolute left-0 top-0 mt-3 ml-4">
        {/*
        <IoIosSearch className="h-4 w-4 fill-current" />
      */}
      </button>
      <input
        type="search"
        name="search"
        value={searchInput}
        onChange={handleSearchChange}
        placeholder="Search By User Name , Property Name etc."
        className="bg-white h-10  px-3 pl-10 rounded-2xl text-sm focus:outline-none transSearchBar"
      />
      
      
    </div>
    

<div className='flex gap-5'> 
        {/* <button   onClick={downloadCSV} className='bg-pink rounded-lg flex gap-2.5 py-2.5 px-4 items-start justify-center text-white'>
        <MdOutlineFileDownload className='' />
        <p className='text-sm font-bold font-nunito'>Download Report</p>
        </button> */}


        <button className='bg-white rounded-lg flex  py-1.5 px-3 items-start justify-center text-black'>
                 
                 <input type="date" name="" id=""  value={fromDate}  onChange={handleFromChange}/>
              
                 </button>


                 <button className='bg-white rounded-lg flex gap-2.5 py-1.5 px-3 items-start justify-center text-black'>
                
                 <input type="date" name="" id="" value={toDate} onChange={handleToChange} />
                 </button>


                 <button onClick={handleClearDate} 
         disabled={!isClearButtonenabled}
        className={`${isClearButtonenabled?'bg-pink text-white':'bg-lightgray text-gray'}   rounded-lg flex  py-2 px-2 items-center justify-center text-sm`}
         >
          Clear 
          <IoIosClose  className='text-2xl'/>
          
          </button>
</div>

               



               
            </div>

      
            <div className='flex flex-row justify-end gap-3 mt-[25px]'>
              <div><MdSort style={{height:"25px",width:"25px"}} onClick={handleShowTransSort} className='cursor-pointer' /></div>
              <div><MdOutlineFilterAlt  onClick={togglePopup} style={{height:"25px",width:"25px"}}  className='cursor-pointer'/></div>
            </div>

            <div className={ `rounded-xl popup-containerBookedFilter  ${isOpenMob ? 'active' : ''}`} style={{ boxShadow: '0px 4px 30px 0px #00000026' }}>
            <TransactionFilter clearFilterSelection={clearFilterSelection}  isOpenMob={isOpenMob} setIsOpenMob={setIsOpenMob}/>
           </div>
            

<TransactionSort setShowTransactionSort={setShowTransactionSort} showTransactionSort={showTransactionSort}/>

            <div className=' flex flex-col gap-3 ' style={{padding:"20px",height:"100vh"}}>

            <div className='flex flex-col' style={{width:"100%"}}>


            <div className='mt-[20px] ml-2'>
            <div className='flex flex-row justify-between' style={{width:"100%",height:"29px"}}>
              <div className='w-3'>No</div>
              <div className='w-24'>Date</div>
              <div className='w-32'>Transaction ID</div>
              <div className='w-60'>Property Name</div>
              <div className='w-25 pl-4'>Price</div>
              <div className='w-48'>User Name</div>
              <div className='w-24'>Status</div>
              <div className='text-white'>Helperhelp</div>

            </div>
          </div>


       
        



          {isLoading ? (
            <div className="alertBar" style={{height:"100vh"}}> 
              <BeatLoader color='#D382A7'/>
            </div>
          ) :transactionMapper &&  transactionMapper.length === 0 && isOneSecondOver ? (
            searchInput === '' ? (
              <div className='noPropContainer'>
                <div className='noPropImg'></div>
                <p className='noPropText text-gray mt-12'>No Matching Transactions.</p>
              </div>
            ) : (
              <div className='noPropContainer'>
                <div className='noPropImg'></div>
                <p className='noPropText text-gray mt-12'>No such transactions found</p>
              </div>
            )
          ) : (
            <div className='propertyContainer bg-white flex-wrap gap-5 mt-2'>
              {transactionMapper && transactionMapper.map((transaction, index) => (
                <TransactionCard 
                  key={index}
                  serialNumber={index + 1}
                  id={transaction.id}
                  transactionId={transaction.transaction_id}
                  transactionDate={transaction.transaction_date}
                  totalPrice={transaction.total_price}
                  numberOfTokens={transaction.no_of_tokens}
                  propertyName={transaction.property_name}
                  propertyCity={transaction.property_city}
                  propertyLocation={transaction.property_location}
                  propertyImages={transaction.property_images}
                  transactionStatus={transaction.transaction_status}
                  modeOfPayment={transaction.mode_of_payment}
                  firstName={transaction.first_name}
                  userId={transaction.user_id}
                  profilePic={transaction.profile_pic}
                  active={transaction.active}
                  image={transaction.property_images[0]?.Key}
                  isSearchActive={isSearchActive} 
                />
              ))}
            </div>
          )}
          


         






                

             
            </div>


    





            <div className="pagination-container">
<ReactPaginate
              previousLabel={<MdOutlineKeyboardArrowLeft/>}
              nextLabel={<MdOutlineKeyboardArrowRight />}
              breakLabel={'...'}
              breakClassName={'pagination-break'}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={toTalPages}
              pageClassName={`pagination-button`}
              onPageChange={handlePageChange}
              forcePage={currentPage-1}
              containerClassName={'pagination flex justify-end items-center gap-1.5'} 
              activeClassName={'pagination-active border border-pink text-black  rounded'} 
              previousClassName={`pagination-prev ${
                currentPage === 1 ? 'pageinateDisable ' : ''
              }`} 
              nextClassName={'pagination-prev '} 
            />




        </div>









            
          </div>
        </div>
    </AdminContainer>
  )
}
