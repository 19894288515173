import React, { useState, useEffect, useRef } from "react";

import { FaSalesforce } from "react-icons/fa";
import { getPropertyDetails } from "../../../Api/PropertyAddApi";
import { ApiBaseUrl } from "../../../Utils/urls";
import SuccessAlert from "../../Alert/SuccessAlert";
import ErrorAlert from "../../Alert/ErrorAlert";
import FloatingLoader from "../../Loader/FloatingLoader";
import EditTenantCard from "../Card/EditTenantCard";
import { useParams } from "react-router-dom";
import CustomButton from "../../Common/CustomeButton";

export default function EditTenant(props) {
  const [propertyinfo, setPropertyInfo] = useState({});
  const [tenantCards, setTenantCards] = useState(
    propertyinfo?.tenants ? propertyinfo?.tenants.length : 0
  );
  const [tenants, setTenants] = useState([]);

  const [isLoading, setLoading] = useState(false);
  const [isTenantLoading, setTenatLoading] = useState(false);
  const [istenantAdded, setTenanatAdded] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [isTenantErr, setTenantErr] = useState(false);
  const [propRes, setPropResp] = useState({});

  const containerRef = useRef(null);
  const lastCardRef = useRef(null);
  const [editingIndex, setEditingIndex] = useState(null); //edit
  const handleEditTenant = (index) => {
    setEditingIndex(index === editingIndex ? null : index); // Toggle editing state
  };
  const accesstoken = localStorage.getItem("accesstoken");
  const adminId = localStorage.getItem("adminId");
  const { id: propId } = useParams();
  const { MintedToken } = props;
  const isAllFieldsFilled = tenants?.every(
    (tenant) =>
      tenant.tenant_name &&
      tenant.lease_period &&
      tenant.lock_in_period &&
      tenant.price_per_sqft
  );

  useEffect(() => {
    if (propId) {
      getPropertyDetails(
        setLoading,
        propId,
        adminId,
        accesstoken,
        setPropResp,
        setPropertyInfo
      );
    }
  }, []);

  useEffect(() => {
    setTenantCards(tenants.length);
  }, [tenants]);

  const handleAddTenant = () => {
    // setTenants([...tenants, {}]);
    setTenants([
      ...tenants,
      {
        tenant_name: "",
        lease_period: "",
        lock_in_period: "",
        price_per_sqft: ""
      }
    ]);
    setTenantCards(tenantCards + 1);

    if (lastCardRef.current) {
      lastCardRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start"
      });
    }
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedTenants = [...tenants];
    updatedTenants[index] = {
      ...updatedTenants[index],
      [name]: value
    };
    setTenants(updatedTenants);
  };

  const saveTenants = () => {
    const tenantsData = tenants.map((tenant) => ({
      tenant_id: tenant.tenant_id || null,
      tenant_name: tenant.tenant_name,
      lock_in_period: parseFloat(tenant.lock_in_period),
      lease_period: parseFloat(tenant.lease_period),
      price_per_sqft: parseFloat(tenant.price_per_sqft)
    }));

    const requestBody = {
      admin_id: adminId,
      property_id: Number(propId),
      tenants: tenantsData
    };
    setTenatLoading(true);
    fetch(`${ApiBaseUrl}/fr-admin/add-property-tenants`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: accesstoken
      },
      body: JSON.stringify(requestBody)
    })
      .then((response) => response.json())
      .then((data) => {
        setTenatLoading(false);
        if (data.success) {
          setTenanatAdded(true);
          setAlertMsg(data.data);
          getPropertyDetails(
            setLoading,
            propId,
            adminId,
            accesstoken,
            setPropResp,
            setPropertyInfo
          );
          setTimeout(() => {
            setTenanatAdded(false);
            setAlertMsg("");
          }, 3000);
        } else if (!data.success) {
          setTenantErr(true);
          setAlertMsg(data.data);
          setTimeout(() => {
            setTenantErr(false);
            setAlertMsg("");
          }, 3000);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (propertyinfo.tenants?.length > 0) {
      setTenants(propertyinfo.tenants);
    } else {
      setTenants([
        {
          tenant_name: "",
          lease_period: "",
          lock_in_period: "",
          price_per_sqft: ""
        }
      ]);
    }
  }, [propertyinfo.tenants]);

  const handleDeleteTenant = (index) => {
    const updatedTenants = tenants.filter((_, i) => i !== index);
    setTenants(updatedTenants);
  };

  return (
    <>
      {istenantAdded && <SuccessAlert message={alertMsg} />}

      {isTenantErr && <ErrorAlert message={alertMsg} />}
      <div className="flex justify-between items-center">
        <p className="text-lg font-medium text-black">
          Tenant Overview
          <span style={{ color: "#bc2222" }}>*</span>
        </p>
        <div className="flex gap-5 items-center">
          <button
            className="propSaveBtn bg-pink text-white"
            onClick={handleAddTenant}
          >
            Add Tenant
          </button>

          <CustomButton
            onClick={saveTenants}
            isDisabled={MintedToken > 0 || !isAllFieldsFilled}
            buttonText={"Save Details"}
            disabledMessage="Editing is no longer available. The token has already been minted"
            className="propSaveBtn"
            position="top"
            MintedToken={MintedToken}
          />
        </div>
      </div>

      <div
        ref={containerRef}
        className="flex gap-4 overflow-x-auto relative"
        style={{ whiteSpace: "nowrap" }}
      >
        {/* Render TenantCard components for existing tenants */}

        {isTenantLoading && <FloatingLoader />}
        {tenants &&
          tenants.map((tenant, index) => (
            <div
              key={index}
              ref={index === tenants.length - 1 ? lastCardRef : null}
            >
              <EditTenantCard
                {...tenant}
                index={index}
                handleInputChange={handleInputChange}
                handleDeleteTenant={handleDeleteTenant}
                handleEditTenant={() => handleEditTenant(index)} // Pass the handleEditTenant function
                isEditing={index === editingIndex}
                // cardRef={index === tenants.length - 1 ? lastCardRef : null}
              />
            </div>
          ))}
      </div>
    </>
  );
}
