import React, { createContext, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
const UserTransactionFilterContext = createContext();
export const useUserTransactionFilters = () => useContext(UserTransactionFilterContext);

const currentDate = new Date();
const sixtyDaysAgo = new Date(currentDate);
sixtyDaysAgo.setDate(sixtyDaysAgo.getDate() - 60);
sixtyDaysAgo.setHours(sixtyDaysAgo.getHours() + 5);
sixtyDaysAgo.setMinutes(sixtyDaysAgo.getMinutes() + 30);
const fromDateString = sixtyDaysAgo.toISOString().slice(0, 19).replace('T', ' ');

currentDate.setHours(currentDate.getHours() + 5); 
currentDate.setMinutes(currentDate.getMinutes() + 30); 
const toDateString = currentDate.toISOString().slice(0, 19).replace('T', ' ');




export const initialUserTransactionFilter = {
  "user_id":"7b433711",
    "filtersByString": {
      "sale_type": null //["Public Sale","Resale","Presale"]
    },
    "filterByStatus": [], //["COMPLETED","PENDING","CANCEL"]
    "purchased_date": {
        "from": fromDateString,
        "to": toDateString
      },
    "filtersByPlaces": [], //[{"city_id": 6, "locations": []}],
    "filtersByBuilders": [], //[8],
    "filtersByPrice": {
      "token_price": {"from": "100000", "to": "200000"}
  }
  };
  
export const UserTransactionFiltersProvider = ({ children }) => {
  const [UserTransactionFilters, setUserTransactionFilters] = useState(initialUserTransactionFilter);

  return (
    <UserTransactionFilterContext.Provider value={{ UserTransactionFilters, setUserTransactionFilters }}>
      {children}
    </UserTransactionFilterContext.Provider>
  );
};



