import React from 'react'
import PopUp from '../PopUp/PopUp'
import FloatingLoader from '../Loader/FloatingLoader'
import { IoMdClose } from 'react-icons/io'
import { PropertImageBaseUrl } from '../../Utils/urls'

export default function EditCityPopUp(
   {
    isCityEditLoading,
    isEditSaveBtnEnabled,
    handleEditCity,
    handleCloseEditCityPopUp,
    selectedCityLabel,
    handleCityIconChange,
    cityIcon,
    selectedCityIconKey,
    cityIconError,
    cityName,
    setCityName,
    cityLocationData,
    selectedCityId,
    fetchedcityIcon
}
) 

{
  
  return (
   <PopUp  maxWidth={'max-w-xl'}>
   {
      isCityEditLoading && (
   <FloatingLoader/>
      )
   }
   
            
   <div className='flex flex-col gap-4'>
   <div className='flex justify-between'>
         <p className='text-blue text-lg font-bold' >Edit City*</p>
   
         <div className='flex gap-4 items-center'>
            <button 
           className={`${isEditSaveBtnEnabled?' text-white bg-pink':'text-gray bg-lightgray'} rounded-lg text-xs px-2 py-1`}
           disabled={!isEditSaveBtnEnabled}
             onClick={handleEditCity}
             > 
             Save Details
             </button>
            <IoMdClose className='text-2xl cursor-pointer' onClick={handleCloseEditCityPopUp} />
         </div>
   
      </div>
      <hr className='prophrClass' />
   
      <div className='flex gap-5'>
                 <div className='flex flex-col gap-2.5 items-start popUpinput'>
                     <p className='text-sm text-blue'>City <span style={{color:"#af2525"}}>*</span> </p>
                     {
              !selectedCityLabel ?
                (
                  selectedCityId && cityLocationData && (
                    <p className='text-blue font-bold'>{cityLocationData.find(city => city.city_id === parseInt(selectedCityId)).city_name}</p>
                  )
                ) :
                <p className='text-blue font-bold'>{selectedCityLabel}</p>
            }
                   
                    
                 </div>
   
                 <div className='flex flex-col gap-2.5 items-start '>
                 <p className='text-sm text-blue'>City Icon <span style={{color:"#af2525"}}>*</span> </p>
                 <div className="CityLogoSec">
                    <label className="cityLogoLabel" for="file">
                       <span className="material-symbols-outlined"> publish</span>
                    </label>
                 <input id="file" type="file" onChange={handleCityIconChange}/>
                 <img src={``} alt="City"  className='cityImg' />
   
   
                  {
                     cityIcon && cityIcon.base64 && (
                        <img className="profile-img" src={cityIcon.base64} alt="Avatar" />
                     )
                 }
                 {
                  selectedCityIconKey && (
                     <img className="profile-img" src={`${PropertImageBaseUrl}/${selectedCityIconKey}`} alt="Avatar" />
                  )
                 }
                {
  !selectedCityIconKey &&
  fetchedcityIcon &&
  fetchedcityIcon.Key &&
  (
    <img
      className="profile-img"
      src={`${PropertImageBaseUrl}/${fetchedcityIcon.Key}`}
      alt="Avatar"
    />
  )
}

         
                 </div>
   
   {cityIconError && <p className='text-xs text-red'>{cityIconError}</p> }
                  </div>
       
                
       
       
             </div>
   
   
             <div className='flex gap-5'>
                 <div className='flex flex-col gap-2.5 items-start popUpinput'>
                 <p className='text-sm text-blue'>New City  <span style={{color:"#af2525"}}>*</span> </p>
                 
                   
                    
                     <input type="text" 
                     name="" 
                     value={cityName}
                     onChange={(e) => setCityName(e.target.value)}
                     className='propInputText'
                     placeholder='Brookefield Mall'
       
                      />
                 </div>
   
               
                
       
       
             </div>
   
   
            
   </div>
   
   
     
   </PopUp>
  )
}
